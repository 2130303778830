import React, { useState, useEffect } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import moment from 'moment';
import { auth, database, storage } from '../../config/firebase';

function Navigation() {
  const [email, setEmail] = useState("");
    const [adminEmail, setAdminEmail] = useState("");
    const [adminId, setAdminId] = useState('');
     const [superAdminId, setSuperAdminId] = useState('');
     const [pharmacyCount, setPharmacyCount] = useState("");
     const [laboratoryCount, setLaboratoryCount,] = useState("");
    let history = useHistory();
    useEffect(() => {       
        const getUserData = async () => {
           await auth.onAuthStateChanged((user) => {
           if (user) {
            setAdminId(auth.currentUser.uid);
             setEmail(auth.currentUser.email);
            database.ref('admin/'+auth.currentUser.uid+'/').once('value', snap=>{
setSuperAdminId(snap.val().assignedByUid);
setPharmacyCount(snap.val().pharmacyCount); 
setLaboratoryCount(snap.val().laboratoryCount); 
            })
             }
             else {
             }
          });  
        }
          getUserData(); 
       },[]);
    

let signOut = e => { 
  auth.signOut().then(()=>{
    window.location.href = '/';
  })
} 


return (
  <header id="header" style={{fontFamily: 'poppins medium'}}>

<nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top" style={{boxShadow:'2px 2px 7px rgba(0, 0, 0, 0.3)'}}>
        <div className="LandingPage_Patients_Login_jengu_logo">
                <a href="/"><img src={ require('./img/Case_hospital.png') } style={{width:'170px'}}/></a>
              </div>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{justifyContent:'flex-end', marginBottom:'.3rem'}}>
            <ul className="navbar-nav">


  <li className="nav-item active mx-2">             
  <Link to={"/viewDepartments"}> <a className="nav-link"  style={{fontWeight:'700', fontFamily:'poppins regular', color:'#11772e', display:'inline-flex'}}>
             Departments</a></Link>
  </li>

  {pharmacyCount == 0?
  null
  :
  <li className="nav-item active mx-2">             
  <Link to={"/viewPharmacy"}> <a className="nav-link"  style={{fontWeight:'700', fontFamily:'poppins regular', color:'#11772e', display:'inline-flex'}}>
             Pharmacy</a></Link>
  </li>
}

{laboratoryCount == 0?
  null
  :
  <li className="nav-item active mx-2">             
  <Link to={"/viewLaboratory"}> <a className="nav-link"  style={{fontWeight:'700', fontFamily:'poppins regular', color:'#11772e', display:'inline-flex'}}>
             Laboratory</a></Link>
  </li>
}


<li className="nav-item mx-2"><Link to={"/paymentDashboard"}><a className="nav-link" style={{fontWeight:'700', fontFamily:'poppins regular', color:'#11772e', display:'inline-flex'}}>
             Payment Dashboard</a></Link>
               </li>

               <li className="nav-item mx-2">   <Link to={"/profileView"}><a className="nav-link" style={{fontWeight:'700', fontFamily:'poppins regular', color:'#11772e', display:'inline-flex'}}>Profile</a></Link>
             </li>

             <li className="nav-item" data-toggle="collapse" data-target="#navbarNav1">
             <button
  style={{border:'2px solid #105c25', padding:'5px 15px',color:'white',float:'left',
  backgroundImage: 'linear-gradient(to right, #11772e 10%, #105c25)', borderRadius:'5px',fontFamily:'poppins regular', cursor:'pointer',boxShadow:'2px 2px 7px rgba(22, 153, 59, 0.9)'}}
 onClick={signOut}>Sign Out</button>
             </li>

            </ul>
          </div>
        </nav>

  </header>
    );

}
export default Navigation;
