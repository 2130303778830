import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { auth, database} from '../../config/firebase';
import {secondaryDatabase} from '../../config/firebaseSecondary';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Navigation from '../navigation/navigation';

function TimeSlots() {

  //const [morningStart, setMorningStart] = useState('');
  const [morningStart, setMorningStart] = useState('08:00');
  const [morningEnd, setMorningEnd] = useState('11:00');

  const [afterNoonStart, setAfterNoonStart] = useState('03:00 PM');
  const [afterNoonEnd, setAfterNoonEnd] = useState('05:00 PM');

  const [eveningStart, setEveningStart] = useState('06:30 PM');
  const [eveningEnd, setEveningEnd] = useState('08:00 PM');

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectEndDate, setSelectEndDate] = useState(null);
  const [timeSlot, setTimeSlot] = useState("");

  const [monStartTime, setMonStartTime] = useState('');
  const [monEndTime, setMonEndTime] = useState('');

  const [tueStartTime, setTueStartTime] = useState(new Date());
  const [tueEndTime, setTueEndTime] = useState(new Date());

  const [wedStartTime, setWedStartTime] = useState(new Date());
  const [wedEndTime, setWedEndTime] = useState(new Date());

  const [thuStartTime, setThuStartTime] = useState(new Date());
  const [thuEndTime, setThuEndTime] = useState(new Date());

  const [friStartTime, setFriStartTime] = useState(new Date());
  const [friEndTime, setFriEndTime] = useState(new Date());

  const [satStartTime, setSatStartTime] = useState(new Date());
  const [satEndTime, setSatEndTime] = useState(new Date());

  const [sunStartTime, setSunStartTime] = useState(new Date());
  const [sunEndTime, setSunEndTime] = useState(new Date());

  const [email, setEmail] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
    const [adminName, setAdminName] = useState("");
     const [adminId, setAdminId] = useState('');
    const [alertMessage, setAlertMessage] = useState(false);
    const [progress, setProgress] = useState("");

    const [doctorName, setDoctorName] = useState("");


    const [previousEndDate, setpreviousEndDate] = useState("");
    const [dateArray, setdateArray] = useState("");
    const locationPass = useLocation();
    let history = useHistory();
    useEffect(() => {     

        const getUserData = async () => {
           await auth.onAuthStateChanged((user) => {
           if (user) {
             setAdminId(auth.currentUser.uid);
             setEmail(auth.currentUser.email);
             console.log(auth.currentUser.uid);
             console.log(auth.currentUser.email);
            
            let userId = user.uid;
            database.ref('admin/'+auth.currentUser.uid+'/').once('value', snap=>{ 
                setAdminName(snap.val().userName); 
            }).then(()=>{
                if(email == adminEmail){
//window.location.href = '/dashboard';

secondaryDatabase.ref().child('doctorsNew/'+('62nvmLXx1jOJvB247tGrT2pRsVf1')+'/appointmentDetailsNew/inPersonConsultation').orderByKey().limitToLast(1).once('value', snap => {
  if(snap.exists()) {
    snap.forEach(child => {
        let previousEndDate = moment(child.key, "YYYYMMDD");
        let previousEndDatesss = moment(previousEndDate, "DD-MM-YYYY");
        let previousEndDates = moment(previousEndDatesss, "DD-MM-YYYY").add('days', 1);
        setpreviousEndDate(previousEndDates);
       // setDoctorName(snap.val().doctorName);
    });
  }else{
    let previous = moment();
    setpreviousEndDate(previous);
   // setDoctorName(snap.val().doctorName);
  }
});

secondaryDatabase.ref('doctorsNew/'
+('62nvmLXx1jOJvB247tGrT2pRsVf1'))
.once('value', snap=>{
setDoctorName(snap.val().doctorName);


});

                }
                else{
                    setAlertMessage(true);
                }
            })

            
             }
             else {
               window.location.href = '/';
             }
          });  
        }

      
          getUserData(); 
   
       },[]);
    
       let handleEndDate = (date) => {

        let enddateselect = moment(date);
        let enddateselects = enddateselect.format("DD-MM-YYYY");
            setEndDate(date);
            setSelectEndDate(enddateselects);
      
            function getDateArrayFull(start, end) {
                let arrayFull = new Array();
                let dt = new Date(start);
                while (dt <= end) {
                  let values = moment(new Date(dt)).format("DD-MM-YYYY");
                  arrayFull.push(new Date(dt));
                  dt.setDate(dt.getDate() + 1);
                }
                return arrayFull;
            }
      
            let dateArray = getDateArrayFull(startDate, date);
            setdateArray(dateArray);
      }       

      let submit = e => {
        e.preventDefault();
        console.log(startDate);
var date3 = moment(startDate).format('DD-MMM-YYYY');
var date1 = new Date(date3 + ' ' + morningStart); 
var date2 = Math.floor(new Date(date3 + ' ' + morningStart).getTime()/1000);        
console.log(date1);
console.log(date2);

        let arra = new Array();
        let weekdays = new Array();
        let weekenddays = new Array();
        arra = dateArray;
        for(let i=0; i<=arra.length; i++){
          if(i<arra.length){
            let values = moment(arra[i]).format("DD-MM-YYYY");
            console.log(values);
          //  var date1 = new Date(moment(arra[i]).format("DD-MM-YYYY") + ' ' + morningStart); 
           // var timeStampMorning = Math.floor(date1.getTime()/1000); 

            let day = arra[i].getDay();

            if(day == 0) {
           secondaryDatabase.ref('doctorsNew/'+('62nvmLXx1jOJvB247tGrT2pRsVf1')+'/appointmentDetailsNew/inPersonConsultation')
      .child('/'+values+'/morning/').update({date:values, session: 'morning',
       day:'Sunday', startTime:morningStart, endTime:morningEnd, 
      // start: Math.floor(new Date(values + ' ' + morningStart).getTime()/1000),
      timeSlot:timeSlot});

      secondaryDatabase.ref('doctorsNew/'+('62nvmLXx1jOJvB247tGrT2pRsVf1')+'/appointmentDetailsNew/inPersonConsultation')
      .child('/'+values+'/afternoon/').update({date:values, session: 'afternoon', day:'Sunday', startTime:afterNoonStart, endTime:afterNoonEnd, 
      timeSlot:timeSlot});

      secondaryDatabase.ref('doctorsNew/'+('62nvmLXx1jOJvB247tGrT2pRsVf1')+'/appointmentDetailsNew/inPersonConsultation')
      .child('/'+values+'/evening/').update({date:values, session: 'evening', day:'Sunday', startTime:eveningStart, endTime:eveningEnd, 
      timeSlot:timeSlot});
         
          }

          if(day == 1) {
            secondaryDatabase.ref('doctorsNew/'+('62nvmLXx1jOJvB247tGrT2pRsVf1')+'/appointmentDetailsNew/inPersonConsultation')
       .child('/'+values+'/morning/').update({date:values, session: 'morning', day:'Monday', startTime:morningStart, endTime:morningEnd, 
       timeSlot:timeSlot});
 
       secondaryDatabase.ref('doctorsNew/'+('62nvmLXx1jOJvB247tGrT2pRsVf1')+'/appointmentDetailsNew/inPersonConsultation')
       .child('/'+values+'/afternoon/').update({date:values, session: 'afternoon', day:'Monday', startTime:afterNoonStart, endTime:afterNoonEnd, 
       timeSlot:timeSlot});
 
       secondaryDatabase.ref('doctorsNew/'+('62nvmLXx1jOJvB247tGrT2pRsVf1')+'/appointmentDetailsNew/inPersonConsultation')
       .child('/'+values+'/evening/').update({date:values, session: 'evening', day:'Monday', startTime:eveningStart, endTime:eveningEnd, 
       timeSlot:timeSlot});
          
           }

           if(day == 2) {
            secondaryDatabase.ref('doctorsNew/'+('62nvmLXx1jOJvB247tGrT2pRsVf1')+'/appointmentDetailsNew/inPersonConsultation')
       .child('/'+values+'/morning/').update({date:values, session: 'morning', day:'Tuesday', startTime:morningStart, endTime:morningEnd, 
       timeSlot:timeSlot});
 
       secondaryDatabase.ref('doctorsNew/'+('62nvmLXx1jOJvB247tGrT2pRsVf1')+'/appointmentDetailsNew/inPersonConsultation')
       .child('/'+values+'/afternoon/').update({date:values, session: 'afternoon', day:'Tuesday', startTime:afterNoonStart, endTime:afterNoonEnd, 
       timeSlot:timeSlot});
 
       secondaryDatabase.ref('doctorsNew/'+('62nvmLXx1jOJvB247tGrT2pRsVf1')+'/appointmentDetailsNew/inPersonConsultation')
       .child('/'+values+'/evening/').update({date:values, session: 'evening', day:'Tuesday', startTime:eveningStart, endTime:eveningEnd, 
       timeSlot:timeSlot});
          
           }

           if(day == 3) {
            secondaryDatabase.ref('doctorsNew/'+('62nvmLXx1jOJvB247tGrT2pRsVf1')+'/appointmentDetailsNew/inPersonConsultation')
       .child('/'+values+'/morning/').update({date:values, session: 'morning', day:'Wednesday', startTime:morningStart, endTime:morningEnd, 
       timeSlot:timeSlot});
 
       secondaryDatabase.ref('doctorsNew/'+('62nvmLXx1jOJvB247tGrT2pRsVf1')+'/appointmentDetailsNew/inPersonConsultation')
       .child('/'+values+'/afternoon/').update({date:values, session: 'afternoon', day:'Wednesday', startTime:afterNoonStart, endTime:afterNoonEnd, 
       timeSlot:timeSlot});
 
       secondaryDatabase.ref('doctorsNew/'+('62nvmLXx1jOJvB247tGrT2pRsVf1')+'/appointmentDetailsNew/inPersonConsultation')
       .child('/'+values+'/evening/').update({date:values, session: 'evening', day:'Wednesday', startTime:eveningStart, endTime:eveningEnd, 
       timeSlot:timeSlot});
          
           }

           if(day == 4) {
            secondaryDatabase.ref('doctorsNew/'+('62nvmLXx1jOJvB247tGrT2pRsVf1')+'/appointmentDetailsNew/inPersonConsultation')
       .child('/'+values+'/morning/').update({date:values, session: 'morning', day:'Thursday', startTime:morningStart, endTime:morningEnd, 
       timeSlot:timeSlot});
 
       secondaryDatabase.ref('doctorsNew/'+('62nvmLXx1jOJvB247tGrT2pRsVf1')+'/appointmentDetailsNew/inPersonConsultation')
       .child('/'+values+'/afternoon/').update({date:values, session: 'afternoon', day:'Thursday', startTime:afterNoonStart, endTime:afterNoonEnd, 
       timeSlot:timeSlot});
 
       secondaryDatabase.ref('doctorsNew/'+('62nvmLXx1jOJvB247tGrT2pRsVf1')+'/appointmentDetailsNew/inPersonConsultation')
       .child('/'+values+'/evening/').update({date:values, session: 'evening', day:'Thursday', startTime:eveningStart, endTime:eveningEnd, 
       timeSlot:timeSlot});
          
           }

           if(day == 5) {
            secondaryDatabase.ref('doctorsNew/'+('62nvmLXx1jOJvB247tGrT2pRsVf1')+'/appointmentDetailsNew/inPersonConsultation')
       .child('/'+values+'/morning/').update({date:values, session: 'morning', day:'Friday', startTime:morningStart, endTime:morningEnd, 
       timeSlot:timeSlot});
 
       secondaryDatabase.ref('doctorsNew/'+('62nvmLXx1jOJvB247tGrT2pRsVf1')+'/appointmentDetailsNew/inPersonConsultation')
       .child('/'+values+'/afternoon/').update({date:values, session: 'afternoon', day:'Friday', startTime:afterNoonStart, endTime:afterNoonEnd, 
       timeSlot:timeSlot});
 
       secondaryDatabase.ref('doctorsNew/'+('62nvmLXx1jOJvB247tGrT2pRsVf1')+'/appointmentDetailsNew/inPersonConsultation')
       .child('/'+values+'/evening/').update({date:values, session: 'evening', day:'Friday', startTime:eveningStart, endTime:eveningEnd, 
       timeSlot:timeSlot});
          
           }

           if(day == 6) {
            secondaryDatabase.ref('doctorsNew/'+('62nvmLXx1jOJvB247tGrT2pRsVf1')+'/appointmentDetailsNew/inPersonConsultation')
       .child('/'+values+'/morning/').update({date:values, session: 'morning', day:'Saturday', startTime:morningStart, endTime:morningEnd, 
       timeSlot:timeSlot});
 
       secondaryDatabase.ref('doctorsNew/'+('62nvmLXx1jOJvB247tGrT2pRsVf1')+'/appointmentDetailsNew/inPersonConsultation')
       .child('/'+values+'/afternoon/').update({date:values, session: 'afternoon', day:'Saturday', startTime:afterNoonStart, endTime:afterNoonEnd, 
       timeSlot:timeSlot});
 
       secondaryDatabase.ref('doctorsNew/'+('62nvmLXx1jOJvB247tGrT2pRsVf1')+'/appointmentDetailsNew/inPersonConsultation')
       .child('/'+values+'/evening/').update({date:values, session: 'evening', day:'Saturday', startTime:eveningStart, endTime:eveningEnd, 
       timeSlot:timeSlot});
          
           }
           
        }
          else{

          }
        }
      }

return (
  <section>
  <Navigation />
<div className="mx-auto" style={{paddingTop: '80px'}}>

<div className="col-xs-14 col-sm-12 col-md-12 col-lg-12 mx-auto pb-4">
  <div className="p-3 col-xs-14 col-sm-12 col-md-12 col-lg-12 mx-auto">
   
      <div>
      <h4 className="text-center pt-4 pb-4">Create Time Slots</h4>

      
<h5>Doctor Name: {doctorName}</h5>
<div className="row">
          <div className="col-sm-4">
          
            <label>Start Date:</label>
            <DatePicker
            dateFormat="dd/MM/yyyy"
            className="date p-1"
            selected={startDate}
            onChange={date => setStartDate(date)}
            minDate={previousEndDate}
            placeholderText="Select a day"
            />
          
          </div>

          <div className="col-sm-4">
         
          <label>End Date:</label>
          <DatePicker
          dateFormat="dd/MM/yyyy"
          className="date p-1"
          selected={endDate}
          onChange={handleEndDate}
          minDate={previousEndDate}
          maxDate={moment(previousEndDate).add(3, "months")}
          placeholderText="Select a day"
          />
         
          </div>

          <div className="col-sm-4">
         
         <label>Time Slots:</label>
         <select id="timeSlot" name="timeSlot" className="form-control" value={timeSlot}  onChange={(event) => { setTimeSlot( event.target.value ); }} required>
         <option>Please select time slots</option>
         <option value="30">30 Minutes</option>
         <option value="60">60 Minutes</option>
         </select>
       
         </div>

          </div>

<h3>In patient consultation</h3>
        <h4>Week days</h4>
        <h4>Morning</h4> 
<input type="text" value={morningStart} />
<input type="text" value={morningEnd} readOnly />

<h4>Afternoon</h4> 
<input type="text" value={afterNoonStart} readOnly />
<input type="text" value={afterNoonEnd} readOnly />

<h4>Evening</h4> 

<input type="text" value={eveningStart} readOnly />
<input type="text" value={eveningEnd} readOnly />

  <br />
       <div align="center">
                        <button className="btn" style={{backgroundColor: '#3A4652', color: '#fff', borderRadius: '25px'}} onClick={submit}>Save</button>&emsp;
                       
                   </div>

                   </div>         

      </div>
      </div>
              
      </div>
</section>
    );

}
export default TimeSlots;
