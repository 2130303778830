import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { auth, database } from '../../config/firebase';
import './SignIn.css';

function Signin() {
    const [email, setEmail] = useState("");
    const [validates, setvalidates] = useState(false);
    const [adminEmail, setAdminEmail] = useState("");
    const [showPassword, setShowPassword] = useState(true);
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(false);
    const [alertMessage, setAlertMessage] = useState(false);
    const [errorMessageAuth, setErrorMessageAuth] = useState('');
    const [emailValidation, setEmailValidation] = useState(false);
    const [emailMessage, setEmailMessage] = useState(false);
    const [passwordMessage, setPasswordMessage] = useState(false);
    useEffect(() => { 
        const getUserData = async () => {
            await auth.onAuthStateChanged((user) => {
                console.log(auth.currentUser.uid);
            if (user) {
             let userId = auth.currentUser.uid;
             database.ref(`admin/${userId}`).once('value', snap=>{
               if(snap.exists()){
                 setAdminEmail(snap.val().emailAddress);  
             }
             else{
              setAdminEmail("");  
             }
             }).then(()=>{
                 if(email == adminEmail){
                  setAlertMessage(false);
                    window.location.href = '/profileView';
                 }
                 else{
                   //  setAlertMessage(true);
                 }
             })
           
              }
              else {
                
              }
           });  
         }
           getUserData(); 

    },[]);

    let validate = (text) => {
      console.log(text);
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ ;
      if(reg.test(text) === false)
      {
      setEmail(text);
      setvalidates(false);
      return false;
        }
      else {
        setEmail(text);
        setvalidates(true);
      }
      }

      let showPasswords = e => {
        setShowPassword(!showPassword);
      }

    let submit = e => {
      if(email == ''){
        setEmailMessage(true);
      }
      else if(validates === false){
        setEmailValidation(true);
    }
    else if(password.length == ''){
      setPasswordMessage(true);
  }
      else {
       auth.signInWithEmailAndPassword(email, password).then((user) => {
           console.log(auth.currentUser.uid);
            if (user) {
                let userId = auth.currentUser.uid;
                console.log(userId);
                database.ref(`admin/${userId}`).once('value', snap=>{
                    setAdminEmail(snap.val().emailAddress);  
                }).then(()=>{
                    if(email == adminEmail){
                      setAlertMessage(false);
                      window.location.href = '/profileView';
                    }
                    else{
                       // setAlertMessage(true);
                    }
                })
              }
              else {
                setErrorMessage(true);
              }
           }).catch(error => {
            setErrorMessageAuth(error.message);
           }); 
        }
    }


    return (
<section className="signin">

<div className="Admin_login_page">
      <div className="navbar-section">
          <div className="row">
            <div className="col-6">
              <div className="Casemedcare_logo">
                <a>
                  <img src={require('./img/Case_hospital.png')} width="170px"/></a>
              </div>
            </div>
            <div className="col-6">
              
            </div>
          </div>
      </div>
      <div className="Admin_login_page_logindetails">
      <div className="container">
        <div className="row m-0">
           <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5" style={{alignSelf:'center'}}>
            <div className="Admin_login_pageform">
              <div className="title mb-4">
                <h3 className="my-2">Welcome Admin!</h3>
                <span className="my-2">Please sign in to your account</span>
              </div>
              <div className="form">
                <div className="email my-3 d-flex">
                      <i className="fa fa-envelope-o" aria-hidden="true"></i>
                       <input value={email} type="email" placeholder="Email Address" onChange={(event) => validate(event.target.value)} required/>
                  </div>
                  <div className="password my-3 d-flex">
                      <i className="fa fa-lock" aria-hidden="true"></i> 
                      <input value={password} onChange={(event) => { setPassword( event.target.value ); }} autoCapitalize="none" type={showPassword ? "password" : "text"} placeholder="Enter your password" required/>
                      {showPassword === true?
                      <i className="Signup_country_code fa fa-eye" onClick={showPasswords}/>
            :
            <i className="Signup_country_code fa fa-eye-slash"  onClick={showPasswords}/>
                     }
                  </div>

                  {errorMessage == true?
              <div className='alert alert-danger alert-dismissible'>
              <button type='button' style={{fontFamily:'poppins medium'}} className='close' onClick={() => setErrorMessage(false)}>&times;</button>
  <strong>Warning!</strong> Email or Password is entered wrongly.
  </div>
                   :
                   null
                   }

 {alertMessage == true?
    <div className='alert alert-danger alert-dismissible'>
    <button type='button' style={{fontFamily:'poppins medium'}} className='close' onClick={() => setAlertMessage(false)}>&times;</button>
<strong>Warning!</strong> Current Email is not a admin.
</div>
                  
                   :
                   null
                   }

             {passwordMessage === true?
        
        <div className='alert alert-danger alert-dismissible'>
                <button style={{fontFamily:'poppins medium'}} type='button' className='close' onClick={() => setPasswordMessage(false)}>&times;</button>
    <strong>Warning!</strong> Please enter password.
    </div>
    :
    null
    }

{emailValidation == true?
       
       <div className='alert alert-danger alert-dismissible'>
               <button style={{fontFamily:'poppins medium'}} type='button' className='close' onClick={() => setEmailValidation(false)}>&times;</button>
   <strong>Warning!</strong> Email is badly formatted.
   </div>
   :
   null
   }
   
   {emailMessage == true?
           
       <div className='alert alert-danger alert-dismissible'>
               <button style={{fontFamily:'poppins medium'}} type='button' className='close' onClick={() => setEmailMessage(false)}>&times;</button>
   <strong>Warning!</strong> Email is empty.
   </div>
   
   :
   null
   }

{errorMessageAuth == ''?
   null    
:

<div className='alert alert-danger alert-dismissible'>
    <button style={{fontFamily:'poppins medium'}} type='button' className='close' onClick={() => setErrorMessageAuth('')}>&times;</button>
{errorMessageAuth}
</div>
}


                  <div className="checkbox">
                      <label className="remember-password">
                          <input type="checkbox"  name="remember"/>&nbsp; Remember me
                        </label>
                  </div>
                <div className="button my-2">
                  <button onClick={submit}>SignIn <i className="fa fa-long-arrow-right text-white" aria-hidden="true"></i></button>
                </div>
                <div className="forgot_password text-center pt-3">
                  <a>Forgot Password?</a>
                </div>
              </div>
            </div>
          </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 Admin_login_page_bg">
              <div className="admin_image">
                <img src={require('./img/admin-image.png')} width="90%"/>
              </div>
          </div>
        </div>
      </div>
      </div>
      <footer className="footer-section py-3">
        <div className="container">
            <div className="footer-content">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-6 col-xl-6 copy-right my-1">
                        <span>Powered by <img src={require('./img/Dr.jengu-logo.png')} width="70px"/></span>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 terms-privacy my-1">
                        <span className="terms px-1">
                            <a href="#">Terms and conditions</a>&nbsp;&nbsp;|</span>
                        <span className="privacy px-1">
                            <a href="#">Privacy Policy</a></span>
                        <span className="contact pl-3">
                        <a href="#" data-toggle="modal" data-target="#contactus">Contact Us</a></span>
                      </div>
                </div>
            </div>
        </div>
    </footer>

    <div className="modal fade" id="contactus" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel"><b>Contact Information</b></h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                Contact details 
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
    </div>
       
</section>
    );

}
export default Signin;
