import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { auth, database, storage } from '../../config/firebase';
import {secondaryDatabase} from '../../config/firebaseSecondary';
import Navigation from '../navigation/navigation';
import './viewAppointment.css';

function ViewAppointment() {
  const [email, setEmail] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [userName, setUserName] = useState("");
    const [adminEmail, setAdminEmail] = useState("");
    const [adminPassword, setAdminPassword] = useState("");
    const [adminName, setAdminName] = useState("");
    const [medicalCenterName, setAdminMedicalCenterName] = useState("");
    const [password, setPassword] = useState("");

    const [datas, setDatas] = useState([]);
    const [datas1, setDatas1] = useState([]);
    const [datas2, setDatas2] = useState([]);

    const [datasVideo, setDatasVideo] = useState([]);
    const [datas1Video, setDatas1Video] = useState([]);
    const [datas2Video, setDatas2Video] = useState([]);

    const [datasChat, setDatasChat] = useState([]);
    const [datas1Chat, setDatas1Chat] = useState([]);
    const [datas2Chat, setDatas2Chat] = useState([]);

    const [departmentDatas, setDepartmentDatas] = useState([]);


    const [department, setDepartment] = useState({});

     const [adminId, setAdminId] = useState('');
    const [departmentId, setDepartmentId] = useState("");
    const [location, setLocation] = useState("");
    const [locationId, setLocationId] = useState("");
    const [doctorImageUrl, setDoctorImageUrl] = useState("");
    const [alertMessage, setAlertMessage] = useState(false);
    const [progress, setProgress] = useState("");

    const locationPass = useLocation();
    const history = useHistory();
    
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);

    let process = (date) => {
      var parts = date.split("-");
      return new Date(parts[2], parts[1] - 1, parts[0]);
    }
    useEffect(() => {       
        const getUserData = async () => {
           await auth.onAuthStateChanged((user) => {
           if (user) {
             setAdminId(auth.currentUser.uid);
             setEmail(auth.currentUser.email);
             console.log(auth.currentUser.uid);
             console.log(auth.currentUser.email);
            
            let userId = user.uid;
            database.ref('admin/'+auth.currentUser.uid+'/').once('value', snap=>{
                setAdminEmail(snap.val().emailAddress);  
                setAdminPassword(snap.val().password); 
                setAdminName(snap.val().userName); 
                setAdminMedicalCenterName(snap.val().medicalCenterName);
            }).then(()=>{
                if(email == adminEmail){

                  let postEvening = secondaryDatabase.ref().child('doctorsNew/'+(locationPass.state.doctorIdPass)+
                  '/appointmentDetailsNew/');
                  postEvening.once('value', async snap => {
                    console.log(snap.val());
                      await snap.forEach(child => {     
                        let position = child.child('/evening/');
                        console.log(position.val());
                        position.forEach(childNew => {
                          let today = moment();
                          let todaydate = today.format("DD-MM-YYYY");
                          let indexselDatevalue = childNew.val().date;
                          const process = (date) => {
                            var parts = date.split("-");
                            return new Date(parts[2], parts[1] - 1, parts[0]);
                          }
                  
                          let returnArray = [];
                          if(process(todaydate) <= process(indexselDatevalue)) {       
                            var item = {};
                            item.key = childNew.key;
                            item.seldate = childNew.val().date;
                            item.selTime = childNew.val().startTime;
                            item.selEndTime = childNew.val().endTime;
                            item.visitingType = childNew.val().visitingType;
                            item.slotSet = childNew.val().slotSet;
                            item.session = childNew.val().session;
                            item.dayName = childNew.val().day;
                            returnArray.push(item);
                            }
                            setDatas(datas => datas.concat(returnArray));
                            setLoading(true);
                          })
                        })
                      })
         
                      let postAfternoon = secondaryDatabase.ref().child('doctorsNew/'+(locationPass.state.doctorIdPass)+
                      '/appointmentDetailsNew/');
                      postAfternoon.once('value', async snap => {
                        console.log(snap.val());
                          await snap.forEach(child => {     
                            let position = child.child('/afternoon/');
                            console.log(position.val());
                            position.forEach(childNew => {
                              let today = moment();
                              let todaydate = today.format("DD-MM-YYYY");
                              let indexselDatevalue = childNew.val().date;
                              const process = (date) => {
                                var parts = date.split("-");
                                return new Date(parts[2], parts[1] - 1, parts[0]);
                              }
                      
                              let returnArray = [];
                              if(process(todaydate) <= process(indexselDatevalue)) {       
                                var item = {};
                                item.key = childNew.key;
                                item.seldate = childNew.val().date;
                                item.selTime = childNew.val().startTime;
                                item.selEndTime = childNew.val().endTime;
                                item.visitingType = childNew.val().visitingType;
                                item.slotSet = childNew.val().slotSet;
                                item.session = childNew.val().session;
                                item.dayName = childNew.val().day;
                                returnArray.push(item);
                                }
                                setDatas(datas => datas.concat(returnArray));
                                setLoading1(true);
                              })
                            })
                          })
                  
                  let post = secondaryDatabase.ref().child('doctorsNew/'+(locationPass.state.doctorIdPass)+
         '/appointmentDetailsNew/');
         post.once('value', async snap => {
           console.log(snap.val());
             await snap.forEach(child => {     
               let position = child.child('/morning/');
               console.log(position.val());
               position.forEach(childNew => {
                 let today = moment();
                 let todaydate = today.format("DD-MM-YYYY");
                 let indexselDatevalue = childNew.val().date;
                 const process = (date) => {
                   var parts = date.split("-");
                   return new Date(parts[2], parts[1] - 1, parts[0]);
                 }
         
                 let returnArray = [];
                 if(process(todaydate) <= process(indexselDatevalue)) {       
                   var item = {};
                   item.key = childNew.key;
                   item.seldate = childNew.val().date;
                   item.selTime = childNew.val().startTime;
                   item.selEndTime = childNew.val().endTime;
                   item.visitingType = childNew.val().visitingType;
                   item.slotSet = childNew.val().slotSet;
                   item.session = childNew.val().session;
                   item.dayName = childNew.val().day;
                   returnArray.push(item);
                   }
                   setDatas(datas => datas.concat(returnArray));
                   setLoading2(true);
                 })
               })
             })

                }
                else{
                    setAlertMessage(true);
                }
            })
           
             }
             else {
              window.location.href = '/';
             }
          });  
        }

      
          getUserData(); 
   
       },[]);

     
       let reset = e => {
        e.preventDefault();
        history.push({
          pathname: 'viewAdminDoctor',
          state: {doctorIdPass: locationPass.state.doctorIdPass,
            departmentIdPass: locationPass.state.departmentIdPass,
          adminIdPass: locationPass.state.adminIdPass,
        superAdminIdPass:locationPass.state.superAdminIdPass}
      })
      }

      let orderVideoData = (data) => {
        let returnArrayVideo = [];
      
        returnArrayVideo = data.sort(function(a, b) {
          return moment(a.seldate, 'DD-MM-YYYY').isBefore(moment(b.seldate, 'DD-MM-YYYY')) ? -1: 1 ;
        });
        return returnArrayVideo;
      }
      
      let orderInpersonData = (data) => {
        let returnArrayInperson = [];
        returnArrayInperson = data.sort(function(a, b) {
        return moment(a.seldate, 'DD-MM-YYYY').isBefore(moment(b.seldate, 'DD-MM-YYYY')) ? -1: 1 ;
             });
        return returnArrayInperson;
      }
      
      let orderChatData = (data) => {
        let returnArrayChat = [];
        returnArrayChat = data.sort(function(a, b) {
        return moment(a.seldate, 'DD-MM-YYYY').isBefore(moment(b.seldate, 'DD-MM-YYYY')) ? -1: 1 ;
             });
        return returnArrayChat;
      }

return (
  <section className="viewAppointment">
  <Navigation />
  <div className="Superadmin_viewappointment" style={{marginTop: '90px'}}>
  <div className="row py-2 mb-2">
  &emsp;<i className="fa fa-arrow-circle-o-left fa-3x" style={{color: '#5CB04D', marginLeft: '10px'}}  onClick={reset}></i> 
     
  <div className="col-11 superadmin_viewappointment_title">
          <h3 className="Superadmin_doctorview_title text-center" style={{fontFamily:'poppins extrabold', color: '#11772e'}}><b>View Appointment</b></h3>          
              </div>
              </div>

      <div className="container" style={{fontFamily:'poppins Medium'}}>
      
          <div className="superadmin_viewappointment_details">
          

              <h5 className="" style={{fontFamily:'poppins semibold', fontSize:'22px', color: '#11772e'}}>In person consultation</h5>
           <div className="table-responsive" style={{border: '2px solid rgb(230, 230, 230)',borderRadius: '10px',borderCollapse: 'collapse', backgroundColor: 'rgb(255, 255, 255)'}}>
           <table className='table table-borderless mb-0'>
                <thead className="text-center" style={{backgroundColor:'#11772e', color:'white'}}>
                  <tr>
                  <th>Patient Name</th>
                  <th>Appointment Date</th>
                  <th>Appointment Time</th>
                 
                  <th>Action</th>
                </tr>
                </thead>
                {loading == true?
        <>
<tbody className='text-center'>
{orderInpersonData(datas).filter(x => x.visitingType == 'inPersonConsultation').map((person,index) =>
  <>
  {(person.slotSet).map((persons,index1) => (
    <>
    {persons.patientId == 'null'?

    null
    :
  <tr style={{fontWeight:'500', fontSize:'16px',color:'#666'}} key={index}> 
  <td key={person.id} style={{textAlign: 'center', verticalAlign: 'middle'}}>{persons.patientName}</td>
 
  <td key={person.id} style={{textAlign: 'center', verticalAlign: 'middle'}}>{person.seldate}</td>
  
  <td key={person.id} style={{textAlign: 'center', verticalAlign: 'middle'}}>{persons.slotStart} - {persons.slotEnd}</td>

  <td key={person.id} style={{textAlign: 'center', verticalAlign: 'middle'}}>
  <button className='btn btn-sm btn-danger ml-1'><i className="fa fa-trash fa-1x text-light" aria-hidden="true"></i>&nbsp;CANCEL</button>
  &emsp;<button className="btn btn-sm btn-success ml-1" /*onClick={() => rescheduleAppointment(person.id, person.selDate)}*/>
  <i className="fa fa-edit fa-1x text-light" aria-hidden="true"></i>&nbsp;RESCHEDULE
  </button>
  </td>
  <td key={person.id} style={{display: 'none'}}>Specialist: {person.selSpecialist}</td>
  </tr>
}
 </>
 ))}
  </>
)}
</tbody>
  

{datas.length == 0?
  <tbody>
<td></td>
<td></td>
<td><h6 style={{whiteSpace: 'nowrap'}}>Appointments not scheduled</h6></td>

<td></td>
</tbody>
:
null
}
</>
                    :
                    <tbody>
                    <td></td>
                    <td></td>
                    <td>
      <div className="lds-roller">
        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </td>
        <td></td>
</tbody>
      }

</table>
</div>
<br />
<h5 className="" style={{fontFamily:'poppins semibold', fontSize:'22px', color: '#11772e'}}>Video consultation</h5>

<div className="table-responsive" style={{border: '2px solid rgb(230, 230, 230)',borderRadius: '10px',borderCollapse: 'collapse', backgroundColor: 'rgb(255, 255, 255)'}}>
           <table className='table table-borderless mb-0'>
                <thead className="text-center" style={{backgroundColor:'#11772e', color:'white'}}>
                  <tr>
                  
                  <th>Patient Name</th>
                  <th>Appointment Date</th>
                  <th>Appointment Time</th>
                 
                  <th>Action</th>
                </tr>
                </thead>
  
                {loading1 == true?
        <>            
<tbody className='text-center'>
{orderVideoData(datas).filter(x => x.visitingType == 'videoConsultation').map((person,index) =>
  <>
  {(person.slotSet).map((persons,index1) => (
    <>
    {persons.patientId == 'null'?

    null
    :
  <tr style={{fontWeight:'500', fontSize:'16px',color:'#666'}} key={index}> 
  <td key={person.id} style={{textAlign: 'center', verticalAlign: 'middle'}}>{persons.patientName}</td>
 
  <td key={person.id} style={{textAlign: 'center', verticalAlign: 'middle'}}>{person.seldate}</td>
  
  <td key={person.id} style={{textAlign: 'center', verticalAlign: 'middle'}}>{persons.slotStart} - {persons.slotEnd}</td>

  <td key={person.id} style={{textAlign: 'center', verticalAlign: 'middle'}}>
  <button className='btn btn-sm btn-danger ml-1'><i className="fa fa-trash fa-1x text-light" aria-hidden="true"></i>&nbsp;CANCEL</button>
  &emsp;<button className="btn btn-sm btn-success ml-1" /*onClick={() => rescheduleAppointment(person.id, person.selDate)}*/>
  <i className="fa fa-edit fa-1x text-light" aria-hidden="true"></i>&nbsp;RESCHEDULE
  </button>
  </td>
  <td key={person.id} style={{display: 'none'}}>Specialist: {person.selSpecialist}</td>
  </tr>

}
 </>
 ))}
  </>
)}
</tbody>

{datas == 0 ?
  <tbody>
<td></td>
<td></td>
<td><h6 style={{whiteSpace: 'nowrap'}}>Appointments not scheduled</h6></td>

<td></td>
</tbody>
:
null
}
</>
                    :
                    <tbody>
                    <td></td>
                    <td></td>
                    <td>
      <div className="lds-roller">
        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </td>
        <td></td>
</tbody>
      }

</table>
</div>

<br />
<h5 className="" style={{fontFamily:'poppins semibold', fontSize:'22px', color: '#11772e'}}>Live chat consultation</h5>

<div className="table-responsive" style={{border: '2px solid rgb(230, 230, 230)',borderRadius: '10px',borderCollapse: 'collapse', backgroundColor: 'rgb(255, 255, 255)'}}>
           <table className='table table-borderless mb-0'>
                <thead className="text-center" style={{backgroundColor:'#11772e', color:'white'}}>
                  <tr>
                  
                  <th>Patient Name</th>
                  <th>Appointment Date</th>
                  <th>Appointment Time</th>   
                  <th>Action</th>
                </tr>
                </thead>
                {loading2 == true?
        <>  
                {orderChatData(datas).filter(x => x.visitingType == 'liveChatConsultation')?
<tbody className='text-center'>
{orderChatData(datas).filter(x => x.visitingType == 'liveChatConsultation').map((person,index) =>
  <>
  {(person.slotSet).map((persons,index1) => (
    <>
    {persons.patientId == 'null'?

    null
    :
  <tr style={{fontWeight:'500', fontSize:'16px',color:'#666'}} key={index}> 
  <td key={person.id} style={{textAlign: 'center', verticalAlign: 'middle'}}>{persons.patientName}</td>
 
  <td key={person.id} style={{textAlign: 'center', verticalAlign: 'middle'}}>{person.seldate}</td>
  
  <td key={person.id} style={{textAlign: 'center', verticalAlign: 'middle'}}>{persons.slotStart} - {persons.slotEnd}</td>

  <td key={person.id} style={{textAlign: 'center', verticalAlign: 'middle'}}>
  <button className='btn btn-sm btn-danger ml-1'><i className="fa fa-trash fa-1x text-light" aria-hidden="true"></i>&nbsp;CANCEL</button>
  &emsp;<button className="btn btn-sm btn-success ml-1" /*onClick={() => rescheduleAppointment(person.id, person.selDate)}*/>
  <i className="fa fa-edit fa-1x text-light" aria-hidden="true"></i>&nbsp;RESCHEDULE
  </button>
  </td>
  <td key={person.id} style={{display: 'none'}}>Specialist: {person.selSpecialist}</td>
  </tr>

}
 </>
 ))}
  </>
)}
</tbody>
:
<tbody>
  <td>No</td>
</tbody>
}

{datas.length == 0 ?
  <tbody>
<td></td>
<td></td>
<td><h6 style={{whiteSpace: 'nowrap'}}>Appointments not scheduled</h6></td>

<td></td>
</tbody>
:
null
}
</>
                    :
                    <tbody>
                    <td></td>
                    <td></td>
                    <td>
      <div className="lds-roller">
        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </td>
        <td></td>
</tbody>
      }

</table>
</div>

<br />

      </div>
    </div>
    </div>                
      
</section>
    );

}
export default ViewAppointment;
