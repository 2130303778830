import React, { useState, useEffect } from 'react';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { auth, database, storage } from '../../config/firebase';
import {secondaryDatabase} from '../../config/firebaseSecondary';
import Navigation from '../navigation/navigation';
import './appointmentSummary.css';

function AppointmentSummary() {
  const [email, setEmail] = useState("");
  const [adminId, setAdminId] = useState('');
    const [adminEmail, setAdminEmail] = useState("");
    const [adminPassword, setAdminPassword] = useState("");
    const [adminName, setAdminName] = useState("");
    const [adminMedicalCenterName, setAdminMedicalCenterName] = useState("");
    const [datas, setDatas] = useState([]);
    const [loading, setLoading] = useState(false);

    const [departmentDatas, setDepartmentDatas] = useState("");
    const [address, setAddress] = useState("");

    const [date1, setDate1] = useState(moment().format("DD-MM-YYYY"));
    let todayDate = moment().format("DD-MM-YYYY");
    let todayWeek = moment(todayDate, "DD-MM-YYYY").isoWeek();
    let todayMonth = moment(todayDate, "DD-MM-YYYY").month();
    let todayYear = moment(todayDate, "DD-MM-YYYY").year();
    let weekNumber = ("" + todayYear + todayWeek);
    let monthNumber = ("" + todayYear + todayMonth);

    const [dateWeek, setDateWeek] = useState(weekNumber);
    const [dateMonth, setDateMonth] = useState(monthNumber);
    const [dateYear, setDateYear] = useState(moment(todayDate, "DD-MM-YYYY").year());

    const locationPass = useLocation();
    let history = useHistory();
    useEffect(() => {       
        const getUserData = async () => {
           await auth.onAuthStateChanged((user) => {
           if (user) {
             setAdminId(auth.currentUser.uid);
             setEmail(auth.currentUser.email);
             console.log(auth.currentUser.uid);
             console.log(auth.currentUser.email);
            
            let userId = user.uid;
            database.ref('admin/'+auth.currentUser.uid+'/').once('value', snap=>{
                setAdminEmail(snap.val().emailAddress);  
                setAdminPassword(snap.val().password); 
                setAdminName(snap.val().userName); 
                setAdminMedicalCenterName(snap.val().medicalCenterName);
                setDepartmentDatas(snap.val().departments || '');
               
                setAddress(snap.val().address);
            }).then(()=>{
                if(email == adminEmail){
//window.location.href = '/dashboard';
                }
                else{
                  window.location.href = '/';
                }
            })

            secondaryDatabase.ref().child('doctorsNew').orderByChild('adminUid').equalTo(locationPass.state.adminIdPass).on('value',function(snap) {
              let returnArray =[];
                snap.forEach(function(item) {
                
                  returnArray.push(item.val());
 console.log(returnArray);
 
                });
                 
            setDatas(returnArray); 
            setLoading(true);
            });

            
             }
             else {
               window.location.href = '/';
             }
          });  
        }

      
          getUserData(); 
   
       },[]);


  return (
    <div className="SuperAdmin_App_summary">
         <Navigation />
          <div className="SuperAdmin_App_summary_section mx-auto" style={{marginTop: '90px'}}>
            <div className="row SuperAdmin_App_summary_form container-fluid mx-auto" >
            <i className="fa fa-arrow-circle-o-left fa-3x" style={{color: '#5CB04D', marginLeft: '10px', cursor: 'pointer'}} onClick={ () => window.location.href = '/viewDepartments' }></i> 
                <div className="col-11">
                <h3 className="SuperAdmin_App_summary_title" style={{fontFamily:'poppins extrabold',display:'flex',justifyContent:'center', color:'#11772e'}}>Appointment Summary</h3>
                </div>
            </div>
          </div>
         
          <div className="SuperAdmin_App_summary_table container">
          <div className="SuperAdmin_App_summary_dept_sortby my-3">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div className="SuperAdmin_App_summary_dept-name">
                  <h5>Department :&nbsp;<span>{locationPass.state.departmentNamePass}</span></h5>
                </div>
              </div>
           {/*}   <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="SuperAdmin_App_summary_sortby">
                  <select className="" style={{padding:'5px 15px', fontFamily:'poppins medium', border:'2px solid #105c25', borderRadius:'3px'}}>
                    <option selected>Today</option>
                    <option>Last 7 Days</option>
                    <option>Last 1 Month</option>
                  </select>
                </div>
  </div>*/}
            </div>
           
          </div>
          <div className="table-responsive mb-5" style={{overflowX: 'hidden', borderRadius: '10px',border:'2px solid #ccc',borderCollapse: 'collapse', backgroundColor: 'rgb(255, 255, 255)',boxShadow:'3px 3px 10px rgba(0, 0, 0, 0.3)'}}>
           <table className='table table-borderless table-striped mb-0' style={{border:'none'}}>
                <thead className="text-center" style={{backgroundColor:'#105c25', color:'#fff',fontFamily:'poppins semibold'}}>
                  <tr style={{fontSize:'16px',fontFamily:'poppins semibold', color:'#fff'}}>
                    <th style={{border: '2px solid #fff'}} className="align-middle" rowspan="2" colspan="1">Doctors</th>
                    <th rowspan="1" colSpan="3" style={{border: '2px solid #fff'}}>Today</th>
                    <th rowspan="1" colSpan="3" style={{border: '2px solid #fff'}}>This Week</th>
                    <th rowspan="1" colSpan="3">This Month</th>
                  </tr>
                  <tr>
                    <th style={{border: '2px solid #fff'}}>In Person</th>
                    <th style={{border: '2px solid #fff'}}>Video</th>
                    <th style={{border: '2px solid #fff'}}>Live Chat</th>

                    <th style={{border: '2px solid #fff'}}>In Person</th>
                    <th style={{border: '2px solid #fff'}}>Video</th>
                    <th style={{border: '2px solid #fff'}}>Live Chat</th>

                    <th style={{border: '2px solid #fff'}}>In Person</th>
                    <th style={{border: '2px solid #fff'}}>Video</th>
                    <th style={{border: '2px solid #fff'}}>Live Chat</th>
                  </tr>
                </thead>
                <tbody className='text-center'>
                {datas.filter(x => x.departmentId == locationPass.state.departmentIdPass).map((person,index) =>
  
                  <tr style={{fontWeight:'500', fontSize:'14px',color:'#1f2123',fontFamily:'poppins Medium'}}> 
                    
                    <td className="align-middle doctor_name">
                    <h6>
                    <a style={{color:'#1f2123'}}>Dr. {person.doctorName}</a>
                    </h6>
                     
                    </td>


                    <td className="align-middle">
                      {person.appointmentCount == undefined?
<h6>0</h6>
:
<>
{Object.values(person.appointmentCount).filter(x => x.date == date1).length == 0 || undefined || null?
                        <h6>0</h6>
:
<>
{Object.values(person.appointmentCount).filter(x => x.date == date1).map((persons, index) =>
<>
  {persons.inpersonCount == undefined ?
  <h6>0</h6>
  :
 <h6>{persons.inpersonCount}</h6>
  
}</>
)}
</>
}
</>
}
                    </td>
                    <td className="align-middle">      
                    {person.appointmentCount == undefined?
<h6>0</h6>
:
<>
   {Object.values(person.appointmentCount).filter(x => x.date == date1).length == 0 || undefined || null?
                        <h6>0</h6>
:
<>
                    {Object.values(person.appointmentCount).filter(x => x.date == date1).map((persons, index) =>
<>
  {persons.videoCount == undefined ?
  <h6>0</h6>
  :
 <h6>{persons.videoCount}</h6>
  
}</>
)}
</>
}
</>
}
                    </td>
                    <td className="align-middle">
                      {person.appointmentCount == undefined?
<h6>0</h6>
:
<>
                            {Object.values(person.appointmentCount).filter(x => x.date == date1).length == 0 || undefined || null?
                        <h6>0</h6>
:
<>
                    {Object.values(person.appointmentCount).filter(x => x.date == date1).map((persons, index) =>
<>
  {persons.chatCount == undefined ?
  <h6>0</h6>
  :
 <h6>{persons.chatCount}</h6>
  
}</>
)}
</>
}
</>
}
                    </td>

                    <td className="align-middle">
                      {person.appointmentWeekCount == undefined?
<h6>0</h6>
:
<>
                            {Object.values(person.appointmentWeekCount).filter(x => x.id == dateWeek).length == 0 || undefined || null?
                        <h6>0</h6>
:
<>
                    {Object.values(person.appointmentWeekCount).filter(x => x.id == dateWeek).map((persons, index) =>
<>
  {persons.inpersonWeekCount == undefined ?
  <h6>0</h6>
  :
 <h6>{persons.inpersonWeekCount}</h6>
  
}</>
)}
</>
}
</>
}
                    </td>

                    <td className="align-middle">
                      {person.appointmentWeekCount == undefined?
<h6>0</h6>
:
<>
                            {Object.values(person.appointmentWeekCount).filter(x => x.id == dateWeek).length == 0 || undefined || null?
                        <h6>0</h6>
:
<>
                    {Object.values(person.appointmentWeekCount).filter(x => x.id == dateWeek).map((persons, index) =>
<>
  {persons.videoWeekCount == undefined ?
  <h6>0</h6>
  :
 <h6>{persons.videoWeekCount}</h6>
  
}</>
)}
</>
}
</>
}
                    </td>

                    <td className="align-middle">
                      {person.appointmentWeekCount == undefined?
<h6>0</h6>
:
<>
                            {Object.values(person.appointmentWeekCount).filter(x => x.id == dateWeek).length == 0 || undefined || null?
                        <h6>0</h6>
:
<>
                    {Object.values(person.appointmentWeekCount).filter(x => x.id == dateWeek).map((persons, index) =>
<>
  {persons.chatWeekCount == undefined ?
  <h6>0</h6>
  :
 <h6>{persons.chatWeekCount}</h6>
  
}</>
)}
</>
}
</>
}
                    </td>

                    <td className="align-middle">
                      {person.appointmentMonthCount == undefined?
<h6>0</h6>
:
<>
                            {Object.values(person.appointmentMonthCount).filter(x => x.id == dateMonth).length == 0 || undefined || null?
                        <h6>0</h6>
:
<>
                    {Object.values(person.appointmentMonthCount).filter(x => x.id == dateMonth).map((persons, index) =>
<>
  {persons.inpersonMonthCount == undefined ?
  <h6>0</h6>
  :
 <h6>{persons.inpersonMonthCount}</h6>
  
}</>
)}
</>
}
</>
}
                    </td>

                    <td className="align-middle">
                      {person.appointmentMonthCount == undefined?
<h6>0</h6>
:
<>
                            {Object.values(person.appointmentMonthCount).filter(x => x.id == dateMonth).length == 0 || undefined || null?
                        <h6>0</h6>
:
<>
                    {Object.values(person.appointmentMonthCount).filter(x => x.id == dateMonth).map((persons, index) =>
<>
  {persons.videoMonthCount == undefined ?
  <h6>0</h6>
  :
 <h6>{persons.videoMonthCount}</h6>
  
}</>
)}
</>
}
</>
}
                    </td>

                    <td className="align-middle">
                      {person.appointmentMonthCount == undefined?
<h6>0</h6>
:
<>
                            {Object.values(person.appointmentMonthCount).filter(x => x.id == dateMonth).length == 0 || undefined || null?
                        <h6>0</h6>
:
<>
                    {Object.values(person.appointmentMonthCount).filter(x => x.id == dateMonth).map((persons, index) =>
<>
  {persons.chatMonthCount == undefined ?
  <h6>0</h6>
  :
 <h6>{persons.chatMonthCount}</h6>
  
}</>
)}
</>
}
</>
}
                    </td>

                  </tr>
                )}
</tbody>
              </table>
           </div>
          </div>
      </div>
  );
}

export default AppointmentSummary;
