import React, { useState, useEffect } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import moment from 'moment';
import { auth, database, storage } from '../../config/firebase';
import Navigation from '../navigation/navigation';
import './profileCreate.css'

function EditAdmin() {
  const [email, setEmail] = useState("");
    const [adminEmail, setAdminEmail] = useState("");
    const [adminPhoneNumber, setAdminPhoneNumber] = useState("");
    const [adminName, setAdminName] = useState("");
    const [adminMedicalCenterName, setAdminMedicalCenterName] = useState("");

    const [departmentDatas, setDepartmentDatas] = useState("");

    const [adminId, setAdminId] = useState('');

     const [superAdminId, setSuperAdminId] = useState('');

    const [location, setLocation] = useState("");
    const [alertMessage, setAlertMessage] = useState(false);
    const [progress, setProgress] = useState(0);
    const [adminImageUrl, setAdminImageUrl] = useState("");

    const [address, setAddress] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [addressOne, setAddressOne] = useState("");

    const [addressMessage, setAddressMessage] = useState(false);
    const [stateMessage, setStateMessage] = useState(false);
    const [countryMessage, setCountryMessage] = useState(false);
    const [pinCodeMessage, setPinCodeMessage] = useState(false);
    const [phoneNumberMessage, setPhoneNumberMessage] = useState(false);

    let history = useHistory();

    useEffect(() => {       
        const getUserData = async () => {
           await auth.onAuthStateChanged((user) => {
           if (user) {
            setAdminId(auth.currentUser.uid);
            setEmail(auth.currentUser.email);
            console.log(auth.currentUser.uid);
            console.log(auth.currentUser.email);
           
           let userId = user.uid;
           database.ref('admin/'+auth.currentUser.uid+'/').once('value', snap=>{
               setAdminEmail(snap.val().emailAddress);  
               setAdminPhoneNumber(snap.val().mobileNumber); 
               setAdminName(snap.val().userName); 
               setAdminMedicalCenterName(snap.val().medicalCenterName);
               setLocation(snap.val().branchPlace);
               setDepartmentDatas(snap.val().departments || '');
               setAdminImageUrl(snap.val().adminImageUrl || '');
               setSuperAdminId(snap.val().assignedByUid);
               setAddress(snap.val().address || '');
               setAddressOne(snap.val().addressOne || '');
               setState(snap.val().state || '');
               setCountry(snap.val().country || '');
               setPinCode(snap.val().pinCode || '');
           }).then(()=>{
               if(email == adminEmail){
//window.location.href = '/dashboard';
                }
                else{
                    setAlertMessage(true);
                }
            })
            
             }
             else {
               window.location.href = '/';
             }
          });  
        }

      
          getUserData(); 
   
       },[]);
    

let handleImageChange = (e) => {
                e.preventDefault();
            
                let reader = new FileReader();
                let file = e.target.files[0];
            
                reader.onloadend = () => {

                  let name = moment().unix()+file.name;
                  let name1 = file.name;
                  let ext = name1.split('.').pop();
            
                  const uploadTask = storage.ref(`admin/${name}`).put(file);
            
                  uploadTask.on('state_changed', (snapshot) => {
                    // progrss function ....
                    const progresses = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgress(progresses);
                  },
                  (error) => {
                       // error function ....
                    console.log(error);
                  },
                () => {
            
                    // complete function ....
                    storage.ref('admin').child(name).getDownloadURL().then(url => {
                        console.log(url);
                        setAdminImageUrl(url);
                    })
                });
                }
                reader.readAsDataURL(file)
                e.target.value = null;
              }
            
            

let submit = e => {
    e.preventDefault();  
    if(adminPhoneNumber.length == ''){
      setPhoneNumberMessage(true);
  }
    else if(address == ''){
      setAddressMessage(true);
    }
      else if(state == ''){
          setStateMessage(true);
      }
      else if(country == ''){
        setCountryMessage(true);
    }
      else if(pinCode.length == ''){
          setPinCodeMessage(true);
      }
    else{

    database.ref('superAdmin/'+(superAdminId)+'/admin/'+(adminId)+'/').update({
adminImageUrl: adminImageUrl,
address: address.replace(/((\s*\S+)*)\s*/, "$1"),
addressOne: addressOne.replace(/((\s*\S+)*)\s*/, "$1"),
country: country.replace(/((\s*\S+)*)\s*/, "$1"),
state: state.replace(/((\s*\S+)*)\s*/, "$1"),
pinCode: pinCode.replace(/((\s*\S+)*)\s*/, "$1"),
mobileNumber: adminPhoneNumber
})

database.ref('admin/'+(adminId)+'/').update({
  adminImageUrl: adminImageUrl,
  address: address.replace(/((\s*\S+)*)\s*/, "$1"),
  addressOne: addressOne.replace(/((\s*\S+)*)\s*/, "$1"),
  country: country.replace(/((\s*\S+)*)\s*/, "$1"),
  state: state.replace(/((\s*\S+)*)\s*/, "$1"),
  pinCode: pinCode.replace(/((\s*\S+)*)\s*/, "$1"),
  mobileNumber: adminPhoneNumber
  }).then(()=>{
  alert('Data is updated successfully'); 
  window.location.href = '/profileView';
})

}
}

let reset = e => {
    e.preventDefault();
    window.location.href = '/profileView';
}

return (
  <section style={{backgroundColor: '#ffffff', overflow: 'auto', minHeight: '100%', fontFamily: 'poppins medium'}}>
   <Navigation />
<div className="adminprofileview">
      <div className="container">
          <div className="superadminprofile_details" 
          style={{marginTop:'100px', marginBottom:'20px', backgroundColor:'white', boxShadow:' 0px 0 5px rgba(0, 0, 0, 0.3)', borderRadius:'10px',
         }}>
          <div className="superadminprofile_title" style={{backgroundColor:'#105c25', color:'white', padding:'17px',
          borderTopLeftRadius:'10px', borderTopRightRadius:'10px'}}>
            <h3 style={{fontWeight:'700', fontSize:'24px', marginLeft:'5px', marginBottom:'0px', fontFamily:'poppins semibold'}}>EDIT PROFILE</h3>          
           </div>
            <div className="row" style={{display:'flex', justifyContent:'center', padding:'30px 50px'}}>
              <div className="">
                <div className="superadminprofile_info">

                    <div className="row">
                        <div className="col-6">
                          <label style={{fontWeight:'600', fontSize:'14px', fontFamily:'poppins medium'}}>FULL NAME </label>
       
      <input type="text" className="form-control" id="adminName" style={{borderRadius:'25px', textIndent:'10px', fontFamily:'poppins regular'}}
      placeholder="Admin Name" name="adminName" value={adminName} readOnly/>
      
      </div>
      <div className="col-6">
                          <label style={{fontWeight:'600', fontSize:'14px', fontFamily:'poppins medium'}}>PHONE NUMBER </label>
       
      <input type="text" className="form-control" id="adminPhoneNumber" style={{borderRadius:'25px', textIndent:'10px', fontFamily:'poppins medium'}}
      placeholder="Phone Number" name="adminPhoneNumber" value={adminPhoneNumber}
      maxLength="11" 
      onChange={(event) => {
        if (isNaN(Number(event.target.value))) {
          return;
        } else {
          setAdminPhoneNumber( event.target.value );
        }
      }} />
      
      </div>
      </div>
      <div className="row mt-2">
                        <div className="col-6">
                        <label style={{fontWeight:'600', fontSize:'14px', fontFamily:'poppins medium'}}>EMAIL ADDRESS </label>
  
       
      <input type="text" className="form-control" id="adminEmail" style={{borderRadius:'25px', textIndent:'10px', fontFamily:'poppins medium'}}
      placeholder="Email Address" name="adminEmail" value={adminEmail} readOnly/>
      
      </div>
      <div className="col-6">
                          <label style={{fontWeight:'600', fontSize:'14px', fontFamily:'poppins medium'}}>LOCATION</label>
       
      <input type="text" className="form-control" id="location" style={{borderRadius:'25px', textIndent:'10px', fontFamily:'poppins medium'}}
      placeholder="Location" name="location" value={location} readOnly/>
      
      </div>
      </div>

      <div className="row mt-2">
                  
      <div className="col-12">
                          <label style={{fontWeight:'600', fontSize:'14px', fontFamily:'poppins medium'}}>ADDRESS</label>
       
      <input type="text" className="form-control" id="address" style={{borderRadius:'25px', textIndent:'10px', fontFamily:'poppins medium' ,marginBottom: '10px'}}
      placeholder="Address" name="address" value={address} onChange={(event) => { setAddress( event.target.value ); }} required/>
     
       <input type="text" className="form-control" id="addressOne" style={{borderRadius:'25px', textIndent:'10px', fontFamily:'poppins medium'}}
      placeholder="Address Line2" name="addressOne" value={addressOne} onChange={(event) => { setAddressOne( event.target.value ); }} required/>
    
      </div>
      </div>

      <div className="row mt-2">
                        <div className="col-6">
                        <label style={{fontWeight:'600', fontSize:'14px', fontFamily:'poppins medium'}}>STATE</label>
  
       
      <input type="text" className="form-control" id="state" style={{borderRadius:'25px', textIndent:'10px', fontFamily:'poppins medium'}}
      placeholder="State" name="state" value={state} onChange={(event) => { setState( event.target.value ); }} required/>
      
      </div>
      <div className="col-6">
                          <label style={{fontWeight:'600', fontSize:'14px', fontFamily:'poppins medium'}}>COUNTRY</label>
       
      <input type="text" className="form-control" id="country" style={{borderRadius:'25px', textIndent:'10px', fontFamily:'poppins medium'}}
      placeholder="Country" name="country" value={country} onChange={(event) => { setCountry( event.target.value ); }} required/>
      
      </div>
      </div>
      
      <div className="row mt-2">
                        <div className="col-6">
                        <label style={{fontWeight:'600', fontSize:'14px', fontFamily:'poppins medium'}}>PIN CODE</label>
  
       
      <input type="tel" maxLength="6" className="form-control" id="pinCode" style={{borderRadius:'25px', textIndent:'10px', fontFamily:'poppins medium'}}
      placeholder="Pin Code" name="pinCode" value={pinCode} 
      onChange={(event) => {
        if (isNaN(Number(event.target.value))) {
          return;
        } else {
          setPinCode( event.target.value );
        }
      }} required/>
      
      </div>
      </div>


          <div className="superadmin_createadmin_selectimage">
                  <h3 style={{fontWeight:'700', fontSize:'18px', marginTop:'15px', marginBottom:'15px',backgroundColor:'#5CB04D', padding:'10px',
                  borderRadius:'5px', fontFamily:'poppins Semibold', color: '#ffffff'
                  }}>PROFILE IMAGE</h3>  
                  </div>
      




        {adminImageUrl == ''?
       <div id='profile-upload'>
       <div className="hvr-profile-img">
       <input type="file" id="files" style={{color: 'transparent', visibility: 'hidden'}} onChange={handleImageChange} className="upload w180" accept="image/*" capture="camera" />
          </div>
       
       <label for="files" className="fa" style={{fontFamily:'poppins medium'}}><b>Upload</b></label>
         
         </div>
         :
         <div id='profile-upload1'> 
         <img src={adminImageUrl} className="avatar" 
         style={{height: '150px', width: '150px', backgroundSize: 'cover', backgroundPosition: 'center', position: 'relative', objectFit: 'contain'}} />
         
         <div className="hvr-profile-img1">
          <input type="file" id="files" style={{color: 'transparent', visibility: 'hidden'}} onChange={handleImageChange} className="upload w180" accept="image/*" capture="camera" />
          </div> <label for="files" className="fa" style={{fontFamily:'poppins medium'}}><b>Update</b></label> 
          </div> 
        }

<br />
{progress == 0?
null:
                    <div className="col-12 my-2" style={{backgroundColor: 'black', borderRadius: '13px', padding: '3px'}}>

  <div style={{width: `${(progress)}%`, color: 'white', backgroundColor: '#0269D9', height: '20px', borderRadius: '10px'}}> 
   <center>{progress}%</center></div>
</div>
  }
  
{phoneNumberMessage == true?
  
  <div className='alert alert-danger alert-dismissible'>
          <button type='button' className='close' onClick={() => setPhoneNumberMessage(false)}>&times;</button>
<strong>Warning!</strong> Please enter mobile number.
</div>

:
null
}

        {addressMessage == true?
  
    <div className='alert alert-success alert-dismissible'>
            <button type='button' className='close' style={{fontFamily:'poppins medium'}} onClick={() => setAddressMessage(false)}>&times;</button>
<strong>Warning!</strong> Please enter address.
</div>

:
null
}

{stateMessage == true?
  
  <div className='alert alert-success alert-dismissible'>
          <button type='button' style={{fontFamily:'poppins medium'}} className='close' onClick={() => setStateMessage(false)}>&times;</button>
          <strong>Warning!</strong> Please enter state.
</div>

:
null
}

{countryMessage == true?
  
  <div className='alert alert-success alert-dismissible'>
          <button type='button' style={{fontFamily:'poppins medium'}} className='close' onClick={() => setCountryMessage(false)}>&times;</button>
          <strong>Warning!</strong> Please enter country.
</div>

:
null
}

{pinCodeMessage == true?
  
  <div className='alert alert-success alert-dismissible'>
          <button type='button' style={{fontFamily:'poppins medium'}} className='close' onClick={() => setPinCodeMessage(false)}>&times;</button>
          <strong>Warning!</strong> Please enter 6 digit pin code.
</div>

:
null
}

<hr/>
                  <div className="superadmincreatedoc_savebtn" style={{textAlign:'center', marginTop:'10px'}}>
                   <button className="btn" style={{backgroundColor: '#105c25', color: '#fff', fontFamily:'poppins medium'}} onClick={submit}>Save</button>&emsp;
                   <button className="btn" style={{backgroundColor: '#3A4652', color: '#fff', fontFamily:'poppins medium'}} onClick={reset}>Cancel</button>
                       <br/><br/>
                   </div>
                   </div>
      </div>
    </div> 
    </div>            
                   
    
      </div>
      </div>
                       
      
</section>
    );

}
export default EditAdmin;
