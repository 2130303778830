import React, { useState, useEffect } from 'react';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { auth, database, storage } from '../../config/firebase';
import {secondaryDatabase} from '../../config/firebaseSecondary';
import Navigation from '../navigation/navigation';

function ViewLaboratory() {
  const [email, setEmail] = useState("");
    const [adminEmail, setAdminEmail] = useState("");
    const [adminPhoneNumber, setAdminPhoneNumber] = useState("");
    const [adminName, setAdminName] = useState("");
    const [adminMedicalCenterName, setAdminMedicalCenterName] = useState("");
     const [adminId, setAdminId] = useState('');

    const [userName, setUserName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [location, setLocation] = useState("");
    const [address, setAddress] = useState("");
    const [addressOne, setAddressOne] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [pharmacyImageUrl, setPharmacyImageUrl] = useState("");

    const [laboratoryDatas, setLaboratoryDatas] = useState([]);
    const [loading, setLoading] = useState(false);

    const locationPass = useLocation();
    let history = useHistory();
    useEffect(() => {       
        const getUserData = async () => {
           await auth.onAuthStateChanged((user) => {
           if (user) {
             setAdminId(auth.currentUser.uid);
             setEmail(auth.currentUser.email);
             console.log(auth.currentUser.uid);
             console.log(auth.currentUser.email);
            
            let userId = user.uid;
            database.ref('admin/'+auth.currentUser.uid+'/').once('value', snap=>{
                setAdminEmail(snap.val().emailAddress);  
                setAdminName(snap.val().userName); 
                setAdminMedicalCenterName(snap.val().medicalCenterName);
                console.log(snap.val().pharmacy);
                setLaboratoryDatas(snap.val().laboratory);
                setLoading(true);
                /*let laboratoryValue = snap.val().pharmacy;

                laboratoryValue.forEach(child => {

                database.ref('pharmacy/'+(child.val().pharmacyId)+'/').once('value', snap=>{
                  setEmailAddress(snap.val().emailAddress);  
                  setMobileNumber(snap.val().mobileNumber); 
                  setUserName(snap.val().userName); 
                  setLocation(snap.val().branchPlace); 
                  setAddress(snap.val().address); 
                  setAddressOne(snap.val().addressOne); 
                  setState(snap.val().state); 
                  setCountry(snap.val().country);
                  setPinCode(snap.val().pinCode); 
                  setPharmacyImageUrl(snap.val().pharmacyImageUrl || '');  
              })

            });*/
                
            }).then(()=>{
                if(email == adminEmail){
//window.location.href = '/dashboard';
                }
                else{
                 //   setAlertMessage(true);
                }
            })


            
             }
             else {
               window.location.href = '/';
             }
          });  
        }

      
          getUserData(); 
   
       },[]);
    


return (
  <section style={{backgroundColor: '#ffffff', minHeight: '100%', fontFamily: 'poppins medium', overflowY: 'hidden', overflowX: 'hidden'}}>
  <Navigation />
  <div className="Superadmin_doctorview">
      <div className="Superadmin_doctorview_section">
          <div className="row py-2 mb-4" style={{marginTop:'80px'}}>
       {/*}   &emsp;<i className="fa fa-arrow-circle-o-left fa-3x" style={{color: '#5CB04D', marginLeft: '10px', cursor: 'pointer'}} onClick={ () => window.location.href = '/viewAdmin' }></i> 
   */}
   <div className="col-12">
            <h3 className="Superadmin_doctorview_title text-center" style={{fontFamily:'poppins extrabold', color: '#11772e'}}>Laboratory Profile</h3>
            </div>
          </div>
        <div className="Superadmin_Profiledetails container mb-4" style={{backgroundColor:'#fff', padding:'20px', borderRadius:'10px', boxShadow:'2px 2px 7px rgba(22, 153, 59, 0.9)'}}>
        {loading == true?
        <>
      {Object.values(laboratoryDatas).map((person, index) =>
        <div className="row m-0" style={{display:'flex'}}>
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" style={{padding:'15px',backgroundColor:'#f1f1f1', borderRadius:'5px'}}>
            <div className="Superadmin_ProfileImage text-center">

                   
                     {person.laboratoryImageUrl == ''?
     <img src={require('./hospital.jpg')} style={{borderRadius:'15px',width:'60%', border:'4px solid #ccc'}}/>
         :
<img src={person.laboratoryImageUrl} style={{borderRadius:'15px',width:'60%', border:'4px solid #ccc'}}/>
        }
                        
                    </div>

                    <div className="Superadmin_Doctorprofile mt-4 mx-2">
                <div className="Superadmin_profile_name my-1">
                <span className="" style={{fontFamily:'poppins semibold', fontSize:'20px', color: '#11772e'}}>
                    {person.userName}
                  </span>
                </div>
                <div className="Superadmin_profile_email my-1">
                  <span className="text-secondary" style={{fontFamily:'poppins medium', fontSize:'15px'}}>
                    {person.emailAddress}
                  </span>
                </div>
                <div className="Superadmin_profile_phone my-1">
                  <span className="text-secondary" style={{fontFamily:'poppins medium', fontSize:'15px'}}>
                    {person.mobileNumber}
                  </span>
                </div>
            </div>
            </div> 
            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
            <div className="">
                <h6 className="" style={{fontFamily:'poppins semibold', fontSize:'20px', color: '#11772e'}}>More Information</h6>
              </div>
              <hr className="m-1"/>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2">
                <div className="Superadmin_profile_hospital">
                  <div className="Superadmin_profile_hospital_name my-1" style={{backgroundColor:'#fff', borderRadius:'5px',padding:'10px', boxShadow:'2px 2px 5px rgba(0, 0, 0, 0.3)'}}>
                  <div className="Superadmin_profile_clinicname">
                  <span className="" style={{fontFamily:'poppins semibold', fontSize:'20px', color: '#11772e'}}>
                  Medical Center Name
                  </span>
                </div>
                <hr className="m-1"/>
                  <span className="text-secondary" style={{fontFamily:'poppins medium'}}>
                  {person.medicalCenterName}
                  </span>
                  </div>
                    
                  <div className="Superadmin_profile_hopital_address my-1" style={{backgroundColor:'#fff', borderRadius:'5px',padding:'10px', boxShadow:'2px 2px 5px rgba(0, 0, 0, 0.3)'}}>
                    <div className="">
                    <span className="" style={{fontFamily:'poppins semibold', fontSize:'20px', color: '#11772e'}}>
                      Address
                      </span> <hr className="m-1"/>
                    </div>
                      <span className="text-secondary" style={{fontFamily:'poppins medium'}}>
                      {person.address}&nbsp;{person.addressOne}
                      </span>
                </div> 

                <div className="Superadmin_profile_hopital_pincode my-1" style={{backgroundColor:'#fff', borderRadius:'5px',padding:'10px', boxShadow:'2px 2px 5px rgba(0, 0, 0, 0.3)'}}>
                      <div className="">
                      <span className="" style={{fontFamily:'poppins semibold', fontSize:'20px', color: '#11772e'}}>
                        Pincode
                        </span> <hr className="m-1"/>
                      </div>
                        <span className="text-secondary" style={{fontFamily:'poppins medium'}}>
                       {person.pinCode}
                        </span>
                    </div>
                </div>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2">
                  <div className="Superadmin_profile_hospital">
                      <div className="Superadmin_profile_hopital_state my-1" style={{backgroundColor:'#fff', borderRadius:'5px',padding:'10px', boxShadow:'2px 2px 5px rgba(0, 0, 0, 0.3)'}}>
                          <div className="">
                          <span className="" style={{fontFamily:'poppins semibold', fontSize:'20px', color: '#11772e'}}>
                            State
                            </span>
                          </div> <hr className="m-1"/>
                            <span className="text-secondary" style={{fontFamily:'poppins medium'}}>
                            {person.state}
                            </span>
                      </div>
                      <div className="Superadmin_profile_hopital_country my-1" style={{backgroundColor:'#fff', borderRadius:'5px',padding:'10px', boxShadow:'2px 2px 5px rgba(0, 0, 0, 0.3)'}}>
                      <div className="">
                      <span className="" style={{fontFamily:'poppins semibold', fontSize:'20px', color: '#11772e'}}>
                        Country
                        </span>
                      </div> <hr className="m-1"/>
                        <span className="text-secondary" style={{fontFamily:'poppins medium'}}>
                        {person.country}
                        </span>
                    </div>


                    

      


                    </div>
                    </div>
      
</div>

      </div>
      </div>



      )}
      </>
                    :
      <center>
      <div className="lds-roller">
        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </center>
      }
    </div>
    </div>
                           
    </div>
</section>
    );

}
export default ViewLaboratory;
