import * as firebase from 'firebase';

var secondaryAppConfig = {
  apiKey: "AIzaSyBXdkh7Mj6vdLKeRaaKFhhERxsX8TVp7Ig",
  authDomain: "casepatientdb.firebaseapp.com",
  databaseURL: "https://casepatientdb.firebaseio.com",
  projectId: "casepatientdb",
  storageBucket: "casepatientdb.appspot.com",
  messagingSenderId: "493413036686",
  appId: "1:493413036686:web:6c58aec8ef2100a9090fdf",
  measurementId: "G-W9H18D5FCE"
  };

// Initialize another app with a different config
var secondary = firebase.initializeApp(secondaryAppConfig, "secondary");

// Retrieve the database.
var secondaryDatabase = secondary.database();
  
  
export {
  secondaryDatabase,
}
 
