import React, { useState, useEffect } from 'react';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { auth, database, storage } from '../../config/firebase';
import {secondaryDatabase} from '../../config/firebaseSecondary';
import Navigation from '../navigation/navigation';
import './viewAdminDoctor.css'

function ViewAdminDoctor() {
  const [email, setEmail] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [userName, setUserName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [adminPassword, setAdminPassword] = useState("");
  const [adminName, setAdminName] = useState("");
  const [medicalCenterName, setAdminMedicalCenterName] = useState("");
     const [superAdminId, setSuperAdminId] = useState('');

     const [datas1, setDatas1] = useState([]);
     const [datas2, setDatas2] = useState([]);
     const [datas3, setDatas3] = useState([]);

     const [adminId, setAdminId] = useState('');
    const [location, setLocation] = useState("");
    const [locationId, setLocationId] = useState("");
    const [doctorImageUrl, setDoctorImageUrl] = useState("");
    const [alertMessage, setAlertMessage] = useState(false);
    const [progress, setProgress] = useState("");
    const [address, setAddress] = useState("");
    const [addressOne, setAddressOne] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [qualification, setQualification] = useState("");
    const [registrationNumber, setRegistrationNumber] = useState("");
    const [specialist, setSpecialist] = useState("");

    const [personDisplay, setPersonDisplay] = useState(false);
    const [videoDisplay, setVideoDisplay] = useState(false);
    const [chatDisplay, setChatDisplay] = useState(false);

    const [loading, setLoading] = useState(false);

    const locationPass = useLocation();
    let history = useHistory();
    useEffect(() => {       
        const getUserData = async () => {
           await auth.onAuthStateChanged((user) => {
           if (user) {
             setAdminId(auth.currentUser.uid);
             setEmail(auth.currentUser.email);
             console.log(auth.currentUser.uid);
             console.log(auth.currentUser.email);
            
            let userId = user.uid;
            database.ref('admin/'+auth.currentUser.uid+'/').once('value', snap=>{
                setAdminEmail(snap.val().emailAddress);  
               // setMobileNumber(snap.val().mobileNumber); 
                setAdminName(snap.val().userName); 
                setAdminMedicalCenterName(snap.val().medicalCenterName);
                setLocation(snap.val().branchPlace); 
                
            }).then(()=>{
                if(email == adminEmail){
//window.location.href = '/dashboard';
                }
                else{
                 //   setAlertMessage(true);
                }
            })
            secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/').once('value', snap=>{
              setEmailAddress(snap.val().email);  
              setMobileNumber(snap.val().mobileNumber); 
              setUserName(snap.val().doctorName); 
              setQualification(snap.val().qualification);
              
              setAddress(snap.val().address); 
              setAddressOne(snap.val().addressOne); 
              setState(snap.val().state); 
              setCountry(snap.val().country);
              setPinCode(snap.val().pinCode); 
              setSpecialist(snap.val().specialist);
              setDoctorImageUrl(snap.val().doctorImageUrl);  
              setRegistrationNumber(snap.val().registrationNumber); 
              
              setLoading(true);
          })

          let postEvening = secondaryDatabase.ref().child('doctorsNew/'+(locationPass.state.doctorIdPass)+
          '/appointmentDetailsNew/');
          postEvening.once('value', async snap => {
            console.log(snap.val());
              await snap.forEach(child => {     
                let position = child.child('/evening/');
                console.log(position.val());
                position.forEach(childNew => {
                  let today = moment();
                  let todaydate = today.format("DD-MM-YYYY");
                  let indexselDatevalue = childNew.val().date;
                  const process = (date) => {
                    var parts = date.split("-");
                    return new Date(parts[2], parts[1] - 1, parts[0]);
                  }
          
                  let returnArray = [];
                  if(process(todaydate) <= process(indexselDatevalue)) {       
                    var item = {};
                    item.key = childNew.key;
                    item.date = childNew.val().date;
                    item.startTime = childNew.val().startTime;
                    item.endTime = childNew.val().endTime;
                    item.visitingType = childNew.val().visitingType;
                    item.slotSet = childNew.val().slotSet;
                    item.session = childNew.val().session;
                    item.timeSlot = childNew.val().timeSlot;
                    item.day = childNew.val().day;
                    item.consultationFees = childNew.val().consultationFees;
                    item.consultationSymbol = childNew.val().consultationSymbol;
                    returnArray.push(item);
                    }
                    setDatas1(datas1 => datas1.concat(returnArray));
                  })
                })
              })
 
              let postAfternoon = secondaryDatabase.ref().child('doctorsNew/'+(locationPass.state.doctorIdPass)+
              '/appointmentDetailsNew/');
              postAfternoon.once('value', async snap => {
                console.log(snap.val());
                  await snap.forEach(child => {     
                    let position = child.child('/afternoon/');
                    console.log(position.val());
                    position.forEach(childNew => {
                      let today = moment();
                      let todaydate = today.format("DD-MM-YYYY");
                      let indexselDatevalue = childNew.val().date;
                      const process = (date) => {
                        var parts = date.split("-");
                        return new Date(parts[2], parts[1] - 1, parts[0]);
                      }
              
                      let returnArray = [];
                      if(process(todaydate) <= process(indexselDatevalue)) {       
                        var item = {};
                        item.key = childNew.key;
                        item.date = childNew.val().date;
                        item.startTime = childNew.val().startTime;
                        item.endTime = childNew.val().endTime;
                        item.visitingType = childNew.val().visitingType;
                        item.slotSet = childNew.val().slotSet;
                        item.session = childNew.val().session;
                        item.timeSlot = childNew.val().timeSlot;
                        item.day = childNew.val().day;
                        item.consultationFees = childNew.val().consultationFees;
                        item.consultationSymbol = childNew.val().consultationSymbol;
                        returnArray.push(item);
                        }
                        setDatas1(datas1 => datas1.concat(returnArray));
                      })
                    })
                  })
          
          let post = secondaryDatabase.ref().child('doctorsNew/'+(locationPass.state.doctorIdPass)+
 '/appointmentDetailsNew/');
 post.once('value', async snap => {
   console.log(snap.val());
     await snap.forEach(child => {     
       let position = child.child('/morning/');
       console.log(position.val());
       position.forEach(childNew => {
         let today = moment();
         let todaydate = today.format("DD-MM-YYYY");
         let indexselDatevalue = childNew.val().date;
         const process = (date) => {
           var parts = date.split("-");
           return new Date(parts[2], parts[1] - 1, parts[0]);
         }
 
         let returnArray = [];
         if(process(todaydate) <= process(indexselDatevalue)) {       
           var item = {};
           item.key = childNew.key;
           item.date = childNew.val().date;
           item.startTime = childNew.val().startTime;
           item.endTime = childNew.val().endTime;
           item.visitingType = childNew.val().visitingType;
           item.slotSet = childNew.val().slotSet;
           item.session = childNew.val().session;
           item.timeSlot = childNew.val().timeSlot;
           item.day = childNew.val().day;
           item.consultationFees = childNew.val().consultationFees;
           item.consultationSymbol = childNew.val().consultationSymbol;
           returnArray.push(item);
           }
           setDatas1(datas1 => datas1.concat(returnArray));
         })
       })
     })

     
             }
             else {
               window.location.href = '/';
             }
          });  
        }

      
          getUserData(); 
   
       },[]);
    

  
       let viewAppointment = (data) => {
        history.push({
            pathname: 'viewAppointment',
            state: {doctorIdPass: data,}
        })
      }
      
      let addSlots = (data) => {
        history.push({
            pathname: 'doctorTimeSlots',
            state: {doctorIdPass: data,
            superAdminIdPass: locationPass.state.superAdminIdPass,
            adminIdPass: locationPass.state.adminIdPass,
            departmentIdPass: locationPass.state.departmentIdPass,
          }
        })
      }
      
      let editAdminDoctor = (data, data1, data2, data3) => {
  history.push({
            pathname: 'editAdminDoctor',
            state: {doctorIdPass: data,
            superAdminIdPass: data2,
            adminIdPass: data1,
            departmentIdPass: data3,
          }
        })
      }

      let handleShow = (data) => {
        setPersonDisplay(!personDisplay);
      }
      
      let handleShow1 = (data) => {
        setVideoDisplay(!videoDisplay);
      }
      
      let handleShow2 = (data) => {
        setChatDisplay(!chatDisplay);
      }

      let orderInpersonData = (data) => {
        let returnArrayInperson = [];
        returnArrayInperson = data.sort(function(a, b) {
        return moment(a.date, 'DD-MM-YYYY').isBefore(moment(b.date, 'DD-MM-YYYY')) ? -1: 1 ;
             });
        return returnArrayInperson;
      }

return (
  <section style={{backgroundColor: '#fff', overflow: 'hidden', minHeight: '100%', fontFamily: 'poppins medium'}}>
  <Navigation />
  <div className="Superadmin_doctorview">
      <div className="Superadmin_doctorview_section">
          <div className="row py-2 mb-4" style={{marginTop:'90px'}}>
          &emsp;<i className="fa fa-arrow-circle-o-left fa-3x" style={{color: '#5CB04D', marginLeft: '10px'}} onClick={() => window.location.href = '/viewDepartments'}></i> 
          <div className="col-11">
            <h3 className="Superadmin_doctorview_title text-center" style={{fontFamily:'poppins extrabold', color: '#11772e'}}>Doctor Profile</h3>
          </div>
          </div>
          {loading == true?
        <>

        <div className="Superadmin_Profiledetails container mb-4" style={{backgroundColor:'#fff', padding:'20px', borderRadius:'10px', boxShadow:'2px 2px 7px rgba(22, 153, 59, 0.9)'}}>

        <div className="row m-0" style={{display:'flex'}}>
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" style={{padding:'15px',backgroundColor:'#f1f1f1', borderRadius:'5px'}}>
            <div className="Superadmin_ProfileImage text-center">

                   
                     {doctorImageUrl == ''?
     <img src={require('./Doctor_male.png')} style={{borderRadius:'15px',width:'60%', border:'4px solid #ccc'}}/>
         :
<img src={doctorImageUrl} style={{borderRadius:'15px',width:'60%', border:'4px solid #ccc'}}/>
        }


</div>

<div className="Superadmin_Doctorprofile mt-4 mx-2">
                <div className="Superadmin_profile_Regno my-2">
                {registrationNumber == ''?
                 null
                 :
                 <>
                    <span className="text-white" style={{fontFamily:'poppins medium', fontSize:'14px', backgroundColor:'#5CB04D',borderRadius:'5px', padding:'5px'}}>
                    Reg No
                  </span>
                  <span className="text-secondary mx-1" style={{fontFamily:'poppins medium', fontSize:'15px'}}>
                    {registrationNumber}
                  </span>
                  </>
                  }
                </div>
                <div className="Superadmin_profile_name my-1">
                <span className="" style={{fontFamily:'poppins semibold', fontSize:'20px', color: '#11772e'}}>
                {userName == ''?
                  null:
                <>
                Dr. {userName}
                 </>
                }
                    
                  </span>
                </div>
                <div className="Superadmin_profile_qualification my-1">
                  <span className="text-secondary" style={{fontFamily:'poppins semibold', fontSize:'17px'}}>
                 {qualification}
                  </span>
                </div>
                <div className="Superadmin_profile_email my-1">
                  <span className="text-secondary" style={{fontFamily:'poppins medium', fontSize:'15px'}}>
                    {emailAddress}
                  </span>
                </div>
                <div className="Superadmin_profile_phone my-1">
                {mobileNumber == ''?
                  null:
                <img src={require('./phone1.png')} width="15px"/>
                }
                  <span className="text-secondary" style={{fontFamily:'poppins medium', fontSize:'15px'}}>
                  {mobileNumber == ''?
                  null:
                <>
                 {mobileNumber}
                 </>
                }
                   
                  </span>
                </div>
            </div>
            <div className="Superadmin_doctorprofile_edit text-center">
               <button className="btn px-3 my-3 mx-1" 
               style={{fontFamily:'poppins medium', boxShadow:'2px 2px 7px rgba(0, 0, 0, 0.3)', backgroundColor: '#105c25', color: '#fff'}}
               data-toggle="tooltip" data-placement="bottom" title="Edit"
               onClick={() => editAdminDoctor(locationPass.state.doctorIdPass, locationPass.state.adminIdPass, locationPass.state.superAdminIdPass, locationPass.state.departmentIdPass)}>
                
                 <i class="fa fa-edit" aria-hidden="true"></i></button>  
                 <button className="btn px-3 my-3 mx-1" 
                  style={{fontFamily:'poppins medium', boxShadow:'2px 2px 7px rgba(0, 0, 0, 0.3)', backgroundColor: '#105c25', color: '#fff'}}
                 data-toggle="tooltip" data-placement="bottom" title="View appointment"
                 onClick={() => viewAppointment(locationPass.state.doctorIdPass)}>
                   <i class="fa fa-eye" aria-hidden="true"></i></button>
               <button className="btn px-3 my-3 mx-1" 
 style={{fontFamily:'poppins medium', boxShadow:'2px 2px 7px rgba(0, 0, 0, 0.3)', backgroundColor: '#105c25', color: '#fff'}}
               data-toggle="tooltip" data-placement="bottom" title="Create time slots"
               onClick={() => addSlots(locationPass.state.doctorIdPass)}> <i class="fa fa-plus" aria-hidden="true"></i></button>    
               </div>
          </div> 

          <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
          <div className="Superadmin_Doctordept my-3" style={{backgroundColor:'#fff', borderRadius:'5px',padding:'10px', boxShadow:'2px 2px 5px rgba(0, 0, 0, 0.3)'}}>
            <div className="Superadmin_profile_Department">
            <span className="" style={{fontFamily:'poppins semibold', fontSize:'14px', borderRadius:'5px', color: '#11772e'}}>
               Department
              </span>
            </div>
            <hr className="m-1"/>
            <div className="Superadmin_profile_email">
              <span className="text-secondary" style={{fontFamily:'poppins medium'}}>
              {specialist}
              </span>
            </div>
            </div>
            <div className="">
                <h6 className="" style={{fontFamily:'poppins semibold', fontSize:'20px', color: '#11772e'}}>More Information</h6>
              </div>
              <hr className="m-1"/>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2">
                <div className="Superadmin_profile_hospital">
                  <div className="Superadmin_profile_hospital_name my-1" style={{backgroundColor:'#fff', borderRadius:'5px',padding:'10px', boxShadow:'2px 2px 5px rgba(0, 0, 0, 0.3)'}}>
                  <div className="Superadmin_profile_clinicname">
                  <span className="" style={{fontFamily:'poppins semibold', fontSize:'14px', color: '#11772e'}}>
                  Medical Center Name
                  </span>
                </div>
                <hr className="m-1"/>
                  <span className="text-secondary" style={{fontFamily:'poppins medium'}}>
                  {medicalCenterName}
                  </span>
                  </div>
                    
                  <div className="Superadmin_profile_hopital_address my-1" style={{backgroundColor:'#fff', borderRadius:'5px',padding:'10px', boxShadow:'2px 2px 5px rgba(0, 0, 0, 0.3)'}}>
                    <div className="">
                    <span className="" style={{fontFamily:'poppins semibold', fontSize:'14px', color: '#11772e'}}>
                      Address
                      </span> <hr className="m-1"/>
                    </div>
                      <span className="text-secondary" style={{fontFamily:'poppins medium'}}>
                      {address}&nbsp;{addressOne}
                      </span>
                </div> 

                <div className="Superadmin_profile_hopital_location my-1" style={{backgroundColor:'#fff', borderRadius:'5px',padding:'10px', boxShadow:'2px 2px 5px rgba(0, 0, 0, 0.3)'}}>
                    <div className="">
                    <span className="" style={{fontFamily:'poppins semibold', fontSize:'14px', color: '#11772e'}}>
                      Location
                      </span>
                    </div> 
                    <hr className="m-1"/>
                      <span className="text-secondary" style={{fontFamily:'poppins medium'}}>
                      {location}
                      </span>
                  </div>
                </div>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 my-2">
                  <div className="Superadmin_profile_hospital">
                      <div className="Superadmin_profile_hopital_state my-1" style={{backgroundColor:'#fff', borderRadius:'5px',padding:'10px', boxShadow:'2px 2px 5px rgba(0, 0, 0, 0.3)'}}>
                          <div className="">
                          <span className="" style={{fontFamily:'poppins semibold', fontSize:'14px', color: '#11772e'}}>
                            State
                            </span>
                          </div> <hr className="m-1"/>
                            <span className="text-secondary" style={{fontFamily:'poppins medium'}}>
                            {state}
                            </span>
                      </div>
                      <div className="Superadmin_profile_hopital_country my-1" style={{backgroundColor:'#fff', borderRadius:'5px',padding:'10px', boxShadow:'2px 2px 5px rgba(0, 0, 0, 0.3)'}}>
                      <div className="">
                      <span className="" style={{fontFamily:'poppins semibold', fontSize:'14px', color: '#11772e'}}>
                        Country
                        </span>
                      </div> <hr className="m-1"/>
                        <span className="text-secondary" style={{fontFamily:'poppins medium'}}>
                        {country}
                        </span>
                    </div>
              
               
                    
                    <div className="Superadmin_profile_hopital_pincode my-1" style={{backgroundColor:'#fff', borderRadius:'5px',padding:'10px', boxShadow:'2px 2px 5px rgba(0, 0, 0, 0.3)'}}>
                      <div className="">
                      <span className="" style={{fontFamily:'poppins semibold', fontSize:'14px', color: '#11772e'}}>
                        Pincode
                        </span> <hr className="m-1"/>
                      </div>
                        <span className="text-secondary" style={{fontFamily:'poppins medium'}}>
                       {pinCode}
                        </span>
                    </div>
      


                    </div>
                    </div>
      
</div>

      </div>
      </div>
    </div>
    <div className="Superadmin_Profiledetails container my-4">
      <h6 className="" style={{fontFamily:'poppins semibold', fontSize:'22px', color: '#11772e'}}>Consultation Details</h6>
</div>
              <div className="Superadmin_Profiledetails container my-4">
              <div style={{minWidth: '100%', borderRadius: '10px', backgroundColor: '#105c25', height: '50px',}}>
              <h6 className="Doctor_ProfileView_subtitle p-2 ml-3" onClick={handleShow} 
              style={{fontFamily:'poppins semibold', fontSize:'22px', cursor: 'pointer', 
              color: 'white', display: 'inline-block', float: 'left'}}>
In person consultation
 </h6>

 <button className="btn" 
 onClick={handleShow} 
               data-toggle="tooltip" data-placement="bottom" title="View time slots"
               style={{marginTop: '10px', color: '#fff', marginRight: '10px', float: 'right', fontFamily:'poppins medium', backgroundColor: '#5CB04D', boxShadow:'2px 2px 7px rgba(0, 0, 0, 0.3)'}}
> 
{personDisplay == true?
  <i className="fa fa-minus" aria-hidden="true" style={{float: 'left'}}></i>
:
<i className="fa fa-plus" aria-hidden="true" style={{float: 'right'}}></i>
}
</button>
</div>          </div>

        <div className="Superadmin_Profiledetails container my-4" style={{
          display: personDisplay == true?
          ''
          :
          'none', backgroundColor:'#fff', padding:'10px', borderRadius:'10px', boxShadow:'2px 2px 7px rgba(0, 93, 214, 0.9)'}}>
                    {datas1.length == 0? 
        <div className="table-responsive viewMobile" style={{border: '2px solid rgb(230, 230, 230)',borderRadius: '10px',borderCollapse: 'collapse', backgroundColor: 'rgb(255, 255, 255)'}}>
        <table className='table table-borderless mb-0'>
             <thead className="text-center" style={{backgroundColor:'#11772e', color:'white'}}>
               <tr>
               <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Date</th>
               <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Day</th>
               <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Session</th>
               <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Time</th>
                <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Consultation Fees</th>
               <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Time Slot</th>
             </tr>
             </thead>
                <tbody>
                <td></td>
                <td></td>
                 <td></td>
                <td style={{textAlign: 'center', fontSize: '12px', fontFamily: 'poppins', verticalAlign: 'middle',whiteSpace: 'nowrap'}}><h6 style={{whiteSpace: 'nowrap'}}>Time slots not created </h6></td>
                <td></td>
                </tbody>
                </table>
</div>
              :
<div className="table-responsive viewMobile" style={{border: '2px solid rgb(230, 230, 230)',borderRadius: '10px',borderCollapse: 'collapse', backgroundColor: 'rgb(255, 255, 255)'}}>
           <table className='table table-borderless mb-0'>
                <thead className="text-center" style={{backgroundColor:'#11772e', color:'white'}}>
                  <tr>
                  <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Date</th>
                  <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Day</th>
                  <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Session</th>
                  <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Time</th>
                   <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Consultation Fees</th>
                  <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Time Slot</th>
                </tr>
                </thead>
                <tbody className='text-center'>
{orderInpersonData(datas1).filter(x => x.visitingType == 'inPersonConsultation').map((persons,index) =>
      <>
      {persons.startTime == '' && persons.endTime == '' ? 
    null
     :
  <tr style={{fontWeight:'500', fontSize:'16px',color:'#666'}} key={index}> 
  <td style={{textAlign: 'center', fontSize: '12px', fontFamily: 'poppins', verticalAlign: 'middle',whiteSpace: 'nowrap'}}>{persons.date}</td>
  <td style={{verticalAlign: 'middle', fontSize: '12px', fontFamily: 'poppins', whiteSpace: 'nowrap'}}>{persons.day}</td>
  <td style={{verticalAlign: 'middle', fontSize: '12px', fontFamily: 'poppins', whiteSpace: 'nowrap', textTransform: 'capitalize'}}>{persons.session}</td>
  <td style={{textAlign: 'center', fontSize: '12px', fontFamily: 'poppins', verticalAlign: 'middle',whiteSpace: 'nowrap'}}>{persons.startTime} - {persons.endTime}</td>
  <td style={{textAlign: 'center', fontSize: '12px', fontFamily: 'poppins', verticalAlign: 'middle',whiteSpace: 'nowrap'}}>{persons.consultationSymbol}. {persons.consultationFees}</td>
  <td style={{textAlign: 'center', fontSize: '12px', fontFamily: 'poppins', verticalAlign: 'middle',whiteSpace: 'nowrap'}}>{persons.timeSlot}</td>
  </tr>
}
</>
)}
</tbody>

</table>
</div>
                  
}
                         </div>

                         <div className="Superadmin_Profiledetails container my-4">
            <div style={{minWidth: '100%', borderRadius: '10px', backgroundColor: '#105c25', height: '50px',}}>
              <h6 className="Doctor_ProfileView_subtitle p-2 ml-3" onClick={handleShow1} 
              style={{fontFamily:'poppins semibold', fontSize:'22px', cursor: 'pointer', 
              color: 'white', display: 'inline-block', float: 'left'}}>
Video consultation
 </h6>

 <button className="btn" 
 onClick={handleShow1} 
               data-toggle="tooltip" data-placement="bottom" title="View time slots"
               style={{marginTop: '10px', color: '#fff', marginRight: '10px', float: 'right', fontFamily:'poppins medium', backgroundColor: '#5CB04D', boxShadow:'2px 2px 7px rgba(0, 0, 0, 0.3)'}}
> 
{videoDisplay == true?
  <i className="fa fa-minus" aria-hidden="true" style={{float: 'left'}}></i>
:
<i className="fa fa-plus" aria-hidden="true" style={{float: 'right'}}></i>
}
</button>
</div>  
                  </div>
        <div className="Superadmin_Profiledetails container my-4" style={{ display: videoDisplay == true?
          ''
          :
          'none', backgroundColor:'#fff', padding:'10px', borderRadius:'10px', boxShadow:'2px 2px 7px rgba(0, 93, 214, 0.9)'}}>
       {datas1.length == 0? 
        <div className="table-responsive viewMobile" style={{border: '2px solid rgb(230, 230, 230)',borderRadius: '10px',borderCollapse: 'collapse', backgroundColor: 'rgb(255, 255, 255)'}}>
        <table className='table table-borderless mb-0'>
             <thead className="text-center" style={{backgroundColor:'#11772e', color:'white'}}>
               <tr>
               <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Date</th>
               <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Day</th>
               <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Session</th>
               <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Time</th>
                <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Consultation Fees</th>
               <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Time Slot</th>
             </tr>
             </thead>
                <tbody>
                <td></td>
                <td></td>
                 <td></td>
                <td style={{textAlign: 'center', fontSize: '12px', fontFamily: 'poppins', verticalAlign: 'middle',whiteSpace: 'nowrap'}}><h6 style={{whiteSpace: 'nowrap'}}>Time slots not created </h6></td>
                <td></td>
                </tbody>
                </table>
</div>
              :
<div className="table-responsive viewMobile" style={{border: '2px solid rgb(230, 230, 230)',borderRadius: '10px',borderCollapse: 'collapse', backgroundColor: 'rgb(255, 255, 255)'}}>
           <table className='table table-borderless mb-0'>
                <thead className="text-center" style={{backgroundColor:'#11772e', color:'white'}}>
                  <tr>
                  <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Date</th>
                  <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Day</th>
                  <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Session</th>
                  <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Time</th>
                   <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Consultation Fees</th>
                  <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Time Slot</th>
                </tr>
                </thead>
                <tbody className='text-center'>
{orderInpersonData(datas1).filter(x => x.visitingType == 'videoConsultation').map((persons,index) =>
      <>
      {persons.startTime == '' && persons.endTime == '' ? 
    null
     :
  <tr style={{fontWeight:'500', fontSize:'16px',color:'#666'}} key={index}> 
  <td style={{textAlign: 'center', fontSize: '12px', fontFamily: 'poppins', verticalAlign: 'middle',whiteSpace: 'nowrap'}}>{persons.date}</td>
  <td style={{verticalAlign: 'middle', fontSize: '12px', fontFamily: 'poppins', whiteSpace: 'nowrap'}}>{persons.day}</td>
  <td style={{verticalAlign: 'middle', fontSize: '12px', fontFamily: 'poppins', whiteSpace: 'nowrap', textTransform: 'capitalize'}}>{persons.session}</td>
  <td style={{textAlign: 'center', fontSize: '12px', fontFamily: 'poppins', verticalAlign: 'middle',whiteSpace: 'nowrap'}}>{persons.startTime} - {persons.endTime}</td>
   <td style={{textAlign: 'center', fontSize: '12px', fontFamily: 'poppins', verticalAlign: 'middle',whiteSpace: 'nowrap'}}>{persons.consultationSymbol}. {persons.consultationFees}</td>
  <td style={{textAlign: 'center', fontSize: '12px', fontFamily: 'poppins', verticalAlign: 'middle',whiteSpace: 'nowrap'}}>{persons.timeSlot}</td>
  </tr>
}
</>
)}
</tbody>

</table>
</div>
                  
}
            </div>

            <div className="Superadmin_Profiledetails container my-4">
            <div style={{minWidth: '100%', borderRadius: '10px', backgroundColor: '#105c25', height: '50px',}}>
              <h6 className="Doctor_ProfileView_subtitle p-2 ml-3" onClick={handleShow2} 
              style={{fontFamily:'poppins semibold', fontSize:'22px', cursor: 'pointer', 
              color: 'white', display: 'inline-block', float: 'left'}}>
Live chat consultation
 </h6>

 <button className="btn" 
 onClick={handleShow2} 
               data-toggle="tooltip" data-placement="bottom" title="View time slots"
               style={{marginTop: '10px', color: '#fff', marginRight: '10px', float: 'right', fontFamily:'poppins medium', backgroundColor: '#5CB04D', boxShadow:'2px 2px 7px rgba(0, 0, 0, 0.3)'}}
> 
{chatDisplay == true?
  <i className="fa fa-minus" aria-hidden="true" style={{float: 'left'}}></i>
:
<i className="fa fa-plus" aria-hidden="true" style={{float: 'right'}}></i>
}
</button>
</div>  
                </div>
        <div className="Superadmin_Profiledetails container my-4" style={{display: chatDisplay == true?
          ''
          :
          'none', backgroundColor:'#fff', padding:'10px', borderRadius:'10px', boxShadow:'2px 2px 7px rgba(0, 93, 214, 0.9)'}}>
       {datas1.length == 0? 
        <div className="table-responsive viewMobile" style={{border: '2px solid rgb(230, 230, 230)',borderRadius: '10px',borderCollapse: 'collapse', backgroundColor: 'rgb(255, 255, 255)'}}>
        <table className='table table-borderless mb-0'>
             <thead className="text-center" style={{backgroundColor:'#11772e', color:'white'}}>
               <tr>
               <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Date</th>
               <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Day</th>
               <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Session</th>
               <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Time</th>
               <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Consultation Fees</th>
               <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Time Slot</th>
             </tr>
             </thead>
                <tbody>
                <td></td>
                <td></td>
                 <td></td>
                <td style={{textAlign: 'center', fontSize: '12px', fontFamily: 'poppins', verticalAlign: 'middle',whiteSpace: 'nowrap'}}><h6 style={{whiteSpace: 'nowrap'}}>Time slots not created </h6></td>
                <td></td>
                </tbody>
                </table>
</div>
              :
<div className="table-responsive viewMobile" style={{border: '2px solid rgb(230, 230, 230)',borderRadius: '10px',borderCollapse: 'collapse', backgroundColor: 'rgb(255, 255, 255)'}}>
           <table className='table table-borderless mb-0'>
                <thead className="text-center" style={{backgroundColor:'#11772e', color:'white'}}>
                  <tr>
                  <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Date</th>
                  <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Day</th>
                  <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Session</th>
                  <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Time</th>
                  <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Consultation Fees</th>
                  <th style={{textAlign: 'center', fontSize: '14px', fontFamily: 'poppins'}}>Time Slot</th>
                </tr>
                </thead>
                <tbody className='text-center'>
{orderInpersonData(datas1).filter(x => x.visitingType == 'liveChatConsultation').map((persons,index) =>
      <>
      {persons.startTime == '' && persons.endTime == '' ? 
    null
     :
  <tr style={{fontWeight:'500', fontSize:'16px',color:'#666'}} key={index}> 
  <td style={{textAlign: 'center', fontSize: '12px', fontFamily: 'poppins', verticalAlign: 'middle',whiteSpace: 'nowrap'}}>{persons.date}</td>
  <td style={{verticalAlign: 'middle', fontSize: '12px', fontFamily: 'poppins', whiteSpace: 'nowrap'}}>{persons.day}</td>
  <td style={{verticalAlign: 'middle', fontSize: '12px', fontFamily: 'poppins', whiteSpace: 'nowrap', textTransform: 'capitalize'}}>{persons.session}</td>
  <td style={{textAlign: 'center', fontSize: '12px', fontFamily: 'poppins', verticalAlign: 'middle',whiteSpace: 'nowrap'}}>{persons.startTime} - {persons.endTime}</td>
  <td style={{textAlign: 'center', fontSize: '12px', fontFamily: 'poppins', verticalAlign: 'middle',whiteSpace: 'nowrap'}}>{persons.consultationSymbol} {persons.consultationFees}</td>
  <td style={{textAlign: 'center', fontSize: '12px', fontFamily: 'poppins', verticalAlign: 'middle',whiteSpace: 'nowrap'}}>{persons.timeSlot}</td>
  </tr>
}
</>
)}
</tbody>
</table>
</div>
                  
}
            </div>
            </>
                    :
                    <div className="Superadmin_Profiledetails container mb-4" style={{backgroundColor:'#fff', padding:'20px', borderRadius:'10px', boxShadow:'2px 2px 7px rgba(22, 153, 59, 0.9)'}}>
      <center>
      <div className="lds-roller">
        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </center>
      </div>
      }
    </div>
    
                </div>                 
      
</section>
    );

}
export default ViewAdminDoctor;
