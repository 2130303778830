import React, { useState, useEffect } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import moment from 'moment';
import { auth, database, storage } from '../../config/firebase';
import './viewDepartments.css'
import Navigation from '../navigation/navigation';

function ProfileView() {
  const [email, setEmail] = useState("");
    const [adminEmail, setAdminEmail] = useState("");
    const [adminPhoneNumber, setAdminPhoneNumber] = useState("");
    const [adminName, setAdminName] = useState("");
    const [adminMedicalCenterName, setAdminMedicalCenterName] = useState("");

    const [departmentDatas, setDepartmentDatas] = useState("");
    const [hodName, setHodName] = useState('Select HOD');
    const [adminId, setAdminId] = useState('');

     const [superAdminId, setSuperAdminId] = useState('');

    const [location, setLocation] = useState("");
    const [alertMessage, setAlertMessage] = useState(false);
    const [progress, setProgress] = useState("");
    const [adminImageUrl, setAdminImageUrl] = useState("");

    const [loading, setLoading] = useState(false);

    let history = useHistory();
    useEffect(() => {       
        const getUserData = async () => {
           await auth.onAuthStateChanged((user) => {
           if (user) {
             setAdminId(auth.currentUser.uid);
             setEmail(auth.currentUser.email);
             console.log(auth.currentUser.uid);
             console.log(auth.currentUser.email);
            
            let userId = user.uid;
            database.ref('admin/'+auth.currentUser.uid+'/').once('value', snap=>{
                setAdminEmail(snap.val().emailAddress);  
                setAdminPhoneNumber(snap.val().mobileNumber); 
                setAdminName(snap.val().userName); 
                setAdminMedicalCenterName(snap.val().medicalCenterName);
                setLocation(snap.val().branchPlace);
                setDepartmentDatas(snap.val().departments || '');
                setAdminImageUrl(snap.val().adminImageUrl || '');
                setSuperAdminId(snap.val().superAdminUid);
                setLoading(true);
            }).then(()=>{
                if(email == adminEmail){
//window.location.href = '/dashboard';
                }
                else{
                    setAlertMessage(true);
                }
            })

            
             }
             else {
               window.location.href = '/';
             }
          });  
        }

      
          getUserData(); 
   
       },[]);
    


let addDoctors = (data, data1, data2, data3) => {
  //alert(data1);
  history.push({
      pathname: 'createDoctor',
      state: {departmentIdPass: data,
    superAdminIdPass:data1,
    adminIdPass:data2,
  departmentNamePass: data3}
  })
}

let submit = (data) => {

  if(hodName == 'Select HOD'){
    alert('Please select HOD');
  }
  else{
  database.ref('superAdmin/'+(superAdminId)+'/admin/'+(adminId)+'/departments/'+(data)+'/').
  update({hodName: hodName});
  database.ref('admin/'+(adminId)+'/'+'/departments/'+
  (data)).update({hodName: hodName}).then(()=>{
    alert('HOD is added.')
    window.location.reload();
  })
  }
  }

  let viewDoctor = (data,data1, data2, data3) => {
   
    history.push({
        pathname: 'viewAdminDoctor',
        state: {doctorIdPass: data,
          superAdminIdPass: data1,
          adminIdPass: data2,
  departmentIdPass: data3,
      }
    })
  }


  let editDepartment = (data, data1) => {
    history.push({
        pathname: 'editDepartment',
        state: {
          superAdminIdPass: data,
          adminIdPass: data1,
      }
    })
  }

  let appointmentSummary = (data, data1, data2, data3) => {
    history.push({
                pathname: 'appointmentSummary',
                state: {departmentIdPass: data,
                adminIdPass: data2,
              superAdminIdPass:data1,
            departmentNamePass: data3}
            })
          }

return (
  <section style={{backgroundColor: '#ffffff', overflow: 'auto', minHeight: '100%', fontFamily: 'poppins medium'}}>
   <Navigation />
   <div className="SuperAdmin_ViewDepartments">
      <div className="SuperAdmin_ViewDepartments_section">
          <div className="py-2 mb-4 " style={{marginTop:'80px'}}>
           
          <h3 className="SuperAdmin_ViewDepartments_title text-center" style={{whiteSpace: 'nowrap', color: '#105c25'}}><b>View Departments</b></h3>
            
             </div>
     
          <div className="Superadmin_Profile_department container my-2">
       {/*}   <div className="SuperAdmin_Viewdebt_createdebtbutton my-2" style={{display:'flex',justifyContent:'flex-end'}}>
          <button className="btn px-4 my-2" style={{float: 'right', color: '#fff', backgroundColor:'#105c25', borderRadius:'25px', boxShadow:'3px 3px 10px rgba(22, 153, 59, 0.9)'}} 
            onClick={() => editDepartment(superAdminId, adminId)}><b>+ Add Department</b></button>
</div>*/}
        <div style={{backgroundColor:'#fff', boxShadow:'2px 2px 7px rgba(22, 153, 59, 0.9)'}}>
        {loading == true?
        <div className="row m-0" style={{display:'flex', fontFamily:'poppins'}}>
      {Object.values(departmentDatas).filter((person1) => person1.checked === true).map((persons,index) =>
   <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 p-3 text-center">
   <div className="Superadmin_Profile_department_name" style={{backgroundColor:'#105c25'}}>
     <h6 style={{fontWeight:'700', color:'#fff', padding:'15px'}}>{persons.departmentName}</h6>
            </div>
            <div className="" style={{position:'absolute', top:'10px', right:'10px'}}>
            <button className="btn btn-danger btn-sm" /*onClick={() => removeDepartment(persons.departmentId)}*/><i class="fa fa-trash"></i></button>
            </div>  
        
            <div className="p-1 text-left">
              <span className="text-success" style={{fontSize:'18px',fontWeight:'600'}}>{persons.hodName}</span>
            </div>
      {persons.hodName == null || undefined?
      <div>
       {persons.doctors == null || undefined? 
      <h6>No HOD assigned</h6>
      :
      <div>
      <div className="p-1 text-left">
            <span className="text-left" style={{fontSize:'14px',fontWeight:'600'}}>Add HOD</span>
            </div>
            <div className="text-left d-flex">
        <select className="form-control col-9" id="hodName" name="hodName" value={hodName} 
onChange={(event) => { setHodName( event.target.value ); }} style={{fontSize:'15px', borderRadius:'3px'}} required>
<option value="Select HOD">Select HOD</option>
{Object.values(persons.doctors).map((personsss,index)=> (
<option key={index} value={personsss.doctorName}>Dr. {personsss.doctorName}</option>
))
}
</select> 
<button className="btn btn-success px-3 btn-sm mx-1" style={{backgroundColor: '#105c25', color: '#fff', borderRadius: '25px'}} 
onClick={() => submit(persons.departmentId)}>Save</button>
</div>
</div>
}
      </div>

:
null
      }
<hr className="m-1"/>

      {persons.doctors == null || undefined? 
      <div>
      
      <div className="p-1 text-left mt-2" style={{display:'flex',justifyContent:'space-between'}}>
      <span className="" style={{fontSize:'18px',fontWeight:'600', color: '#5CB04D'}}>Doctors</span>
    {/*  <button className="btn btn-success btn-sm mx-1" style={{borderRadius:'25px',fontWeight:'600'}}
      onClick={() => addDoctors(persons.departmentId, superAdminId, adminId, persons.departmentName)}>+ Add Doctor</button>*/}
    <button className="btn btn-success btn-sm mx-1" disabled style={{borderRadius:'25px',fontWeight:'600'}}
      onClick={() => appointmentSummary(persons.departmentId, superAdminId, adminId, persons.departmentName)}>Appointment Summary</button>

     {/*} <button className="btn btn-success btn-sm mx-1" style={{borderRadius:'25px',fontWeight:'600'}}
      onClick={() => addDoctors(persons.departmentId, superAdminId, adminId, persons.departmentName)}>+ Add Doctor</button>*/}
      </div>
      <hr className="m-1"/>
      <h6>No doctors</h6>
      </div>
      :
      <div>
<div className="p-1 text-left mt-2" style={{display:'flex',justifyContent:'space-between'}}>
<span className="" style={{fontSize:'18px',fontWeight:'600', color: '#5CB04D'}}>Doctors</span>
              {/*  <button className="btn btn-success btn-sm mx-1" style={{borderRadius:'25px',fontWeight:'600'}}
      onClick={() => addDoctors(persons.departmentId, superAdminId, adminId, persons.departmentName)}>+ Add Doctor</button>*/}
             <button className="btn btn-success btn-sm mx-1" style={{borderRadius:'25px',fontWeight:'600'}}
      onClick={() => appointmentSummary(persons.departmentId, superAdminId, adminId, persons.departmentName)}>Appointment Summary</button>
            </div>
            <hr className="m-1"/>
            <div className="p-1">
      {Object.values(persons.doctors).map((personss,indexs)=> 
      
<div className="p-1 text-left">
              <span className="linkRef" onClick={() => viewDoctor(personss.doctorId, superAdminId, adminId, persons.departmentId)} 
              style={{fontSize:'17px',fontWeight:'500', cursor: 'pointer'}}>
                <a style={{color:'#000'}}>Dr. {personss.doctorName}
             {/*}   <span style={{fontSize:'13px',color:'#666'}}> ({personss.registrationNumber})</span>*/}
                </a></span>
            </div>

     
      )}
    </div>
 </div>         
     
}
      </div>
       
      )}
      </div>

:
<center>
<div className="lds-roller">
  <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
</center>
}
</div>

      </div>
      </div>
      <br/>
    </div>
  
                     
</section>
    );

}
export default ProfileView;
