import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { auth, database} from '../../config/firebase';
import {secondaryDatabase} from '../../config/firebaseSecondary';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Navigation from '../navigation/navigation';

function Appoinments() {
  const [startDate, setStartDate] = useState(null);
  const [timeSlotsnew, setTimeSlotsNew] = useState([]);
  const [timebookingss, setTimeBookingss] = useState('');
  const [selectedTime, setSelectedTime] = useState('');

  const [timeSlotsnew1, setTimeSlotsNew1] = useState([]);
  const [timebookingss1, setTimeBookingss1] = useState('');

  const [timeSlotsnew2, setTimeSlotsNew2] = useState([]);
  const [timebookingss2, setTimeBookingss2] = useState('');

  const [selectedDate, setSelectedDate] = useState('');

  const [email, setEmail] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
    const [adminName, setAdminName] = useState("");
     const [adminId, setAdminId] = useState('');
    const [alertMessage, setAlertMessage] = useState(false);
    const [progress, setProgress] = useState("");

    const [doctorName, setDoctorName] = useState("");


    const [previousEndDate, setpreviousEndDate] = useState("");
    const [dateArray, setdateArray] = useState("");
    const locationPass = useLocation();
    let history = useHistory();
    useEffect(() => {     

        const getUserData = async () => {
           await auth.onAuthStateChanged((user) => {
           if (user) {
             setAdminId(auth.currentUser.uid);
             setEmail(auth.currentUser.email);
             console.log(auth.currentUser.uid);
             console.log(auth.currentUser.email);
            
            let userId = user.uid;
            database.ref('admin/'+auth.currentUser.uid+'/').once('value', snap=>{ 
                setAdminName(snap.val().userName); 
            }).then(()=>{
                if(email == adminEmail){
//window.location.href = '/dashboard';

secondaryDatabase.ref().child('doctorsNew/'+('62nvmLXx1jOJvB247tGrT2pRsVf1')+'/appointmentDetailsNew/inPersonConsultation').orderByKey().limitToLast(1).once('value', snap => {
  if(snap.exists()) {
    snap.forEach(child => {
        let previousEndDate = moment(child.key, "YYYYMMDD");
        let previousEndDatesss = moment(previousEndDate, "DD-MM-YYYY");
        let previousEndDates = moment(previousEndDatesss, "DD-MM-YYYY").add('days', 1);
        setpreviousEndDate(previousEndDates);
       // setDoctorName(snap.val().doctorName);
    });
  }else{
    let previous = moment();
    setpreviousEndDate(previous);
   // setDoctorName(snap.val().doctorName);
  }
});

secondaryDatabase.ref('doctorsNew/'
+('62nvmLXx1jOJvB247tGrT2pRsVf1'))
.once('value', snap=>{
setDoctorName(snap.val().doctorName);


});

                }
                else{
                    setAlertMessage(true);
                }
            })

            
             }
             else {
              // window.location.href = '/';
             }
          });  
        }

      
          getUserData(); 
   
       },[]);

let select = (param) => {
  //alert(param);
     setSelectedTime(param);
}

       let handleChange = (date) => { 
        setStartDate(date);
        let dateselect = moment(date);
        let dateselects = dateselect.format("DD-MM-YYYY");

        setSelectedDate(dateselects);

        console.log(dateselects);
        const doctorUserId = '62nvmLXx1jOJvB247tGrT2pRsVf1';
        //Make a Time slots from start and end time with time period
        function getTimeStopsnew(startnew, endnew, timeslotnew){
            let startTimenew = moment(startnew, 'HH:mm');
            let endTimenew = moment(endnew, 'HH:mm');
            if( endTimenew.isBefore(startTimenew) ){
                  endTimenew.add(1, 'day');
              }
            let timeStopsnew = [];
            while(startTimenew < endTimenew){
                  timeStopsnew.push(new moment(startTimenew).format('HH:mm'));
                  startTimenew.add(timeslotnew, 'minutes');
              }
            return timeStopsnew;
        }
      //In-Person Consultation morning
            const rootRefs = secondaryDatabase.ref().child('doctorsNew/'+doctorUserId+'/appointmentsNew/inPersonConsultation/'+dateselects+'/morning/');
            rootRefs.on('value', snap=>{
                let times = snap.val();
                if (snap.exists()){
                  function getBookedTimess(times){
                    let timebookedss = [];
                    const rootRefssss = secondaryDatabase.ref().child('doctorsNew/'+doctorUserId+'/appointmentsNew/inPersonConsultation/'+dateselects+'/morning/');
                    rootRefssss.on('value', snap=>{
                       snap.forEach(function(data) {
                         timebookedss.push(data.key);
                        });
                      });
                      return timebookedss;
                    }
                  let timebookingss = getBookedTimess(times);
                  setTimeBookingss(timebookingss);
                  let startTnew;
                  let endTnew;
                  let timeslotTnew;
                  const rootRefsnew = secondaryDatabase.ref().child('doctorsNew/'+doctorUserId+'/appointmentDetailsNew/inPersonConsultation/'+dateselects+'/morning/');
                  rootRefsnew.on('value', snap=>{
                      let timesnew = snap.val();
                      if (snap.exists()){
                       startTnew = timesnew.startTime;
                       endTnew = timesnew.endTime;
                       timeslotTnew = timesnew.timeSlot;

                       console.log(timesnew.startTime);
                       }
                     });
                  //Make a Time slots from start and end time with time period
                 const timeSlotsnew = getTimeStopsnew(startTnew, endTnew, timeslotTnew);
                 setTimeSlotsNew(timeSlotsnew);
      
               }else{
                 //If New Booking Date
                 setTimeBookingss('timebookingss');
                 let startTnew;
                 let endTnew;
                 let timeslotTnew;
                 const rootRefsnew = secondaryDatabase.ref().child('doctorsNew/'+doctorUserId+'/appointmentDetailsNew/inPersonConsultation/'+dateselects+'/morning/');
                 rootRefsnew.on('value', snap=>{
                     let timesnew = snap.val();
                     if (snap.exists()){
                      startTnew = timesnew.startTime;
                      endTnew = timesnew.endTime;
                      timeslotTnew = timesnew.timeSlot;

                      console.log(timesnew.startTime);
                    }
                    });
                 //Make a Time slots from start and end time with time period
                 const timeSlotsnew = getTimeStopsnew(startTnew, endTnew, timeslotTnew);
      
                 setTimeSlotsNew(timeSlotsnew);
      
               }
             });

                //Make a Time slots from start and end time with time period Afternoon
        function getTimeStopsnew1(startnew1, endnew1, timeslotnew1){
          let startTimenew1 = moment(startnew1, 'HH:mm');
          let endTimenew1 = moment(endnew1, 'HH:mm');
          if( endTimenew1.isBefore(startTimenew1) ){
                endTimenew1.add(1, 'day');
            }
          let timeStopsnew1 = [];
          while(startTimenew1 < endTimenew1){
                timeStopsnew1.push(new moment(startTimenew1).format('HH:mm'));
                startTimenew1.add(timeslotnew1, 'minutes');
            }
          return timeStopsnew1;
      }
    //In-Person Consultation afternoon
          const rootRefss = secondaryDatabase.ref().child('doctorsNew/'+doctorUserId+'/appointmentsNew/inPersonConsultation/'+dateselects+'/afternoon/');
          rootRefss.on('value', snap=>{
              let times1 = snap.val();
              if (snap.exists()){
                function getBookedTimess1(times1){
                  let timebookedss1 = [];
                  const rootRefsssss = secondaryDatabase.ref().child('doctorsNew/'+doctorUserId+'/appointmentsNew/inPersonConsultation/'+dateselects+'/afternoon/');
                  rootRefsssss.on('value', snap=>{
                     snap.forEach(function(data) {
                       timebookedss1.push(data.key);
                      });
                    });
                    return timebookedss1;
                  }
                let timebookingss1 = getBookedTimess1(times1);
                setTimeBookingss1(timebookingss1);
                let startTnew1;
                let endTnew1;
                let timeslotTnew1;
                const rootRefsnews = secondaryDatabase.ref().child('doctorsNew/'+doctorUserId+'/appointmentDetailsNew/inPersonConsultation/'+dateselects+'/afternoon/');
                rootRefsnews.on('value', snap=>{
                    let timesnew1 = snap.val();
                    if (snap.exists()){
                     startTnew1 = timesnew1.startTime;
                     endTnew1 = timesnew1.endTime;
                     timeslotTnew1 = timesnew1.timeSlot;

                     console.log(timesnew1.startTime);
                     }
                   });
                //Make a Time slots from start and end time with time period
               const timeSlotsnew1 = getTimeStopsnew1(startTnew1, endTnew1, timeslotTnew1);
               setTimeSlotsNew1(timeSlotsnew1);
    
             }else{
               //If New Booking Date
               setTimeBookingss1('timebookingsss');
               let startTnew1;
               let endTnew1;
               let timeslotTnew1;
               const rootRefsnews = secondaryDatabase.ref().child('doctorsNew/'+doctorUserId+'/appointmentDetailsNew/inPersonConsultation/'+dateselects+'/afternoon/');
               rootRefsnews.on('value', snap=>{
                   let timesnew1 = snap.val();
                   if (snap.exists()){
                    startTnew1 = timesnew1.startTime;
                    endTnew1 = timesnew1.endTime;
                    timeslotTnew1 = timesnew1.timeSlot;

                    console.log(timesnew1.startTime);
                  }
                  });
               //Make a Time slots from start and end time with time period
               const timeSlotsnew1 = getTimeStopsnew1(startTnew1, endTnew1, timeslotTnew1);
    
               setTimeSlotsNew1(timeSlotsnew1);
    
             }
           });

              //Make a Time slots from start and end time with time period Evening
        function getTimeStopsnew2(startnew2, endnew2, timeslotnew2){
          let startTimenew2 = moment(startnew2, 'HH:mm');
          let endTimenew2 = moment(endnew2, 'HH:mm');
          if( endTimenew2.isBefore(startTimenew2) ){
                endTimenew2.add(1, 'day');
            }
          let timeStopsnew2 = [];
          while(startTimenew2 < endTimenew2){
                timeStopsnew2.push(new moment(startTimenew2).format('HH:mm'));
                startTimenew2.add(timeslotnew2, 'minutes');
            }
          return timeStopsnew2;
      }
    //In-Person Consultation evening
          const rootRefsss = secondaryDatabase.ref().child('doctorsNew/'+doctorUserId+'/appointmentsNew/inPersonConsultation/'+dateselects+'/evening/');
          rootRefsss.on('value', snap=>{
              let times2 = snap.val();
              if (snap.exists()){
                function getBookedTimess2(times2){
                  let timebookedss2 = [];
                  const rootRefssssss = secondaryDatabase.ref().child('doctorsNew/'+doctorUserId+'/appointmentsNew/inPersonConsultation/'+dateselects+'/evening/');
                  rootRefssssss.on('value', snap=>{
                     snap.forEach(function(data) {
                       timebookedss2.push(data.key);
                      });
                    });
                    return timebookedss2;
                  }
                let timebookingss2 = getBookedTimess2(times2);
                setTimeBookingss2(timebookingss2);
                let startTnew2;
                let endTnew2;
                let timeslotTnew2;
                const rootRefsnewss = secondaryDatabase.ref().child('doctorsNew/'+doctorUserId+'/appointmentDetailsNew/inPersonConsultation/'+dateselects+'/evening/');
                rootRefsnewss.on('value', snap=>{
                    let timesnew2 = snap.val();
                    if (snap.exists()){
                     startTnew2 = timesnew2.startTime;
                     endTnew2 = timesnew2.endTime;
                     timeslotTnew2 = timesnew2.timeSlot;

                     console.log(timesnew2.startTime);
                     }
                   });
                //Make a Time slots from start and end time with time period
               const timeSlotsnew2 = getTimeStopsnew2(startTnew2, endTnew2, timeslotTnew2);
               setTimeSlotsNew2(timeSlotsnew2);
    
             }else{
               //If New Booking Date
               setTimeBookingss2('timebookingssss');
               let startTnew2;
               let endTnew2;
               let timeslotTnew2;
               const rootRefsnewss = secondaryDatabase.ref().child('doctorsNew/'+doctorUserId+'/appointmentDetailsNew/inPersonConsultation/'+dateselects+'/evening/');
               rootRefsnewss.on('value', snap=>{
                   let timesnew2 = snap.val();
                   if (snap.exists()){
                    startTnew2 = timesnew2.startTime;
                    endTnew2 = timesnew2.endTime;
                    timeslotTnew2 = timesnew2.timeSlot;

                    console.log(timesnew2.startTime);
                  }
                  });
               //Make a Time slots from start and end time with time period
               const timeSlotsnew2 = getTimeStopsnew2(startTnew2, endTnew2, timeslotTnew2);
    
               setTimeSlotsNew2(timeSlotsnew2);
    
             }
           });


       }

       let booking = (e) => {
         alert('okay');
         console.log('okay');
      //  e.preventDefault();
       // if (window.confirm("Are you sure want to Book Appointment ?")){
        //  auth.onAuthStateChanged((user) => {
          //if (user) {
           
            let userId = '0Ld2tCenQedTibNNBqWct1ruvn03';
            alert(userId);
           secondaryDatabase.ref('users/'+'0Ld2tCenQedTibNNBqWct1ruvn03'+'').once('value', snap=>{
                let userName = snap.val().username;
                let userInfo = {
                    patientName : userName,
                    patientId : userId,
                   /* doctName : (this.state.doctorName),
                    doctId : (this.props.location.state.key),
                    selSpecialist : (this.state.specialist),
                    selDate : (this.state.selectDate),
                    selTime : (this.state.selectedTime),*/
                  };
                  secondaryDatabase.ref('users/'+'0Ld2tCenQedTibNNBqWct1ruvn03'+'/appointmentDetailsNew/inPersonConsultation').push(userInfo);
                  secondaryDatabase.ref('doctorsNew/'+'62nvmLXx1jOJvB247tGrT2pRsVf1'+'/appointmentDetailsNew/inPersonConsultation/'+(selectedDate)+'/morning/'+'/bookings').push(userInfo);
                  secondaryDatabase.ref('doctorsNew/'+'62nvmLXx1jOJvB247tGrT2pRsVf1'+'/appointmentsNew/inPersonConsultation/'+(selectedDate)+'/morning'+'/').update({[(selectedTime)]: "booked"});
            });
         // }
        //});
    /*    }else{
          return false;
        }*/
        }

        let bookings = (e) => {
          alert('okay');
          console.log('okay');
       //  e.preventDefault();
        // if (window.confirm("Are you sure want to Book Appointment ?")){
         //  auth.onAuthStateChanged((user) => {
           //if (user) {
            
             let userId = '0Ld2tCenQedTibNNBqWct1ruvn03';
             alert(userId);
            secondaryDatabase.ref('users/'+'0Ld2tCenQedTibNNBqWct1ruvn03'+'').once('value', snap=>{
                 let userName = snap.val().username;
                 let userInfo = {
                     patientName : userName,
                     patientId : userId,
                    /* doctName : (this.state.doctorName),
                     doctId : (this.props.location.state.key),
                     selSpecialist : (this.state.specialist),
                     selDate : (this.state.selectDate),
                     selTime : (this.state.selectedTime),*/
                   };
                   secondaryDatabase.ref('users/'+'0Ld2tCenQedTibNNBqWct1ruvn03'+'/appointmentDetailsNew/inPersonConsultation').push(userInfo);
                   secondaryDatabase.ref('doctorsNew/'+'62nvmLXx1jOJvB247tGrT2pRsVf1'+'/appointmentDetailsNew/inPersonConsultation/'+(selectedDate)+'/afternoon/'+'/bookings').push(userInfo);
                   secondaryDatabase.ref('doctorsNew/'+'62nvmLXx1jOJvB247tGrT2pRsVf1'+'/appointmentsNew/inPersonConsultation/'+(selectedDate)+'/afternoon'+'/').update({[(selectedTime)]: "booked"});
             });
          // }
         //});
     /*    }else{
           return false;
         }*/
         }

         let bookingss = (e) => {
          alert('okay');
          console.log('okay');
       //  e.preventDefault();
        // if (window.confirm("Are you sure want to Book Appointment ?")){
         //  auth.onAuthStateChanged((user) => {
           //if (user) {
            
             let userId = '0Ld2tCenQedTibNNBqWct1ruvn03';
             alert(userId);
            secondaryDatabase.ref('users/'+'0Ld2tCenQedTibNNBqWct1ruvn03'+'').once('value', snap=>{
                 let userName = snap.val().username;
                 let userInfo = {
                     patientName : userName,
                     patientId : userId,
                    /* doctName : (this.state.doctorName),
                     doctId : (this.props.location.state.key),
                     selSpecialist : (this.state.specialist),
                     selDate : (this.state.selectDate),
                     selTime : (this.state.selectedTime),*/
                   };
                   secondaryDatabase.ref('users/'+'0Ld2tCenQedTibNNBqWct1ruvn03'+'/appointmentDetailsNew/inPersonConsultation').push(userInfo);
                   secondaryDatabase.ref('doctorsNew/'+'62nvmLXx1jOJvB247tGrT2pRsVf1'+'/appointmentDetailsNew/inPersonConsultation/'+(selectedDate)+'/evening/'+'/bookings').push(userInfo);
                   secondaryDatabase.ref('doctorsNew/'+'62nvmLXx1jOJvB247tGrT2pRsVf1'+'/appointmentsNew/inPersonConsultation/'+(selectedDate)+'/evening'+'/').update({[(selectedTime)]: "booked"});
             });
          // }
         //});
     /*    }else{
           return false;
         }*/
         }
    
return (
  <section>
  <Navigation />
<div className="mx-auto" style={{paddingTop: '80px'}}>

<div className="col-xs-14 col-sm-12 col-md-12 col-lg-12 mx-auto pb-4">
  <div className="p-3 col-xs-14 col-sm-12 col-md-12 col-lg-12 mx-auto">
   
      <div>
      <h4 className="text-center pt-4 pb-4">Book Appoinments</h4>

      
<h5>Doctor Name: {doctorName}</h5>
<div className="row">
          <div className="col-sm-4">
          
            <label>Start Date:</label>
            <DatePicker
            dateFormat="dd/MM/yyyy"
            className="date p-1"
            selected={startDate}
            onChange={handleChange}
         //   minDate={new Date()}
            maxDate={new Date().getTime() + 7 * 86400000}
            placeholderText="Select a day"
            />
          
          </div>



          </div>

          <div className="row">
                                        { timeSlotsnew && timeSlotsnew.length > 0 ? timeSlotsnew && timeSlotsnew.map((item,index) =>
                                        { return timebookingss.includes(item) ? (
                                              <div className="col-6 col-xs-6 col-sm-6 col-md-4 col-lg-4 row-eq-height">
                                                  <p className="p-2" key={index}><button className="btn btn-warning" disabled="disabled">{item}</button></p>
                                              </div>
                                            ) : (
                                              <div className="col-6 col-xs-6 col-sm-6 col-md-4 col-lg-4 row-eq-height">
                                                  <p className="p-2" key={index}><button className="btn btn-success" data-toggle="modal" data-target="#inpersonModal" /*onChange={date => setSelectedTime(date)}*/ onClick={() => select(item)} id="available">{item}</button><br></br><small className="pl-2">available</small></p>
                                              </div>
                                            )}
                                        ) : <div className="text-secondary ml-3">*Not available these days*</div>
                                      }
                                  </div>

                                  <div className="row">
                                        { timeSlotsnew1 && timeSlotsnew1.length > 0 ? timeSlotsnew1 && timeSlotsnew1.map((item1,index1) =>
                                        { return timebookingss1.includes(item1) ? (
                                              <div className="col-6 col-xs-6 col-sm-6 col-md-4 col-lg-4 row-eq-height">
                                                  <p className="p-2" key={index1}><button className="btn btn-warning" disabled="disabled">{item1}</button></p>
                                              </div>
                                            ) : (
                                              <div className="col-6 col-xs-6 col-sm-6 col-md-4 col-lg-4 row-eq-height">
                                                  <p className="p-2" key={index1}><button className="btn btn-success" data-toggle="modal" data-target="#inpersonModalAfternoon" onClick={() => select(item1)} id="available">{item1}</button><br></br><small className="pl-2">available</small></p>
                                              </div>
                                            )}
                                        ) : <div className="text-secondary ml-3">*Not available these days*</div>
                                      }
                                  </div>

                                  <div className="row">
                                        { timeSlotsnew2 && timeSlotsnew2.length > 0 ? timeSlotsnew2 && timeSlotsnew2.map((item2,index2) =>
                                        { return timebookingss2.includes(item2) ? (
                                              <div className="col-6 col-xs-6 col-sm-6 col-md-4 col-lg-4 row-eq-height">
                                                  <p className="p-2" key={index2}><button className="btn btn-warning" disabled="disabled">{item2}</button></p>
                                              </div>
                                            ) : (
                                              <div className="col-6 col-xs-6 col-sm-6 col-md-4 col-lg-4 row-eq-height">
                                                  <p className="p-2" key={index2}><button className="btn btn-success" data-toggle="modal" data-target="#inpersonModalEvening" onClick={() => select(item2)} id="available">{item2}</button><br></br><small className="pl-2">available</small></p>
                                              </div>
                                            )}
                                        ) : <div className="text-secondary ml-3">*Not available these days*</div>
                                      }
                                  </div>


                                  <div className="modal col-12 col-xs-12 col-sm-12 col-md-10 col-lg-5 col-xl-5 mx-auto" id="inpersonModal">
              <div className="modal-dialog">
              <div className="modal-content">
              <div className="modal-body">
              <div className="modal-header">
                <h5 className="modal-title text-center">Booking Conformation</h5>
                <button className="close" data-dismiss="modal">&times;</button>
              </div>
              <div id="cont" className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto">
              <div className="mx-auto">
              <div className="mx-auto p-3">
            {/*}  <div className="p-3"><b>Patient Name :</b> {this.state.userName}<br></br></div>
              <div className="p-3"><b>Doctor Name  :</b> {this.state.doctorName}<br></br></div>
                                    <div className="p-3"><b>Booking Date :</b> {this.state.selectDate}<br></br></div>*/}
                            
              <div className="p-3"><b>Booking Time :</b> {selectedTime}<br></br></div>
             {/*} <div className="p-3"><b>Speacialist  :</b> {this.state.specialist}<br></br></div>*/}
              <div className="p-3 row">
                <div><button id="back" className="btn btn-info float-center" data-dismiss="modal" onClick={() => booking(selectedTime)}>Book Confirm</button></div>
                <div className="pl-3"><button className="btn btn-secondary" data-dismiss="modal">Cancel</button></div>
              </div>
              </div>
              </div>
              </div>
              </div>
              </div>
              </div>
              </div>



              <div className="modal col-12 col-xs-12 col-sm-12 col-md-10 col-lg-5 col-xl-5 mx-auto" id="inpersonModalAfternoon">
              <div className="modal-dialog">
              <div className="modal-content">
              <div className="modal-body">
              <div className="modal-header">
                <h5 className="modal-title text-center">Booking Conformation</h5>
                <button className="close" data-dismiss="modal">&times;</button>
              </div>
              <div id="cont" className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto">
              <div className="mx-auto">
              <div className="mx-auto p-3">
            {/*}  <div className="p-3"><b>Patient Name :</b> {this.state.userName}<br></br></div>
              <div className="p-3"><b>Doctor Name  :</b> {this.state.doctorName}<br></br></div>
                                    <div className="p-3"><b>Booking Date :</b> {this.state.selectDate}<br></br></div>*/}
                            
              <div className="p-3"><b>Booking Time :</b> {selectedTime}<br></br></div>
             {/*} <div className="p-3"><b>Speacialist  :</b> {this.state.specialist}<br></br></div>*/}
              <div className="p-3 row">
                <div><button id="back" className="btn btn-info float-center" data-dismiss="modal" onClick={() => bookings(selectedTime)}>Book Confirm</button></div>
                <div className="pl-3"><button className="btn btn-secondary" data-dismiss="modal">Cancel</button></div>
              </div>
              </div>
              </div>
              </div>
              </div>
              </div>
              </div>
              </div>


              <div className="modal col-12 col-xs-12 col-sm-12 col-md-10 col-lg-5 col-xl-5 mx-auto" id="inpersonModalEvening">
              <div className="modal-dialog">
              <div className="modal-content">
              <div className="modal-body">
              <div className="modal-header">
                <h5 className="modal-title text-center">Booking Conformation</h5>
                <button className="close" data-dismiss="modal">&times;</button>
              </div>
              <div id="cont" className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto">
              <div className="mx-auto">
              <div className="mx-auto p-3">
            {/*}  <div className="p-3"><b>Patient Name :</b> {this.state.userName}<br></br></div>
              <div className="p-3"><b>Doctor Name  :</b> {this.state.doctorName}<br></br></div>
                                    <div className="p-3"><b>Booking Date :</b> {this.state.selectDate}<br></br></div>*/}
                            
              <div className="p-3"><b>Booking Time :</b> {selectedTime}<br></br></div>
             {/*} <div className="p-3"><b>Speacialist  :</b> {this.state.specialist}<br></br></div>*/}
              <div className="p-3 row">
                <div><button id="back" className="btn btn-info float-center" data-dismiss="modal" onClick={() => bookingss(selectedTime)}>Book Confirm</button></div>
                <div className="pl-3"><button className="btn btn-secondary" data-dismiss="modal">Cancel</button></div>
              </div>
              </div>
              </div>
              </div>
              </div>
              </div>
              </div>
              </div>

                   </div>         

      </div>
      </div>
              
      </div>
</section>
    );

}
export default Appoinments;
