import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { auth, database} from '../../config/firebase';
import {secondaryDatabase} from '../../config/firebaseSecondary';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Navigation from '../navigation/navigation';
import {setMinutes, setHours} from "date-fns";
import './doctorTimeSlots.css'

function DoctorTimeSlots() {

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [selectEndDate, setSelectEndDate] = useState(null);
  const [timeSlot, setTimeSlot] = useState("15");
  const [visitingType, setVisitingType] = useState("");

  const [consultationFees, setConsultationFees] = useState("");
  const [consultationSymbol, setConsultationSymbol] = useState("UGX");

  const [now, setNow] = useState(new Date());

  const [monStartTime, setMonStartTime] = useState("");
  const [monEndTime, setMonEndTime] = useState("");

  const [tueStartTime, setTueStartTime] = useState("");
  const [tueEndTime, setTueEndTime] = useState("");

  const [wedStartTime, setWedStartTime] = useState("");
  const [wedEndTime, setWedEndTime] = useState("");

  const [thuStartTime, setThuStartTime] = useState("");
  const [thuEndTime, setThuEndTime] = useState("");

  const [friStartTime, setFriStartTime] = useState("");
  const [friEndTime, setFriEndTime] = useState("");

  const [satStartTime, setSatStartTime] = useState("");
  const [satEndTime, setSatEndTime] = useState("");

  const [sunStartTime, setSunStartTime] = useState("");
  const [sunEndTime, setSunEndTime] = useState("");

  const [monStartTimeAf, setMonStartTimeAf] = useState("");
  const [monEndTimeAf, setMonEndTimeAf] = useState("");

  const [tueStartTimeAf, setTueStartTimeAf] = useState("");
  const [tueEndTimeAf, setTueEndTimeAf] = useState("");

  const [wedStartTimeAf, setWedStartTimeAf] = useState("");
  const [wedEndTimeAf, setWedEndTimeAf] = useState("");

  const [thuStartTimeAf, setThuStartTimeAf] = useState("");
  const [thuEndTimeAf, setThuEndTimeAf] = useState("");

  const [friStartTimeAf, setFriStartTimeAf] = useState("");
  const [friEndTimeAf, setFriEndTimeAf] = useState("");

  const [satStartTimeAf, setSatStartTimeAf] = useState("");
  const [satEndTimeAf, setSatEndTimeAf] = useState("");

  const [sunStartTimeAf, setSunStartTimeAf] = useState("");
  const [sunEndTimeAf, setSunEndTimeAf] = useState("");

  const [monStartTimeEv, setMonStartTimeEv] = useState("");
  const [monEndTimeEv, setMonEndTimeEv] = useState("");

  const [tueStartTimeEv, setTueStartTimeEv] = useState("");
  const [tueEndTimeEv, setTueEndTimeEv] = useState("");

  const [wedStartTimeEv, setWedStartTimeEv] = useState("");
  const [wedEndTimeEv, setWedEndTimeEv] = useState("");

  const [thuStartTimeEv, setThuStartTimeEv] = useState("");
  const [thuEndTimeEv, setThuEndTimeEv] = useState("");

  const [friStartTimeEv, setFriStartTimeEv] = useState("");
  const [friEndTimeEv, setFriEndTimeEv] = useState("");

  const [satStartTimeEv, setSatStartTimeEv] = useState("");
  const [satEndTimeEv, setSatEndTimeEv] = useState("");

  const [sunStartTimeEv, setSunStartTimeEv] = useState("");
  const [sunEndTimeEv, setSunEndTimeEv] = useState("");

  //Video

  const [monStartTimeVideo, setMonStartTimeVideo] = useState("");
  const [monEndTimeVideo, setMonEndTimeVideo] = useState("");

  const [tueStartTimeVideo, setTueStartTimeVideo] = useState("");
  const [tueEndTimeVideo, setTueEndTimeVideo] = useState("");

  const [wedStartTimeVideo, setWedStartTimeVideo] = useState("");
  const [wedEndTimeVideo, setWedEndTimeVideo] = useState("");

  const [thuStartTimeVideo, setThuStartTimeVideo] = useState("");
  const [thuEndTimeVideo, setThuEndTimeVideo] = useState("");

  const [friStartTimeVideo, setFriStartTimeVideo] = useState("");
  const [friEndTimeVideo, setFriEndTimeVideo] = useState("");

  const [satStartTimeVideo, setSatStartTimeVideo] = useState("");
  const [satEndTimeVideo, setSatEndTimeVideo] = useState("");

  const [sunStartTimeVideo, setSunStartTimeVideo] = useState("");
  const [sunEndTimeVideo, setSunEndTimeVideo] = useState("");

  const [monStartTimeAfVideo, setMonStartTimeAfVideo] = useState("");
  const [monEndTimeAfVideo, setMonEndTimeAfVideo] = useState("");

  const [tueStartTimeAfVideo, setTueStartTimeAfVideo] = useState("");
  const [tueEndTimeAfVideo, setTueEndTimeAfVideo] = useState("");

  const [wedStartTimeAfVideo, setWedStartTimeAfVideo] = useState("");
  const [wedEndTimeAfVideo, setWedEndTimeAfVideo] = useState("");

  const [thuStartTimeAfVideo, setThuStartTimeAfVideo] = useState("");
  const [thuEndTimeAfVideo, setThuEndTimeAfVideo] = useState("");

  const [friStartTimeAfVideo, setFriStartTimeAfVideo] = useState("");
  const [friEndTimeAfVideo, setFriEndTimeAfVideo] = useState("");

  const [satStartTimeAfVideo, setSatStartTimeAfVideo] = useState("");
  const [satEndTimeAfVideo, setSatEndTimeAfVideo] = useState("");

  const [sunStartTimeAfVideo, setSunStartTimeAfVideo] = useState("");
  const [sunEndTimeAfVideo, setSunEndTimeAfVideo] = useState("");

  const [monStartTimeEvVideo, setMonStartTimeEvVideo] = useState("");
  const [monEndTimeEvVideo, setMonEndTimeEvVideo] = useState("");

  const [tueStartTimeEvVideo, setTueStartTimeEvVideo] = useState("");
  const [tueEndTimeEvVideo, setTueEndTimeEvVideo] = useState("");

  const [wedStartTimeEvVideo, setWedStartTimeEvVideo] = useState("");
  const [wedEndTimeEvVideo, setWedEndTimeEvVideo] = useState("");

  const [thuStartTimeEvVideo, setThuStartTimeEvVideo] = useState("");
  const [thuEndTimeEvVideo, setThuEndTimeEvVideo] = useState("");

  const [friStartTimeEvVideo, setFriStartTimeEvVideo] = useState("");
  const [friEndTimeEvVideo, setFriEndTimeEvVideo] = useState("");

  const [satStartTimeEvVideo, setSatStartTimeEvVideo] = useState("");
  const [satEndTimeEvVideo, setSatEndTimeEvVideo] = useState("");

  const [sunStartTimeEvVideo, setSunStartTimeEvVideo] = useState("");
  const [sunEndTimeEvVideo, setSunEndTimeEvVideo] = useState("");

  //Chat
  const [monStartTimeChat, setMonStartTimeChat] = useState("");
  const [monEndTimeChat, setMonEndTimeChat] = useState("");

  const [tueStartTimeChat, setTueStartTimeChat] = useState("");
  const [tueEndTimeChat, setTueEndTimeChat] = useState("");

  const [wedStartTimeChat, setWedStartTimeChat] = useState("");
  const [wedEndTimeChat, setWedEndTimeChat] = useState("");

  const [thuStartTimeChat, setThuStartTimeChat] = useState("");
  const [thuEndTimeChat, setThuEndTimeChat] = useState("");

  const [friStartTimeChat, setFriStartTimeChat] = useState("");
  const [friEndTimeChat, setFriEndTimeChat] = useState("");

  const [satStartTimeChat, setSatStartTimeChat] = useState("");
  const [satEndTimeChat, setSatEndTimeChat] = useState("");

  const [sunStartTimeChat, setSunStartTimeChat] = useState("");
  const [sunEndTimeChat, setSunEndTimeChat] = useState("");

  const [monStartTimeAfChat, setMonStartTimeAfChat] = useState("");
  const [monEndTimeAfChat, setMonEndTimeAfChat] = useState("");

  const [tueStartTimeAfChat, setTueStartTimeAfChat] = useState("");
  const [tueEndTimeAfChat, setTueEndTimeAfChat] = useState("");

  const [wedStartTimeAfChat, setWedStartTimeAfChat] = useState("");
  const [wedEndTimeAfChat, setWedEndTimeAfChat] = useState("");

  const [thuStartTimeAfChat, setThuStartTimeAfChat] = useState("");
  const [thuEndTimeAfChat, setThuEndTimeAfChat] = useState("");

  const [friStartTimeAfChat, setFriStartTimeAfChat] = useState("");
  const [friEndTimeAfChat, setFriEndTimeAfChat] = useState("");

  const [satStartTimeAfChat, setSatStartTimeAfChat] = useState("");
  const [satEndTimeAfChat, setSatEndTimeAfChat] = useState("");

  const [sunStartTimeAfChat, setSunStartTimeAfChat] = useState("");
  const [sunEndTimeAfChat, setSunEndTimeAfChat] = useState("");

  const [monStartTimeEvChat, setMonStartTimeEvChat] = useState("");
  const [monEndTimeEvChat, setMonEndTimeEvChat] = useState("");

  const [tueStartTimeEvChat, setTueStartTimeEvChat] = useState("");
  const [tueEndTimeEvChat, setTueEndTimeEvChat] = useState("");

  const [wedStartTimeEvChat, setWedStartTimeEvChat] = useState("");
  const [wedEndTimeEvChat, setWedEndTimeEvChat] = useState("");

  const [thuStartTimeEvChat, setThuStartTimeEvChat] = useState("");
  const [thuEndTimeEvChat, setThuEndTimeEvChat] = useState("");

  const [friStartTimeEvChat, setFriStartTimeEvChat] = useState("");
  const [friEndTimeEvChat, setFriEndTimeEvChat] = useState("");

  const [satStartTimeEvChat, setSatStartTimeEvChat] = useState("");
  const [satEndTimeEvChat, setSatEndTimeEvChat] = useState("");

  const [sunStartTimeEvChat, setSunStartTimeEvChat] = useState("");
  const [sunEndTimeEvChat, setSunEndTimeEvChat] = useState("");

  const [email, setEmail] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
    const [adminName, setAdminName] = useState("");
     const [adminId, setAdminId] = useState('');
    const [alertMessage, setAlertMessage] = useState(false);
    const [progress, setProgress] = useState("");

    const [doctorName, setDoctorName] = useState("");


    const [previousEndDate, setPreviousEndDate] = useState(new Date());
    const [previousEndDate1, setPreviousEndDate1] = useState(new Date().getTime() + 6 * 86400000);

    const [previousEndDateVideo, setPreviousEndDateVideo] = useState(new Date());
    const [previousEndDate1Video, setPreviousEndDate1Video] = useState(new Date().getTime() + 6 * 86400000);

    const [previousEndDateChat, setPreviousEndDateChat] = useState(new Date());
    const [previousEndDate1Chat, setPreviousEndDate1Chat] = useState(new Date().getTime() + 6 * 86400000);


    const [dateArray, setdateArray] = useState("");

    const [slotSet, setSlotSet] = useState([]);
    const [slotSet1, setSlotSet1] = useState([]);
    const [slotSet2, setSlotSet2] = useState([]);
    const [slotSet3, setSlotSet3] = useState([]);
    const [slotSet4, setSlotSet4] = useState([]);
    const [slotSet5, setSlotSet5] = useState([]);
    const [slotSet6, setSlotSet6] = useState([]);

    const [slotSetAf, setSlotSetAf] = useState([]);
    const [slotSet1Af, setSlotSet1Af] = useState([]);
    const [slotSet2Af, setSlotSet2Af] = useState([]);
    const [slotSet3Af, setSlotSet3Af] = useState([]);
    const [slotSet4Af, setSlotSet4Af] = useState([]);
    const [slotSet5Af, setSlotSet5Af] = useState([]);
    const [slotSet6Af, setSlotSet6Af] = useState([]);

    const [slotSetEv, setSlotSetEv] = useState([]);
    const [slotSet1Ev, setSlotSet1Ev] = useState([]);
    const [slotSet2Ev, setSlotSet2Ev] = useState([]);
    const [slotSet3Ev, setSlotSet3Ev] = useState([]);
    const [slotSet4Ev, setSlotSet4Ev] = useState([]);
    const [slotSet5Ev, setSlotSet5Ev] = useState([]);
    const [slotSet6Ev, setSlotSet6Ev] = useState([]);

    const [slotSetVideo, setSlotSetVideo] = useState([]);
    const [slotSet1Video, setSlotSet1Video] = useState([]);
    const [slotSet2Video, setSlotSet2Video] = useState([]);
    const [slotSet3Video, setSlotSet3Video] = useState([]);
    const [slotSet4Video, setSlotSet4Video] = useState([]);
    const [slotSet5Video, setSlotSet5Video] = useState([]);
    const [slotSet6Video, setSlotSet6Video] = useState([]);

    const [slotSetAfVideo, setSlotSetAfVideo] = useState([]);
    const [slotSet1AfVideo, setSlotSet1AfVideo] = useState([]);
    const [slotSet2AfVideo, setSlotSet2AfVideo] = useState([]);
    const [slotSet3AfVideo, setSlotSet3AfVideo] = useState([]);
    const [slotSet4AfVideo, setSlotSet4AfVideo] = useState([]);
    const [slotSet5AfVideo, setSlotSet5AfVideo] = useState([]);
    const [slotSet6AfVideo, setSlotSet6AfVideo] = useState([]);

    const [slotSetEvVideo, setSlotSetEvVideo] = useState([]);
    const [slotSet1EvVideo, setSlotSet1EvVideo] = useState([]);
    const [slotSet2EvVideo, setSlotSet2EvVideo] = useState([]);
    const [slotSet3EvVideo, setSlotSet3EvVideo] = useState([]);
    const [slotSet4EvVideo, setSlotSet4EvVideo] = useState([]);
    const [slotSet5EvVideo, setSlotSet5EvVideo] = useState([]);
    const [slotSet6EvVideo, setSlotSet6EvVideo] = useState([]);

    const [slotSetChat, setSlotSetChat] = useState([]);
    const [slotSet1Chat, setSlotSet1Chat] = useState([]);
    const [slotSet2Chat, setSlotSet2Chat] = useState([]);
    const [slotSet3Chat, setSlotSet3Chat] = useState([]);
    const [slotSet4Chat, setSlotSet4Chat] = useState([]);
    const [slotSet5Chat, setSlotSet5Chat] = useState([]);
    const [slotSet6Chat, setSlotSet6Chat] = useState([]);

    const [slotSetAfChat, setSlotSetAfChat] = useState([]);
    const [slotSet1AfChat, setSlotSet1AfChat] = useState([]);
    const [slotSet2AfChat, setSlotSet2AfChat] = useState([]);
    const [slotSet3AfChat, setSlotSet3AfChat] = useState([]);
    const [slotSet4AfChat, setSlotSet4AfChat] = useState([]);
    const [slotSet5AfChat, setSlotSet5AfChat] = useState([]);
    const [slotSet6AfChat, setSlotSet6AfChat] = useState([]);

    const [slotSetEvChat, setSlotSetEvChat] = useState([]);
    const [slotSet1EvChat, setSlotSet1EvChat] = useState([]);
    const [slotSet2EvChat, setSlotSet2EvChat] = useState([]);
    const [slotSet3EvChat, setSlotSet3EvChat] = useState([]);
    const [slotSet4EvChat, setSlotSet4EvChat] = useState([]);
    const [slotSet5EvChat, setSlotSet5EvChat] = useState([]);
    const [slotSet6EvChat, setSlotSet6EvChat] = useState([]);

    const [morMonLast, setMorMonLast] = useState('');
    const [morTueLast, setMorTueLast] = useState('');
    const [morWedLast, setMorWedLast] = useState('');
    const [morThuLast, setMorThuLast] = useState('');
    const [morFriLast, setMorFriLast] = useState('');
    const [morSatLast, setMorSatLast] = useState('');
    const [morSunLast, setMorSunLast] = useState('');

    const [afMonLast, setAfMonLast] = useState('');
    const [afTueLast, setAfTueLast] = useState('');
    const [afWedLast, setAfWedLast] = useState('');
    const [afThuLast, setAfThuLast] = useState('');
    const [afFriLast, setAfFriLast] = useState('');
    const [afSatLast, setAfSatLast] = useState('');
    const [afSunLast, setAfSunLast] = useState('');

    const [evMonLast, setEvMonLast] = useState('');
    const [evTueLast, setEvTueLast] = useState('');
    const [evWedLast, setEvWedLast] = useState('');
    const [evThuLast, setEvThuLast] = useState('');
    const [evFriLast, setEvFriLast] = useState('');
    const [evSatLast, setEvSatLast] = useState('');
    const [evSunLast, setEvSunLast] = useState('');

    const locationPass = useLocation();
    let history = useHistory();
    useEffect(() => {     

        const getUserData = async () => {
           await auth.onAuthStateChanged((user) => {
           if (user) {
             setAdminId(auth.currentUser.uid);
             setEmail(auth.currentUser.email);
             console.log(auth.currentUser.uid);
             console.log(auth.currentUser.email);
            
            let userId = user.uid;
            database.ref('admin/'+auth.currentUser.uid+'/').once('value', snap=>{ 
                setAdminName(snap.val().userName); 
            }).then(()=>{
                if(email == adminEmail){
//window.location.href = '/dashboard';
/*secondaryDatabase.ref().child('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew/inPersonConsultation/').once('value', snap => {

  if(snap.exists()) {
    let returnArrayCheck = [];
    snap.forEach(child => {
      var inPersonDate = child.key;
      var parts = inPersonDate.split('-');
      var newdate = new Date(parts[2] + "-" + parts[1] + "-" + parts[0]);
      returnArrayCheck.push(newdate);
      console.log(returnArrayCheck); 
      var maxDate=new Date(Math.max.apply(null,returnArrayCheck))
      console.log(maxDate);
      var newdate1 = new Date(maxDate.setTime(maxDate.getTime() + 1 * 86400000));
      var newdate2 = new Date(maxDate.setTime(maxDate.getTime() + 7 * 86400000));
      if(new Date(maxDate) > new Date()){
       setPreviousEndDate(newdate1);
       setPreviousEndDate1(newdate2);
      }
      else{
        setPreviousEndDate(new Date());
        setPreviousEndDate1(new Date(new Date().setTime(new Date().getTime() + 7 * 86400000)));
      }

    });

  }else{
    let previous = moment();
    setPreviousEndDate(new Date());
    setPreviousEndDate1(new Date().getTime() + 7 * 86400000);
   // setDoctorName(snap.val().doctorName);
  }
});

secondaryDatabase.ref().child('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew/videoConsultation').once('value', snap => {
  if(snap.exists()) {
    let returnArrayCheckVideo = [];
    snap.forEach(child => {
var inVideoDate = child.key;
var parts = inVideoDate.split('-');
var newdate = new Date(parts[2] + "-" + parts[1] + "-" + parts[0]);
returnArrayCheckVideo.push(newdate);
console.log(returnArrayCheckVideo); 
var maxDate=new Date(Math.max.apply(null,returnArrayCheckVideo))
console.log(maxDate);
var newdate1 = new Date(maxDate.setTime(maxDate.getTime() + 1 * 86400000));
var newdate2 = new Date(maxDate.setTime(maxDate.getTime() + 7 * 86400000));
if(new Date(maxDate) > new Date()){
 setPreviousEndDateVideo(newdate1);
 setPreviousEndDate1Video(newdate2);
}
else{
  setPreviousEndDateVideo(new Date());
  setPreviousEndDate1Video(new Date(new Date().setTime(new Date().getTime() + 7 * 86400000)));
}


    });
  }else{
    let previous = moment();
    setPreviousEndDateVideo(new Date());
    setPreviousEndDate1Video(new Date().getTime() + 7 * 86400000);
   // setDoctorName(snap.val().doctorName);
  }
});

secondaryDatabase.ref().child('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew/liveChatConsultation').once('value', snap => {
  if(snap.exists()) {
    let returnArrayCheckChat = [];
    snap.forEach(child => {
var inChatDate = child.key;
var parts = inChatDate.split('-');
var newdate = new Date(parts[2] + "-" + parts[1] + "-" + parts[0]);
returnArrayCheckChat.push(newdate);
console.log(returnArrayCheckChat); 
var maxDate=new Date(Math.max.apply(null,returnArrayCheckChat))
console.log(maxDate);
var newdate1 = new Date(maxDate.setTime(maxDate.getTime() + 1 * 86400000));
var newdate2 = new Date(maxDate.setTime(maxDate.getTime() + 7 * 86400000));
if(new Date(maxDate) > new Date()){
 setPreviousEndDateChat(newdate1);
 setPreviousEndDate1Chat(newdate2);
}
else{
  setPreviousEndDateChat(new Date());
  setPreviousEndDate1Chat(new Date(new Date().setTime(new Date().getTime() + 7 * 86400000)));
}

    });
  }else{
    let previous = moment();
    setPreviousEndDateChat(new Date());
    setPreviousEndDate1Chat(new Date().getTime() + 7 * 86400000);
   // setDoctorName(snap.val().doctorName);
  }
});*/

secondaryDatabase.ref('doctorsNew/'
+(locationPass.state.doctorIdPass))
.once('value', snap=>{
setDoctorName(snap.val().doctorName);


});

                }
                else{
                    setAlertMessage(true);
                }
            })

            
             }
             else {
              window.location.href = '/';
             }
          });  
        }

      
          getUserData(); 
   
    
 
   
       },[]);
    
           

let reset = e => {
    e.preventDefault();
    history.push({
      pathname: 'viewAdminDoctor',
      state: {doctorIdPass: locationPass.state.doctorIdPass,
        departmentIdPass: locationPass.state.departmentIdPass,
      adminIdPass: locationPass.state.adminIdPass,
    superAdminIdPass:locationPass.state.superAdminIdPass}
    })
}

let signOut = e => { 
  auth.signOut().then(()=>{
    window.location.href = '/';
  })
} 

let dateFormat = (date) => {
  var postDate = moment.unix(date);
  var data = moment(postDate).format("llll");        
  return data;
}
 
let handleMonEndTime = (time) => {
  setSlotSet([]);
  console.log(time.target.value);
  let monEndTimeHHMM = (moment(time.target.value, 'hh:mm A').format("hh:mm A"));
  setMonEndTime(time.target.value);
var dayTime = moment.utc(monEndTimeHHMM, 'hh:mm A');
var dayTime2 = moment.utc({h: monStartTime});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.keys = dayTime2.format('hh:mm A');
  data.patientId = "null";
  data.slotStart = dayTime2.format('hh:mm A');
  data.slotEnd = dayTime2.clone().add(timeSlot, 'minute').format('hh:mm A');
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);



dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm A'));
}
setSlotSet(slotSet => slotSet.concat(returnArray));
console.log(slotSet);
}

let handleTueEndTime = (time) => {
  setSlotSet1([]);
  console.log(time.target.value);
  let tueEndTimeHHMM = (moment(time.target.value, 'hh:mm A').format("hh:mm A"));
  setTueEndTime(time.target.value);
var dayTime = moment.utc(tueEndTimeHHMM, 'hh:mm A');
var dayTime2 = moment.utc({h: tueStartTime});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = dayTime2.format('hh:mm A');
  data.slotEnd = dayTime2.clone().add(timeSlot, 'minute').format('hh:mm A');
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm A'));
}
setSlotSet1(slotSet1 => slotSet1.concat(returnArray));
} 


let handleWedEndTime = (time) => {
  setSlotSet2([]);
  console.log(time.target.value);
  let wedEndTimeHHMM = (moment(time.target.value, 'hh:mm A').format("hh:mm A"));
  setWedEndTime(time.target.value);
var dayTime = moment.utc(wedEndTimeHHMM, 'hh:mm A');
var dayTime2 = moment.utc({h: wedStartTime});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = dayTime2.format('hh:mm A');
  data.slotEnd = dayTime2.clone().add(timeSlot, 'minute').format('hh:mm A');
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm A'));
}
setSlotSet2(slotSet2 => slotSet2.concat(returnArray));
} 

let handleThuEndTime = (time) => {
  setSlotSet3([]);
  console.log(time.target.value);
  let thuEndTimeHHMM = (moment(time.target.value, 'hh:mm A').format("hh:mm A"));
  setThuEndTime(time.target.value);
var dayTime = moment.utc(thuEndTimeHHMM, 'hh:mm A');
var dayTime2 = moment.utc({h: thuStartTime});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = dayTime2.format('hh:mm A');
  data.slotEnd = dayTime2.clone().add(timeSlot, 'minute').format('hh:mm A');
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm A'));
}
setSlotSet3(slotSet3 => slotSet3.concat(returnArray));
} 

let handleFriEndTime = (time) => {
  setSlotSet4([]);
  console.log(time.target.value);
  let friEndTimeHHMM = (moment(time.target.value, 'hh:mm A').format("hh:mm A"));
  setFriEndTime(time.target.value);
var dayTime = moment.utc(friEndTimeHHMM, 'hh:mm A');
var dayTime2 = moment.utc({h: friStartTime});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = dayTime2.format('hh:mm A');
  data.slotEnd = dayTime2.clone().add(timeSlot, 'minute').format('hh:mm A');
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm A'));
}
setSlotSet4(slotSet4 => slotSet4.concat(returnArray));
} 

let handleSatEndTime = (time) => {
  setSlotSet5([]);
  console.log(time.target.value);
  let satEndTimeHHMM = (moment(time.target.value, 'hh:mm A').format("hh:mm A"));
  setSatEndTime(time.target.value);
var dayTime = moment.utc(satEndTimeHHMM, 'hh:mm A');
var dayTime2 = moment.utc({h: satStartTime});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = dayTime2.format('hh:mm A');
  data.slotEnd = dayTime2.clone().add(timeSlot, 'minute').format('hh:mm A');
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm A'));
}
setSlotSet5(slotSet5 => slotSet5.concat(returnArray));
} 

let handleSunEndTime = (time) => {
  setSlotSet6([]);
  console.log(time.target.value);
  let sunEndTimeHHMM = (moment(time.target.value, 'hh:mm A').format("hh:mm A"));
  setSunEndTime(time.target.value);
var dayTime = moment.utc(sunEndTimeHHMM, 'hh:mm A');
var dayTime2 = moment.utc({h: sunStartTime});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = dayTime2.format('hh:mm A');
  data.slotEnd = dayTime2.clone().add(timeSlot, 'minute').format('hh:mm A');
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm A'));
}
setSlotSet6(slotSet6 => slotSet6.concat(returnArray));
} 

let handleMonEndTimeAf = (time) => {
  setSlotSetAf([]);
  console.log(time.target.value);
  let monEndTimeHHMMAf = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setMonEndTimeAf(time.target.value);
var dayTime = moment.utc(monEndTimeHHMMAf, 'hh:mm');
if(monStartTimeAf == "12:00 PM"){
  var dayTime2 = moment.utc({h: "00:00 PM"});
}
else{
  var dayTime2 = moment.utc({h: monStartTimeAf});
}

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSetAf(slotSetAf => slotSetAf.concat(returnArray));
} 

let handleTueEndTimeAf = (time) => {
  setSlotSet1Af([]);
  console.log(time.target.value);
  let tueEndTimeHHMMAf = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setTueEndTimeAf(time.target.value);
var dayTime = moment.utc(tueEndTimeHHMMAf, 'hh:mm');
if(tueStartTimeAf == "12:00 PM"){
  var dayTime2 = moment.utc({h: "00:00 PM"});
}
else{
  var dayTime2 = moment.utc({h: tueStartTimeAf});
}

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet1Af(slotSet1Af => slotSet1Af.concat(returnArray));
} 

let handleWedEndTimeAf = (time) => {
  setSlotSet2Af([]);
  console.log(time.target.value);
  let wedEndTimeHHMMAf = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setWedEndTimeAf(time.target.value);
var dayTime = moment.utc(wedEndTimeHHMMAf, 'hh:mm');
if(wedStartTimeAf == "12:00 PM"){
  var dayTime2 = moment.utc({h: "00:00 PM"});
}
else{
  var dayTime2 = moment.utc({h: wedStartTimeAf});
}

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet2Af(slotSet2Af => slotSet2Af.concat(returnArray));
} 

let handleThuEndTimeAf = (time) => {
  setSlotSet3Af([]);
  console.log(time.target.value);
  let thuEndTimeHHMMAf = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setThuEndTimeAf(time.target.value);
var dayTime = moment.utc(thuEndTimeHHMMAf, 'hh:mm');
if(thuStartTimeAf == "12:00 PM"){
  var dayTime2 = moment.utc({h: "00:00 PM"});
}
else{
  var dayTime2 = moment.utc({h: thuStartTimeAf});
}

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet3Af(slotSet3Af => slotSet3Af.concat(returnArray));
} 

let handleFriEndTimeAf = (time) => {
  setSlotSet4Af([]);
  console.log(time.target.value);
  let friEndTimeHHMMAf = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setFriEndTimeAf(time.target.value);
var dayTime = moment.utc(friEndTimeHHMMAf, 'hh:mm');
if(friStartTimeAf == "12:00 PM"){
  var dayTime2 = moment.utc({h: "00:00 PM"});
}
else{
  var dayTime2 = moment.utc({h: friStartTimeAf});
}

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet4Af(slotSet4Af => slotSet4Af.concat(returnArray));
} 

let handleSatEndTimeAf = (time) => {
  setSlotSet5Af([]);
  console.log(time.target.value);
  let satEndTimeHHMMAf = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setSatEndTimeAf(time.target.value);
var dayTime = moment.utc(satEndTimeHHMMAf, 'hh:mm');
if(satStartTimeAf == "12:00 PM"){
  var dayTime2 = moment.utc({h: "00:00 PM"});
}
else{
  var dayTime2 = moment.utc({h: satStartTimeAf});
}

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet5Af(slotSet5Af => slotSet5Af.concat(returnArray));
} 

let handleSunEndTimeAf = (time) => {
  setSlotSet6Af([]);
  console.log(time.target.value);
  let sunEndTimeHHMMAf = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setSunEndTimeAf(time.target.value);
var dayTime = moment.utc(sunEndTimeHHMMAf, 'hh:mm');
if(sunStartTimeAf == "12:00 PM"){
  var dayTime2 = moment.utc({h: "00:00 PM"});
}
else{
  var dayTime2 = moment.utc({h: sunStartTimeAf});
}

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet6Af(slotSet6Af => slotSet6Af.concat(returnArray));
} 

let handleMonEndTimeEv = (time) => {
  setSlotSetEv([]);
  console.log(time.target.value);
  let monEndTimeHHMMEv = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setMonEndTimeEv(time.target.value);
var dayTime = moment.utc(monEndTimeHHMMEv, 'hh:mm');
var dayTime2 = moment.utc({h: monStartTimeEv});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSetEv(slotSetEv => slotSetEv.concat(returnArray));
}  

let handleTueEndTimeEv = (time) => {
  setSlotSet1Ev([]);
  console.log(time.target.value);
  let tueEndTimeHHMMEv = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setTueEndTimeEv(time.target.value);
var dayTime = moment.utc(tueEndTimeHHMMEv, 'hh:mm');
var dayTime2 = moment.utc({h: tueStartTimeEv});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet1Ev(slotSet1Ev => slotSet1Ev.concat(returnArray));
} 

let handleWedEndTimeEv = (time) => {
  setSlotSet2Ev([]);
  console.log(time.target.value);
  let wedEndTimeHHMMEv = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setWedEndTimeEv(time.target.value);
var dayTime = moment.utc(wedEndTimeHHMMEv, 'hh:mm');
var dayTime2 = moment.utc({h: wedStartTimeEv});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet2Ev(slotSet2Ev => slotSet2Ev.concat(returnArray));
} 

let handleThuEndTimeEv = (time) => {
  setSlotSet3Ev([]);
  console.log(time.target.value);
  let thuEndTimeHHMMEv = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setThuEndTimeEv(time.target.value);
var dayTime = moment.utc(thuEndTimeHHMMEv, 'hh:mm');
var dayTime2 = moment.utc({h: thuStartTimeEv});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet3Ev(slotSet3Ev => slotSet3Ev.concat(returnArray));
} 

let handleFriEndTimeEv = (time) => {
  setSlotSet4Ev([]);
  console.log(time.target.value);
  let friEndTimeHHMMEv = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setFriEndTimeEv(time.target.value);
var dayTime = moment.utc(friEndTimeHHMMEv, 'hh:mm');
var dayTime2 = moment.utc({h: friStartTimeEv});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet4Ev(slotSet4Ev => slotSet4Ev.concat(returnArray));
} 

let handleSatEndTimeEv = (time) => {
  setSlotSet5Ev([]);
  console.log(time.target.value);
  let satEndTimeHHMMEv = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setSatEndTimeEv(time.target.value);
var dayTime = moment.utc(satEndTimeHHMMEv, 'hh:mm');
var dayTime2 = moment.utc({h: satStartTimeEv});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet5Ev(slotSet5Ev => slotSet5Ev.concat(returnArray));
}  

let handleSunEndTimeEv = (time) => {
  setSlotSet6Ev([]);
  console.log(time.target.value);
  let sunEndTimeHHMMEv = (moment(time.target.value, 'hh:mm').format('hh:mm'));
  setSunEndTimeEv(time.target.value);
var dayTime = moment.utc(sunEndTimeHHMMEv, 'hh:mm');
var dayTime2 = moment.utc({h: (moment(sunStartTimeEv, 'hh:mm').format('hh:mm'))});

let returnArray = [];
while (dayTime2 < dayTime) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet6Ev(slotSet6Ev => slotSet6Ev.concat(returnArray));
} 


let handleMonEndTimeVideo = (time) => {
 setSlotSetVideo([]);
 console.log(time.target.value);
 let monEndTimeHHMMVideo = (moment(time.target.value, 'hh:mm A').format("hh:mm A"));
 setMonEndTimeVideo(time.target.value);
var dayTime = moment.utc(monEndTimeHHMMVideo, 'hh:mm A');
var dayTime2 = moment.utc({h: monStartTimeVideo});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

 var data = {};
 data.keys = dayTime2.format('hh:mm A');
 data.patientId = "null";
 data.slotStart = dayTime2.format('hh:mm A');
 data.slotEnd = dayTime2.clone().add(timeSlot, 'minute').format('hh:mm A');
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm A'));
}
setSlotSetVideo(slotSetVideo => slotSetVideo.concat(returnArray));
console.log(slotSetVideo);
} 

let handleTueEndTimeVideo = (time) => {
 setSlotSet1Video([]);
 console.log(time.target.value);
 let tueEndTimeHHMMVideo = (moment(time.target.value, 'hh:mm A').format("hh:mm A"));
 setTueEndTimeVideo(time.target.value);
var dayTime = moment.utc(tueEndTimeHHMMVideo, 'hh:mm A');
var dayTime2 = moment.utc({h: tueStartTimeVideo});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

 var data = {};
 data.patientId = "null";
 data.slotStart = dayTime2.format('hh:mm A');
 data.slotEnd = dayTime2.clone().add(timeSlot, 'minute').format('hh:mm A');
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm A'));
}
setSlotSet1Video(slotSet1Video => slotSet1Video.concat(returnArray));
}  

let handleWedEndTimeVideo = (time) => {
 setSlotSet2Video([]);
 console.log(time.target.value);
 let wedEndTimeHHMMVideo = (moment(time.target.value, 'hh:mm A').format("hh:mm A"));
 setWedEndTimeVideo(time.target.value);
var dayTime = moment.utc(wedEndTimeHHMMVideo, 'hh:mm A');
var dayTime2 = moment.utc({h: wedStartTimeVideo});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

 var data = {};
 data.patientId = "null";
 data.slotStart = dayTime2.format('hh:mm A');
 data.slotEnd = dayTime2.clone().add(timeSlot, 'minute').format('hh:mm A');
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm A'));
}
setSlotSet2Video(slotSet2Video => slotSet2Video.concat(returnArray));
} 

let handleThuEndTimeVideo = (time) => {
 setSlotSet3Video([]);
 console.log(time.target.value);
 let thuEndTimeHHMMVideo = (moment(time.target.value, 'hh:mm A').format("hh:mm A"));
 setThuEndTimeVideo(time.target.value);
var dayTime = moment.utc(thuEndTimeHHMMVideo, 'hh:mm A');
var dayTime2 = moment.utc({h: thuStartTimeVideo});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

 var data = {};
 data.patientId = "null";
 data.slotStart = dayTime2.format('hh:mm A');
 data.slotEnd = dayTime2.clone().add(timeSlot, 'minute').format('hh:mm A');
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm A'));
}
setSlotSet3Video(slotSet3Video => slotSet3Video.concat(returnArray));
} 

let handleFriEndTimeVideo = (time) => {
 setSlotSet4Video([]);
 console.log(time.target.value);
 let friEndTimeHHMMVideo = (moment(time.target.value, 'hh:mm A').format("hh:mm A"));
 setFriEndTimeVideo(time.target.value);
var dayTime = moment.utc(friEndTimeHHMMVideo, 'hh:mm A');
var dayTime2 = moment.utc({h: friStartTimeVideo});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

 var data = {};
 data.patientId = "null";
 data.slotStart = dayTime2.format('hh:mm A');
 data.slotEnd = dayTime2.clone().add(timeSlot, 'minute').format('hh:mm A');
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm A'));
}
setSlotSet4Video(slotSet4Video => slotSet4Video.concat(returnArray));
} 


let handleSatEndTimeVideo = (time) => {
 setSlotSet5Video([]);
 console.log(time.target.value);
 let satEndTimeHHMMVideo = (moment(time.target.value, 'hh:mm A').format("hh:mm A"));
 setSatEndTimeVideo(time.target.value);
var dayTime = moment.utc(satEndTimeHHMMVideo, 'hh:mm A');
var dayTime2 = moment.utc({h: satStartTimeVideo});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

 var data = {};
 data.patientId = "null";
 data.slotStart = dayTime2.format('hh:mm A');
 data.slotEnd = dayTime2.clone().add(timeSlot, 'minute').format('hh:mm A');
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm A'));
}
setSlotSet5Video(slotSet5Video => slotSet5Video.concat(returnArray));
} 

let handleSunEndTimeVideo = (time) => {
 setSlotSet6Video([]);
 console.log(time.target.value);
 let sunEndTimeHHMMVideo = (moment(time.target.value, 'hh:mm A').format("hh:mm A"));
 setSunEndTimeVideo(time.target.value);
var dayTime = moment.utc(sunEndTimeHHMMVideo, 'hh:mm A');
var dayTime2 = moment.utc({h: sunStartTimeVideo});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

 var data = {};
 data.patientId = "null";
 data.slotStart = dayTime2.format('hh:mm A');
 data.slotEnd = dayTime2.clone().add(timeSlot, 'minute').format('hh:mm A');
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm A'));
}
setSlotSet6Video(slotSet6Video => slotSet6Video.concat(returnArray));
} 

let handleMonEndTimeAfVideo = (time) => {
  setSlotSetAfVideo([]);
  console.log(time.target.value);
  let monEndTimeHHMMAfVideo = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setMonEndTimeAfVideo(time.target.value);
var dayTime = moment.utc(monEndTimeHHMMAfVideo, 'hh:mm');
if(monStartTimeAfVideo == "12:00 PM"){
  var dayTime2 = moment.utc({h: "00:00 PM"});
}
else{
  var dayTime2 = moment.utc({h: monStartTimeAfVideo});
}

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSetAfVideo(slotSetAfVideo => slotSetAfVideo.concat(returnArray));
} 

let handleTueEndTimeAfVideo = (time) => {
  setSlotSet1AfVideo([]);
  console.log(time.target.value);
  let tueEndTimeHHMMAfVideo = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setTueEndTimeAfVideo(time.target.value);
var dayTime = moment.utc(tueEndTimeHHMMAfVideo, 'hh:mm');
if(tueStartTimeAfVideo == "12:00 PM"){
  var dayTime2 = moment.utc({h: "00:00 PM"});
}
else{
  var dayTime2 = moment.utc({h: tueStartTimeAfVideo});
}

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet1AfVideo(slotSet1AfVideo => slotSet1AfVideo.concat(returnArray));
} 

let handleWedEndTimeAfVideo = (time) => {
  setSlotSet2AfVideo([]);
  console.log(time.target.value);
  let wedEndTimeHHMMAfVideo = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setWedEndTimeAfVideo(time.target.value);
var dayTime = moment.utc(wedEndTimeHHMMAfVideo, 'hh:mm');
if(wedStartTimeAfVideo == "12:00 PM"){
  var dayTime2 = moment.utc({h: "00:00 PM"});
}
else{
  var dayTime2 = moment.utc({h: wedStartTimeAfVideo});
}

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet2AfVideo(slotSet2AfVideo => slotSet2AfVideo.concat(returnArray));
} 

let handleThuEndTimeAfVideo = (time) => {
  setSlotSet3AfVideo([]);
  console.log(time.target.value);
  let thuEndTimeHHMMAfVideo = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setThuEndTimeAfVideo(time.target.value);
var dayTime = moment.utc(thuEndTimeHHMMAfVideo, 'hh:mm');
if(thuStartTimeAfVideo == "12:00 PM"){
  var dayTime2 = moment.utc({h: "00:00 PM"});
}
else{
  var dayTime2 = moment.utc({h: thuStartTimeAfVideo});
}

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet3AfVideo(slotSet3AfVideo => slotSet3AfVideo.concat(returnArray));
} 

let handleFriEndTimeAfVideo = (time) => {
  setSlotSet4AfVideo([]);
  console.log(time.target.value);
  let friEndTimeHHMMAfVideo = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setFriEndTimeAfVideo(time.target.value);
var dayTime = moment.utc(friEndTimeHHMMAfVideo, 'hh:mm');
if(friStartTimeAfVideo == "12:00 PM"){
  var dayTime2 = moment.utc({h: "00:00 PM"});
}
else{
  var dayTime2 = moment.utc({h: friStartTimeAfVideo});
}

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet4AfVideo(slotSet4AfVideo => slotSet4AfVideo.concat(returnArray));
} 

let handleSatEndTimeAfVideo = (time) => {
  setSlotSet5AfVideo([]);
  console.log(time.target.value);
  let satEndTimeHHMMAfVideo = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setSatEndTimeAfVideo(time.target.value);
var dayTime = moment.utc(satEndTimeHHMMAfVideo, 'hh:mm');
if(satStartTimeAfVideo == "12:00 PM"){
  var dayTime2 = moment.utc({h: "00:00 PM"});
}
else{
  var dayTime2 = moment.utc({h: satStartTimeAfVideo});
}
console.log(dayTime2);
console.log(dayTime);
let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet5AfVideo(slotSet5AfVideo => slotSet5AfVideo.concat(returnArray));
} 

let handleSunEndTimeAfVideo = (time) => {
  setSlotSet6AfVideo([]);
  console.log(time.target.value);
  let sunEndTimeHHMMAfVideo = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setSunEndTimeAfVideo(time.target.value);
var dayTime = moment.utc(sunEndTimeHHMMAfVideo, 'hh:mm');
if(sunStartTimeAfVideo == "12:00 PM"){
  var dayTime2 = moment.utc({h: "00:00 PM"});
}
else{
  var dayTime2 = moment.utc({h: sunStartTimeAfVideo});
}
console.log(dayTime2);
console.log(dayTime);
let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";
  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet6AfVideo(slotSet6AfVideo => slotSet6AfVideo.concat(returnArray));
} 

let handleMonEndTimeEvVideo = (time) => {
 setSlotSetEvVideo([]);
 console.log(time.target.value);
 let monEndTimeHHMMEvVideo = (moment(time.target.value, 'hh:mm').format("hh:mm"));
 setMonEndTimeEvVideo(time.target.value);
var dayTime = moment.utc(monEndTimeHHMMEvVideo, 'hh:mm');
var dayTime2 = moment.utc({h: monStartTimeEvVideo});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

 var data = {};
 data.patientId = "null";
 data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
 data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSetEvVideo(slotSetEvVideo => slotSetEvVideo.concat(returnArray));
} 

let handleTueEndTimeEvVideo = (time) => {
 setSlotSet1EvVideo([]);
 console.log(time.target.value);
 let tueEndTimeHHMMEvVideo = (moment(time.target.value, 'hh:mm').format("hh:mm"));
 setTueEndTimeEvVideo(time.target.value);
var dayTime = moment.utc(tueEndTimeHHMMEvVideo, 'hh:mm');
var dayTime2 = moment.utc({h: tueStartTimeEvVideo});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

 var data = {};
 data.patientId = "null";
 data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
 data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet1EvVideo(slotSet1EvVideo => slotSet1EvVideo.concat(returnArray));
} 

let handleWedEndTimeEvVideo = (time) => {
 setSlotSet2EvVideo([]);
 console.log(time.target.value);
 let wedEndTimeHHMMEvVideo = (moment(time.target.value, 'hh:mm').format("hh:mm"));
 setWedEndTimeEvVideo(time.target.value);
var dayTime = moment.utc(wedEndTimeHHMMEvVideo, 'hh:mm');
var dayTime2 = moment.utc({h: wedStartTimeEvVideo});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

 var data = {};
 data.patientId = "null";
 data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
 data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet2EvVideo(slotSet2EvVideo => slotSet2EvVideo.concat(returnArray));
} 

let handleThuEndTimeEvVideo = (time) => {
 setSlotSet3EvVideo([]);
 console.log(time.target.value);
 let thuEndTimeHHMMEvVideo = (moment(time.target.value, 'hh:mm').format("hh:mm"));
 setThuEndTimeEvVideo(time.target.value);
var dayTime = moment.utc(thuEndTimeHHMMEvVideo, 'hh:mm');
var dayTime2 = moment.utc({h: thuStartTimeEvVideo});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

 var data = {};
 data.patientId = "null";
 data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
 data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet3EvVideo(slotSet3EvVideo => slotSet3EvVideo.concat(returnArray));
} 

let handleFriEndTimeEvVideo = (time) => {
 setSlotSet4EvVideo([]);
 console.log(time.target.value);
 let friEndTimeHHMMEvVideo = (moment(time.target.value, 'hh:mm').format("hh:mm"));
 setFriEndTimeEvVideo(time.target.value);
var dayTime = moment.utc(friEndTimeHHMMEvVideo, 'hh:mm');
var dayTime2 = moment.utc({h: friStartTimeEvVideo});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

 var data = {};
 data.patientId = "null";
 data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
 data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet4EvVideo(slotSet4EvVideo => slotSet4EvVideo.concat(returnArray));
} 

let handleSatEndTimeEvVideo = (time) => {
 setSlotSet5EvVideo([]);
 console.log(time.target.value);
 let satEndTimeHHMMEvVideo = (moment(time.target.value, 'hh:mm').format("hh:mm"));
 setSatEndTimeEvVideo(time.target.value);
var dayTime = moment.utc(satEndTimeHHMMEvVideo, 'hh:mm');
var dayTime2 = moment.utc({h: satStartTimeEvVideo});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

 var data = {};
 data.patientId = "null";
 data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
 data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet5EvVideo(slotSet5EvVideo => slotSet5EvVideo.concat(returnArray));
}  

let handleSunEndTimeEvVideo = (time) => {
 setSlotSet6EvVideo([]);
 console.log(time.target.value);
 let sunEndTimeHHMMEvVideo = (moment(time.target.value, 'hh:mm').format('hh:mm'));
 setSunEndTimeEvVideo(time.target.value);
var dayTime = moment.utc(sunEndTimeHHMMEvVideo, 'hh:mm');
var dayTime2 = moment.utc({h: (moment(sunStartTimeEvVideo, 'hh:mm').format('hh:mm'))});

let returnArray = [];
while (dayTime2 < dayTime) {

 var data = {};
 data.patientId = "null";
 data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
 data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet6EvVideo(slotSet6EvVideo => slotSet6EvVideo.concat(returnArray));
} 

let handleMonEndTimeChat = (time) => {
 setSlotSetChat([]);
 console.log(time.target.value);
 let monEndTimeHHMMChat = (moment(time.target.value, 'hh:mm A').format("hh:mm A"));
 setMonEndTimeChat(time.target.value);
var dayTime = moment.utc(monEndTimeHHMMChat, 'hh:mm A');
var dayTime2 = moment.utc({h: monStartTimeChat});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

 var data = {};
 data.keys = dayTime2.format('hh:mm A');
 data.patientId = "null";
 data.slotStart = dayTime2.format('hh:mm A');
 data.slotEnd = dayTime2.clone().add(timeSlot, 'minute').format('hh:mm A');
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);



dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm A'));
}
setSlotSetChat(slotSetChat => slotSetChat.concat(returnArray));
console.log(slotSetChat);
}

let handleTueEndTimeChat = (time) => {
 setSlotSet1Chat([]);
 console.log(time.target.value);
 let tueEndTimeHHMMChat = (moment(time.target.value, 'hh:mm A').format("hh:mm A"));
 setTueEndTimeChat(time.target.value);
var dayTime = moment.utc(tueEndTimeHHMMChat, 'hh:mm A');
var dayTime2 = moment.utc({h: tueStartTimeChat});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

 var data = {};
 data.patientId = "null";
 data.slotStart = dayTime2.format('hh:mm A');
 data.slotEnd = dayTime2.clone().add(timeSlot, 'minute').format('hh:mm A');
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm A'));
}
setSlotSet1Chat(slotSet1Chat => slotSet1Chat.concat(returnArray));
} 


let handleWedEndTimeChat = (time) => {
 setSlotSet2Chat([]);
 console.log(time.target.value);
 let wedEndTimeHHMMChat = (moment(time.target.value, 'hh:mm A').format("hh:mm A"));
 setWedEndTimeChat(time.target.value);
var dayTime = moment.utc(wedEndTimeHHMMChat, 'hh:mm A');
var dayTime2 = moment.utc({h: wedStartTimeChat});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

 var data = {};
 data.patientId = "null";
 data.slotStart = dayTime2.format('hh:mm A');
 data.slotEnd = dayTime2.clone().add(timeSlot, 'minute').format('hh:mm A');
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm A'));
}
setSlotSet2Chat(slotSet2Chat => slotSet2Chat.concat(returnArray));
} 

let handleThuEndTimeChat = (time) => {
 setSlotSet3Chat([]);
 console.log(time.target.value);
 let thuEndTimeHHMMChat = (moment(time.target.value, 'hh:mm A').format("hh:mm A"));
 setThuEndTimeChat(time.target.value);
var dayTime = moment.utc(thuEndTimeHHMMChat, 'hh:mm A');
var dayTime2 = moment.utc({h: thuStartTimeChat});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

 var data = {};
 data.patientId = "null";
 data.slotStart = dayTime2.format('hh:mm A');
 data.slotEnd = dayTime2.clone().add(timeSlot, 'minute').format('hh:mm A');
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm A'));
}
setSlotSet3Chat(slotSet3Chat => slotSet3Chat.concat(returnArray));
} 

let handleFriEndTimeChat = (time) => {
 setSlotSet4Chat([]);
 console.log(time.target.value);
 let friEndTimeHHMMChat = (moment(time.target.value, 'hh:mm A').format("hh:mm A"));
 setFriEndTimeChat(time.target.value);
var dayTime = moment.utc(friEndTimeHHMMChat, 'hh:mm A');
var dayTime2 = moment.utc({h: friStartTimeChat});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

 var data = {};
 data.patientId = "null";
 data.slotStart = dayTime2.format('hh:mm A');
 data.slotEnd = dayTime2.clone().add(timeSlot, 'minute').format('hh:mm A');
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm A'));
}
setSlotSet4Chat(slotSet4Chat => slotSet4Chat.concat(returnArray));
} 

let handleSatEndTimeChat = (time) => {
 setSlotSet5Chat([]);
 console.log(time.target.value);
 let satEndTimeHHMMChat = (moment(time.target.value, 'hh:mm A').format("hh:mm A"));
 setSatEndTimeChat(time.target.value);
var dayTime = moment.utc(satEndTimeHHMMChat, 'hh:mm A');
var dayTime2 = moment.utc({h: satStartTimeChat});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

 var data = {};
 data.patientId = "null";
 data.slotStart = dayTime2.format('hh:mm A');
 data.slotEnd = dayTime2.clone().add(timeSlot, 'minute').format('hh:mm A');
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm A'));
}
setSlotSet5Chat(slotSet5Chat => slotSet5Chat.concat(returnArray));
} 

let handleSunEndTimeChat = (time) => {
 setSlotSet6Chat([]);
 console.log(time.target.value);
 let sunEndTimeHHMMChat = (moment(time.target.value, 'hh:mm A').format("hh:mm A"));
 setSunEndTimeChat(time.target.value);
var dayTime = moment.utc(sunEndTimeHHMMChat, 'hh:mm A');
var dayTime2 = moment.utc({h: sunStartTimeChat});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

 var data = {};
 data.patientId = "null";
 data.slotStart = dayTime2.format('hh:mm A');
 data.slotEnd = dayTime2.clone().add(timeSlot, 'minute').format('hh:mm A');
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm A'));
}
setSlotSet6Chat(slotSet6Chat => slotSet6Chat.concat(returnArray));
} 

let handleMonEndTimeAfChat = (time) => {
  setSlotSetAfChat([]);
  console.log(time.target.value);
  let monEndTimeHHMMAfChat = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setMonEndTimeAfChat(time.target.value);
var dayTime = moment.utc(monEndTimeHHMMAfChat, 'hh:mm');
if(monStartTimeAfChat == "12:00 PM"){
  var dayTime2 = moment.utc({h: "00:00 PM"});
}
else{
  var dayTime2 = moment.utc({h: monStartTimeAfChat});
}

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSetAfChat(slotSetAfChat => slotSetAfChat.concat(returnArray));
} 

let handleTueEndTimeAfChat = (time) => {
  setSlotSet1AfChat([]);
  console.log(time.target.value);
  let tueEndTimeHHMMAfChat = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setTueEndTimeAfChat(time.target.value);
var dayTime = moment.utc(tueEndTimeHHMMAfChat, 'hh:mm');
if(tueStartTimeAfChat == "12:00 PM"){
  var dayTime2 = moment.utc({h: "00:00 PM"});
}
else{
  var dayTime2 = moment.utc({h: tueStartTimeAfChat});
}

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet1AfChat(slotSet1AfChat => slotSet1AfChat.concat(returnArray));
} 

let handleWedEndTimeAfChat = (time) => {
  setSlotSet2AfChat([]);
  console.log(time.target.value);
  let wedEndTimeHHMMAfChat = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setWedEndTimeAfChat(time.target.value);
var dayTime = moment.utc(wedEndTimeHHMMAfChat, 'hh:mm');
if(wedStartTimeAfChat == "12:00 PM"){
  var dayTime2 = moment.utc({h: "00:00 PM"});
}
else{
  var dayTime2 = moment.utc({h: wedStartTimeAfChat});
}

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet2AfChat(slotSet2AfChat => slotSet2AfChat.concat(returnArray));
} 

let handleThuEndTimeAfChat = (time) => {
  setSlotSet3AfChat([]);
  console.log(time.target.value);
  let thuEndTimeHHMMAfChat = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setThuEndTimeAfChat(time.target.value);
var dayTime = moment.utc(thuEndTimeHHMMAfChat, 'hh:mm');
if(thuStartTimeAfChat == "12:00 PM"){
  var dayTime2 = moment.utc({h: "00:00 PM"});
}
else{
  var dayTime2 = moment.utc({h: thuStartTimeAfChat});
}

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet3AfChat(slotSet3AfChat => slotSet3AfChat.concat(returnArray));
} 

let handleFriEndTimeAfChat = (time) => {
  setSlotSet4AfChat([]);
  console.log(time.target.value);
  let friEndTimeHHMMAfChat = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setFriEndTimeAfChat(time.target.value);
var dayTime = moment.utc(friEndTimeHHMMAfChat, 'hh:mm');
if(friStartTimeAfChat == "12:00 PM"){
  var dayTime2 = moment.utc({h: "00:00 PM"});
}
else{
  var dayTime2 = moment.utc({h: friStartTimeAfChat});
}

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet4AfChat(slotSet4AfChat => slotSet4AfChat.concat(returnArray));
} 

let handleSatEndTimeAfChat = (time) => {
  setSlotSet5AfChat([]);
  console.log(time.target.value);
  let satEndTimeHHMMAfChat = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setSatEndTimeAfChat(time.target.value);
var dayTime = moment.utc(satEndTimeHHMMAfChat, 'hh:mm');
if(satStartTimeAfChat == "12:00 PM"){
  var dayTime2 = moment.utc({h: "00:00 PM"});
}
else{
  var dayTime2 = moment.utc({h: satStartTimeAfChat});
}

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet5AfChat(slotSet5AfChat => slotSet5AfChat.concat(returnArray));
} 

let handleSunEndTimeAfChat = (time) => {
  setSlotSet6AfChat([]);
  console.log(time.target.value);
  let sunEndTimeHHMMAfChat = (moment(time.target.value, 'hh:mm').format("hh:mm"));
  setSunEndTimeAfChat(time.target.value);
var dayTime = moment.utc(sunEndTimeHHMMAfChat, 'hh:mm');
if(sunStartTimeAfChat == "12:00 PM"){
  var dayTime2 = moment.utc({h: "00:00 PM"});
}
else{
  var dayTime2 = moment.utc({h: sunStartTimeAfChat});
}

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

  var data = {};
  data.patientId = "null";
  data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
  data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
  data.slotStatus = "free";
  data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  data.consultationSymbol = consultationSymbol;
  data.paymentStatus = "null";
  data.bookedAt = "null";

  returnArray.push(data);
  console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

 console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet6AfChat(slotSet6AfChat => slotSet6AfChat.concat(returnArray));
} 

let handleMonEndTimeEvChat = (time) => {
 setSlotSetEvChat([]);
 console.log(time.target.value);
 let monEndTimeHHMMEvChat = (moment(time.target.value, 'hh:mm').format("hh:mm"));
 setMonEndTimeEvChat(time.target.value);
var dayTime = moment.utc(monEndTimeHHMMEvChat, 'hh:mm');
var dayTime2 = moment.utc({h: monStartTimeEvChat});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

 var data = {};
 data.patientId = "null";
 data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
 data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSetEvChat(slotSetEvChat => slotSetEvChat.concat(returnArray));
}  

let handleTueEndTimeEvChat = (time) => {
 setSlotSet1EvChat([]);
 console.log(time.target.value);
 let tueEndTimeHHMMEvChat = (moment(time.target.value, 'hh:mm').format("hh:mm"));
 setTueEndTimeEvChat(time.target.value);
var dayTime = moment.utc(tueEndTimeHHMMEvChat, 'hh:mm');
var dayTime2 = moment.utc({h: tueStartTimeEvChat});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

 var data = {};
 data.patientId = "null";
 data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
 data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet1EvChat(slotSet1EvChat => slotSet1EvChat.concat(returnArray));
} 

let handleWedEndTimeEvChat = (time) => {
 setSlotSet2EvChat([]);
 console.log(time.target.value);
 let wedEndTimeHHMMEvChat = (moment(time.target.value, 'hh:mm').format("hh:mm"));
 setWedEndTimeEvChat(time.target.value);
var dayTime = moment.utc(wedEndTimeHHMMEvChat, 'hh:mm');
var dayTime2 = moment.utc({h: wedStartTimeEvChat});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

 var data = {};
 data.patientId = "null";
 data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
 data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet2EvChat(slotSet2EvChat => slotSet2EvChat.concat(returnArray));
} 

let handleThuEndTimeEvChat = (time) => {
 setSlotSet3EvChat([]);
 console.log(time.target.value);
 let thuEndTimeHHMMEvChat = (moment(time.target.value, 'hh:mm').format("hh:mm"));
 setThuEndTimeEvChat(time.target.value);
var dayTime = moment.utc(thuEndTimeHHMMEvChat, 'hh:mm');
var dayTime2 = moment.utc({h: thuStartTimeEvChat});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

 var data = {};
 data.patientId = "null";
 data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
 data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet3EvChat(slotSet3EvChat => slotSet3EvChat.concat(returnArray));
} 

let handleFriEndTimeEvChat = (time) => {
 setSlotSet4EvChat([]);
 console.log(time.target.value);
 let friEndTimeHHMMEvChat = (moment(time.target.value, 'hh:mm').format("hh:mm"));
 setFriEndTimeEvChat(time.target.value);
var dayTime = moment.utc(friEndTimeHHMMEvChat, 'hh:mm');
var dayTime2 = moment.utc({h: friStartTimeEvChat});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

 var data = {};
 data.patientId = "null";
 data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
 data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet4EvChat(slotSet4EvChat => slotSet4EvChat.concat(returnArray));
} 

let handleSatEndTimeEvChat = (time) => {
 setSlotSet5EvChat([]);
 console.log(time.target.value);
 let satEndTimeHHMMEvChat = (moment(time.target.value, 'hh:mm').format("hh:mm"));
 setSatEndTimeEvChat(time.target.value);
var dayTime = moment.utc(satEndTimeHHMMEvChat, 'hh:mm');
var dayTime2 = moment.utc({h: satStartTimeEvChat});

let returnArray = [];
while (dayTime2.isBefore(dayTime)) {

 var data = {};
 data.patientId = "null";
 data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
 data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet5EvChat(slotSet5EvChat => slotSet5EvChat.concat(returnArray));
}  

let handleSunEndTimeEvChat = (time) => {
 setSlotSet6EvChat([]);
 console.log(time.target.value);
 let sunEndTimeHHMMEvChat = (moment(time.target.value, 'hh:mm').format('hh:mm'));
 setSunEndTimeEvChat(time.target.value);
var dayTime = moment.utc(sunEndTimeHHMMEvChat, 'hh:mm');
var dayTime2 = moment.utc({h: sunStartTimeEvChat});

let returnArray = [];
while (dayTime2 < dayTime) {

 var data = {};
 data.patientId = "null";
 data.slotStart = (dayTime2.format('hh:mm')) + ' ' + 'PM';
 data.slotEnd = (dayTime2.clone().add(timeSlot, 'minute').format('hh:mm')) + ' ' + 'PM';
 data.slotStatus = "free";
 data.consultationFees = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
 data.consultationSymbol = consultationSymbol;
 data.paymentStatus = "null";
 data.bookedAt = "null";

 returnArray.push(data);
 console.log(returnArray);

dayTime2.add(timeSlot, 'minute');

console.log('TIME: ' + dayTime2.format('hh:mm'));
}
setSlotSet6EvChat(slotSet6EvChat => slotSet6EvChat.concat(returnArray));
} 

let handleConsultationFees =(event) => {
    if (isNaN(Number(event.target.value))) {
      return;
    } else {
      var num = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      console.log(num);
      setConsultationFees( num );
    }
}

let handleStartDate = (date) => {
 setStartDate(date);
 let startdateselect = moment(date);
 let startdateselects = startdateselect.format("DD-MM-YYYY");

 var parts = startdateselects.split('-');
 var lastDate = new Date(parts[2] + "-" + parts[1] + "-" + parts[0]);

 var lastDate1 = new Date(lastDate.setTime(lastDate.getTime() + 1 * 86400000));
 var lastDate11 = moment(lastDate1).format("DD-MM-YYYY");

 var lastDate2 = new Date(lastDate.setTime(lastDate.getTime() + 1 * 86400000));
 var lastDate22 = moment(lastDate2).format("DD-MM-YYYY");

 var lastDate3 = new Date(lastDate.setTime(lastDate.getTime() + 1 * 86400000));
 var lastDate33 = moment(lastDate3).format("DD-MM-YYYY");

 var lastDate4 = new Date(lastDate.setTime(lastDate.getTime() + 1 * 86400000));
 var lastDate44 = moment(lastDate4).format("DD-MM-YYYY");

 var lastDate5 = new Date(lastDate.setTime(lastDate.getTime() + 1 * 86400000));
 var lastDate55 = moment(lastDate5).format("DD-MM-YYYY");

 var lastDate6 = new Date(lastDate.setTime(lastDate.getTime() + 1 * 86400000));
 var lastDate66 = moment(lastDate6).format("DD-MM-YYYY");

 secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
 .child('/'+startdateselects+'/morning/').limitToLast(1).once('value', snap => {
   if(snap.exists()) {
     snap.forEach(childNew => {
     if(childNew.val().day == 'Monday')
     {
       setMorMonLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Tuesday')
     {
       setMorTueLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Wednesday')
     {
       setMorWedLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Thursday')
     {
       setMorThuLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Friday')
     {
       setMorFriLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Saturday')
     {
       setMorSatLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Sunday')
     {
       setMorSunLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
   });
   }
 })

 secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
 .child('/'+startdateselects+'/afternoon/').limitToLast(1).once('value', snap => {
   if(snap.exists()) {
     snap.forEach(childNew => {
     if(childNew.val().day == 'Monday')
     {
       setAfMonLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Tuesday')
     {
       setAfTueLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Wednesday')
     {
       setAfWedLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Thursday')
     {
       setAfThuLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Friday')
     {
       setAfFriLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Saturday')
     {
       setAfSatLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Sunday')
     {
       setAfSunLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
   });
   }
 })

 secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
 .child('/'+startdateselects+'/evening/').limitToLast(1).once('value', snap => {
   if(snap.exists()) {
     snap.forEach(childNew => {
     if(childNew.val().day == 'Monday')
     {
       setEvMonLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Tuesday')
     {
       setEvTueLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Wednesday')
     {
       setEvWedLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Thursday')
     {
       setEvThuLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Friday')
     {
       setEvFriLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Saturday')
     {
       setEvSatLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Sunday')
     {
       setEvSunLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
   });
   }
 })

 secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
 .child('/'+lastDate11+'/morning/').limitToLast(1).once('value', snap => {
   if(snap.exists()) {
     snap.forEach(childNew => {
     if(childNew.val().day == 'Monday')
     {
       setMorMonLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Tuesday')
     {
       setMorTueLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Wednesday')
     {
       setMorWedLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Thursday')
     {
       setMorThuLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Friday')
     {
       setMorFriLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Saturday')
     {
       setMorSatLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Sunday')
     {
       setMorSunLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
   });
   }
 })

 secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
 .child('/'+lastDate11+'/afternoon/').limitToLast(1).once('value', snap => {
   if(snap.exists()) {
     snap.forEach(childNew => {
     if(childNew.val().day == 'Monday')
     {
       setAfMonLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Tuesday')
     {
       setAfTueLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Wednesday')
     {
       setAfWedLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Thursday')
     {
       setAfThuLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Friday')
     {
       setAfFriLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Saturday')
     {
       setAfSatLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Sunday')
     {
       setAfSunLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
   });
   }
 })

 secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
 .child('/'+lastDate11+'/evening/').limitToLast(1).once('value', snap => {
   if(snap.exists()) {
     snap.forEach(childNew => {
     if(childNew.val().day == 'Monday')
     {
       setEvMonLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Tuesday')
     {
       setEvTueLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Wednesday')
     {
       setEvWedLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Thursday')
     {
       setEvThuLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Friday')
     {
       setEvFriLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Saturday')
     {
       setEvSatLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Sunday')
     {
       setEvSunLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
   });
   }
 })

 secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
 .child('/'+lastDate22+'/morning/').limitToLast(1).once('value', snap => {
   if(snap.exists()) {
     snap.forEach(childNew => {
     if(childNew.val().day == 'Monday')
     {
       setMorMonLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Tuesday')
     {
       setMorTueLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Wednesday')
     {
       setMorWedLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Thursday')
     {
       setMorThuLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Friday')
     {
       setMorFriLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Saturday')
     {
       setMorSatLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Sunday')
     {
       setMorSunLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
   });
   }
 })

 secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
 .child('/'+lastDate22+'/afternoon/').limitToLast(1).once('value', snap => {
   if(snap.exists()) {
     snap.forEach(childNew => {
     if(childNew.val().day == 'Monday')
     {
       setAfMonLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Tuesday')
     {
       setAfTueLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Wednesday')
     {
       setAfWedLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Thursday')
     {
       setAfThuLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Friday')
     {
       setAfFriLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Saturday')
     {
       setAfSatLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Sunday')
     {
       setAfSunLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
   });
   }
 })

 secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
 .child('/'+lastDate22+'/evening/').limitToLast(1).once('value', snap => {
   if(snap.exists()) {
     snap.forEach(childNew => {
     if(childNew.val().day == 'Monday')
     {
       setEvMonLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Tuesday')
     {
       setEvTueLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Wednesday')
     {
       setEvWedLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Thursday')
     {
       setEvThuLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Friday')
     {
       setEvFriLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Saturday')
     {
       setEvSatLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Sunday')
     {
       setEvSunLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
   });
   }
 })

 secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
 .child('/'+lastDate33+'/morning/').limitToLast(1).once('value', snap => {
   if(snap.exists()) {
     snap.forEach(childNew => {
     if(childNew.val().day == 'Monday')
     {
       setMorMonLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Tuesday')
     {
       setMorTueLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Wednesday')
     {
       setMorWedLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Thursday')
     {
       setMorThuLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Friday')
     {
       setMorFriLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Saturday')
     {
       setMorSatLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Sunday')
     {
       setMorSunLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
   });
   }
 })

 secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
 .child('/'+lastDate33+'/afternoon/').limitToLast(1).once('value', snap => {
   if(snap.exists()) {
     snap.forEach(childNew => {
     if(childNew.val().day == 'Monday')
     {
       setAfMonLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Tuesday')
     {
       setAfTueLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Wednesday')
     {
       setAfWedLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Thursday')
     {
       setAfThuLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Friday')
     {
       setAfFriLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Saturday')
     {
       setAfSatLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Sunday')
     {
       setAfSunLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
   });
   }
 })

 secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
 .child('/'+lastDate33+'/evening/').limitToLast(1).once('value', snap => {
   if(snap.exists()) {
     snap.forEach(childNew => {
     if(childNew.val().day == 'Monday')
     {
       setEvMonLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Tuesday')
     {
       setEvTueLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Wednesday')
     {
       setEvWedLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Thursday')
     {
       setEvThuLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Friday')
     {
       setEvFriLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Saturday')
     {
       setEvSatLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Sunday')
     {
       setEvSunLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
   });
   }
 })

 secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
 .child('/'+lastDate44+'/morning/').limitToLast(1).once('value', snap => {
   if(snap.exists()) {
     snap.forEach(childNew => {
     if(childNew.val().day == 'Monday')
     {
       setMorMonLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Tuesday')
     {
       setMorTueLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Wednesday')
     {
       setMorWedLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Thursday')
     {
       setMorThuLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Friday')
     {
       setMorFriLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Saturday')
     {
       setMorSatLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Sunday')
     {
       setMorSunLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
   });
   }
 })

 secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
 .child('/'+lastDate44+'/afternoon/').limitToLast(1).once('value', snap => {
   if(snap.exists()) {
     snap.forEach(childNew => {
     if(childNew.val().day == 'Monday')
     {
       setAfMonLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Tuesday')
     {
       setAfTueLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Wednesday')
     {
       setAfWedLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Thursday')
     {
       setAfThuLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Friday')
     {
       setAfFriLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Saturday')
     {
       setAfSatLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Sunday')
     {
       setAfSunLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
   });
   }
 })

 secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
 .child('/'+lastDate44+'/evening/').limitToLast(1).once('value', snap => {
   if(snap.exists()) {
     snap.forEach(childNew => {
     if(childNew.val().day == 'Monday')
     {
       setEvMonLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Tuesday')
     {
       setEvTueLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Wednesday')
     {
       setEvWedLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Thursday')
     {
       setEvThuLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Friday')
     {
       setEvFriLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Saturday')
     {
       setEvSatLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Sunday')
     {
       setEvSunLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
   });
   }
 })

 secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
 .child('/'+lastDate55+'/morning/').limitToLast(1).once('value', snap => {
   if(snap.exists()) {
     snap.forEach(childNew => {
     if(childNew.val().day == 'Monday')
     {
       setMorMonLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Tuesday')
     {
       setMorTueLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Wednesday')
     {
       setMorWedLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Thursday')
     {
       setMorThuLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Friday')
     {
       setMorFriLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Saturday')
     {
       setMorSatLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Sunday')
     {
       setMorSunLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
   });
   }
 })

 secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
 .child('/'+lastDate55+'/afternoon/').limitToLast(1).once('value', snap => {
   if(snap.exists()) {
     snap.forEach(childNew => {
     if(childNew.val().day == 'Monday')
     {
       setAfMonLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Tuesday')
     {
       setAfTueLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Wednesday')
     {
       setAfWedLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Thursday')
     {
       setAfThuLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Friday')
     {
       setAfFriLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Saturday')
     {
       setAfSatLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Sunday')
     {
       setAfSunLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
   });
   }
 })

 secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
 .child('/'+lastDate55+'/evening/').limitToLast(1).once('value', snap => {
   if(snap.exists()) {
     snap.forEach(childNew => {
     if(childNew.val().day == 'Monday')
     {
       setEvMonLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Tuesday')
     {
       setEvTueLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Wednesday')
     {
       setEvWedLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Thursday')
     {
       setEvThuLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Friday')
     {
       setEvFriLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Saturday')
     {
       setEvSatLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Sunday')
     {
       setEvSunLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
   });
   }
 })

 secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
 .child('/'+lastDate66+'/morning/').limitToLast(1).once('value', snap => {
   if(snap.exists()) {
     snap.forEach(childNew => {
     if(childNew.val().day == 'Monday')
     {
       setMorMonLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Tuesday')
     {
       setMorTueLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Wednesday')
     {
       setMorWedLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Thursday')
     {
       setMorThuLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Friday')
     {
       setMorFriLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Saturday')
     {
       setMorSatLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Sunday')
     {
       setMorSunLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
   });
   }
 })

 secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
 .child('/'+lastDate66+'/afternoon/').limitToLast(1).once('value', snap => {
   if(snap.exists()) {
     snap.forEach(childNew => {
     if(childNew.val().day == 'Monday')
     {
       setAfMonLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Tuesday')
     {
       setAfTueLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Wednesday')
     {
       setAfWedLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Thursday')
     {
       setAfThuLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Friday')
     {
       setAfFriLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Saturday')
     {
       setAfSatLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Sunday')
     {
       setAfSunLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
   });
   }
 })

 secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
 .child('/'+lastDate66+'/evening/').limitToLast(1).once('value', snap => {
   if(snap.exists()) {
     snap.forEach(childNew => {
     if(childNew.val().day == 'Monday')
     {
       setEvMonLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Tuesday')
     {
       setEvTueLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Wednesday')
     {
       setEvWedLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Thursday')
     {
       setEvThuLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Friday')
     {
       setEvFriLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Saturday')
     {
       setEvSatLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
     else if(childNew.val().day == 'Sunday')
     {
       setEvSunLast(childNew.val().endTime);
       console.log(childNew.val().endTime);
     }
   });
   }
 })

}

let handleEndDate = (date) => {

 let enddateselect = moment(date);
 let enddateselects = enddateselect.format("DD-MM-YYYY");
     setEndDate(date);
     setSelectEndDate(enddateselects);

     function getDateArrayFull(start, end) {
         let arrayFull = new Array();
         let dt = new Date(start);
         while (dt <= end) {
           let values = moment(new Date(dt)).format("DD-MM-YYYY");
           arrayFull.push(new Date(dt));
           dt.setDate(dt.getDate() + 1);
         }
         return arrayFull;
     }

     let dateArray = getDateArrayFull(startDate, date);
     setdateArray(dateArray);
}

let weekdaysAndWeekenddays = (e) => {
  e.preventDefault();
if(timeSlot == ''){
  alert('Please select timeslot');
}
else if(startDate == null){
  alert('Please select start date');
}
else if(endDate == null){
  alert('Please select end date');
}
else if(consultationFees == ''){
  alert('Please enter consultation fees');
}
else{
  //var num = consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
//console.log(num);
  let arra = new Array();
  let weekdays = new Array();
  let weekenddays = new Array();
  arra = dateArray;

  for(let i=0; i<=arra.length; i++){
    if(i<arra.length){
      let values = moment(arra[i]).format("DD-MM-YYYY");
      let day = arra[i].getDay();

      let valuesIndex = moment(arra[i]).format("YYYYMMDD");

      if(day == 0) {
  if(sunStartTime == ''){

  }
  else if(sunEndTime == ''){

  }
  else{
  secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
  .child('/'+values+'/morning/').push({date:values, session: 'morning',
   day:'Sunday', startTime: (moment(sunStartTime, 'hh:mm A').format("hh:mm A")), 
   endTime: (moment(sunEndTime, 'hh:mm A').format("hh:mm A")), 
   consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
   consultationSymbol: consultationSymbol,
 timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet6 , numberOfSlots: slotSet6.length, 
 numberOfFreeSlots: slotSet6.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
  }
  
  if(sunStartTimeAf == ''){

  }
  else if(sunEndTimeAf == ''){

  }
  else{
  secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
  .child('/'+values+'/afternoon/').push({date:values, session: 'afternoon', day:'Sunday', 
  startTime: (moment(sunStartTimeAf, 'hh:mm A').format("hh:mm A")), 
  endTime: (moment(sunEndTimeAf, 'hh:mm A').format("hh:mm A")),
  consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  consultationSymbol: consultationSymbol,
  timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet6Af , numberOfSlots: slotSet6Af.length, numberOfFreeSlots: slotSet6Af.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
  }

  if(sunStartTimeEv == ''){

  }
  else if(sunEndTimeEv == ''){

  }
  else{
  secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
  .child('/'+values+'/evening/').push({date:values, session: 'evening', day:'Sunday', 
  startTime: (moment(sunStartTimeEv, 'hh:mm A').format("hh:mm A")), 
  endTime: (moment(sunEndTimeEv, 'hh:mm A').format("hh:mm A")),
  consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  consultationSymbol: consultationSymbol,
  timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet6Ev , numberOfSlots: slotSet6Ev.length, numberOfFreeSlots: slotSet6Ev.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
} 

      }

      if(day == 1) {
  if(monStartTime == ''){

  }
  else if(monEndTime == ''){

  }
  else{
        secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/morning/').push({date:values, session: 'morning', day:'Monday', 
   startTime: (moment(monStartTime, 'hh:mm A').format("hh:mm A")), 
  endTime: (moment(monEndTime, 'hh:mm A').format("hh:mm A")),
  consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet , numberOfSlots: slotSet.length, numberOfFreeSlots: slotSet.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
  }

  if(monStartTimeAf == ''){

  }
  else if(monEndTimeAf == ''){

  }
  else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/afternoon/').push({date:values, session: 'afternoon', day:'Monday', 
   startTime: (moment(monStartTimeAf, 'hh:mm A').format("hh:mm A")), 
  endTime: (moment(monEndTimeAf, 'hh:mm A').format("hh:mm A")),
  consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSetAf , numberOfSlots: slotSetAf.length, numberOfFreeSlots: slotSetAf.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(monStartTimeEv == ''){

}
else if(monEndTimeEv == ''){

}
else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/evening/').push({date:values, session: 'evening', day:'Monday', 
   startTime: (moment(monStartTimeEv, 'hh:mm A').format("hh:mm A")), 
   endTime: (moment(monEndTimeEv, 'hh:mm A').format("hh:mm A")),
   consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
   consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSetEv , numberOfSlots: slotSetEv.length, numberOfFreeSlots: slotSetEv.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}
       }
      

       if(day == 2) {
  if(tueStartTime == ''){
  }
  else if(tueEndTime == ''){
  }
else{
        secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/morning/').push({date:values, session: 'morning', day:'Tuesday', 
   startTime: (moment(tueStartTime, 'hh:mm A').format("hh:mm A")), 
  endTime: (moment(tueEndTime, 'hh:mm A').format("hh:mm A")),
  consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet1 , numberOfSlots: slotSet1.length, numberOfFreeSlots: slotSet1.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(tueStartTimeAf == ''){
}
else if(tueEndTimeAf == ''){
}
else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/afternoon/').push({date:values, session: 'afternoon', day:'Tuesday', 
   startTime: (moment(tueStartTimeAf, 'hh:mm A').format("hh:mm A")), 
  endTime: (moment(tueEndTimeAf, 'hh:mm A').format("hh:mm A")), 
  consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet1Af , numberOfSlots: slotSet1Af.length, numberOfFreeSlots: slotSet1Af.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(tueStartTimeEv == ''){
}
else if(tueEndTimeEv == ''){
}
else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/evening/').push({date:values, session: 'evening', day:'Tuesday', 
   startTime: (moment(tueStartTimeEv, 'hh:mm A').format("hh:mm A")), 
  endTime: (moment(tueEndTimeEv, 'hh:mm A').format("hh:mm A")),
  consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet1Ev , numberOfSlots: slotSet1Ev.length, numberOfFreeSlots: slotSet1Ev.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
 }
       }

       if(day == 3) {
  if(wedStartTime == ''){
  }
  else if(wedEndTime == ''){
  }
else{
        secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/morning/').push({date:values, session: 'morning', day:'Wednesday', 
   startTime: (moment(wedStartTime, 'hh:mm A').format("hh:mm A")), 
  endTime: (moment(wedEndTime, 'hh:mm A').format("hh:mm A")),
  consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet2 , numberOfSlots: slotSet2.length, numberOfFreeSlots: slotSet2.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(wedStartTimeAf == ''){
}
else if(wedEndTimeAf == ''){
}
else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/afternoon/').push({date:values, session: 'afternoon', day:'Wednesday', 
   startTime: (moment(wedStartTimeAf, 'hh:mm A').format("hh:mm A")), 
  endTime: (moment(wedEndTimeAf, 'hh:mm A').format("hh:mm A")),
  consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet2Af , numberOfSlots: slotSet2Af.length, numberOfFreeSlots: slotSet2Af.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(wedStartTimeEv == ''){
}
else if(wedEndTimeEv == ''){
}
else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/evening/').push({date:values, session: 'evening', day:'Wednesday', 
   startTime: (moment(wedStartTimeEv, 'hh:mm A').format("hh:mm A")), 
   endTime: (moment(wedEndTimeEv, 'hh:mm A').format("hh:mm A")),
   consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
   consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet2Ev , numberOfSlots: slotSet2Ev.length, numberOfFreeSlots: slotSet2Ev.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
 }
       }

       if(day == 4) {
  if(thuStartTime == ''){
  }
  else if(thuEndTime == ''){
  }
else{
        secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/morning/').push({date:values, session: 'morning', day:'Thursday', 
   startTime: (moment(thuStartTime, 'hh:mm A').format("hh:mm A")), 
  endTime: (moment(thuEndTime, 'hh:mm A').format("hh:mm A")),
  consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet3 , numberOfSlots: slotSet3.length, numberOfFreeSlots: slotSet3.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(thuStartTimeAf == ''){
}
else if(thuEndTimeAf == ''){
}
else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/afternoon/').push({date:values, session: 'afternoon', day:'Thursday', 
   startTime: (moment(thuStartTimeAf, 'hh:mm A').format("hh:mm A")), 
  endTime: (moment(thuEndTimeAf, 'hh:mm A').format("hh:mm A")),
  consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet3Af , numberOfSlots: slotSet3Af.length, numberOfFreeSlots: slotSet3Af.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(thuStartTimeEv == ''){
}
else if(thuEndTimeEv == ''){
}
else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/evening/').push({date:values, session: 'evening', day:'Thursday', 
   startTime: (moment(thuStartTimeEv, 'hh:mm A').format("hh:mm A")), 
  endTime: (moment(thuEndTimeEv, 'hh:mm A').format("hh:mm A")),
  consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet3Ev , numberOfSlots: slotSet3Ev.length, numberOfFreeSlots: slotSet3Ev.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
 }
       }

       if(day == 5) {
  if(friStartTime == ''){
  }
  else if(friEndTime == ''){
  }
else{
        secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/morning/').push({date:values, session: 'morning', day:'Friday', 
   startTime: (moment(friStartTime, 'hh:mm A').format("hh:mm A")), 
   endTime: (moment(friEndTime, 'hh:mm A').format("hh:mm A")),
   consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
   consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet4 , numberOfSlots: slotSet4.length, numberOfFreeSlots: slotSet4.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(friStartTimeAf == ''){
}
else if(friEndTimeAf == ''){
}
else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/afternoon/').push({date:values, session: 'afternoon', day:'Friday', 
   startTime: (moment(friStartTimeAf, 'hh:mm A').format("hh:mm A")), 
   endTime: (moment(friEndTimeAf, 'hh:mm A').format("hh:mm A")),
   consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
   consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet4Af , numberOfSlots: slotSet4Af.length, numberOfFreeSlots: slotSet4Af.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(friStartTimeEv == ''){
}
else if(friEndTimeEv == ''){
}
else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/evening/').push({date:values, session: 'evening', day:'Friday', 
   startTime: (moment(friStartTimeEv, 'hh:mm A').format("hh:mm A")), 
   endTime: (moment(friEndTimeEv, 'hh:mm A').format("hh:mm A")),
   consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
   consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet4Ev , numberOfSlots: slotSet4Ev.length, numberOfFreeSlots: slotSet4Ev.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
 }
       }

       if(day == 6) {

  if(satStartTime == ''){
  }
  else if(satEndTime == ''){
  }
else{
        secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/morning/').push({date:values, session: 'morning', day:'Saturday', 
   startTime: (moment(satStartTime, 'hh:mm A').format("hh:mm A")), 
   endTime: (moment(satEndTime, 'hh:mm A').format("hh:mm A")),
   consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
   consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet5 , numberOfSlots: slotSet5.length, numberOfFreeSlots: slotSet5.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(satStartTimeAf == ''){
}
else if(satEndTimeAf == ''){
}
else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/afternoon/').push({date:values, session: 'afternoon', day:'Saturday', 
   startTime: (moment(satStartTimeAf, 'hh:mm A').format("hh:mm A")), 
   endTime: (moment(satEndTimeAf, 'hh:mm A').format("hh:mm A")),
   consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
   consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet5Af , numberOfSlots: slotSet5Af.length, numberOfFreeSlots: slotSet5Af.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(satStartTimeEv == ''){
}
else if(satEndTimeEv == ''){
}
else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/evening/').push({date:values, session: 'evening', day:'Saturday', 
   startTime: (moment(satStartTimeEv, 'hh:mm A').format("hh:mm A")), 
   endTime: (moment(satEndTimeEv, 'hh:mm A').format("hh:mm A")),
   consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
   consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet5Ev , numberOfSlots: slotSet5Ev.length, numberOfFreeSlots: slotSet5Ev.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}   
     }
   }
   else{
  alert("In Person Consultation Data Saved Successfully");

  setStartDate(null);
  setEndDate(null);
  setTimeSlot("");
  setVisitingType("");
  setConsultationFees("");

  history.push({
    pathname: 'viewAdminDoctor',
    state: {doctorIdPass: locationPass.state.doctorIdPass,
      departmentIdPass: locationPass.state.departmentIdPass,
    adminIdPass: locationPass.state.adminIdPass,
  superAdminIdPass:locationPass.state.superAdminIdPass}
  })

}
  }
}
}

let weekdaysAndWeekenddaysVideo = (e) => {
    if(timeSlot == ''){
      alert('Please select timeslot');
    }
    else if(startDate == null){
      alert('Please select start date');
    }
    else if(endDate == null){
      alert('Please select end date');
    }
    else if(consultationFees == ''){
      alert('Please enter consultation fees');
    }
    else{
    let arra = new Array();
    let weekdays = new Array();
    let weekenddays = new Array();
    arra = dateArray;
  
    for(let i=0; i<=arra.length; i++){
      if(i<arra.length){
        let values = moment(arra[i]).format("DD-MM-YYYY");
        let day = arra[i].getDay();
  
        let valuesIndex = moment(arra[i]).format("YYYYMMDD");
        if(day == 0) {
    if(sunStartTimeVideo == ''){
    }
    else if(sunEndTimeVideo == ''){
    }
else{
    secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
    .child('/'+values+'/morning/').push({date:values, session: 'morning',
    day:'Sunday', startTime: (moment(sunStartTimeVideo, 'hh:mm A').format("hh:mm A")), 
    endTime: (moment(sunEndTimeVideo, 'hh:mm A').format("hh:mm A")), 
    consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
    consultationSymbol: consultationSymbol,
  timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet6Video , numberOfSlots: slotSet6Video.length, numberOfFreeSlots: slotSet6Video.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(sunStartTimeAfVideo == ''){
}
else if(sunEndTimeAfVideo == ''){
}
else{
       secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
    .child('/'+values+'/afternoon/').push({date:values, session: 'afternoon', day:'Sunday', 
    startTime: (moment(sunStartTimeAfVideo, 'hh:mm A').format("hh:mm A")), 
    endTime: (moment(sunEndTimeAfVideo, 'hh:mm A').format("hh:mm A")),
    consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
    consultationSymbol: consultationSymbol,
    timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet6AfVideo , numberOfSlots: slotSet6AfVideo.length, numberOfFreeSlots: slotSet6AfVideo.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(sunStartTimeEvVideo == ''){
}
else if(sunEndTimeEvVideo == ''){
}
else{
    secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
    .child('/'+values+'/evening/').push({date:values, session: 'evening', day:'Sunday', 
    startTime: (moment(sunStartTimeEvVideo, 'hh:mm A').format("hh:mm A")), 
    endTime: (moment(sunEndTimeEvVideo, 'hh:mm A').format("hh:mm A")),
    consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
    consultationSymbol: consultationSymbol,
    timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet6EvVideo , numberOfSlots: slotSet6EvVideo.length, numberOfFreeSlots: slotSet6EvVideo.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
 }
      }


 
        if(day == 1) {
  if(monStartTimeVideo == ''){
  }
  else if(monEndTimeVideo == ''){
  }
else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/morning/').push({date:values, session: 'morning', day:'Monday', 
   startTime: (moment(monStartTimeVideo, 'hh:mm A').format("hh:mm A")), 
  endTime: (moment(monEndTimeVideo, 'hh:mm A').format("hh:mm A")),
  consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSetVideo , numberOfSlots: slotSetVideo.length, numberOfFreeSlots: slotSetVideo.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(monStartTimeAfVideo == ''){
}
else if(monEndTimeAfVideo == ''){
}
else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/afternoon/').push({date:values, session: 'afternoon', day:'Monday', 
   startTime: (moment(monStartTimeAfVideo, 'hh:mm A').format("hh:mm A")), 
  endTime: (moment(monEndTimeAfVideo, 'hh:mm A').format("hh:mm A")),
  consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSetAfVideo , numberOfSlots: slotSetAfVideo.length, numberOfFreeSlots: slotSetAfVideo.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(monStartTimeEvVideo == ''){
}
else if(monEndTimeEvVideo == ''){
}
else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/evening/').push({date:values, session: 'evening', day:'Monday', 
   startTime: (moment(monStartTimeEvVideo, 'hh:mm A').format("hh:mm A")), 
   endTime: (moment(monEndTimeEvVideo, 'hh:mm A').format("hh:mm A")),
   consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
   consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSetEvVideo , numberOfSlots: slotSetEvVideo.length, numberOfFreeSlots: slotSetEvVideo.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
 }
         }
 
         if(day == 2) {
    if(tueStartTimeVideo == ''){
    }
    else if(tueEndTimeVideo == ''){
    }
else{
     secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
     .child('/'+values+'/morning/').push({date:values, session: 'morning', day:'Tuesday', 
     startTime: (moment(tueStartTimeVideo, 'hh:mm A').format("hh:mm A")), 
    endTime: (moment(tueEndTimeVideo, 'hh:mm A').format("hh:mm A")),
    consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
    consultationSymbol: consultationSymbol,
     timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet1Video , numberOfSlots: slotSet1Video.length, numberOfFreeSlots: slotSet1Video.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(tueStartTimeAfVideo == ''){
}
else if(tueEndTimeAfVideo == ''){
}
else{
     secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
     .child('/'+values+'/afternoon/').push({date:values, session: 'afternoon', day:'Tuesday', 
     startTime: (moment(tueStartTimeAfVideo, 'hh:mm A').format("hh:mm A")), 
    endTime: (moment(tueEndTimeAfVideo, 'hh:mm A').format("hh:mm A")), 
    consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
    consultationSymbol: consultationSymbol,
     timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet1AfVideo , numberOfSlots: slotSet1AfVideo.length, numberOfFreeSlots: slotSet1AfVideo.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(tueStartTimeEvVideo == ''){
}
else if(tueEndTimeEvVideo == ''){
}
else{
     secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
     .child('/'+values+'/evening/').push({date:values, session: 'evening', day:'Tuesday', 
     startTime: (moment(tueStartTimeEvVideo, 'hh:mm A').format("hh:mm A")), 
    endTime: (moment(tueEndTimeEvVideo, 'hh:mm A').format("hh:mm A")),
    consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
    consultationSymbol: consultationSymbol,
     timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet1EvVideo , numberOfSlots: slotSet1EvVideo.length, numberOfFreeSlots: slotSet1EvVideo.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
   }
         }
 
         if(day == 3) {
    if(wedStartTimeVideo == ''){
    }
    else if(wedEndTimeVideo == ''){
    }
else{
     secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
     .child('/'+values+'/morning/').push({date:values, session: 'morning', day:'Wednesday', 
     startTime: (moment(wedStartTimeVideo, 'hh:mm A').format("hh:mm A")), 
    endTime: (moment(wedEndTimeVideo, 'hh:mm A').format("hh:mm A")),
    consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
    consultationSymbol: consultationSymbol,
     timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet2Video , numberOfSlots: slotSet2Video.length, numberOfFreeSlots: slotSet2Video.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(wedStartTimeAfVideo == ''){
}
else if(wedEndTimeAfVideo == ''){
}
else{
     secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
     .child('/'+values+'/afternoon/').push({date:values, session: 'afternoon', day:'Wednesday', 
     startTime: (moment(wedStartTimeAfVideo, 'hh:mm A').format("hh:mm A")), 
    endTime: (moment(wedEndTimeAfVideo, 'hh:mm A').format("hh:mm A")),
    consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
    consultationSymbol: consultationSymbol,
     timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet2AfVideo , numberOfSlots: slotSet2AfVideo.length, numberOfFreeSlots: slotSet2AfVideo.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(wedStartTimeEvVideo == ''){
}
else if(wedEndTimeEvVideo == ''){
}
else{
     secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
     .child('/'+values+'/evening/').push({date:values, session: 'evening', day:'Wednesday', 
     startTime: (moment(wedStartTimeEvVideo, 'hh:mm A').format("hh:mm A")), 
     endTime: (moment(wedEndTimeEvVideo, 'hh:mm A').format("hh:mm A")),
     consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
     consultationSymbol: consultationSymbol,
     timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet2EvVideo , numberOfSlots: slotSet2EvVideo.length, numberOfFreeSlots: slotSet2EvVideo.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
   }
         }
 
         if(day == 4) {
    if(thuStartTimeVideo == ''){
    }
    else if(thuEndTimeVideo == ''){
    }
else{
     secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
     .child('/'+values+'/morning/').push({date:values, session: 'morning', day:'Thursday', 
     startTime: (moment(thuStartTimeVideo, 'hh:mm A').format("hh:mm A")), 
    endTime: (moment(thuEndTimeVideo, 'hh:mm A').format("hh:mm A")),
    consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
    consultationSymbol: consultationSymbol,
     timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet3Video , numberOfSlots: slotSet3Video.length, numberOfFreeSlots: slotSet3Video.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(thuStartTimeAfVideo == ''){
}
else if(thuEndTimeAfVideo == ''){
}
else{
     secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
     .child('/'+values+'/afternoon/').push({date:values, session: 'afternoon', day:'Thursday', 
     startTime: (moment(thuStartTimeAfVideo, 'hh:mm A').format("hh:mm A")), 
    endTime: (moment(thuEndTimeAfVideo, 'hh:mm A').format("hh:mm A")),
    consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
    consultationSymbol: consultationSymbol,
     timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet3AfVideo , numberOfSlots: slotSet3AfVideo.length, numberOfFreeSlots: slotSet3AfVideo.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(thuStartTimeEvVideo == ''){
}
else if(thuEndTimeEvVideo == ''){
}
else{
     secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
     .child('/'+values+'/evening/').push({date:values, session: 'evening', day:'Thursday', 
     startTime: (moment(thuStartTimeEvVideo, 'hh:mm A').format("hh:mm A")), 
    endTime: (moment(thuEndTimeEvVideo, 'hh:mm A').format("hh:mm A")),
    consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
    consultationSymbol: consultationSymbol,
     timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet3EvVideo , numberOfSlots: slotSet3EvVideo.length, numberOfFreeSlots: slotSet3EvVideo.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
   }
         }
 
         if(day == 5) {
    if(friStartTimeVideo == ''){
    }
    else if(friEndTimeVideo == ''){
    }
else{
          secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
     .child('/'+values+'/morning/').push({date:values, session: 'morning', day:'Friday', 
     startTime: (moment(friStartTimeVideo, 'hh:mm A').format("hh:mm A")), 
     endTime: (moment(friEndTimeVideo, 'hh:mm A').format("hh:mm A")),
     consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
     consultationSymbol: consultationSymbol,
     timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet4Video , numberOfSlots: slotSet4Video.length, numberOfFreeSlots: slotSet4Video.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(friStartTimeAfVideo == ''){
}
else if(friEndTimeAfVideo == ''){
}
else{
     secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
     .child('/'+values+'/afternoon/').push({date:values, session: 'afternoon', day:'Friday', 
     startTime: (moment(friStartTimeAfVideo, 'hh:mm A').format("hh:mm A")), 
     endTime: (moment(friEndTimeAfVideo, 'hh:mm A').format("hh:mm A")),
     consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
     consultationSymbol: consultationSymbol,
     timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet4AfVideo , numberOfSlots: slotSet4AfVideo.length, numberOfFreeSlots: slotSet4AfVideo.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(friStartTimeEvVideo == ''){
}
else if(friEndTimeEvVideo == ''){
}
else{
     secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
     .child('/'+values+'/evening/').push({date:values, session: 'evening', day:'Friday', 
     startTime: (moment(friStartTimeEvVideo, 'hh:mm A').format("hh:mm A")), 
     endTime: (moment(friEndTimeEvVideo, 'hh:mm A').format("hh:mm A")),
     consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
     consultationSymbol: consultationSymbol,
     timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet4EvVideo , numberOfSlots: slotSet4EvVideo.length, numberOfFreeSlots: slotSet4EvVideo.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
   }
         }
 
         if(day == 6) {
    if(satStartTimeVideo == ''){
    }
    else if(satEndTimeVideo == ''){
    }
else{
     secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
     .child('/'+values+'/morning/').push({date:values, session: 'morning', day:'Saturday', 
     startTime: (moment(satStartTimeVideo, 'hh:mm A').format("hh:mm A")), 
     endTime: (moment(satEndTimeVideo, 'hh:mm A').format("hh:mm A")),
     consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
     consultationSymbol: consultationSymbol,
     timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet5Video , numberOfSlots: slotSet5Video.length, numberOfFreeSlots: slotSet5Video.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(satStartTimeAfVideo == ''){
}
else if(satEndTimeAfVideo == ''){
}
else{
     secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
     .child('/'+values+'/afternoon/').push({date:values, session: 'afternoon', day:'Saturday', 
     startTime: (moment(satStartTimeAfVideo, 'hh:mm A').format("hh:mm A")), 
     endTime: (moment(satEndTimeAfVideo, 'hh:mm A').format("hh:mm A")),
     consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
     consultationSymbol: consultationSymbol,
     timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet5AfVideo , numberOfSlots: slotSet5AfVideo.length, numberOfFreeSlots: slotSet5AfVideo.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}
if(satStartTimeEvVideo == ''){
}
else if(satEndTimeEvVideo == ''){
}
else{ 
     secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
     .child('/'+values+'/evening/').push({date:values, session: 'evening', day:'Saturday', 
     startTime: (moment(satStartTimeEvVideo, 'hh:mm A').format("hh:mm A")), 
     endTime: (moment(satEndTimeEvVideo, 'hh:mm A').format("hh:mm A")),
     consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
     consultationSymbol: consultationSymbol,
     timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet5EvVideo , numberOfSlots: slotSet5EvVideo.length, numberOfFreeSlots: slotSet5EvVideo.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
   }
         }
     }
     else{
  alert("Video Consultation Data Saved Successfully");

  setStartDate(null);
  setEndDate(null);
  setTimeSlot("");
  setVisitingType("");
  setConsultationFees("");

  history.push({
    pathname: 'viewAdminDoctor',
    state: {doctorIdPass: locationPass.state.doctorIdPass,
      departmentIdPass: locationPass.state.departmentIdPass,
    adminIdPass: locationPass.state.adminIdPass,
  superAdminIdPass:locationPass.state.superAdminIdPass}
  })
}
  }
}
}

let weekdaysAndWeekenddaysChat = (e) => {
  if(timeSlot == ''){
    alert('Please select timeslot');
  }
  else if(startDate == null){
    alert('Please select start date');
  }
  else if(endDate == null){
    alert('Please select end date');
  }
  else if(consultationFees == ''){
    alert('Please enter consultation fees');
  }
  else{
  let arra = new Array();
  let weekdays = new Array();
  let weekenddays = new Array();
  arra = dateArray;

  for(let i=0; i<=arra.length; i++){
    if(i<arra.length){
      let values = moment(arra[i]).format("DD-MM-YYYY");
      let day = arra[i].getDay();

      let valuesIndex = moment(arra[i]).format("YYYYMMDD");
      if(day == 0) {
  if(sunStartTimeChat == ''){
  }
  else if(sunEndTimeChat == ''){
  }
else{
  secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
  .child('/'+values+'/morning/').push({date:values, session: 'morning',
   day:'Sunday', startTime: (moment(sunStartTimeChat, 'hh:mm A').format("hh:mm A")), 
   endTime: (moment(sunEndTimeChat, 'hh:mm A').format("hh:mm A")), 
   consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
   consultationSymbol: consultationSymbol,
 timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet6Chat , numberOfSlots: slotSet6Chat.length, numberOfFreeSlots: slotSet6Chat.length, numberOfBookedSlots: 0, createdBy: adminName, createdByRole: 'Admin'});
}

if(sunStartTimeAfChat == ''){
}
else if(sunEndTimeAfChat == ''){
}
else{
  secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
  .child('/'+values+'/afternoon/').push({date:values, session: 'afternoon', day:'Sunday', 
  startTime: (moment(sunStartTimeAfChat, 'hh:mm A').format("hh:mm A")), 
  endTime: (moment(sunEndTimeAfChat, 'hh:mm A').format("hh:mm A")),
  consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  consultationSymbol: consultationSymbol,
  timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet6AfChat , numberOfSlots: slotSet6AfChat.length, numberOfFreeSlots: slotSet6AfChat.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(sunStartTimeEvChat == ''){
}
else if(sunEndTimeEvChat == ''){
}
else{
  secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
  .child('/'+values+'/evening/').push({date:values, session: 'evening', day:'Sunday', 
  startTime: (moment(sunStartTimeEvChat, 'hh:mm A').format("hh:mm A")), 
  endTime: (moment(sunEndTimeEvChat, 'hh:mm A').format("hh:mm A")),
  consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  consultationSymbol: consultationSymbol,
  timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet6EvChat , numberOfSlots: slotSet6EvChat.length, numberOfFreeSlots: slotSet6EvChat.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
} 
      }

      if(day == 1) {
  if(monStartTimeChat == ''){
  }
  else if(monEndTimeChat == ''){
  }
else{
        secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/morning/').push({date:values, session: 'morning', day:'Monday', 
   startTime: (moment(monStartTimeChat, 'hh:mm A').format("hh:mm A")), 
  endTime: (moment(monEndTimeChat, 'hh:mm A').format("hh:mm A")),
  consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSetChat , numberOfSlots: slotSetChat.length, numberOfFreeSlots: slotSetChat.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(monStartTimeAfChat == ''){
}
else if(monEndTimeAfChat == ''){
}
else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/afternoon/').push({date:values, session: 'afternoon', day:'Monday', 
   startTime: (moment(monStartTimeAfChat, 'hh:mm A').format("hh:mm A")), 
  endTime: (moment(monEndTimeAfChat, 'hh:mm A').format("hh:mm A")),
  consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSetAfChat , numberOfSlots: slotSetAfChat.length, numberOfFreeSlots: slotSetAfChat.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(monStartTimeEvChat == ''){
}
else if(monEndTimeEvChat == ''){
}
else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/evening/').push({date:values, session: 'evening', day:'Monday', 
   startTime: (moment(monStartTimeEvChat, 'hh:mm A').format("hh:mm A")), 
   endTime: (moment(monEndTimeEvChat, 'hh:mm A').format("hh:mm A")),
   consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
   consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSetEvChat , numberOfSlots: slotSetEvChat.length, numberOfFreeSlots: slotSetEvChat.length, numberOfBookedSlots: 0, createdBy: adminName, createdByRole: 'Admin'});
}
       }

       if(day == 2) {
  if(tueStartTimeChat == ''){
  }
  else if(tueEndTimeChat == ''){
  }
else{
        secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/morning/').push({date:values, session: 'morning', day:'Tuesday', 
   startTime: (moment(tueStartTimeChat, 'hh:mm A').format("hh:mm A")), 
  endTime: (moment(tueEndTimeChat, 'hh:mm A').format("hh:mm A")),
  consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet1Chat , numberOfSlots: slotSet1Chat.length, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(tueStartTimeAfChat == ''){
}
else if(tueEndTimeAfChat == ''){
}
else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/afternoon/').push({date:values, session: 'afternoon', day:'Tuesday', 
   startTime: (moment(tueStartTimeAfChat, 'hh:mm A').format("hh:mm A")), 
  endTime: (moment(tueEndTimeAfChat, 'hh:mm A').format("hh:mm A")), 
  consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet1AfChat , numberOfSlots: slotSet1AfChat.length, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(tueStartTimeEvChat == ''){
}
else if(tueEndTimeEvChat == ''){
}
else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/evening/').push({date:values, session: 'evening', day:'Tuesday', 
   startTime: (moment(tueStartTimeEvChat, 'hh:mm A').format("hh:mm A")), 
  endTime: (moment(tueEndTimeEvChat, 'hh:mm A').format("hh:mm A")),
  consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet1EvChat , numberOfSlots: slotSet1EvChat.length, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
 }
      }

       if(day == 3) {
  if(wedStartTimeChat == ''){
  }
  else if(wedEndTimeChat == ''){
  }
else{
        secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/morning/').push({date:values, session: 'morning', day:'Wednesday', 
   startTime: (moment(wedStartTimeChat, 'hh:mm A').format("hh:mm A")), 
  endTime: (moment(wedEndTimeChat, 'hh:mm A').format("hh:mm A")),
  consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet2Chat , numberOfSlots: slotSet2Chat.length, numberOfFreeSlots: slotSet2Chat.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(wedStartTimeAfChat == ''){
}
else if(wedEndTimeAfChat == ''){
}
else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/afternoon/').push({date:values, session: 'afternoon', day:'Wednesday', 
   startTime: (moment(wedStartTimeAfChat, 'hh:mm A').format("hh:mm A")), 
  endTime: (moment(wedEndTimeAfChat, 'hh:mm A').format("hh:mm A")),
  consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet2AfChat , numberOfSlots: slotSet2AfChat.length, numberOfFreeSlots: slotSet2AfChat.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(wedStartTimeEvChat == ''){
}
else if(wedEndTimeEvChat == ''){
}
else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/evening/').push({date:values, session: 'evening', day:'Wednesday', 
   startTime: (moment(wedStartTimeEvChat, 'hh:mm A').format("hh:mm A")), 
   endTime: (moment(wedEndTimeEvChat, 'hh:mm A').format("hh:mm A")),
   consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
   consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet2EvChat , numberOfSlots: slotSet2EvChat.length, numberOfFreeSlots: slotSet2EvChat.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
 }
       }

       if(day == 4) {
  if(thuStartTimeChat == ''){
  }
  else if(thuEndTimeChat == ''){
  }
else{
        secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/morning/').push({date:values, session: 'morning', day:'Thursday', 
   startTime: (moment(thuStartTimeChat, 'hh:mm A').format("hh:mm A")), 
  endTime: (moment(thuEndTimeChat, 'hh:mm A').format("hh:mm A")),
  consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet3Chat , numberOfSlots: slotSet3Chat.length, numberOfFreeSlots: slotSet3Chat.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(thuStartTimeAfChat == ''){
}
else if(thuEndTimeAfChat == ''){
}
else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/afternoon/').push({date:values, session: 'afternoon', day:'Thursday', 
   startTime: (moment(thuStartTimeAfChat, 'hh:mm A').format("hh:mm A")), 
  endTime: (moment(thuEndTimeAfChat, 'hh:mm A').format("hh:mm A")),
  consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet3AfChat , numberOfSlots: slotSet3AfChat.length, numberOfFreeSlots: slotSet3AfChat.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(thuStartTimeEvChat == ''){
}
else if(thuEndTimeEvChat == ''){
}
else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/evening/').push({date:values, session: 'evening', day:'Thursday', 
   startTime: (moment(thuStartTimeEvChat, 'hh:mm A').format("hh:mm A")), 
  endTime: (moment(thuEndTimeEvChat, 'hh:mm A').format("hh:mm A")),
  consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
  consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet3EvChat , numberOfSlots: slotSet3EvChat.length, numberOfFreeSlots: slotSet3EvChat.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
 }
       }

       if(day == 5) {
  if(friStartTimeChat == ''){
  }
  else if(friEndTimeChat == ''){
  }
else{
        secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/morning/').push({date:values, session: 'morning', day:'Friday', 
   startTime: (moment(friStartTimeChat, 'hh:mm A').format("hh:mm A")), 
   endTime: (moment(friEndTimeChat, 'hh:mm A').format("hh:mm A")),
   consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
   consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet4Chat , numberOfSlots: slotSet4Chat.length, numberOfFreeSlots: slotSet4Chat.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(friStartTimeAfChat == ''){
}
else if(friEndTimeAfChat == ''){
}
else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/afternoon/').push({date:values, session: 'afternoon', day:'Friday', 
   startTime: (moment(friStartTimeAfChat, 'hh:mm A').format("hh:mm A")), 
   endTime: (moment(friEndTimeAfChat, 'hh:mm A').format("hh:mm A")),
   consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
   consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet4AfChat , numberOfSlots: slotSet4AfChat.length, numberOfFreeSlots: slotSet4AfChat.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(friStartTimeEvChat == ''){
}
else if(friEndTimeEvChat == ''){
}
else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/evening/').push({date:values, session: 'evening', day:'Friday', 
   startTime: (moment(friStartTimeEvChat, 'hh:mm A').format("hh:mm A")), 
   endTime: (moment(friEndTimeEvChat, 'hh:mm A').format("hh:mm A")),
   consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
   consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet4EvChat , numberOfSlots: slotSet4EvChat.length, numberOfFreeSlots: slotSet4EvChat.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
 }
       }

       if(day == 6) {
  if(satStartTimeChat == ''){
  }
  else if(satEndTimeChat == ''){
  }
else{
        secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/morning/').push({date:values, session: 'morning', day:'Saturday', 
   startTime: (moment(satStartTimeChat, 'hh:mm A').format("hh:mm A")), 
   endTime: (moment(satEndTimeChat, 'hh:mm A').format("hh:mm A")),
   consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
   consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet5Chat , numberOfSlots: slotSet5Chat.length, numberOfFreeSlots: slotSet5Chat.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(satStartTimeAfChat == ''){
}
else if(satEndTimeAfChat == ''){
}
else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/afternoon/').push({date:values, session: 'afternoon', day:'Saturday', 
   startTime: (moment(satStartTimeAfChat, 'hh:mm A').format("hh:mm A")), 
   endTime: (moment(satEndTimeAfChat, 'hh:mm A').format("hh:mm A")),
   consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
   consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet5AfChat , numberOfSlots: slotSet5AfChat.length, numberOfFreeSlots: slotSet5AfChat.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
}

if(satStartTimeEvChat == ''){
}
else if(satEndTimeEvChat == ''){
}
else{
   secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/appointmentDetailsNew')
   .child('/'+values+'/evening/').push({date:values, session: 'evening', day:'Saturday', 
   startTime: (moment(satStartTimeEvChat, 'hh:mm A').format("hh:mm A")), 
   endTime: (moment(satEndTimeEvChat, 'hh:mm A').format("hh:mm A")),
   consultationFees: consultationFees.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
   consultationSymbol: consultationSymbol,
   timeSlot:timeSlot, visitingType: visitingType, slotSet: slotSet5EvChat , numberOfSlots: slotSet5EvChat.length, numberOfFreeSlots: slotSet5EvChat.length, numberOfBookedSlots: 0, createdAt: new Date(), createdBy: adminName, createdByRole: 'Admin'});
  }
        }
    }else{
  alert("Live Chat Data Saved Successfully");

  setStartDate(null);
  setEndDate(null);
  setTimeSlot("");
  setVisitingType("");
  setConsultationFees("");

   history.push({
    pathname: 'viewAdminDoctor',
    state: {doctorIdPass: locationPass.state.doctorIdPass,
      departmentIdPass: locationPass.state.departmentIdPass,
    adminIdPass: locationPass.state.adminIdPass,
  superAdminIdPass:locationPass.state.superAdminIdPass}
})

}
  }
}

}

return (
  <section style={{backgroundColor: '#fff', overflow: 'auto', minHeight: '100%', fontFamily: 'poppins medium'}}>
  <Navigation />
  <div className="py-2 mb-4" style={{marginTop:'80px'}}>
  <i className="fa fa-arrow-circle-o-left fa-3x" style={{color: '#5CB04D', marginLeft: '10px'}} onClick={reset}></i> 
        </div>
 
<div className="superadmin_doctortimeslots">
      <div className="container">
          <div className="superadmin_doctortimeslots_details" 
          style={{marginTop:'5px', marginBottom:'20px', backgroundColor:'white', boxShadow:' 0px 0 5px rgba(0, 0, 0, 0.3)', borderRadius:'10px',
         }}>
          <div className="superadmin_doctortimeslots_title" style={{backgroundColor:'#105c25', color:'white', padding:'17px',
          borderTopLeftRadius:'10px', borderTopRightRadius:'10px'}}>
            <h3 style={{fontWeight:'700', fontSize:'24px', marginLeft:'5px', marginBottom:'0px'}}>CREATE TIME SLOTS</h3>          
           </div>
            <div className="row" style={{display:'block', justifyContent:'center', padding:'30px 50px'}}>
              <div className="">
                <div className="superadmin_doctortimeslots_info">
                    <div className="row">

                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 mb-2">
                            <div class="form-group">
                              <label style={{fontWeight:'600', fontSize:'14px'}}>TYPE OF VISTING</label>
                              <select class="form-control" style={{borderRadius:'25px', textIndent:'2px'}} 
                              value={visitingType}  onChange={(event) => { setVisitingType( event.target.value ); }} required>
                                <option>Select Visting Type</option>
                                <option value="inPersonConsultation">In-Person Consultation</option>
                                <option value="videoConsultation">Video Consultation</option>
                                <option value="liveChatConsultation">Live Chat Consultation</option>
                              </select>
                            </div>
                        </div>
                        {visitingType == "inPersonConsultation"?
<>
                        <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 mb-2">
                          <label style={{fontWeight:'600', fontSize:'14px'}}>START DATE</label>
                      
            
            <DatePicker
            dateFormat="dd/MM/yyyy"
            className="form-control"
      selected={startDate}
      minDate={previousEndDate}
      maxDate={previousEndDate1}
      onChange={handleStartDate}
      placeholderText="Select a date"
      showDisabledMonthNavigation/>
         
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 mb-2">
                          <label style={{fontWeight:'600', fontSize:'14px'}}>END DATE </label>
          <DatePicker
          dateFormat="dd/MM/yyyy"
          className="form-control"
          selected={endDate}
          onChange={handleEndDate}
          minDate={previousEndDate}
          maxDate={previousEndDate1}
          placeholderText="Select a date"
          showDisabledMonthNavigation/>
         
          </div>
                        <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 mb-2">
                            <div class="form-group">
                              <label style={{fontWeight:'600', fontSize:'14px'}}>TIME SLOTS</label>
         <select id="timeSlot" name="timeSlot" className="form-control" style={{borderRadius:'25px', textIndent:'10px'}} 
         value={timeSlot}  onChange={(event) => { setTimeSlot( event.target.value ); }} required>
         <option value="">Please select time slots</option>
         <option value="15">15 Minutes</option>
         <option value="30">30 Minutes</option>
         <option value="60">60 Minutes</option>
         </select>
       
         </div>
         </div>

         <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 mb-2">
                            <div class="form-group">
                              <label style={{fontWeight:'600', fontSize:'14px'}}>CONSULTATION FEE</label>
                             
                              <div class="input-group mb-3">
    <div class="input-group-prepend">
      <span class="input-group-text">UGX</span>
    </div>
    <input class="form-control" value={consultationFees} type="text" maxLength="11"
                  /*onChange={handleConsultationFees}*/  onChange={(event) => {
                      if (isNaN(Number(event.target.value))) {
                        return;
                      } else { 
                           setConsultationFees( event.target.value );
                      }
                    }} placeholder="Enter consultaion fee" />
  </div>

                            </div>
                        </div>

         </>
         :
null
                        }

{visitingType == "videoConsultation"?
<>
                        <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 mb-2">
                          <label style={{fontWeight:'600', fontSize:'14px'}}>START DATE</label>
                      
            
            <DatePicker
            dateFormat="dd/MM/yyyy"
            className="form-control"
            selected={startDate}
      minDate={previousEndDateVideo}
      maxDate={previousEndDate1Video}
      onChange={handleStartDate}
      placeholderText="Select a date"
      showDisabledMonthNavigation/>
         
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 mb-2">
                          <label style={{fontWeight:'600', fontSize:'14px'}}>END DATE </label>
          <DatePicker
          dateFormat="dd/MM/yyyy"
          className="form-control"
          selected={endDate}
          onChange={handleEndDate}
          minDate={previousEndDateVideo}
          maxDate={previousEndDate1Video}
          placeholderText="Select a date"
          showDisabledMonthNavigation/>
         
          </div>

      



                        <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 mb-2">
                            <div class="form-group">
                              <label style={{fontWeight:'600', fontSize:'14px'}}>TIME SLOTS</label>
         <select id="timeSlot" name="timeSlot" className="form-control" style={{borderRadius:'25px', textIndent:'10px'}} 
         value={timeSlot}  onChange={(event) => { setTimeSlot( event.target.value ); }} required>
         <option value="">Please select time slots</option>
         <option value="15">15 Minutes</option>
         <option value="30">30 Minutes</option>
         <option value="60">60 Minutes</option>
         </select>
       
         </div>
         </div>

         <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 mb-2">
                            <div class="form-group">
                              <label style={{fontWeight:'600', fontSize:'14px'}}>CONSULTATION FEE</label>
                             
                              <div class="input-group mb-3">
    <div class="input-group-prepend">
      <span class="input-group-text">UGX</span>
    </div>
    <input class="form-control" value={consultationFees} type="tel" maxLength="11"
                     onChange={(event) => {
                      if (isNaN(Number(event.target.value))) {
                        return;
                      } else {
                        setConsultationFees( event.target.value );
                      }
                    }} placeholder="Enter consultaion fee" />
  </div>

                            </div>
                        </div>

         </>
         :
null
                        }

{visitingType == "liveChatConsultation"?
<>
                        <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 mb-2">
                          <label style={{fontWeight:'600', fontSize:'14px'}}>START DATE</label>
                      
            
            <DatePicker
            dateFormat="dd/MM/yyyy"
            className="form-control"
            selected={startDate}
      minDate={previousEndDateChat}
      maxDate={previousEndDate1Chat}
      onChange={handleStartDate}
      placeholderText="Select a date"
      showDisabledMonthNavigation/>
         
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 mb-2">
                          <label style={{fontWeight:'600', fontSize:'14px'}}>END DATE </label>
          <DatePicker
          dateFormat="dd/MM/yyyy"
          className="form-control"
          selected={endDate}
          onChange={handleEndDate}
          minDate={previousEndDateChat}
          maxDate={previousEndDate1Chat}
          placeholderText="Select a date"
          showDisabledMonthNavigation/>
         
          </div>

      



                        <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 mb-2">
                            <div class="form-group">
                              <label style={{fontWeight:'600', fontSize:'14px'}}>TIME SLOTS</label>
         <select id="timeSlot" name="timeSlot" className="form-control" style={{borderRadius:'25px', textIndent:'10px'}} 
         value={timeSlot}  onChange={(event) => { setTimeSlot( event.target.value ); }} required>
         <option value="">Please select time slots</option>
         <option value="15">15 Minutes</option>
         <option value="30">30 Minutes</option>
         <option value="60">60 Minutes</option>
         </select>
       
         </div>
         </div>

         <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 mb-2">
                            <div class="form-group">
                              <label style={{fontWeight:'600', fontSize:'14px'}}>CONSULTATION FEE</label>
                             
                              <div class="input-group mb-3">
    <div class="input-group-prepend">
      <span class="input-group-text">UGX</span>
    </div>
    <input class="form-control" value={consultationFees} type="tel" maxLength="11"
                     onChange={(event) => {
                      if (isNaN(Number(event.target.value))) {
                        return;
                      } else {
                        setConsultationFees( event.target.value );
                      }
                    }} placeholder="Enter consultaion fee" />
  </div>

                            </div>
                        </div>

         </>
         :
null

}
          </div>

          {visitingType == "inPersonConsultation"?
<>
<center>
<h3 style={{textTransform: 'uppercase'}}>Morning</h3>
</center>

          <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 superadmin_doctortimeslots__weekdaysslot">
                    <h3 style={{fontWeight:'700', fontSize:'18px', marginTop:'15px', marginBottom:'15px',backgroundColor:'#5CB04D', color: '#fff', padding:'10px',
                  borderRadius:'5px'}}>TIME SLOTS FOR WEEK DAYS</h3>
        
        <div className="slot_monday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Monday</label>
                
                   <div className="row">
               
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={monStartTime} 
                  onChange={(event) => { setMonStartTime( event.target.value ); }}>
                     <option value="">Start</option>
  {morMonLast > '06:00 AM'?
                     <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morMonLast > '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morMonLast > '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morMonLast > '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morMonLast > '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morMonLast > '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morMonLast > '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}
                    </select>
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                <select className="form-control" value={monEndTime} onChange={handleMonEndTime}>
                <option value="">End</option>
                {morMonLast >= '06:00 AM'?
                     <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morMonLast >= '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morMonLast >= '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morMonLast >= '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morMonLast >= '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morMonLast >= '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morMonLast >= '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}

              
                    </select>
                </div>
                    </div>
                  </div>

                  <div className="slot_tuesday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Tuesday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={tueStartTime}
                   onChange={(event) => { setTueStartTime( event.target.value ); }}>
                     <option value="">Start</option>
                  {morTueLast > '06:00 AM'?
                     <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morTueLast > '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morTueLast > '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morTueLast > '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morTueLast > '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morTueLast > '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morTueLast > '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}
                    </select>
              </div>
              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
              <select className="form-control" value={tueEndTime} onChange={handleTueEndTime}>
              <option value="">End</option>
              {morTueLast >= '06:00 AM'?
                     <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morTueLast >= '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morTueLast >= '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morTueLast >= '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morTueLast >= '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morTueLast >= '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morTueLast >= '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}
                    </select>
              </div>
            </div>
            </div>

            <div className="slot_wednesday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Wednesday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={wedStartTime} 
                   onChange={(event) => { setWedStartTime( event.target.value ); }}>
                     <option value="">Start</option>
{morWedLast > '06:00 AM'?
                     <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morWedLast > '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morWedLast > '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morWedLast > '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morWedLast > '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morWedLast > '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morWedLast > '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}
                    </select>
              </div>
              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
              <select className="form-control" value={wedEndTime} onChange={handleWedEndTime}>
              <option value="">End</option>
              {morWedLast >= '06:00 AM'?
                     <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morWedLast >= '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morWedLast >= '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morWedLast >= '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morWedLast >= '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morWedLast >= '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morWedLast >= '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}
                    </select>
              </div>
                    </div>
                  </div>

                  <div className="slot_thursday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Thursday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={thuStartTime}
                   onChange={(event) => { setThuStartTime( event.target.value ); }}>
                     <option value="">Start</option>
{morThuLast > '06:00 AM'?
                     <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morThuLast > '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morThuLast > '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morThuLast > '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morThuLast > '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morThuLast > '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morThuLast > '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}
                    </select>
              </div>
              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
              <select className="form-control" value={thuEndTime} onChange={handleThuEndTime}>
              <option value="">End</option>
              {morThuLast >= '06:00 AM'?
                     <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morThuLast >= '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morThuLast >= '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morThuLast >= '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morThuLast >= '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morThuLast >= '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morThuLast >= '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}
                    </select>
            </div>
                    </div>
                  </div>

          <div className="slot_friday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Friday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={friStartTime}
                   onChange={(event) => { setFriStartTime( event.target.value ); }}>
                     <option value="">Start</option>
{morFriLast > '06:00 AM'?
                  <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morFriLast > '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morFriLast > '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morFriLast > '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morFriLast > '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morFriLast > '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morFriLast > '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}
                    </select>
              </div>
              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
              <select className="form-control" value={friEndTime} onChange={handleFriEndTime}>
              <option value="">End</option>
              {morFriLast >= '06:00 AM'?
                     <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morFriLast >= '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morFriLast >= '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morFriLast >= '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morFriLast >= '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morFriLast >= '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morFriLast >= '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}
                    </select>
              </div>
            </div>
            </div>            
</div>


<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 superadmin_doctortimeslots_weekendsslot">
                    <h3 style={{fontWeight:'700', fontSize:'18px', marginTop:'15px', marginBottom:'15px',backgroundColor:'#5CB04D', color: '#fff', padding:'10px',
                  borderRadius:'5px'}}>TIME SLOTS FOR WEEK ENDS</h3>          
                  <div className="slot_Saturday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Saturday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={satStartTime}
                   onChange={(event) => { setSatStartTime( event.target.value ); }}>
                     <option value="">Start</option>
{morSatLast > '06:00 AM'?
                  <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morSatLast > '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morSatLast > '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morSatLast > '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morSatLast > '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morSatLast > '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morSatLast > '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}
                    </select>
            </div>
            <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
            <select className="form-control" value={satEndTime} onChange={handleSatEndTime}>
            <option value="">End</option>
            {morSatLast >= '06:00 AM'?
                     <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morSatLast >= '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morSatLast >= '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morSatLast >= '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morSatLast >= '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morSatLast >= '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morSatLast >= '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}
                    </select>
            </div>
                    </div>
                  </div> 

                  <div className="slot_sunday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Sunday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={sunStartTime} onChange={(event) => { setSunStartTime( event.target.value ); }}>
                      <option value="">Start</option>
                      {morSunLast > '06:00 AM'?
                  <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morSunLast > '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morSunLast > '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morSunLast > '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morSunLast > '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morSunLast > '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morSunLast > '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}
                    </select>
            </div>
            <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
            <select className="form-control" value={sunEndTime} onChange={handleSunEndTime}>
            <option value="">End</option>
            {morSunLast >= '06:00 AM'?
                     <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morSunLast >= '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morSunLast >= '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morSunLast >= '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morSunLast >= '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morSunLast >= '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morSunLast >= '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}
                    </select>
            </div>
          </div>
          </div>
          </div>
          </div>


          <center>
<h3 style={{textTransform: 'uppercase'}}>Afternoon</h3>
</center>


          <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 superadmin_doctortimeslots__weekdaysslot">
                    <h3 style={{fontWeight:'700', fontSize:'18px', marginTop:'15px', marginBottom:'15px',backgroundColor:'#5CB04D', color: '#fff', padding:'10px',
                  borderRadius:'5px'}}>TIME SLOTS FOR WEEK DAYS</h3>
        <div className="slot_monday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Monday</label>
          
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={monStartTimeAf} 
                  onChange={(event) => { setMonStartTimeAf( event.target.value ); }}>
<option value="">Start</option>
{afMonLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afMonLast > '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afMonLast > '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afMonLast > '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afMonLast > '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}

                    </select>
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                <select className="form-control" value={monEndTimeAf} onChange={handleMonEndTimeAf}>
                <option value="">End</option>
                {afMonLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afMonLast >= '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afMonLast >= '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afMonLast >= '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afMonLast >= '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
                </div>
                    </div>
                  </div>

                  <div className="slot_tuesday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Tuesday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={tueStartTimeAf}
                   onChange={(event) => { setTueStartTimeAf( event.target.value ); }}>
                     <option value="">Start</option>
{afTueLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afTueLast > '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afTueLast > '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afTueLast > '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afTueLast > '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
              </div>
              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
              <select className="form-control" value={tueEndTimeAf} onChange={handleTueEndTimeAf}>
              <option value="">End</option>
              {afTueLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afTueLast >= '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afTueLast >= '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afTueLast >= '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afTueLast >= '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
              </div>
            </div>
            </div>

            <div className="slot_wednesday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Wednesday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={wedStartTimeAf} 
                   onChange={(event) => { setWedStartTimeAf( event.target.value ); }}>
                     <option value="">Start</option>
{afWedLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afWedLast > '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afWedLast > '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afWedLast > '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afWedLast > '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
              </div>
              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
              <select className="form-control" value={wedEndTimeAf} onChange={handleWedEndTimeAf}>
              <option value="">End</option>
              {afWedLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afWedLast >= '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afWedLast >= '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afWedLast >= '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afWedLast >= '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
              </div>
                    </div>
                  </div>

                  <div className="slot_thursday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Thursday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={thuStartTimeAf}
                   onChange={(event) => { setThuStartTimeAf( event.target.value ); }} >
                     <option value="">Start</option>
{afThuLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afThuLast > '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afThuLast > '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afThuLast > '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afThuLast > '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
              </div>
              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
              <select className="form-control" value={thuEndTimeAf} onChange={handleThuEndTimeAf}>
              <option value="">End</option>
              {afThuLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afThuLast >= '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afThuLast >= '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afThuLast >= '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afThuLast >= '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
            </div>
                    </div>
                  </div>

          <div className="slot_friday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Friday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={friStartTimeAf}
                   onChange={(event) => { setFriStartTimeAf( event.target.value ); }}>
                     <option value="">Start</option>
{afFriLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afFriLast > '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afFriLast > '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afFriLast > '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afFriLast > '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
              </div>
              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
              <select className="form-control" value={friEndTimeAf} onChange={handleFriEndTimeAf}>
              <option value="">End</option>
              {afFriLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afFriLast >= '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afFriLast >= '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afFriLast >= '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afFriLast >= '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
              </div>
            </div>
            </div>            
</div>


<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 superadmin_doctortimeslots_weekendsslot">
                    <h3 style={{fontWeight:'700', fontSize:'18px', marginTop:'15px', marginBottom:'15px',backgroundColor:'#5CB04D', color: '#fff', padding:'10px',
                  borderRadius:'5px'}}>TIME SLOTS FOR WEEK ENDS</h3>          
                  <div className="slot_Saturday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Saturday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={satStartTimeAf}
                   onChange={(event) => { setSatStartTimeAf( event.target.value ); }}>
                     <option value="">Start</option>
{afSatLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afSatLast > '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afSatLast > '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afSatLast > '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afSatLast > '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
            </div>
            <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
            <select className="form-control" value={satEndTimeAf} onChange={handleSatEndTimeAf}>
            <option value="">End</option>
            {afSatLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afSatLast >= '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afSatLast >= '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afSatLast >= '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afSatLast >= '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
            </div>
                    </div>
                  </div> 

                  <div className="slot_sunday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Sunday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={sunStartTimeAf}
                   onChange={(event) => { setSunStartTimeAf( event.target.value ); }}>
<option value="">Start</option>
{afSunLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afSunLast > '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afSunLast > '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afSunLast > '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afSunLast > '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
            </div>
            <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
            <select className="form-control" value={sunEndTimeAf} onChange={handleSunEndTimeAf}>
            <option value="">End</option>
            {afSunLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afSunLast >= '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afSunLast >= '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afSunLast >= '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afSunLast >= '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
            </div>
          </div>
          </div>
          </div>
          </div>


          <center>
<h3 style={{textTransform: 'uppercase'}}>Evening</h3>
</center>

          <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 superadmin_doctortimeslots__weekdaysslot">
                    <h3 style={{fontWeight:'700', fontSize:'18px', marginTop:'15px', marginBottom:'15px',backgroundColor:'#5CB04D', color: '#fff', padding:'10px',
                  borderRadius:'5px'}}>TIME SLOTS FOR WEEK DAYS</h3>
        
        <div className="slot_monday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Monday</label>
           
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={monStartTimeEv} 
                  onChange={(event) => { setMonStartTimeEv( event.target.value ); }}>
<option value="">Start</option>
{evMonLast > '04:00 PM'?
 <option value="04:00 PM" disabled>04:00 PM</option>   
                     :
                     <option value="04:00 PM">04:00 PM</option>    
}

{evMonLast > '05:00 PM'?
                     <option value="05:00 PM" disabled>05:00 PM</option>
                     :
                     <option value="05:00 PM">05:00 PM</option>
}
{evMonLast > '06:00 PM'?
                     <option value="06:00 PM" disabled>06:00 PM</option>
                     :
                     <option value="06:00 PM">06:00 PM</option>
}
{evMonLast > '07:00 PM'?
                          <option value="07:00 PM" disabled>07:00 PM</option>
                     :
                     <option value="07:00 PM">07:00 PM</option>
}
{evMonLast > '08:00 PM'?
                     <option value="08:00 PM" disabled>08:00 PM</option>
                     :
                     <option value="08:00 PM">08:00 PM</option>
}
{evMonLast > '09:00 PM'?
                     <option value="09:00 PM" disabled>09:00 PM</option>
                     :
                     <option value="09:00 PM">09:00 PM</option>
}
{evMonLast > '10:00 PM'?
                     <option value="10:00 PM" disabled>10:00 PM</option>
                     :
                     <option value="10:00 PM">10:00 PM</option>
}
                    </select>
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                <select className="form-control" value={monEndTimeEv} onChange={handleMonEndTimeEv}>
                <option value="">End</option>
                {evMonLast >= '04:00 PM'?
 <option value="04:00 PM" disabled>04:00 PM</option>   
                     :
                     <option value="04:00 PM">04:00 PM</option>    
}

{evMonLast >= '05:00 PM'?
                     <option value="05:00 PM" disabled>05:00 PM</option>
                     :
                     <option value="05:00 PM">05:00 PM</option>
}
{evMonLast >= '06:00 PM'?
                     <option value="06:00 PM" disabled>06:00 PM</option>
                     :
                     <option value="06:00 PM">06:00 PM</option>
}
{evMonLast >= '07:00 PM'?
                          <option value="07:00 PM" disabled>07:00 PM</option>
                     :
                     <option value="07:00 PM">07:00 PM</option>
}
{evMonLast > '08:00 PM'?
                     <option value="08:00 PM" disabled>08:00 PM</option>
                     :
                     <option value="08:00 PM">08:00 PM</option>
}
{evMonLast >= '09:00 PM'?
                     <option value="09:00 PM" disabled>09:00 PM</option>
                     :
                     <option value="09:00 PM">09:00 PM</option>
}
{evMonLast >= '10:00 PM'?
                     <option value="10:00 PM" disabled>10:00 PM</option>
                     :
                     <option value="10:00 PM">10:00 PM</option>
}
                    </select>
                </div>
                    </div>
                  </div>

                  <div className="slot_tuesday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Tuesday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={tueStartTimeEv}
                   onChange={(event) => { setTueStartTimeEv( event.target.value ); }}>
                     <option value="">Start</option>
{evTueLast > '04:00 PM'?
 <option value="04:00 PM" disabled>04:00 PM</option>   
                     :
                     <option value="04:00 PM">04:00 PM</option>    
}

{evTueLast > '05:00 PM'?
                     <option value="05:00 PM" disabled>05:00 PM</option>
                     :
                     <option value="05:00 PM">05:00 PM</option>
}
{evTueLast > '06:00 PM'?
                     <option value="06:00 PM" disabled>06:00 PM</option>
                     :
                     <option value="06:00 PM">06:00 PM</option>
}
{evTueLast > '07:00 PM'?
                          <option value="07:00 PM" disabled>07:00 PM</option>
                     :
                     <option value="07:00 PM">07:00 PM</option>
}
{evTueLast > '08:00 PM'?
                     <option value="08:00 PM" disabled>08:00 PM</option>
                     :
                     <option value="08:00 PM">08:00 PM</option>
}
{evTueLast > '09:00 PM'?
                     <option value="09:00 PM" disabled>09:00 PM</option>
                     :
                     <option value="09:00 PM">09:00 PM</option>
}
{evTueLast > '10:00 PM'?
                     <option value="10:00 PM" disabled>10:00 PM</option>
                     :
                     <option value="10:00 PM">10:00 PM</option>
}
                    </select>
              </div>
              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
              <select className="form-control" value={tueEndTimeEv} onChange={handleTueEndTimeEv}>
              <option value="">End</option>
              {evTueLast >= '04:00 PM'?
 <option value="04:00 PM" disabled>04:00 PM</option>   
                     :
                     <option value="04:00 PM">04:00 PM</option>    
}

{evTueLast >= '05:00 PM'?
                     <option value="05:00 PM" disabled>05:00 PM</option>
                     :
                     <option value="05:00 PM">05:00 PM</option>
}
{evTueLast >= '06:00 PM'?
                     <option value="06:00 PM" disabled>06:00 PM</option>
                     :
                     <option value="06:00 PM">06:00 PM</option>
}
{evTueLast >= '07:00 PM'?
                          <option value="07:00 PM" disabled>07:00 PM</option>
                     :
                     <option value="07:00 PM">07:00 PM</option>
}
{evTueLast >= '08:00 PM'?
                     <option value="08:00 PM" disabled>08:00 PM</option>
                     :
                     <option value="08:00 PM">08:00 PM</option>
}
{evTueLast >= '09:00 PM'?
                     <option value="09:00 PM" disabled>09:00 PM</option>
                     :
                     <option value="09:00 PM">09:00 PM</option>
}
{evTueLast >= '10:00 PM'?
                     <option value="10:00 PM" disabled>10:00 PM</option>
                     :
                     <option value="10:00 PM">10:00 PM</option>
}
                    </select>
              </div>
            </div>
            </div>

            <div className="slot_wednesday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Wednesday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={wedStartTimeEv} 
                   onChange={(event) => { setWedStartTimeEv( event.target.value ); }}>
                     <option value="">Start</option>
{evWedLast > '04:00 PM'?
 <option value="04:00 PM" disabled>04:00 PM</option>   
                     :
                     <option value="04:00 PM">04:00 PM</option>    
}

{evWedLast > '05:00 PM'?
                     <option value="05:00 PM" disabled>05:00 PM</option>
                     :
                     <option value="05:00 PM">05:00 PM</option>
}
{evWedLast > '06:00 PM'?
                     <option value="06:00 PM" disabled>06:00 PM</option>
                     :
                     <option value="06:00 PM">06:00 PM</option>
}
{evWedLast > '07:00 PM'?
                          <option value="07:00 PM" disabled>07:00 PM</option>
                     :
                     <option value="07:00 PM">07:00 PM</option>
}
{evWedLast > '08:00 PM'?
                     <option value="08:00 PM" disabled>08:00 PM</option>
                     :
                     <option value="08:00 PM">08:00 PM</option>
}
{evWedLast > '09:00 PM'?
                     <option value="09:00 PM" disabled>09:00 PM</option>
                     :
                     <option value="09:00 PM">09:00 PM</option>
}
{evWedLast > '10:00 PM'?
                     <option value="10:00 PM" disabled>10:00 PM</option>
                     :
                     <option value="10:00 PM">10:00 PM</option>
}
                    </select>
              </div>
              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
              <select className="form-control" value={wedEndTimeEv} onChange={handleWedEndTimeEv}>
              <option value="">End</option>
              {evWedLast >= '04:00 PM'?
 <option value="04:00 PM" disabled>04:00 PM</option>   
                     :
                     <option value="04:00 PM">04:00 PM</option>    
}

{evWedLast >= '05:00 PM'?
                     <option value="05:00 PM" disabled>05:00 PM</option>
                     :
                     <option value="05:00 PM">05:00 PM</option>
}
{evWedLast >= '06:00 PM'?
                     <option value="06:00 PM" disabled>06:00 PM</option>
                     :
                     <option value="06:00 PM">06:00 PM</option>
}
{evWedLast >= '07:00 PM'?
                          <option value="07:00 PM" disabled>07:00 PM</option>
                     :
                     <option value="07:00 PM">07:00 PM</option>
}
{evWedLast >= '08:00 PM'?
                     <option value="08:00 PM" disabled>08:00 PM</option>
                     :
                     <option value="08:00 PM">08:00 PM</option>
}
{evWedLast >= '09:00 PM'?
                     <option value="09:00 PM" disabled>09:00 PM</option>
                     :
                     <option value="09:00 PM">09:00 PM</option>
}
{evWedLast >= '10:00 PM'?
                     <option value="10:00 PM" disabled>10:00 PM</option>
                     :
                     <option value="10:00 PM">10:00 PM</option>
}
                    </select>
              </div>
                    </div>
                  </div>

                  <div className="slot_thursday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Thursday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={thuStartTimeEv}
                   onChange={(event) => { setThuStartTimeEv( event.target.value ); }}>
                     <option value="">Start</option>
{evThuLast > '04:00 PM'?
 <option value="04:00 PM" disabled>04:00 PM</option>   
                     :
                     <option value="04:00 PM">04:00 PM</option>    
}

{evThuLast > '05:00 PM'?
                     <option value="05:00 PM" disabled>05:00 PM</option>
                     :
                     <option value="05:00 PM">05:00 PM</option>
}
{evThuLast > '06:00 PM'?
                     <option value="06:00 PM" disabled>06:00 PM</option>
                     :
                     <option value="06:00 PM">06:00 PM</option>
}
{evThuLast > '07:00 PM'?
                          <option value="07:00 PM" disabled>07:00 PM</option>
                     :
                     <option value="07:00 PM">07:00 PM</option>
}
{evThuLast > '08:00 PM'?
                     <option value="08:00 PM" disabled>08:00 PM</option>
                     :
                     <option value="08:00 PM">08:00 PM</option>
}
{evThuLast > '09:00 PM'?
                     <option value="09:00 PM" disabled>09:00 PM</option>
                     :
                     <option value="09:00 PM">09:00 PM</option>
}
{evThuLast > '10:00 PM'?
                     <option value="10:00 PM" disabled>10:00 PM</option>
                     :
                     <option value="10:00 PM">10:00 PM</option>
}
                    </select>
              </div>
              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
              <select className="form-control" value={thuEndTimeEv} onChange={handleThuEndTimeEv}>
              <option value="">End</option>
              {evThuLast >= '04:00 PM'?
 <option value="04:00 PM" disabled>04:00 PM</option>   
                     :
                     <option value="04:00 PM">04:00 PM</option>    
}

{evThuLast >= '05:00 PM'?
                     <option value="05:00 PM" disabled>05:00 PM</option>
                     :
                     <option value="05:00 PM">05:00 PM</option>
}
{evThuLast >= '06:00 PM'?
                     <option value="06:00 PM" disabled>06:00 PM</option>
                     :
                     <option value="06:00 PM">06:00 PM</option>
}
{evThuLast >= '07:00 PM'?
                          <option value="07:00 PM" disabled>07:00 PM</option>
                     :
                     <option value="07:00 PM">07:00 PM</option>
}
{evThuLast >= '08:00 PM'?
                     <option value="08:00 PM" disabled>08:00 PM</option>
                     :
                     <option value="08:00 PM">08:00 PM</option>
}
{evThuLast >= '09:00 PM'?
                     <option value="09:00 PM" disabled>09:00 PM</option>
                     :
                     <option value="09:00 PM">09:00 PM</option>
}
{evThuLast >= '10:00 PM'?
                     <option value="10:00 PM" disabled>10:00 PM</option>
                     :
                     <option value="10:00 PM">10:00 PM</option>
}
                    </select>
            </div>
                    </div>
                  </div>

          <div className="slot_friday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Friday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={friStartTimeEv}
                   onChange={(event) => { setFriStartTimeEv( event.target.value ); }}>
                     <option value="">Start</option>
            {evFriLast > '04:00 PM'?
 <option value="04:00 PM" disabled>04:00 PM</option>   
                     :
                     <option value="04:00 PM">04:00 PM</option>    
}

{evFriLast > '05:00 PM'?
                     <option value="05:00 PM" disabled>05:00 PM</option>
                     :
                     <option value="05:00 PM">05:00 PM</option>
}
{evFriLast > '06:00 PM'?
                     <option value="06:00 PM" disabled>06:00 PM</option>
                     :
                     <option value="06:00 PM">06:00 PM</option>
}
{evFriLast > '07:00 PM'?
                          <option value="07:00 PM" disabled>07:00 PM</option>
                     :
                     <option value="07:00 PM">07:00 PM</option>
}
{evFriLast > '08:00 PM'?
                     <option value="08:00 PM" disabled>08:00 PM</option>
                     :
                     <option value="08:00 PM">08:00 PM</option>
}
{evFriLast > '09:00 PM'?
                     <option value="09:00 PM" disabled>09:00 PM</option>
                     :
                     <option value="09:00 PM">09:00 PM</option>
}
{evFriLast > '10:00 PM'?
                     <option value="10:00 PM" disabled>10:00 PM</option>
                     :
                     <option value="10:00 PM">10:00 PM</option>
}
                    </select>
              </div>
              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
              <select className="form-control" value={friEndTimeEv} onChange={handleFriEndTimeEv}>
              <option value="">End</option>
              {evFriLast >= '04:00 PM'?
 <option value="04:00 PM" disabled>04:00 PM</option>   
                     :
                     <option value="04:00 PM">04:00 PM</option>    
}

{evFriLast >= '05:00 PM'?
                     <option value="05:00 PM" disabled>05:00 PM</option>
                     :
                     <option value="05:00 PM">05:00 PM</option>
}
{evFriLast >= '06:00 PM'?
                     <option value="06:00 PM" disabled>06:00 PM</option>
                     :
                     <option value="06:00 PM">06:00 PM</option>
}
{evFriLast >= '07:00 PM'?
                          <option value="07:00 PM" disabled>07:00 PM</option>
                     :
                     <option value="07:00 PM">07:00 PM</option>
}
{evFriLast >= '08:00 PM'?
                     <option value="08:00 PM" disabled>08:00 PM</option>
                     :
                     <option value="08:00 PM">08:00 PM</option>
}
{evFriLast >= '09:00 PM'?
                     <option value="09:00 PM" disabled>09:00 PM</option>
                     :
                     <option value="09:00 PM">09:00 PM</option>
}
{evFriLast >= '10:00 PM'?
                     <option value="10:00 PM" disabled>10:00 PM</option>
                     :
                     <option value="10:00 PM">10:00 PM</option>
}

                    </select>
              </div>
            </div>
            </div>            
</div>


<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 superadmin_doctortimeslots_weekendsslot">
                    <h3 style={{fontWeight:'700', fontSize:'18px', marginTop:'15px', marginBottom:'15px',backgroundColor:'#5CB04D', color: '#fff', padding:'10px',
                  borderRadius:'5px'}}>TIME SLOTS FOR WEEK ENDS</h3>          
                  <div className="slot_Saturday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Saturday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={satStartTimeEv}
                   onChange={(event) => { setSatStartTimeEv( event.target.value ); }}>
                     <option value="">Start</option>
            {evSatLast > '04:00 PM'?
 <option value="04:00 PM" disabled>04:00 PM</option>   
                     :
                     <option value="04:00 PM">04:00 PM</option>    
}

{evSatLast > '05:00 PM'?
                     <option value="05:00 PM" disabled>05:00 PM</option>
                     :
                     <option value="05:00 PM">05:00 PM</option>
}
{evSatLast > '06:00 PM'?
                     <option value="06:00 PM" disabled>06:00 PM</option>
                     :
                     <option value="06:00 PM">06:00 PM</option>
}
{evSatLast > '07:00 PM'?
                          <option value="07:00 PM" disabled>07:00 PM</option>
                     :
                     <option value="07:00 PM">07:00 PM</option>
}
{evSatLast > '08:00 PM'?
                     <option value="08:00 PM" disabled>08:00 PM</option>
                     :
                     <option value="08:00 PM">08:00 PM</option>
}
{evSatLast > '09:00 PM'?
                     <option value="09:00 PM" disabled>09:00 PM</option>
                     :
                     <option value="09:00 PM">09:00 PM</option>
}
{evSatLast > '10:00 PM'?
                     <option value="10:00 PM" disabled>10:00 PM</option>
                     :
                     <option value="10:00 PM">10:00 PM</option>
}
                    </select>
            </div>
            <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
            <select className="form-control" value={satEndTimeEv} onChange={handleSatEndTimeEv}>
            <option value="">End</option>
            {evSatLast >= '04:00 PM'?
 <option value="04:00 PM" disabled>04:00 PM</option>   
                     :
                     <option value="04:00 PM">04:00 PM</option>    
}

{evSatLast >= '05:00 PM'?
                     <option value="05:00 PM" disabled>05:00 PM</option>
                     :
                     <option value="05:00 PM">05:00 PM</option>
}
{evSatLast >= '06:00 PM'?
                     <option value="06:00 PM" disabled>06:00 PM</option>
                     :
                     <option value="06:00 PM">06:00 PM</option>
}
{evSatLast >= '07:00 PM'?
                          <option value="07:00 PM" disabled>07:00 PM</option>
                     :
                     <option value="07:00 PM">07:00 PM</option>
}
{evSatLast >= '08:00 PM'?
                     <option value="08:00 PM" disabled>08:00 PM</option>
                     :
                     <option value="08:00 PM">08:00 PM</option>
}
{evSatLast >= '09:00 PM'?
                     <option value="09:00 PM" disabled>09:00 PM</option>
                     :
                     <option value="09:00 PM">09:00 PM</option>
}
{evSatLast >= '10:00 PM'?
                     <option value="10:00 PM" disabled>10:00 PM</option>
                     :
                     <option value="10:00 PM">10:00 PM</option>
}
                    </select>
            </div>
                    </div>
                  </div> 

                  <div className="slot_sunday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Sunday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={sunStartTimeEv}
                   onChange={(event) => { setSunStartTimeEv( event.target.value ); }}>
                     <option value="">Start</option>
            {evSunLast > '04:00 PM'?
 <option value="04:00 PM" disabled>04:00 PM</option>   
                     :
                     <option value="04:00 PM">04:00 PM</option>    
}

{evSunLast > '05:00 PM'?
                     <option value="05:00 PM" disabled>05:00 PM</option>
                     :
                     <option value="05:00 PM">05:00 PM</option>
}
{evSunLast > '06:00 PM'?
                     <option value="06:00 PM" disabled>06:00 PM</option>
                     :
                     <option value="06:00 PM">06:00 PM</option>
}
{evSunLast > '07:00 PM'?
                          <option value="07:00 PM" disabled>07:00 PM</option>
                     :
                     <option value="07:00 PM">07:00 PM</option>
}
{evSunLast > '08:00 PM'?
                     <option value="08:00 PM" disabled>08:00 PM</option>
                     :
                     <option value="08:00 PM">08:00 PM</option>
}
{evSunLast > '09:00 PM'?
                     <option value="09:00 PM" disabled>09:00 PM</option>
                     :
                     <option value="09:00 PM">09:00 PM</option>
}
{evSunLast > '10:00 PM'?
                     <option value="10:00 PM" disabled>10:00 PM</option>
                     :
                     <option value="10:00 PM">10:00 PM</option>
}
                    </select>
            </div>
            <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
            <select className="form-control" value={sunEndTimeEv} onChange={handleSunEndTimeEv}>
            <option value="">End</option>
            {evSunLast >= '04:00 PM'?
 <option value="04:00 PM" disabled>04:00 PM</option>   
                     :
                     <option value="04:00 PM">04:00 PM</option>    
}

{evSunLast >= '05:00 PM'?
                     <option value="05:00 PM" disabled>05:00 PM</option>
                     :
                     <option value="05:00 PM">05:00 PM</option>
}
{evSunLast >= '06:00 PM'?
                     <option value="06:00 PM" disabled>06:00 PM</option>
                     :
                     <option value="06:00 PM">06:00 PM</option>
}
{evSunLast >= '07:00 PM'?
                          <option value="07:00 PM" disabled>07:00 PM</option>
                     :
                     <option value="07:00 PM">07:00 PM</option>
}
{evSunLast >= '08:00 PM'?
                     <option value="08:00 PM" disabled>08:00 PM</option>
                     :
                     <option value="08:00 PM">08:00 PM</option>
}
{evSunLast >= '09:00 PM'?
                     <option value="09:00 PM" disabled>09:00 PM</option>
                     :
                     <option value="09:00 PM">09:00 PM</option>
}
{evSunLast >= '10:00 PM'?
                     <option value="10:00 PM" disabled>10:00 PM</option>
                     :
                     <option value="10:00 PM">10:00 PM</option>
}
                    </select>
            </div>
          </div>
          </div>
          </div>
          </div>

  <hr />
  <div className="superadmin_doctortimeslots_savebtn" style={{textAlign:'center', marginTop:'10px'}}>

                       <button className="btn" style={{backgroundColor: '#105c25', color: '#fff', fontFamily:'poppins medium'}} onClick={weekdaysAndWeekenddays}>Save</button>&emsp;
                        <button className="btn" style={{backgroundColor: '#3A4652', color: '#fff', fontFamily:'poppins medium'}} onClick={reset}>Cancel</button>
                        </div>
                        </>
                     :
                     null
        }
                   
                   {visitingType == "videoConsultation"?
<>

<center>
<h3 style={{textTransform: 'uppercase'}}>Morning</h3>
</center>

          <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 superadmin_doctortimeslots__weekdaysslot">
                    <h3 style={{fontWeight:'700', fontSize:'18px', marginTop:'15px', marginBottom:'15px',backgroundColor:'#5CB04D', color: '#fff', padding:'10px',
                  borderRadius:'5px'}}>TIME SLOTS FOR WEEK DAYS</h3>

        <div className="slot_monday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Monday</label>

                  <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
<select className="form-control" value={monStartTimeVideo} 
                  onChange={(event) => { setMonStartTimeVideo( event.target.value ); }}>
                    <option value="">Start</option>
  {morMonLast > '06:00 AM'?
                     <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morMonLast > '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morMonLast > '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morMonLast > '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morMonLast > '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morMonLast > '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morMonLast > '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}
                    </select>

      </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                <select className="form-control" value={monEndTimeVideo} onChange={handleMonEndTimeVideo}>
                <option value="">End</option>
                {morMonLast >= '06:00 AM'?
                     <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morMonLast >= '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morMonLast >= '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morMonLast >= '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morMonLast >= '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morMonLast >= '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morMonLast >= '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}

                    </select>
                </div>
                    </div>
                  </div>

                  <div className="slot_tuesday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Tuesday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={tueStartTimeVideo}
                   onChange={(event) => { setTueStartTimeVideo( event.target.value ); }}>
                <option value="">Start</option>
                 {morTueLast > '06:00 AM'?
                     <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morTueLast > '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morTueLast > '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morTueLast > '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morTueLast > '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morTueLast > '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morTueLast > '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}
                    </select>
              </div>
              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
              <select className="form-control" value={tueEndTimeVideo} onChange={handleTueEndTimeVideo}>
              <option value="">End</option>
              {morTueLast >= '06:00 AM'?
                     <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morTueLast >= '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morTueLast >= '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morTueLast >= '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morTueLast >= '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morTueLast >= '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morTueLast >= '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}
                    </select>
              </div>
            </div>
            </div>

            <div className="slot_wednesday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Wednesday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={wedStartTimeVideo} 
                   onChange={(event) => { setWedStartTimeVideo( event.target.value ); }}>
                     <option value="">Start</option>
{morWedLast > '06:00 AM'?
                     <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morWedLast > '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morWedLast > '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morWedLast > '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morWedLast > '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morWedLast > '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morWedLast > '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}
                    </select>
              </div>
              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
              <select className="form-control" value={wedEndTimeVideo} onChange={handleWedEndTimeVideo}>
              <option value="">End</option>
              {morWedLast >= '06:00 AM'?
                     <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morWedLast >= '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morWedLast >= '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morWedLast >= '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morWedLast >= '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morWedLast >= '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morWedLast >= '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}
                    </select>
              </div>
                    </div>
                  </div>

                  <div className="slot_thursday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Thursday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={thuStartTimeVideo}
                   onChange={(event) => { setThuStartTimeVideo( event.target.value ); }}>
                     <option value="">Start</option>
               {morThuLast > '06:00 AM'?
                     <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morThuLast > '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morThuLast > '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morThuLast > '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morThuLast > '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morThuLast > '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morThuLast > '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}
                    </select>
              </div>
              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
              <select className="form-control" value={thuEndTimeVideo} onChange={handleThuEndTimeVideo}>
              <option value="">End</option>
              {morThuLast >= '06:00 AM'?
                     <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morThuLast >= '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morThuLast >= '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morThuLast >= '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morThuLast >= '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morThuLast >= '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morThuLast >= '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}
                    </select>
            </div>
                    </div>
                  </div>

          <div className="slot_friday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Friday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={friStartTimeVideo}
                   onChange={(event) => { setFriStartTimeVideo( event.target.value ); }}>
                 <option value="">Start</option>
                  {morFriLast > '06:00 AM'?
                  <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morFriLast > '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morFriLast > '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morFriLast > '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morFriLast > '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morFriLast > '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morFriLast > '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}
                    </select>
              </div>
              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
              <select className="form-control" value={friEndTimeVideo} onChange={handleFriEndTimeVideo}>
              <option value="">End</option>
              {morFriLast >= '06:00 AM'?
                     <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morFriLast >= '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morFriLast >= '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morFriLast >= '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morFriLast >= '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morFriLast >= '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morFriLast >= '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}
                    </select>
              </div>
            </div>
            </div>            
</div>


<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 superadmin_doctortimeslots_weekendsslot">
                    <h3 style={{fontWeight:'700', fontSize:'18px', marginTop:'15px', marginBottom:'15px',backgroundColor:'#5CB04D', color: '#fff', padding:'10px',
                  borderRadius:'5px'}}>TIME SLOTS FOR WEEK ENDS</h3>          
                  <div className="slot_Saturday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Saturday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={satStartTimeVideo}
                   onChange={(event) => { setSatStartTimeVideo( event.target.value ); }}>
                 <option value="">Start</option>
                   {morSatLast > '06:00 AM'?
                  <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morSatLast > '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morSatLast > '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morSatLast > '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morSatLast > '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morSatLast > '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morSatLast > '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}
                    </select>
            </div>
            <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
            <select className="form-control" value={satEndTimeVideo} onChange={handleSatEndTimeVideo}>
            <option value="">End</option>
            {morSatLast >= '06:00 AM'?
                     <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morSatLast >= '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morSatLast >= '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morSatLast >= '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morSatLast >= '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morSatLast >= '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morSatLast >= '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}
                    </select>
            </div>
                    </div>
                  </div> 

                  <div className="slot_sunday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Sunday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={sunStartTimeVideo} onChange={(event) => { setSunStartTimeVideo( event.target.value ); }}>
                      <option value="">Start</option>
                      {morSunLast > '06:00 AM'?
                  <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morSunLast > '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morSunLast > '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morSunLast > '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morSunLast > '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morSunLast > '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morSunLast > '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}
                    </select>
            </div>
            <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
            <select className="form-control" value={sunEndTimeVideo} onChange={handleSunEndTimeVideo}>
            <option value="">End</option>
            {morSunLast >= '06:00 AM'?
                     <option value="06:00 AM" disabled>06:00 AM</option>
                     :
                     <option value="06:00 AM">06:00 AM</option>
}

{morSunLast >= '07:00 AM'?
                     <option value="07:00 AM" disabled>07:00 AM</option>
                     :
                     <option value="07:00 AM">07:00 AM</option>
}
{morSunLast >= '08:00 AM'?
                     <option value="08:00 AM" disabled>08:00 AM</option>
                     :
                    <option value="08:00 AM">08:00 AM</option>
}
{morSunLast >= '09:00 AM'?
                     <option value="09:00 AM" disabled>09:00 AM</option>
                     :
<option value="09:00 AM">09:00 AM</option>
}
{morSunLast >= '10:00 AM'?
                     <option value="10:00 AM" disabled>10:00 AM</option>
                     :
                  <option value="10:00 AM">10:00 AM</option>
}
{morSunLast >= '11:00 AM'?
                     <option value="11:00 AM" disabled>11:00 AM</option>
                     :
                  <option value="11:00 AM">11:00 AM</option>
}
{morSunLast >= '12:00 PM'?
                     <option value="12:00 PM" disabled>12:00 PM</option>
                     :
                  <option value="12:00 PM">12:00 PM</option>
}
                    </select>
            </div>
          </div>
          </div>
          </div>
          </div>

          <center>
<h3 style={{textTransform: 'uppercase'}}>Afternoon</h3>
</center>

          <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 superadmin_doctortimeslots__weekdaysslot">
                    <h3 style={{fontWeight:'700', fontSize:'18px', marginTop:'15px', marginBottom:'15px',backgroundColor:'#5CB04D', color: '#fff', padding:'10px',
                  borderRadius:'5px'}}>TIME SLOTS FOR WEEK DAYS</h3>
        
        <div className="slot_monday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Monday</label>
          
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={monStartTimeAfVideo} 
                  onChange={(event) => { setMonStartTimeAfVideo( event.target.value ); }}>
                    <option value="">Start</option>
{afMonLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afMonLast > '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afMonLast > '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afMonLast > '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afMonLast > '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                <select className="form-control" value={monEndTimeAfVideo} onChange={handleMonEndTimeAfVideo}>
                <option value="">End</option>
                {afMonLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afMonLast >= '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afMonLast >= '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afMonLast >= '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afMonLast >= '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
                </div>
                    </div>
                  </div>

                  <div className="slot_tuesday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Tuesday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={tueStartTimeAfVideo}
                   onChange={(event) => { setTueStartTimeAfVideo( event.target.value ); }}>
                     <option value="">Start</option>
{afTueLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afTueLast > '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afTueLast > '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afTueLast > '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afTueLast > '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
              </div>
              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
              <select className="form-control" value={tueEndTimeAfVideo} onChange={handleTueEndTimeAfVideo}>
              <option value="">End</option>
              {afTueLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afTueLast >= '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afTueLast >= '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afTueLast >= '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afTueLast >= '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
              </div>
            </div>
            </div>

            <div className="slot_wednesday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Wednesday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={wedStartTimeAfVideo} 
                   onChange={(event) => { setWedStartTimeAfVideo( event.target.value ); }}>
                     <option value="">Start</option>
{afWedLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afWedLast > '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afWedLast > '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afWedLast > '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afWedLast > '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
              </div>
              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
              <select className="form-control" value={wedEndTimeAfVideo} onChange={handleWedEndTimeAfVideo}>
              <option value="">End</option>
              {afWedLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afWedLast >= '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afWedLast >= '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afWedLast >= '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afWedLast >= '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
              </div>
                    </div>
                  </div>

                  <div className="slot_thursday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Thursday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={thuStartTimeAfVideo}
                   onChange={(event) => { setThuStartTimeAfVideo( event.target.value ); }} >
                     <option value="">Start</option>
{afThuLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afThuLast > '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afThuLast > '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afThuLast > '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afThuLast > '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
              </div>
              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
              <select className="form-control" value={thuEndTimeAfVideo} onChange={handleThuEndTimeAfVideo}>
              <option value="">End</option>
              {afThuLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afThuLast >= '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afThuLast >= '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afThuLast >= '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afThuLast >= '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
            </div>
                    </div>
                  </div>

          <div className="slot_friday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Friday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={friStartTimeAfVideo}
                   onChange={(event) => { setFriStartTimeAfVideo( event.target.value ); }}>
                     <option value="">Start</option>
{afFriLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afFriLast > '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afFriLast > '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afFriLast > '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afFriLast > '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
              </div>
              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
              <select className="form-control" value={friEndTimeAfVideo} onChange={handleFriEndTimeAfVideo}>
              <option value="">End</option>
              {afFriLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afFriLast >= '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afFriLast >= '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afFriLast >= '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afFriLast >= '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
              </div>
            </div>
            </div>            
</div>


<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 superadmin_doctortimeslots_weekendsslot">
                    <h3 style={{fontWeight:'700', fontSize:'18px', marginTop:'15px', marginBottom:'15px',backgroundColor:'#5CB04D', color: '#fff', padding:'10px',
                  borderRadius:'5px'}}>TIME SLOTS FOR WEEK ENDS</h3>          
                  <div className="slot_Saturday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Saturday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={satStartTimeAfVideo}
                   onChange={(event) => { setSatStartTimeAfVideo( event.target.value ); }}>
                     <option value="">Start</option>
{afSatLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afSatLast > '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afSatLast > '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afSatLast > '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afSatLast > '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
            </div>
            <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
            <select className="form-control" value={satEndTimeAfVideo} onChange={handleSatEndTimeAfVideo}>
            <option value="">End</option>
            {afSatLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afSatLast >= '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afSatLast >= '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afSatLast >= '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afSatLast >= '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
            </div>
                    </div>
                  </div> 

                  <div className="slot_sunday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Sunday</label>
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={sunStartTimeAfVideo}
                   onChange={(event) => { setSunStartTimeAfVideo( event.target.value ); }}>
                     <option value="">Start</option>
{afSunLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afSunLast > '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afSunLast > '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afSunLast > '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afSunLast > '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
            </div>
            <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
            <select className="form-control" value={sunEndTimeAfVideo} onChange={handleSunEndTimeAfVideo}>
            <option value="">End</option>
            {afSunLast == ''?
 <option value="12:00 PM">12:00 PM</option>
                     
                     :
            <option value="12:00 PM" disabled>12:00 PM</option>        
}

{afSunLast >= '01:00 PM'?
                     <option value="01:00 PM" disabled>01:00 PM</option>
                     :
                     <option value="01:00 PM">01:00 PM</option>
}
{afSunLast >= '02:00 PM'?
                     <option value="02:00 PM" disabled>02:00 PM</option>
                     :
                     <option value="02:00 PM">02:00 PM</option>
}
{afSunLast >= '03:00 PM'?
                          <option value="03:00 PM" disabled>03:00 PM</option>
                     :
                     <option value="03:00 PM">03:00 PM</option>
}
{afSunLast >= '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>
                     :
                     <option value="04:00 PM">04:00 PM</option>
}
                    </select>
            </div>
          </div>
          </div>
          </div>
          </div>


          <center>
<h3 style={{textTransform: 'uppercase'}}>Evening</h3>
</center>

          <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 superadmin_doctortimeslots__weekdaysslot">
                    <h3 style={{fontWeight:'700', fontSize:'18px', marginTop:'15px', marginBottom:'15px',backgroundColor:'#5CB04D', color: '#fff', padding:'10px',
                  borderRadius:'5px'}}>TIME SLOTS FOR WEEK DAYS</h3>
        
        <div className="slot_monday mb-2">
                  <label style={{fontWeight:'600', fontSize:'14px'}}>Monday</label>
         
                   <div className="row">
                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                      <select className="form-control" value={monStartTimeEvVideo} 
                  onChange={(event) => { setMonStartTimeEvVideo( event.target.value ); }}>
                    <option value="">Start</option>
{evMonLast > '04:00 PM'?
 <option value="04:00 PM" disabled>04:00 PM</option>   
                     :
                     <option value="04:00 PM">04:00 PM</option>    
}

{evMonLast > '05:00 PM'?
                     <option value="05:00 PM" disabled>05:00 PM</option>
                     :
                     <option value="05:00 PM">05:00 PM</option>
                    }
                    {evMonLast > '06:00 PM'?
                                         <option value="06:00 PM" disabled>06:00 PM</option>
                                         :
                                         <option value="06:00 PM">06:00 PM</option>
                    }
                    {evMonLast > '07:00 PM'?
                                              <option value="07:00 PM" disabled>07:00 PM</option>
                                         :
                                         <option value="07:00 PM">07:00 PM</option>
                    }
                    {evMonLast > '08:00 PM'?
                                         <option value="08:00 PM" disabled>08:00 PM</option>
                                         :
                                         <option value="08:00 PM">08:00 PM</option>
                    }
                    {evMonLast > '09:00 PM'?
                                         <option value="09:00 PM" disabled>09:00 PM</option>
                                         :
                                         <option value="09:00 PM">09:00 PM</option>
                    }
                    {evMonLast > '10:00 PM'?
                                         <option value="10:00 PM" disabled>10:00 PM</option>
                                         :
                                         <option value="10:00 PM">10:00 PM</option>
                    }
                                        </select>
                                    </div>
                                    <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                    <select className="form-control" value={monEndTimeEvVideo} onChange={handleMonEndTimeEvVideo}>
                                    <option value="">End</option>
                                    {evMonLast >= '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>   
                                         :
                                         <option value="04:00 PM">04:00 PM</option>    
                    }
                    
                    {evMonLast >= '05:00 PM'?
                                         <option value="05:00 PM" disabled>05:00 PM</option>
                                         :
                                         <option value="05:00 PM">05:00 PM</option>
                    }
                    {evMonLast >= '06:00 PM'?
                                         <option value="06:00 PM" disabled>06:00 PM</option>
                                         :
                                         <option value="06:00 PM">06:00 PM</option>
                    }
                    {evMonLast >= '07:00 PM'?
                                              <option value="07:00 PM" disabled>07:00 PM</option>
                                         :
                                         <option value="07:00 PM">07:00 PM</option>
                    }
                    {evMonLast > '08:00 PM'?
                                         <option value="08:00 PM" disabled>08:00 PM</option>
                                         :
                                         <option value="08:00 PM">08:00 PM</option>
                    }
                    {evMonLast >= '09:00 PM'?
                                         <option value="09:00 PM" disabled>09:00 PM</option>
                                         :
                                         <option value="09:00 PM">09:00 PM</option>
                    }
                    {evMonLast >= '10:00 PM'?
                                         <option value="10:00 PM" disabled>10:00 PM</option>
                                         :
                                         <option value="10:00 PM">10:00 PM</option>
                    }
                                        </select>
                                    </div>
                                        </div>
                                      </div>
                    
                                      <div className="slot_tuesday mb-2">
                                      <label style={{fontWeight:'600', fontSize:'14px'}}>Tuesday</label>
                                       <div className="row">
                                          <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                          <select className="form-control" value={tueStartTimeEvVideo}
                                       onChange={(event) => { setTueStartTimeEvVideo( event.target.value ); }}>
                  <option value="">Start</option>
                    {evTueLast > '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>   
                                         :
                                         <option value="04:00 PM">04:00 PM</option>    
                    }
                    
                    {evTueLast > '05:00 PM'?
                                         <option value="05:00 PM" disabled>05:00 PM</option>
                                         :
                                         <option value="05:00 PM">05:00 PM</option>
                    }
                    {evTueLast > '06:00 PM'?
                                         <option value="06:00 PM" disabled>06:00 PM</option>
                                         :
                                         <option value="06:00 PM">06:00 PM</option>
                    }
                    {evTueLast > '07:00 PM'?
                                              <option value="07:00 PM" disabled>07:00 PM</option>
                                         :
                                         <option value="07:00 PM">07:00 PM</option>
                    }
                    {evTueLast > '08:00 PM'?
                                         <option value="08:00 PM" disabled>08:00 PM</option>
                                         :
                                         <option value="08:00 PM">08:00 PM</option>
                    }
                    {evTueLast > '09:00 PM'?
                                         <option value="09:00 PM" disabled>09:00 PM</option>
                                         :
                                         <option value="09:00 PM">09:00 PM</option>
                    }
                    {evTueLast > '10:00 PM'?
                                         <option value="10:00 PM" disabled>10:00 PM</option>
                                         :
                                         <option value="10:00 PM">10:00 PM</option>
                    }
                                        </select>
                                  </div>
                                  <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                  <select className="form-control" value={tueEndTimeEvVideo} onChange={handleTueEndTimeEvVideo}>
                                  <option value="">End</option>
                                  {evTueLast >= '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>   
                                         :
                                         <option value="04:00 PM">04:00 PM</option>    
                    }
                    
                    {evTueLast >= '05:00 PM'?
                                         <option value="05:00 PM" disabled>05:00 PM</option>
                                         :
                                         <option value="05:00 PM">05:00 PM</option>
                    }
                    {evTueLast >= '06:00 PM'?
                                         <option value="06:00 PM" disabled>06:00 PM</option>
                                         :
                                         <option value="06:00 PM">06:00 PM</option>
                    }
                    {evTueLast >= '07:00 PM'?
                                              <option value="07:00 PM" disabled>07:00 PM</option>
                                         :
                                         <option value="07:00 PM">07:00 PM</option>
                    }
                    {evTueLast >= '08:00 PM'?
                                         <option value="08:00 PM" disabled>08:00 PM</option>
                                         :
                                         <option value="08:00 PM">08:00 PM</option>
                    }
                    {evTueLast >= '09:00 PM'?
                                         <option value="09:00 PM" disabled>09:00 PM</option>
                                         :
                                         <option value="09:00 PM">09:00 PM</option>
                    }
                    {evTueLast >= '10:00 PM'?
                                         <option value="10:00 PM" disabled>10:00 PM</option>
                                         :
                                         <option value="10:00 PM">10:00 PM</option>
                    }
                                        </select>
                                  </div>
                                </div>
                                </div>
                    
                                <div className="slot_wednesday mb-2">
                                      <label style={{fontWeight:'600', fontSize:'14px'}}>Wednesday</label>
                                       <div className="row">
                                          <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                          <select className="form-control" value={wedStartTimeEvVideo} 
                                       onChange={(event) => { setWedStartTimeEvVideo( event.target.value ); }}>
                   <option value="">Start</option>
                      {evWedLast > '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>   
                                         :
                                         <option value="04:00 PM">04:00 PM</option>    
                    }
                    
                    {evWedLast > '05:00 PM'?
                                         <option value="05:00 PM" disabled>05:00 PM</option>
                                         :
                                         <option value="05:00 PM">05:00 PM</option>
                    }
                    {evWedLast > '06:00 PM'?
                                         <option value="06:00 PM" disabled>06:00 PM</option>
                                         :
                                         <option value="06:00 PM">06:00 PM</option>
                    }
                    {evWedLast > '07:00 PM'?
                                              <option value="07:00 PM" disabled>07:00 PM</option>
                                         :
                                         <option value="07:00 PM">07:00 PM</option>
                    }
                    {evWedLast > '08:00 PM'?
                                         <option value="08:00 PM" disabled>08:00 PM</option>
                                         :
                                         <option value="08:00 PM">08:00 PM</option>
                    }
                    {evWedLast > '09:00 PM'?
                                         <option value="09:00 PM" disabled>09:00 PM</option>
                                         :
                                         <option value="09:00 PM">09:00 PM</option>
                    }
                    {evWedLast > '10:00 PM'?
                                         <option value="10:00 PM" disabled>10:00 PM</option>
                                         :
                                         <option value="10:00 PM">10:00 PM</option>
                    }
                                        </select>
                                  </div>
                                  <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                  <select className="form-control" value={wedEndTimeEvVideo} onChange={handleWedEndTimeEvVideo}>
                                  <option value="">End</option>
                                  {evWedLast >= '04:00 PM'?
                     <option value="04:00 PM" disabled>04:00 PM</option>   
                                         :
                                         <option value="04:00 PM">04:00 PM</option>    
                    }
                    
                    {evWedLast >= '05:00 PM'?
                                         <option value="05:00 PM" disabled>05:00 PM</option>
                                         :
                                         <option value="05:00 PM">05:00 PM</option>
                    }
                    {evWedLast >= '06:00 PM'?
                                         <option value="06:00 PM" disabled>06:00 PM</option>
                                         :
                                         <option value="06:00 PM">06:00 PM</option>
                    }
                    {evWedLast >= '07:00 PM'?
                          <option value="07:00 PM" disabled>07:00 PM</option>
                          :
                          <option value="07:00 PM">07:00 PM</option>
     }
     {evWedLast >= '08:00 PM'?
                          <option value="08:00 PM" disabled>08:00 PM</option>
                          :
                          <option value="08:00 PM">08:00 PM</option>
     }
     {evWedLast >= '09:00 PM'?
                          <option value="09:00 PM" disabled>09:00 PM</option>
                          :
                          <option value="09:00 PM">09:00 PM</option>
     }
     {evWedLast >= '10:00 PM'?
                          <option value="10:00 PM" disabled>10:00 PM</option>
                          :
                          <option value="10:00 PM">10:00 PM</option>
     }
                         </select>
                   </div>
                         </div>
                       </div>
     
                       <div className="slot_thursday mb-2">
                       <label style={{fontWeight:'600', fontSize:'14px'}}>Thursday</label>
                        <div className="row">
                           <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                           <select className="form-control" value={thuStartTimeEvVideo}
                        onChange={(event) => { setThuStartTimeEvVideo( event.target.value ); }}>
                          <option value="">Start</option>
                      {evThuLast > '04:00 PM'?
      <option value="04:00 PM" disabled>04:00 PM</option>   
                          :
                          <option value="04:00 PM">04:00 PM</option>    
     }
     
     {evThuLast > '05:00 PM'?
                          <option value="05:00 PM" disabled>05:00 PM</option>
                          :
                          <option value="05:00 PM">05:00 PM</option>
     }
     {evThuLast > '06:00 PM'?
                          <option value="06:00 PM" disabled>06:00 PM</option>
                          :
                          <option value="06:00 PM">06:00 PM</option>
     }
     {evThuLast > '07:00 PM'?
                               <option value="07:00 PM" disabled>07:00 PM</option>
                          :
                          <option value="07:00 PM">07:00 PM</option>
     }
     {evThuLast > '08:00 PM'?
                          <option value="08:00 PM" disabled>08:00 PM</option>
                          :
                          <option value="08:00 PM">08:00 PM</option>
     }
     {evThuLast > '09:00 PM'?
                          <option value="09:00 PM" disabled>09:00 PM</option>
                          :
                          <option value="09:00 PM">09:00 PM</option>
     }
     {evThuLast > '10:00 PM'?
                          <option value="10:00 PM" disabled>10:00 PM</option>
                          :
                          <option value="10:00 PM">10:00 PM</option>
     }
                         </select>
                   </div>
                   <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                   <select className="form-control" value={thuEndTimeEvVideo} onChange={handleThuEndTimeEvVideo}>
                   <option value="">End</option>
                   {evThuLast >= '04:00 PM'?
      <option value="04:00 PM" disabled>04:00 PM</option>   
                          :
                          <option value="04:00 PM">04:00 PM</option>    
     }
     
     {evThuLast >= '05:00 PM'?
                          <option value="05:00 PM" disabled>05:00 PM</option>
                          :
                          <option value="05:00 PM">05:00 PM</option>
     }
     {evThuLast >= '06:00 PM'?
                          <option value="06:00 PM" disabled>06:00 PM</option>
                          :
                          <option value="06:00 PM">06:00 PM</option>
     }
     {evThuLast >= '07:00 PM'?
                               <option value="07:00 PM" disabled>07:00 PM</option>
                          :
                          <option value="07:00 PM">07:00 PM</option>
     }
     {evThuLast >= '08:00 PM'?
                          <option value="08:00 PM" disabled>08:00 PM</option>
                          :
                          <option value="08:00 PM">08:00 PM</option>
     }
     {evThuLast >= '09:00 PM'?
                          <option value="09:00 PM" disabled>09:00 PM</option>
                          :
                          <option value="09:00 PM">09:00 PM</option>
     }
     {evThuLast >= '10:00 PM'?
                          <option value="10:00 PM" disabled>10:00 PM</option>
                          :
                          <option value="10:00 PM">10:00 PM</option>
     }
                         </select>
                 </div>
                         </div>
                       </div>
     
               <div className="slot_friday mb-2">
                       <label style={{fontWeight:'600', fontSize:'14px'}}>Friday</label>
                        <div className="row">
                           <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                           <select className="form-control" value={friStartTimeEvVideo}
                        onChange={(event) => { setFriStartTimeEvVideo( event.target.value ); }}>
                          <option value="">Start</option>
                     {evFriLast > '04:00 PM'?
      <option value="04:00 PM" disabled>04:00 PM</option>   
                          :
                          <option value="04:00 PM">04:00 PM</option>    
     }
     
     {evFriLast > '05:00 PM'?
                          <option value="05:00 PM" disabled>05:00 PM</option>
                          :
                          <option value="05:00 PM">05:00 PM</option>
     }
     {evFriLast > '06:00 PM'?
                          <option value="06:00 PM" disabled>06:00 PM</option>
                          :
                          <option value="06:00 PM">06:00 PM</option>
     }
     {evFriLast > '07:00 PM'?
                               <option value="07:00 PM" disabled>07:00 PM</option>
                          :
                          <option value="07:00 PM">07:00 PM</option>
     }
     {evFriLast > '08:00 PM'?
                          <option value="08:00 PM" disabled>08:00 PM</option>
                          :
                          <option value="08:00 PM">08:00 PM</option>
     }
     {evFriLast > '09:00 PM'?
                          <option value="09:00 PM" disabled>09:00 PM</option>
                          :
                          <option value="09:00 PM">09:00 PM</option>
     }
     {evFriLast > '10:00 PM'?
                          <option value="10:00 PM" disabled>10:00 PM</option>
                          :
                          <option value="10:00 PM">10:00 PM</option>
     }
                         </select>
                   </div>
                   <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                   <select className="form-control" value={friEndTimeEvVideo} onChange={handleFriEndTimeEvVideo}>
                   <option value="">End</option>
                   {evFriLast >= '04:00 PM'?
      <option value="04:00 PM" disabled>04:00 PM</option>   
                          :
                          <option value="04:00 PM">04:00 PM</option>    
     }
     
     {evFriLast >= '05:00 PM'?
                          <option value="05:00 PM" disabled>05:00 PM</option>
                          :
                          <option value="05:00 PM">05:00 PM</option>
     }
     {evFriLast >= '06:00 PM'?
                          <option value="06:00 PM" disabled>06:00 PM</option>
                          :
                          <option value="06:00 PM">06:00 PM</option>
     }
     {evFriLast >= '07:00 PM'?
                               <option value="07:00 PM" disabled>07:00 PM</option>
                          :
                          <option value="07:00 PM">07:00 PM</option>
     }
     {evFriLast >= '08:00 PM'?
                          <option value="08:00 PM" disabled>08:00 PM</option>
                          :
                          <option value="08:00 PM">08:00 PM</option>
     }
     {evFriLast >= '09:00 PM'?
                          <option value="09:00 PM" disabled>09:00 PM</option>
                          :
                          <option value="09:00 PM">09:00 PM</option>
     }
     {evFriLast >= '10:00 PM'?
                          <option value="10:00 PM" disabled>10:00 PM</option>
                          :
                          <option value="10:00 PM">10:00 PM</option>
     }
     
                         </select>
                   </div>
                 </div>
                 </div>            
     </div>
     
     
     <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 superadmin_doctortimeslots_weekendsslot">
                         <h3 style={{fontWeight:'700', fontSize:'18px', marginTop:'15px', marginBottom:'15px',backgroundColor:'#5CB04D', color: '#fff', padding:'10px',
                       borderRadius:'5px'}}>TIME SLOTS FOR WEEK ENDS</h3>          
                       <div className="slot_Saturday mb-2">
                       <label style={{fontWeight:'600', fontSize:'14px'}}>Saturday</label>
                        <div className="row">
                           <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                           <select className="form-control" value={satStartTimeEvVideo}
                        onChange={(event) => { setSatStartTimeEvVideo( event.target.value ); }}>
   <option value="">Start</option>
     {evSatLast > '04:00 PM'?
      <option value="04:00 PM" disabled>04:00 PM</option>   
                          :
                          <option value="04:00 PM">04:00 PM</option>    
     }
     
     {evSatLast > '05:00 PM'?
                          <option value="05:00 PM" disabled>05:00 PM</option>
                          :
                          <option value="05:00 PM">05:00 PM</option>
     }
     {evSatLast > '06:00 PM'?
                          <option value="06:00 PM" disabled>06:00 PM</option>
                          :
                          <option value="06:00 PM">06:00 PM</option>
     }
     {evSatLast > '07:00 PM'?
                               <option value="07:00 PM" disabled>07:00 PM</option>
                          :
                          <option value="07:00 PM">07:00 PM</option>
     }
     {evSatLast > '08:00 PM'?
                          <option value="08:00 PM" disabled>08:00 PM</option>
                          :
                          <option value="08:00 PM">08:00 PM</option>
     }
     {evSatLast > '09:00 PM'?
                          <option value="09:00 PM" disabled>09:00 PM</option>
                          :
                          <option value="09:00 PM">09:00 PM</option>
     }
     {evSatLast > '10:00 PM'?
                          <option value="10:00 PM" disabled>10:00 PM</option>
                          :
                          <option value="10:00 PM">10:00 PM</option>
     }
                         </select>
                 </div>
                 <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                 <select className="form-control" value={satEndTimeEvVideo} onChange={handleSatEndTimeEvVideo}>
                 <option value="">End</option>
                 {evSatLast >= '04:00 PM'?
      <option value="04:00 PM" disabled>04:00 PM</option>   
                          :
                          <option value="04:00 PM">04:00 PM</option>    
     }
     
     {evSatLast >= '05:00 PM'?
                          <option value="05:00 PM" disabled>05:00 PM</option>
                          :
                          <option value="05:00 PM">05:00 PM</option>
     }
     {evSatLast >= '06:00 PM'?
                          <option value="06:00 PM" disabled>06:00 PM</option>
                          :
                          <option value="06:00 PM">06:00 PM</option>
     }
     {evSatLast >= '07:00 PM'?
                               <option value="07:00 PM" disabled>07:00 PM</option>
                          :
                          <option value="07:00 PM">07:00 PM</option>
     }
     {evSatLast >= '08:00 PM'?
                          <option value="08:00 PM" disabled>08:00 PM</option>
                          :
                          <option value="08:00 PM">08:00 PM</option>
     }
     {evSatLast >= '09:00 PM'?
                          <option value="09:00 PM" disabled>09:00 PM</option>
                          :
                          <option value="09:00 PM">09:00 PM</option>
     }
     {evSatLast >= '10:00 PM'?
                          <option value="10:00 PM" disabled>10:00 PM</option>
                          :
                          <option value="10:00 PM">10:00 PM</option>
     }
                         </select>
                 </div>
                         </div>
                       </div> 
     
                       <div className="slot_sunday mb-2">
                       <label style={{fontWeight:'600', fontSize:'14px'}}>Sunday</label>
                        <div className="row">
                           <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                           <select className="form-control" value={sunStartTimeEvVideo}
                        onChange={(event) => { setSunStartTimeEvVideo( event.target.value ); }}>
     <option value="">Start</option>
      {evSunLast > '04:00 PM'?
      <option value="04:00 PM" disabled>04:00 PM</option>   
                          :
                          <option value="04:00 PM">04:00 PM</option>    
     }
     
     {evSunLast > '05:00 PM'?
                          <option value="05:00 PM" disabled>05:00 PM</option>
                          :
                          <option value="05:00 PM">05:00 PM</option>
     }
     {evSunLast > '06:00 PM'?
                          <option value="06:00 PM" disabled>06:00 PM</option>
                          :
                          <option value="06:00 PM">06:00 PM</option>
     }
     {evSunLast > '07:00 PM'?
                               <option value="07:00 PM" disabled>07:00 PM</option>
                          :
                          <option value="07:00 PM">07:00 PM</option>
     }
     {evSunLast > '08:00 PM'?
                          <option value="08:00 PM" disabled>08:00 PM</option>
                          :
                          <option value="08:00 PM">08:00 PM</option>
     }
     {evSunLast > '09:00 PM'?
                          <option value="09:00 PM" disabled>09:00 PM</option>
                          :
                          <option value="09:00 PM">09:00 PM</option>
     }
     {evSunLast > '10:00 PM'?
                          <option value="10:00 PM" disabled>10:00 PM</option>
                          :
                          <option value="10:00 PM">10:00 PM</option>
     }
                         </select>
                 </div>
                 <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                 <select className="form-control" value={sunEndTimeEvVideo} onChange={handleSunEndTimeEvVideo}>
                 <option value="">End</option>
                 {evSunLast >= '04:00 PM'?
      <option value="04:00 PM" disabled>04:00 PM</option>   
                          :
                          <option value="04:00 PM">04:00 PM</option>    
     }
     
     {evSunLast >= '05:00 PM'?
                          <option value="05:00 PM" disabled>05:00 PM</option>
                          :
                          <option value="05:00 PM">05:00 PM</option>
     }
     {evSunLast >= '06:00 PM'?
                          <option value="06:00 PM" disabled>06:00 PM</option>
                          :
                          <option value="06:00 PM">06:00 PM</option>
     }
     {evSunLast >= '07:00 PM'?
                               <option value="07:00 PM" disabled>07:00 PM</option>
                          :
                          <option value="07:00 PM">07:00 PM</option>
     }
     {evSunLast >= '08:00 PM'?
                          <option value="08:00 PM" disabled>08:00 PM</option>
                          :
                          <option value="08:00 PM">08:00 PM</option>
     }
     {evSunLast >= '09:00 PM'?
                          <option value="09:00 PM" disabled>09:00 PM</option>
                          :
                          <option value="09:00 PM">09:00 PM</option>
     }
     {evSunLast >= '10:00 PM'?
                          <option value="10:00 PM" disabled>10:00 PM</option>
                          :
                          <option value="10:00 PM">10:00 PM</option>
     }
                         </select>
                 </div>
               </div>
               </div>
               </div>
               </div>
     
       <hr />
       <div className="superadmin_doctortimeslots_savebtn" style={{textAlign:'center', marginTop:'10px'}}>
     
                            <button className="btn" style={{backgroundColor: '#105c25', color: '#fff', fontFamily:'poppins medium'}} onClick={weekdaysAndWeekenddaysVideo}>Save</button>&emsp;
                             <button className="btn" style={{backgroundColor: '#3A4652', color: '#fff', fontFamily:'poppins medium'}} onClick={reset}>Cancel</button>
                             </div>
                             </>
                          :
                          null
             } 
     
             {visitingType == "liveChatConsultation"?
     <>
     
     <center>
     <h3 style={{textTransform: 'uppercase'}}>Morning</h3>
     </center>
     
               <div className="row">
                       <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 superadmin_doctortimeslots__weekdaysslot">
                         <h3 style={{fontWeight:'700', fontSize:'18px', marginTop:'15px', marginBottom:'15px',backgroundColor:'#5CB04D', color: '#fff', padding:'10px',
                       borderRadius:'5px'}}>TIME SLOTS FOR WEEK DAYS</h3>
             
             <div className="slot_monday mb-2">
                       <label style={{fontWeight:'600', fontSize:'14px'}}>Monday</label>
                     
                        <div className="row">
                           <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                           <select className="form-control" value={monStartTimeChat} 
                       onChange={(event) => { setMonStartTimeChat( event.target.value ); }}>
                         <option value="">Start</option>
     {morMonLast > '06:00 AM'?
                          <option value="06:00 AM" disabled>06:00 AM</option>
                          :
                          <option value="06:00 AM">06:00 AM</option>
     }
     
     {morMonLast > '07:00 AM'?
                          <option value="07:00 AM" disabled>07:00 AM</option>
                          :
                          <option value="07:00 AM">07:00 AM</option>
     }
     {morMonLast > '08:00 AM'?
                          <option value="08:00 AM" disabled>08:00 AM</option>
                          :
                         <option value="08:00 AM">08:00 AM</option>
     }
     {morMonLast > '09:00 AM'?
                          <option value="09:00 AM" disabled>09:00 AM</option>
                          :
     <option value="09:00 AM">09:00 AM</option>
     }
     {morMonLast > '10:00 AM'?
                          <option value="10:00 AM" disabled>10:00 AM</option>
                          :
                       <option value="10:00 AM">10:00 AM</option>
     }
     {morMonLast > '11:00 AM'?
                          <option value="11:00 AM" disabled>11:00 AM</option>
                          :
                       <option value="11:00 AM">11:00 AM</option>
     }
     {morMonLast > '12:00 PM'?
                          <option value="12:00 PM" disabled>12:00 PM</option>
                          :
                       <option value="12:00 PM">12:00 PM</option>
     }
                         </select>
                     </div>
                     <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                     <select className="form-control" value={monEndTimeChat} onChange={handleMonEndTimeChat}>
                     <option value="">End</option>
                     {morMonLast >= '06:00 AM'?
                          <option value="06:00 AM" disabled>06:00 AM</option>
                          :
                          <option value="06:00 AM">06:00 AM</option>
     }
     
     {morMonLast >= '07:00 AM'?
                          <option value="07:00 AM" disabled>07:00 AM</option>
                          :
                          <option value="07:00 AM">07:00 AM</option>
     }
     {morMonLast >= '08:00 AM'?
                          <option value="08:00 AM" disabled>08:00 AM</option>
                          :
                         <option value="08:00 AM">08:00 AM</option>
     }
     {morMonLast >= '09:00 AM'?
                          <option value="09:00 AM" disabled>09:00 AM</option>
                          :
     <option value="09:00 AM">09:00 AM</option>
     }
     {morMonLast >= '10:00 AM'?
                          <option value="10:00 AM" disabled>10:00 AM</option>
                          :
                       <option value="10:00 AM">10:00 AM</option>
     }
     {morMonLast >= '11:00 AM'?
                          <option value="11:00 AM" disabled>11:00 AM</option>
                          :
                       <option value="11:00 AM">11:00 AM</option>
     }
     {morMonLast >= '12:00 PM'?
                          <option value="12:00 PM" disabled>12:00 PM</option>
                          :
                       <option value="12:00 PM">12:00 PM</option>
     }
                         </select>
                     </div>
                         </div>
                       </div>
     
                       <div className="slot_tuesday mb-2">
                       <label style={{fontWeight:'600', fontSize:'14px'}}>Tuesday</label>
                        <div className="row">
                           <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                           <select className="form-control" value={tueStartTimeChat}
                        onChange={(event) => { setTueStartTimeChat( event.target.value ); }}>
                        <option value="">Start</option>   
       {morTueLast > '06:00 AM'?
                          <option value="06:00 AM" disabled>06:00 AM</option>
                          :
                          <option value="06:00 AM">06:00 AM</option>
     }
     
     {morTueLast > '07:00 AM'?
                          <option value="07:00 AM" disabled>07:00 AM</option>
                          :
                          <option value="07:00 AM">07:00 AM</option>
     }
     {morTueLast > '08:00 AM'?
                          <option value="08:00 AM" disabled>08:00 AM</option>
                          :
                         <option value="08:00 AM">08:00 AM</option>
     }
     {morTueLast > '09:00 AM'?
                          <option value="09:00 AM" disabled>09:00 AM</option>
                          :
     <option value="09:00 AM">09:00 AM</option>
     }
     {morTueLast > '10:00 AM'?
                          <option value="10:00 AM" disabled>10:00 AM</option>
                          :
                       <option value="10:00 AM">10:00 AM</option>
     }
     {morTueLast > '11:00 AM'?
                          <option value="11:00 AM" disabled>11:00 AM</option>
                          :
                       <option value="11:00 AM">11:00 AM</option>
     }
     {morTueLast > '12:00 PM'?
                          <option value="12:00 PM" disabled>12:00 PM</option>
                          :
                       <option value="12:00 PM">12:00 PM</option>
     }
                         </select>
                   </div>
                   <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                   <select className="form-control" value={tueEndTimeChat} onChange={handleTueEndTimeChat}>
                   <option value="">End</option>
                   {morTueLast >= '06:00 AM'?
                          <option value="06:00 AM" disabled>06:00 AM</option>
                          :
                          <option value="06:00 AM">06:00 AM</option>
     }
     
     {morTueLast >= '07:00 AM'?
                          <option value="07:00 AM" disabled>07:00 AM</option>
                          :
                          <option value="07:00 AM">07:00 AM</option>
     }
     {morTueLast >= '08:00 AM'?
                          <option value="08:00 AM" disabled>08:00 AM</option>
                          :
                         <option value="08:00 AM">08:00 AM</option>
     }
     {morTueLast >= '09:00 AM'?
                          <option value="09:00 AM" disabled>09:00 AM</option>
                          :
     <option value="09:00 AM">09:00 AM</option>
     }
     {morTueLast >= '10:00 AM'?
                          <option value="10:00 AM" disabled>10:00 AM</option>
                          :
                       <option value="10:00 AM">10:00 AM</option>
     }
     {morTueLast >= '11:00 AM'?
                          <option value="11:00 AM" disabled>11:00 AM</option>
                          :
                       <option value="11:00 AM">11:00 AM</option>
     }
     {morTueLast >= '12:00 PM'?
                          <option value="12:00 PM" disabled>12:00 PM</option>
                          :
                       <option value="12:00 PM">12:00 PM</option>
     }
                         </select>
                   </div>
                 </div>
                 </div>
     
                 <div className="slot_wednesday mb-2">
                       <label style={{fontWeight:'600', fontSize:'14px'}}>Wednesday</label>
                        <div className="row">
                           <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                           <select className="form-control" value={wedStartTimeChat} 
                        onChange={(event) => { setWedStartTimeChat( event.target.value ); }}>
                           <option value="">Start</option>
     {morWedLast > '06:00 AM'?
                          <option value="06:00 AM" disabled>06:00 AM</option>
                          :
                          <option value="06:00 AM">06:00 AM</option>
     }
     
     {morWedLast > '07:00 AM'?
                          <option value="07:00 AM" disabled>07:00 AM</option>
                          :
                          <option value="07:00 AM">07:00 AM</option>
     }
     {morWedLast > '08:00 AM'?
                          <option value="08:00 AM" disabled>08:00 AM</option>
                          :
                         <option value="08:00 AM">08:00 AM</option>
     }
     {morWedLast > '09:00 AM'?
                          <option value="09:00 AM" disabled>09:00 AM</option>
                          :
     <option value="09:00 AM">09:00 AM</option>
     }
     {morWedLast > '10:00 AM'?
                          <option value="10:00 AM" disabled>10:00 AM</option>
                          :
                       <option value="10:00 AM">10:00 AM</option>
     }
     {morWedLast > '11:00 AM'?
                          <option value="11:00 AM" disabled>11:00 AM</option>
                          :
                       <option value="11:00 AM">11:00 AM</option>
     }
     {morWedLast > '12:00 PM'?
                          <option value="12:00 PM" disabled>12:00 PM</option>
                          :
                       <option value="12:00 PM">12:00 PM</option>
     }
                         </select>
                   </div>
                   <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                   <select className="form-control" value={wedEndTimeChat} onChange={handleWedEndTimeChat}>
                   <option value="">End</option>
                   {morWedLast >= '06:00 AM'?
                          <option value="06:00 AM" disabled>06:00 AM</option>
                          :
                          <option value="06:00 AM">06:00 AM</option>
     }
     
     {morWedLast >= '07:00 AM'?
                          <option value="07:00 AM" disabled>07:00 AM</option>
                          :
                          <option value="07:00 AM">07:00 AM</option>
     }
     {morWedLast >= '08:00 AM'?
                          <option value="08:00 AM" disabled>08:00 AM</option>
                          :
                         <option value="08:00 AM">08:00 AM</option>
     }
     {morWedLast >= '09:00 AM'?
                          <option value="09:00 AM" disabled>09:00 AM</option>
                          :
     <option value="09:00 AM">09:00 AM</option>
     }
     {morWedLast >= '10:00 AM'?
                          <option value="10:00 AM" disabled>10:00 AM</option>
                          :
                       <option value="10:00 AM">10:00 AM</option>
     }
     {morWedLast >= '11:00 AM'?
                          <option value="11:00 AM" disabled>11:00 AM</option>
                          :
                       <option value="11:00 AM">11:00 AM</option>
     }
     {morWedLast >= '12:00 PM'?
                          <option value="12:00 PM" disabled>12:00 PM</option>
                          :
                       <option value="12:00 PM">12:00 PM</option>
     }
                         </select>
                   </div>
                         </div>
                       </div>
     
                       <div className="slot_thursday mb-2">
                       <label style={{fontWeight:'600', fontSize:'14px'}}>Thursday</label>
                        <div className="row">
                           <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                           <select className="form-control" value={thuStartTimeChat}
                        onChange={(event) => { setThuStartTimeChat( event.target.value ); }}>
                        <option value="">Start</option>
                        {morThuLast > '06:00 AM'?
                          <option value="06:00 AM" disabled>06:00 AM</option>
                          :
                          <option value="06:00 AM">06:00 AM</option>
     }
     
     {morThuLast > '07:00 AM'?
                          <option value="07:00 AM" disabled>07:00 AM</option>
                          :
                          <option value="07:00 AM">07:00 AM</option>
     }
     {morThuLast > '08:00 AM'?
                          <option value="08:00 AM" disabled>08:00 AM</option>
                          :
                         <option value="08:00 AM">08:00 AM</option>
     }
     {morThuLast > '09:00 AM'?
                          <option value="09:00 AM" disabled>09:00 AM</option>
                          :
     <option value="09:00 AM">09:00 AM</option>
     }
     {morThuLast > '10:00 AM'?
                          <option value="10:00 AM" disabled>10:00 AM</option>
                          :
                       <option value="10:00 AM">10:00 AM</option>
     }
     {morThuLast > '11:00 AM'?
                          <option value="11:00 AM" disabled>11:00 AM</option>
                          :
                       <option value="11:00 AM">11:00 AM</option>
     }
     {morThuLast > '12:00 PM'?
                          <option value="12:00 PM" disabled>12:00 PM</option>
                          :
                       <option value="12:00 PM">12:00 PM</option>
     }
                         </select>
                   </div>
                   <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                   <select className="form-control" value={thuEndTimeChat} onChange={handleThuEndTimeChat}>
                   <option value="">End</option>
                   {morThuLast >= '06:00 AM'?
                          <option value="06:00 AM" disabled>06:00 AM</option>
                          :
                          <option value="06:00 AM">06:00 AM</option>
     }
     
     {morThuLast >= '07:00 AM'?
                          <option value="07:00 AM" disabled>07:00 AM</option>
                          :
                          <option value="07:00 AM">07:00 AM</option>
     }
     {morThuLast >= '08:00 AM'?
                          <option value="08:00 AM" disabled>08:00 AM</option>
                          :
                         <option value="08:00 AM">08:00 AM</option>
     }
     {morThuLast >= '09:00 AM'?
                          <option value="09:00 AM" disabled>09:00 AM</option>
                          :
     <option value="09:00 AM">09:00 AM</option>
     }
     {morThuLast >= '10:00 AM'?
                          <option value="10:00 AM" disabled>10:00 AM</option>
                          :
                       <option value="10:00 AM">10:00 AM</option>
     }
     {morThuLast >= '11:00 AM'?
                          <option value="11:00 AM" disabled>11:00 AM</option>
                          :
                       <option value="11:00 AM">11:00 AM</option>
     }
     {morThuLast >= '12:00 PM'?
                          <option value="12:00 PM" disabled>12:00 PM</option>
                          :
                       <option value="12:00 PM">12:00 PM</option>
     }
                         </select>
                 </div>
                         </div>
                       </div>
     
               <div className="slot_friday mb-2">
                       <label style={{fontWeight:'600', fontSize:'14px'}}>Friday</label>
                        <div className="row">
                           <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                           <select className="form-control" value={friStartTimeChat}
                        onChange={(event) => { setFriStartTimeChat( event.target.value ); }}>
                           <option value="">Start</option>
     {morFriLast > '06:00 AM'?
                       <option value="06:00 AM" disabled>06:00 AM</option>
                          :
                          <option value="06:00 AM">06:00 AM</option>
     }
     
     {morFriLast > '07:00 AM'?
                          <option value="07:00 AM" disabled>07:00 AM</option>
                          :
                          <option value="07:00 AM">07:00 AM</option>
     }
     {morFriLast > '08:00 AM'?
                          <option value="08:00 AM" disabled>08:00 AM</option>
                          :
                         <option value="08:00 AM">08:00 AM</option>
     }
     {morFriLast > '09:00 AM'?
                          <option value="09:00 AM" disabled>09:00 AM</option>
                          :
     <option value="09:00 AM">09:00 AM</option>
     }
     {morFriLast > '10:00 AM'?
                          <option value="10:00 AM" disabled>10:00 AM</option>
                          :
                       <option value="10:00 AM">10:00 AM</option>
     }
     {morFriLast > '11:00 AM'?
                          <option value="11:00 AM" disabled>11:00 AM</option>
                          :
                       <option value="11:00 AM">11:00 AM</option>
     }
     {morFriLast > '12:00 PM'?
                          <option value="12:00 PM" disabled>12:00 PM</option>
                          :
                       <option value="12:00 PM">12:00 PM</option>
     }
                         </select>
                   </div>
                   <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                   <select className="form-control" value={friEndTimeChat} onChange={handleFriEndTimeChat}>
                   <option value="">End</option>
                   {morFriLast >= '06:00 AM'?
                          <option value="06:00 AM" disabled>06:00 AM</option>
                          :
                          <option value="06:00 AM">06:00 AM</option>
     }
     
     {morFriLast >= '07:00 AM'?
                          <option value="07:00 AM" disabled>07:00 AM</option>
                          :
                          <option value="07:00 AM">07:00 AM</option>
     }
     {morFriLast >= '08:00 AM'?
                          <option value="08:00 AM" disabled>08:00 AM</option>
                          :
                         <option value="08:00 AM">08:00 AM</option>
     }
     {morFriLast >= '09:00 AM'?
                          <option value="09:00 AM" disabled>09:00 AM</option>
                          :
     <option value="09:00 AM">09:00 AM</option>
     }
     {morFriLast >= '10:00 AM'?
                          <option value="10:00 AM" disabled>10:00 AM</option>
                          :
                       <option value="10:00 AM">10:00 AM</option>
     }
     {morFriLast >= '11:00 AM'?
                          <option value="11:00 AM" disabled>11:00 AM</option>
                          :
                       <option value="11:00 AM">11:00 AM</option>
     }
     {morFriLast >= '12:00 PM'?
                          <option value="12:00 PM" disabled>12:00 PM</option>
                          :
                       <option value="12:00 PM">12:00 PM</option>
     }
                         </select>
                   </div>
                 </div>
                 </div>            
     </div>
     
     
     <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 superadmin_doctortimeslots_weekendsslot">
                         <h3 style={{fontWeight:'700', fontSize:'18px', marginTop:'15px', marginBottom:'15px',backgroundColor:'#5CB04D', color: '#fff', padding:'10px',
                       borderRadius:'5px'}}>TIME SLOTS FOR WEEK ENDS</h3>          
                       <div className="slot_Saturday mb-2">
                       <label style={{fontWeight:'600', fontSize:'14px'}}>Saturday</label>
                        <div className="row">
                           <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                           <select className="form-control" value={satStartTimeChat}
                        onChange={(event) => { setSatStartTimeChat( event.target.value ); }}>
                           <option value="">Start</option>
     {morSatLast > '06:00 AM'?
                       <option value="06:00 AM" disabled>06:00 AM</option>
                          :
                          <option value="06:00 AM">06:00 AM</option>
     }
     
     {morSatLast > '07:00 AM'?
                          <option value="07:00 AM" disabled>07:00 AM</option>
                          :
                          <option value="07:00 AM">07:00 AM</option>
     }
     {morSatLast > '08:00 AM'?
                          <option value="08:00 AM" disabled>08:00 AM</option>
                          :
                         <option value="08:00 AM">08:00 AM</option>
     }
     {morSatLast > '09:00 AM'?
                          <option value="09:00 AM" disabled>09:00 AM</option>
                          :
     <option value="09:00 AM">09:00 AM</option>
     }
     {morSatLast > '10:00 AM'?
                          <option value="10:00 AM" disabled>10:00 AM</option>
                          :
                       <option value="10:00 AM">10:00 AM</option>
     }
     {morSatLast > '11:00 AM'?
                          <option value="11:00 AM" disabled>11:00 AM</option>
                          :
                       <option value="11:00 AM">11:00 AM</option>
     }
     {morSatLast > '12:00 PM'?
                          <option value="12:00 PM" disabled>12:00 PM</option>
                          :
                       <option value="12:00 PM">12:00 PM</option>
     }
                         </select>
                 </div>
                 <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                 <select className="form-control" value={satEndTimeChat} onChange={handleSatEndTimeChat}>
                 <option value="">End</option>
                 {morSatLast >= '06:00 AM'?
                          <option value="06:00 AM" disabled>06:00 AM</option>
                          :
                          <option value="06:00 AM">06:00 AM</option>
     }
     
     {morSatLast >= '07:00 AM'?
                          <option value="07:00 AM" disabled>07:00 AM</option>
                          :
                          <option value="07:00 AM">07:00 AM</option>
     }
     {morSatLast >= '08:00 AM'?
                          <option value="08:00 AM" disabled>08:00 AM</option>
                          :
                         <option value="08:00 AM">08:00 AM</option>
     }
     {morSatLast >= '09:00 AM'?
                          <option value="09:00 AM" disabled>09:00 AM</option>
                          :
     <option value="09:00 AM">09:00 AM</option>
     }
     {morSatLast >= '10:00 AM'?
                          <option value="10:00 AM" disabled>10:00 AM</option>
                          :
                       <option value="10:00 AM">10:00 AM</option>
     }
     {morSatLast >= '11:00 AM'?
                          <option value="11:00 AM" disabled>11:00 AM</option>
                          :
                       <option value="11:00 AM">11:00 AM</option>
     }
     {morSatLast >= '12:00 PM'?
                          <option value="12:00 PM" disabled>12:00 PM</option>
                          :
                       <option value="12:00 PM">12:00 PM</option>
     }
                         </select>
                 </div>
                         </div>
                       </div> 
     
                       <div className="slot_sunday mb-2">
                       <label style={{fontWeight:'600', fontSize:'14px'}}>Sunday</label>
                        <div className="row">
                           <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                           <select className="form-control" value={sunStartTimeChat} onChange={(event) => { setSunStartTimeChat( event.target.value ); }}>
                           <option value="">Start</option>
                           {morSunLast > '06:00 AM'?
                       <option value="06:00 AM" disabled>06:00 AM</option>
                          :
                          <option value="06:00 AM">06:00 AM</option>
     }
     
     {morSunLast > '07:00 AM'?
                          <option value="07:00 AM" disabled>07:00 AM</option>
                          :
                          <option value="07:00 AM">07:00 AM</option>
     }
     {morSunLast > '08:00 AM'?
                          <option value="08:00 AM" disabled>08:00 AM</option>
                          :
                         <option value="08:00 AM">08:00 AM</option>
     }
     {morSunLast > '09:00 AM'?
                          <option value="09:00 AM" disabled>09:00 AM</option>
                          :
     <option value="09:00 AM">09:00 AM</option>
     }
     {morSunLast > '10:00 AM'?
                          <option value="10:00 AM" disabled>10:00 AM</option>
                          :
                       <option value="10:00 AM">10:00 AM</option>
     }
     {morSunLast > '11:00 AM'?
                          <option value="11:00 AM" disabled>11:00 AM</option>
                          :
                       <option value="11:00 AM">11:00 AM</option>
     }
     {morSunLast > '12:00 PM'?
                          <option value="12:00 PM" disabled>12:00 PM</option>
                          :
                       <option value="12:00 PM">12:00 PM</option>
     }
                         </select>
                 </div>
                 <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                 <select className="form-control" value={sunEndTimeChat} onChange={handleSunEndTimeChat}>
                 <option value="">End</option>
                 {morSunLast >= '06:00 AM'?
                          <option value="06:00 AM" disabled>06:00 AM</option>
                          :
                          <option value="06:00 AM">06:00 AM</option>
     }
     
     {morSunLast >= '07:00 AM'?
                          <option value="07:00 AM" disabled>07:00 AM</option>
                          :
                          <option value="07:00 AM">07:00 AM</option>
     }
     {morSunLast >= '08:00 AM'?
                          <option value="08:00 AM" disabled>08:00 AM</option>
                          :
                         <option value="08:00 AM">08:00 AM</option>
     }
     {morSunLast >= '09:00 AM'?
                          <option value="09:00 AM" disabled>09:00 AM</option>
                          :
     <option value="09:00 AM">09:00 AM</option>
     }
     {morSunLast >= '10:00 AM'?
                          <option value="10:00 AM" disabled>10:00 AM</option>
                          :
                       <option value="10:00 AM">10:00 AM</option>
     }
     {morSunLast >= '11:00 AM'?
                          <option value="11:00 AM" disabled>11:00 AM</option>
                          :
                       <option value="11:00 AM">11:00 AM</option>
     }
     {morSunLast >= '12:00 PM'?
                          <option value="12:00 PM" disabled>12:00 PM</option>
                          :                     
                          <option value="12:00 PM">12:00 PM</option>
                        }
                                            </select>
                                    </div>
                                  </div>
                                  </div>
                                  </div>
                                  </div>
                        
                        
                                  <center>
                        <h3 style={{textTransform: 'uppercase'}}>Afternoon</h3>
                        </center>
                        
                                  <div className="row">
                                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 superadmin_doctortimeslots__weekdaysslot">
                                            <h3 style={{fontWeight:'700', fontSize:'18px', marginTop:'15px', marginBottom:'15px',backgroundColor:'#5CB04D', color: '#fff', padding:'10px',
                                          borderRadius:'5px'}}>TIME SLOTS FOR WEEK DAYS</h3>
                                
                                <div className="slot_monday mb-2">
                                          <label style={{fontWeight:'600', fontSize:'14px'}}>Monday</label>
                                  
                                           <div className="row">
                                              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                              <select className="form-control" value={monStartTimeAfChat} 
                                          onChange={(event) => { setMonStartTimeAfChat( event.target.value ); }}>
                        <option value="">Start</option>
                        {afMonLast == ''?
                         <option value="12:00 PM">12:00 PM</option>
                                             
                                             :
                                    <option value="12:00 PM" disabled>12:00 PM</option>        
                        }
                        
                        {afMonLast > '01:00 PM'?
                                             <option value="01:00 PM" disabled>01:00 PM</option>
                                             :
                                             <option value="01:00 PM">01:00 PM</option>
                        }
                        {afMonLast > '02:00 PM'?
                                             <option value="02:00 PM" disabled>02:00 PM</option>
                                             :
                                             <option value="02:00 PM">02:00 PM</option>
                        }
                        {afMonLast > '03:00 PM'?
                                                  <option value="03:00 PM" disabled>03:00 PM</option>
                                             :
                                             <option value="03:00 PM">03:00 PM</option>
                        }
                        {afMonLast > '04:00 PM'?
                                             <option value="04:00 PM" disabled>04:00 PM</option>
                                             :
                                             <option value="04:00 PM">04:00 PM</option>
                        }
                                            </select>
                                        </div>
                                        <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                        <select className="form-control" value={monEndTimeAfChat} onChange={handleMonEndTimeAfChat}>
                                        <option value="">End</option>
                                        {afMonLast == ''?
                         <option value="12:00 PM">12:00 PM</option>
                                             
                                             :
                                    <option value="12:00 PM" disabled>12:00 PM</option>        
                        }
                        
                        {afMonLast >= '01:00 PM'?
                                             <option value="01:00 PM" disabled>01:00 PM</option>
                                             :
                                             <option value="01:00 PM">01:00 PM</option>
                        }
                        {afMonLast >= '02:00 PM'?
                                             <option value="02:00 PM" disabled>02:00 PM</option>
                                             :
                                             <option value="02:00 PM">02:00 PM</option>
                        }
                        {afMonLast >= '03:00 PM'?
                                                  <option value="03:00 PM" disabled>03:00 PM</option>
                                             :
                                             <option value="03:00 PM">03:00 PM</option>
                        }
                        {afMonLast >= '04:00 PM'?
                                             <option value="04:00 PM" disabled>04:00 PM</option>
                                             :
                                             <option value="04:00 PM">04:00 PM</option>
                        }
                                            </select>
                                        </div>
                                            </div>
                                          </div>
                        
                                          <div className="slot_tuesday mb-2">
                                          <label style={{fontWeight:'600', fontSize:'14px'}}>Tuesday</label>
                                           <div className="row">
                                              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                              <select className="form-control" value={tueStartTimeAfChat}
                                           onChange={(event) => { setTueStartTimeAfChat( event.target.value ); }}>
                         <option value="">Start</option>
                        {afTueLast == ''?
                         <option value="12:00 PM">12:00 PM</option>
                                             
                                             :
                                    <option value="12:00 PM" disabled>12:00 PM</option>        
                        }
                        
                        {afTueLast > '01:00 PM'?
                                             <option value="01:00 PM" disabled>01:00 PM</option>
                                             :
                                             <option value="01:00 PM">01:00 PM</option>
                        }
                        {afTueLast > '02:00 PM'?
                                             <option value="02:00 PM" disabled>02:00 PM</option>
                                             :
                                             <option value="02:00 PM">02:00 PM</option>
                        }
                        {afTueLast > '03:00 PM'?
                                                  <option value="03:00 PM" disabled>03:00 PM</option>
                                             :
                                             <option value="03:00 PM">03:00 PM</option>
                        }
                        {afTueLast > '04:00 PM'?
                                             <option value="04:00 PM" disabled>04:00 PM</option>
                                             :
                                             <option value="04:00 PM">04:00 PM</option>
                        }
                                            </select>
                                      </div>
                                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                      <select className="form-control" value={tueEndTimeAfChat} onChange={handleTueEndTimeAfChat}>
                                      <option value="">End</option>
                                      {afTueLast == ''?
                         <option value="12:00 PM">12:00 PM</option>
                                             
                                             :
                                    <option value="12:00 PM" disabled>12:00 PM</option>        
                        }
                        
                        {afTueLast >= '01:00 PM'?
                                             <option value="01:00 PM" disabled>01:00 PM</option>
                                             :
                                             <option value="01:00 PM">01:00 PM</option>
                        }
                        {afTueLast >= '02:00 PM'?
                                             <option value="02:00 PM" disabled>02:00 PM</option>
                                             :
                                             <option value="02:00 PM">02:00 PM</option>
                        }
                        {afTueLast >= '03:00 PM'?
                                                  <option value="03:00 PM" disabled>03:00 PM</option>
                                             :
                                             <option value="03:00 PM">03:00 PM</option>
                        }
                        {afTueLast >= '04:00 PM'?
                                             <option value="04:00 PM" disabled>04:00 PM</option>
                                             :
                                             <option value="04:00 PM">04:00 PM</option>
                        }
                                            </select>
                                      </div>
                                    </div>
                                    </div>
                        
                                    <div className="slot_wednesday mb-2">
                                          <label style={{fontWeight:'600', fontSize:'14px'}}>Wednesday</label>
                                           <div className="row">
                                              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                              <select className="form-control" value={wedStartTimeAfChat} 
                                           onChange={(event) => { setWedStartTimeAfChat( event.target.value ); }}>
                         <option value="">Start</option>
                        {afWedLast == ''?
                         <option value="12:00 PM">12:00 PM</option>
                                             
                                             :
                                    <option value="12:00 PM" disabled>12:00 PM</option>        
                        }
                        
                        {afWedLast > '01:00 PM'?
                                             <option value="01:00 PM" disabled>01:00 PM</option>
                                             :
                                             <option value="01:00 PM">01:00 PM</option>
                        }
                        {afWedLast > '02:00 PM'?
                                             <option value="02:00 PM" disabled>02:00 PM</option>
                                             :
                                             <option value="02:00 PM">02:00 PM</option>
                        }
                        {afWedLast > '03:00 PM'?
                                                  <option value="03:00 PM" disabled>03:00 PM</option>
                                             :
                                             <option value="03:00 PM">03:00 PM</option>
                        }
                        {afWedLast > '04:00 PM'?
                                             <option value="04:00 PM" disabled>04:00 PM</option>
                                             :
                                             <option value="04:00 PM">04:00 PM</option>
                        }
                                            </select>
                                      </div>
                                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                      <select className="form-control" value={wedEndTimeAfChat} onChange={handleWedEndTimeAfChat}>
                                      <option value="">End</option>
                                      {afWedLast == ''?
                         <option value="12:00 PM">12:00 PM</option>
                                             
                                             :
                                    <option value="12:00 PM" disabled>12:00 PM</option>        
                        }
                        
                        {afWedLast >= '01:00 PM'?
                                             <option value="01:00 PM" disabled>01:00 PM</option>
                                             :
                                             <option value="01:00 PM">01:00 PM</option>
                        }
                        {afWedLast >= '02:00 PM'?
                                             <option value="02:00 PM" disabled>02:00 PM</option>
                                             :
                                             <option value="02:00 PM">02:00 PM</option>
                        }
                        {afWedLast >= '03:00 PM'?
                                                  <option value="03:00 PM" disabled>03:00 PM</option>
                                             :
                                             <option value="03:00 PM">03:00 PM</option>
                        }
                        {afWedLast >= '04:00 PM'?
                                             <option value="04:00 PM" disabled>04:00 PM</option>
                                             :
                                             <option value="04:00 PM">04:00 PM</option>
                        }
                                            </select>
                                      </div>
                                            </div>
                                          </div>
                        
                                          <div className="slot_thursday mb-2">
                                          <label style={{fontWeight:'600', fontSize:'14px'}}>Thursday</label>
                                           <div className="row">
                                              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                              <select className="form-control" value={thuStartTimeAfChat}
                                           onChange={(event) => { setThuStartTimeAfChat( event.target.value ); }} >
                         <option value="">Start</option>
                        {afThuLast == ''?
                         <option value="12:00 PM">12:00 PM</option>
                                             
                                             :
                                    <option value="12:00 PM" disabled>12:00 PM</option>        
                        }
                        
                        {afThuLast > '01:00 PM'?
                                             <option value="01:00 PM" disabled>01:00 PM</option>
                                             :
                                             <option value="01:00 PM">01:00 PM</option>
                        }
                        {afThuLast > '02:00 PM'?
                                             <option value="02:00 PM" disabled>02:00 PM</option>
                                             :
                                             <option value="02:00 PM">02:00 PM</option>
                        }
                        {afThuLast > '03:00 PM'?
                                                  <option value="03:00 PM" disabled>03:00 PM</option>
                                             :
                                             <option value="03:00 PM">03:00 PM</option>
                        }
                        {afThuLast > '04:00 PM'?
                                             <option value="04:00 PM" disabled>04:00 PM</option>
                                             :
                                             <option value="04:00 PM">04:00 PM</option>
                        }
                                            </select>
                                      </div>
                                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                      <select className="form-control" value={thuEndTimeAfChat} onChange={handleThuEndTimeAfChat}>
                                      <option value="">End</option>
                                      {afThuLast == ''?
                         <option value="12:00 PM">12:00 PM</option>
                                             
                                             :
                                    <option value="12:00 PM" disabled>12:00 PM</option>        
                        }
                        
                        {afThuLast >= '01:00 PM'?
                                             <option value="01:00 PM" disabled>01:00 PM</option>
                                             :
                                             <option value="01:00 PM">01:00 PM</option>
                        }
                        {afThuLast >= '02:00 PM'?
                                             <option value="02:00 PM" disabled>02:00 PM</option>
                                             :
                                             <option value="02:00 PM">02:00 PM</option>
                        }
                        {afThuLast >= '03:00 PM'?
                                                  <option value="03:00 PM" disabled>03:00 PM</option>
                                             :
                                             <option value="03:00 PM">03:00 PM</option>
                        }
                        {afThuLast >= '04:00 PM'?
                                             <option value="04:00 PM" disabled>04:00 PM</option>
                                             :
                                             <option value="04:00 PM">04:00 PM</option>
                        }
                                            </select>
                                    </div>
                                            </div>
                                          </div>
                        
                                  <div className="slot_friday mb-2">
                                          <label style={{fontWeight:'600', fontSize:'14px'}}>Friday</label>
                                           <div className="row">
                                              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                              <select className="form-control" value={friStartTimeAfChat}
                                           onChange={(event) => { setFriStartTimeAfChat( event.target.value ); }}>
                       <option value="">Start</option>
                        {afFriLast == ''?
                         <option value="12:00 PM">12:00 PM</option>
                                             
                                             :
                                    <option value="12:00 PM" disabled>12:00 PM</option>        
                        }
                        
                        {afFriLast > '01:00 PM'?
                                             <option value="01:00 PM" disabled>01:00 PM</option>
                                             :
                                             <option value="01:00 PM">01:00 PM</option>
                        }
                        {afFriLast > '02:00 PM'?
                                             <option value="02:00 PM" disabled>02:00 PM</option>
                                             :
                                             <option value="02:00 PM">02:00 PM</option>
                        }
                        {afFriLast > '03:00 PM'?
                                                  <option value="03:00 PM" disabled>03:00 PM</option>
                                             :
                                             <option value="03:00 PM">03:00 PM</option>
                        }
                        {afFriLast > '04:00 PM'?
                                             <option value="04:00 PM" disabled>04:00 PM</option>
                                             :
                                             <option value="04:00 PM">04:00 PM</option>
                        }
                                            </select>
                                      </div>
                                      <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                      <select className="form-control" value={friEndTimeAfChat} onChange={handleFriEndTimeAfChat}>
                                      <option value="">End</option>
                                      {afFriLast == ''?
                         <option value="12:00 PM">12:00 PM</option>
                                             
                                             :
                                    <option value="12:00 PM" disabled>12:00 PM</option>        
                        }
                        
                        {afFriLast >= '01:00 PM'?
                                             <option value="01:00 PM" disabled>01:00 PM</option>
                                             :
                                             <option value="01:00 PM">01:00 PM</option>
                        }
                        {afFriLast >= '02:00 PM'?
                                             <option value="02:00 PM" disabled>02:00 PM</option>
                                             :
                                             <option value="02:00 PM">02:00 PM</option>
                        }
                        {afFriLast >= '03:00 PM'?
                                                  <option value="03:00 PM" disabled>03:00 PM</option>
                                             :
                                             <option value="03:00 PM">03:00 PM</option>
                        }
                        {afFriLast >= '04:00 PM'?
                                             <option value="04:00 PM" disabled>04:00 PM</option>
                                             :
                                             <option value="04:00 PM">04:00 PM</option>
                        }
                                            </select>
                                      </div>
                                    </div>
                                    </div>            
                        </div>
                        
                        
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 superadmin_doctortimeslots_weekendsslot">
                                            <h3 style={{fontWeight:'700', fontSize:'18px', marginTop:'15px', marginBottom:'15px',backgroundColor:'#5CB04D', color: '#fff', padding:'10px',
                                          borderRadius:'5px'}}>TIME SLOTS FOR WEEK ENDS</h3>          
                                          <div className="slot_Saturday mb-2">
                                          <label style={{fontWeight:'600', fontSize:'14px'}}>Saturday</label>
                                           <div className="row">
                                              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                              <select className="form-control" value={satStartTimeAfChat}
                                           onChange={(event) => { setSatStartTimeAfChat( event.target.value ); }}>
                                          <option value="">Start</option>
                                          {afSatLast == ''?
                         <option value="12:00 PM">12:00 PM</option>
                                             
                                             :
                                    <option value="12:00 PM" disabled>12:00 PM</option>        
                        }
                        
                        {afSatLast > '01:00 PM'?
                                             <option value="01:00 PM" disabled>01:00 PM</option>
                                             :
                                             <option value="01:00 PM">01:00 PM</option>
                        }
                        {afSatLast > '02:00 PM'?
                                             <option value="02:00 PM" disabled>02:00 PM</option>
                                             :
                                             <option value="02:00 PM">02:00 PM</option>
                        }
                        {afSatLast > '03:00 PM'?
                                                  <option value="03:00 PM" disabled>03:00 PM</option>
                                             :
                                             <option value="03:00 PM">03:00 PM</option>
                        }
                        {afSatLast > '04:00 PM'?
                                             <option value="04:00 PM" disabled>04:00 PM</option>
                                             :
                                             <option value="04:00 PM">04:00 PM</option>
                        }
                                            </select>
                                    </div>
                                    <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                    <select className="form-control" value={satEndTimeAfChat} onChange={handleSatEndTimeAfChat}>
                                    <option value="">End</option>
                                    {afSatLast == ''?
                         <option value="12:00 PM">12:00 PM</option>
                                             
                                             :
                                    <option value="12:00 PM" disabled>12:00 PM</option>        
                        }
                        
                        {afSatLast >= '01:00 PM'?
                                             <option value="01:00 PM" disabled>01:00 PM</option>
                                             :
                                             <option value="01:00 PM">01:00 PM</option>
                        }
                        {afSatLast >= '02:00 PM'?
                                             <option value="02:00 PM" disabled>02:00 PM</option>
                                             :
                                             <option value="02:00 PM">02:00 PM</option>
                        }
                        {afSatLast >= '03:00 PM'?
                                                  <option value="03:00 PM" disabled>03:00 PM</option>
                                             :
                                             <option value="03:00 PM">03:00 PM</option>
                        }
                        {afSatLast >= '04:00 PM'?
                                             <option value="04:00 PM" disabled>04:00 PM</option>
                                             :
                                             <option value="04:00 PM">04:00 PM</option>
                        }
                                            </select>
                                    </div>
                                            </div>
                                          </div> 
                        
                                          <div className="slot_sunday mb-2">
                                          <label style={{fontWeight:'600', fontSize:'14px'}}>Sunday</label>
                                           <div className="row">
                                              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                              <select className="form-control" value={sunStartTimeAfChat}
                                           onChange={(event) => { setSunStartTimeAfChat( event.target.value ); }}>
                      <option value="">Start</option>
                        {afSunLast == ''?
                         <option value="12:00 PM">12:00 PM</option>
                                             
                                             :
                                    <option value="12:00 PM" disabled>12:00 PM</option>        
                        }
                        
                        {afSunLast > '01:00 PM'?
                                             <option value="01:00 PM" disabled>01:00 PM</option>
                                             :
                                             <option value="01:00 PM">01:00 PM</option>
                        }
                        {afSunLast > '02:00 PM'?
                                             <option value="02:00 PM" disabled>02:00 PM</option>
                                             :
                                             <option value="02:00 PM">02:00 PM</option>
                        }
                        {afSunLast > '03:00 PM'?
                                                  <option value="03:00 PM" disabled>03:00 PM</option>
                                             :
                                             <option value="03:00 PM">03:00 PM</option>
                        }
                        {afSunLast > '04:00 PM'?
                                             <option value="04:00 PM" disabled>04:00 PM</option>
                                             :
                                             <option value="04:00 PM">04:00 PM</option>
                        }
                                            </select>
                                    </div>
                                    <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                    <select className="form-control" value={sunEndTimeAfChat} onChange={handleSunEndTimeAfChat}>
                                    <option value="">End</option>
                                    {afSunLast == ''?
                         <option value="12:00 PM">12:00 PM</option>
                                             
                                             :
                                    <option value="12:00 PM" disabled>12:00 PM</option>        
                        }
                        
                        {afSunLast >= '01:00 PM'?
                                             <option value="01:00 PM" disabled>01:00 PM</option>
                                             :
                                             <option value="01:00 PM">01:00 PM</option>
                        }
                        {afSunLast >= '02:00 PM'?
                                             <option value="02:00 PM" disabled>02:00 PM</option>
                                             :
                                             <option value="02:00 PM">02:00 PM</option>
                        }
                        {afSunLast >= '03:00 PM'?
                                                  <option value="03:00 PM" disabled>03:00 PM</option>
                                             :
                                             <option value="03:00 PM">03:00 PM</option>
                        }
                        {afSunLast >= '04:00 PM'?
                                             <option value="04:00 PM" disabled>04:00 PM</option>
                                             :
                                             <option value="04:00 PM">04:00 PM</option>
                        }
                                            </select>
                                    </div>
                                  </div>
                                  </div>
                                  </div>
                                  </div>
                        
                        
                                  <center>
                        <h3 style={{textTransform: 'uppercase'}}>Evening</h3>
                        </center>
                        
                                  <div className="row">
                                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 superadmin_doctortimeslots__weekdaysslot">
                                            <h3 style={{fontWeight:'700', fontSize:'18px', marginTop:'15px', marginBottom:'15px',backgroundColor:'#5CB04D', color: '#fff', padding:'10px',
                                          borderRadius:'5px'}}>TIME SLOTS FOR WEEK DAYS</h3>
                                
                                <div className="slot_monday mb-2">
                                          <label style={{fontWeight:'600', fontSize:'14px'}}>Monday</label>
                                 
                                           <div className="row">
                                              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                              <select className="form-control" value={monStartTimeEvChat} 
                                          onChange={(event) => { setMonStartTimeEvChat( event.target.value ); }}>
                        <option value="">Start</option>
                        {evMonLast > '04:00 PM'?
                         <option value="04:00 PM" disabled>04:00 PM</option>   
                                             :
                                             <option value="04:00 PM">04:00 PM</option>    
                        }
                        
                        {evMonLast > '05:00 PM'?
                                             <option value="05:00 PM" disabled>05:00 PM</option>
                                             :
                                             <option value="05:00 PM">05:00 PM</option>
                        }
                        {evMonLast > '06:00 PM'?
                                             <option value="06:00 PM" disabled>06:00 PM</option>
                                             :
                                             <option value="06:00 PM">06:00 PM</option>
                        }
                        {evMonLast > '07:00 PM'?
                                                  <option value="07:00 PM" disabled>07:00 PM</option>
                                             :
                                             <option value="07:00 PM">07:00 PM</option>
                        }
                        {evMonLast > '08:00 PM'?
                                             <option value="08:00 PM" disabled>08:00 PM</option>
                                             :
                                             <option value="08:00 PM">08:00 PM</option>
                        }
                        {evMonLast > '09:00 PM'?
                                             <option value="09:00 PM" disabled>09:00 PM</option>
                                             :
                                             <option value="09:00 PM">09:00 PM</option>
                        }
                        {evMonLast > '10:00 PM'?
                                             <option value="10:00 PM" disabled>10:00 PM</option>
                                             :
                                             <option value="10:00 PM">10:00 PM</option>
                        }
                                            </select>
                                        </div>
                                        <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                        <select className="form-control" value={monEndTimeEvChat} onChange={handleMonEndTimeEvChat}>
                                        <option value="">End</option>
                                        {evMonLast >= '04:00 PM'?
                         <option value="04:00 PM" disabled>04:00 PM</option>   
                                             :
                                             <option value="04:00 PM">04:00 PM</option>    
                        }
                        
                        {evMonLast >= '05:00 PM'?
                                             <option value="05:00 PM" disabled>05:00 PM</option>
                                             :
                                             <option value="05:00 PM">05:00 PM</option>
                        }
                        {evMonLast >= '06:00 PM'?
                                             <option value="06:00 PM" disabled>06:00 PM</option>
                                             :
                                             <option value="06:00 PM">06:00 PM</option>
                        }
                        {evMonLast >= '07:00 PM'?
                                                  <option value="07:00 PM" disabled>07:00 PM</option>
                                             :
                                             <option value="07:00 PM">07:00 PM</option>
                        }
                        {evMonLast >= '08:00 PM'?
                                             <option value="08:00 PM" disabled>08:00 PM</option>
                                             :
                                             <option value="08:00 PM">08:00 PM</option>
                        }
                        {evMonLast >= '09:00 PM'?
                                             <option value="09:00 PM" disabled>09:00 PM</option>
                                             :
                                             <option value="09:00 PM">09:00 PM</option>
                        }
                        {evMonLast >= '10:00 PM'?
                                             <option value="10:00 PM" disabled>10:00 PM</option>
                                             :
                                             <option value="10:00 PM">10:00 PM</option>
                        }
                                            </select>
                                        </div>
                                            </div>
                                          </div>
                        
                                          <div className="slot_tuesday mb-2">
                                          <label style={{fontWeight:'600', fontSize:'14px'}}>Tuesday</label>
                                           <div className="row">
                                              <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                              <select className="form-control" value={tueStartTimeEvChat}
                                           onChange={(event) => { setTueStartTimeEvChat( event.target.value ); }}>
                      <option value="">Start</option>
                        {evTueLast > '04:00 PM'?
                         <option value="04:00 PM" disabled>04:00 PM</option>   
                                             :
                                             <option value="04:00 PM">04:00 PM</option>    
                        }
                        
                        {evTueLast > '05:00 PM'?
                                             <option value="05:00 PM" disabled>05:00 PM</option>
                                             :
                                             <option value="05:00 PM">05:00 PM</option>
                        }
                        {evTueLast > '06:00 PM'?
                                             <option value="06:00 PM" disabled>06:00 PM</option>
                                             :
                                             <option value="06:00 PM">06:00 PM</option>
                        }
                        {evTueLast > '07:00 PM'?
                                                  <option value="07:00 PM" disabled>07:00 PM</option>
                                             :
                                             <option value="07:00 PM">07:00 PM</option>
                        }
                        {evTueLast > '08:00 PM'?
                                             <option value="08:00 PM" disabled>08:00 PM</option>
                                             :
                                             <option value="08:00 PM">08:00 PM</option>
                        }
                        {evTueLast > '09:00 PM'?
                                             <option value="09:00 PM" disabled>09:00 PM</option>
                                             :
                                             <option value="09:00 PM">09:00 PM</option>
                        }
                        {evTueLast > '10:00 PM'?
                                             <option value="10:00 PM" disabled>10:00 PM</option>
                                             :
                                             <option value="10:00 PM">10:00 PM</option>
                                            }
                                                                </select>
                                                          </div>
                                                          <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                                          <select className="form-control" value={tueEndTimeEvChat} onChange={handleTueEndTimeEvChat}>
                                                          <option value="">End</option>
                                                          {evTueLast >= '04:00 PM'?
                                             <option value="04:00 PM" disabled>04:00 PM</option>   
                                                                 :
                                                                 <option value="04:00 PM">04:00 PM</option>    
                                            }
                                            
                                            {evTueLast >= '05:00 PM'?
                                                                 <option value="05:00 PM" disabled>05:00 PM</option>
                                                                 :
                                                                 <option value="05:00 PM">05:00 PM</option>
                                            }
                                            {evTueLast >= '06:00 PM'?
                                                                 <option value="06:00 PM" disabled>06:00 PM</option>
                                                                 :
                                                                 <option value="06:00 PM">06:00 PM</option>
                                            }
                                            {evTueLast >= '07:00 PM'?
                                                                      <option value="07:00 PM" disabled>07:00 PM</option>
                                                                 :
                                                                 <option value="07:00 PM">07:00 PM</option>
                                            }
                                            {evTueLast >= '08:00 PM'?
                                                                 <option value="08:00 PM" disabled>08:00 PM</option>
                                                                 :
                                                                 <option value="08:00 PM">08:00 PM</option>
                                            }
                                            {evTueLast >= '09:00 PM'?
                                                                 <option value="09:00 PM" disabled>09:00 PM</option>
                                                                 :
                                                                 <option value="09:00 PM">09:00 PM</option>
                                            }
                                            {evTueLast >= '10:00 PM'?
                                                                 <option value="10:00 PM" disabled>10:00 PM</option>
                                                                 :
                                                                 <option value="10:00 PM">10:00 PM</option>
                                            }
                                                          </select>
                                                          </div>
                                                        </div>
                                                        </div>
                                            
                                                        <div className="slot_wednesday mb-2">
                                                              <label style={{fontWeight:'600', fontSize:'14px'}}>Wednesday</label>
                                                               <div className="row">
                                                                  <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                                                  <select className="form-control" value={wedStartTimeEvChat} 
                                                               onChange={(event) => { setWedStartTimeEvChat( event.target.value ); }}>
                                                            <option value="">Start</option>
                                               
                                                           {evWedLast > '04:00 PM'?
                                             <option value="04:00 PM" disabled>04:00 PM</option>   
                                                                 :
                                                                 <option value="04:00 PM">04:00 PM</option>    
                                            }
                                            
                                            {evWedLast > '05:00 PM'?
                                                                 <option value="05:00 PM" disabled>05:00 PM</option>
                                                                 :
                                                                 <option value="05:00 PM">05:00 PM</option>
                                            }
                                            {evWedLast > '06:00 PM'?
                                                                 <option value="06:00 PM" disabled>06:00 PM</option>
                                                                 :
                                                                 <option value="06:00 PM">06:00 PM</option>
                                            }
                                            {evWedLast > '07:00 PM'?
                                                                      <option value="07:00 PM" disabled>07:00 PM</option>
                                                                 :
                                                                 <option value="07:00 PM">07:00 PM</option>
                                            }
                                            {evWedLast > '08:00 PM'?
                                                                 <option value="08:00 PM" disabled>08:00 PM</option>
                                                                 :
                                                                 <option value="08:00 PM">08:00 PM</option>
                                            }
                                            {evWedLast > '09:00 PM'?
                                                                 <option value="09:00 PM" disabled>09:00 PM</option>
                                                                 :
                                                                 <option value="09:00 PM">09:00 PM</option>
                                            }
                                            {evWedLast > '10:00 PM'?
                                                                 <option value="10:00 PM" disabled>10:00 PM</option>
                                                                 :
                                                                 <option value="10:00 PM">10:00 PM</option>
                                            }
                                                                </select>
                                                          </div>
                                                          <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                                          <select className="form-control" value={wedEndTimeEvChat} onChange={handleWedEndTimeEvChat}>
                                                          <option value="">End</option>
                                                          {evWedLast >= '04:00 PM'?
                                             <option value="04:00 PM" disabled>04:00 PM</option>   
                                                                 :
                                                                 <option value="04:00 PM">04:00 PM</option>    
                                            }
                                            
                                            {evWedLast >= '05:00 PM'?
                                                                 <option value="05:00 PM" disabled>05:00 PM</option>
                                                                 :
                                                                 <option value="05:00 PM">05:00 PM</option>
                                            }
                                            {evWedLast >= '06:00 PM'?
                                                                 <option value="06:00 PM" disabled>06:00 PM</option>
                                                                 :
                                                                 <option value="06:00 PM">06:00 PM</option>
                                            }
                                            {evWedLast >= '07:00 PM'?
                                                                      <option value="07:00 PM" disabled>07:00 PM</option>
                                                                 :
                                                                 <option value="07:00 PM">07:00 PM</option>
                                            }
                                            {evWedLast >= '08:00 PM'?
                                                                 <option value="08:00 PM" disabled>08:00 PM</option>
                                                                 :
                                                                 <option value="08:00 PM">08:00 PM</option>
                                            }
                                            {evWedLast >= '09:00 PM'?
                                                                 <option value="09:00 PM" disabled>09:00 PM</option>
                                                                 :
                                                                 <option value="09:00 PM">09:00 PM</option>
                                            }
                                            {evWedLast >= '10:00 PM'?
                                                                 <option value="10:00 PM" disabled>10:00 PM</option>
                                                                 :
                                                                 <option value="10:00 PM">10:00 PM</option>
                                            }
                                                                </select>
                                                          </div>
                                                                </div>
                                                              </div>
                                            
                                                              <div className="slot_thursday mb-2">
                                                              <label style={{fontWeight:'600', fontSize:'14px'}}>Thursday</label>
                                                               <div className="row">
                                                                  <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                                                  <select className="form-control" value={thuStartTimeEvChat}
                                                               onChange={(event) => { setThuStartTimeEvChat( event.target.value ); }}>
                                                         <option value="">Start</option>
                                                                {evThuLast > '04:00 PM'?
                                             <option value="04:00 PM" disabled>04:00 PM</option>   
                                                                 :
                                                                 <option value="04:00 PM">04:00 PM</option>    
                                            }
                                            
                                            {evThuLast > '05:00 PM'?
                                                                 <option value="05:00 PM" disabled>05:00 PM</option>
                                                                 :
                                                                 <option value="05:00 PM">05:00 PM</option>
                                            }
                                            {evThuLast > '06:00 PM'?
                                                                 <option value="06:00 PM" disabled>06:00 PM</option>
                                                                 :
                                                                 <option value="06:00 PM">06:00 PM</option>
                                            }
                                            {evThuLast > '07:00 PM'?
                                                                      <option value="07:00 PM" disabled>07:00 PM</option>
                                                                 :
                                                                 <option value="07:00 PM">07:00 PM</option>
                                            }
                                            {evThuLast > '08:00 PM'?
                                                                 <option value="08:00 PM" disabled>08:00 PM</option>
                                                                 :
                                                                 <option value="08:00 PM">08:00 PM</option>
                                            }
                                            {evThuLast > '09:00 PM'?
                                                                 <option value="09:00 PM" disabled>09:00 PM</option>
                                                                 :
                                                                 <option value="09:00 PM">09:00 PM</option>
                                            }
                                            {evThuLast > '10:00 PM'?
                                                                 <option value="10:00 PM" disabled>10:00 PM</option>
                                                                 :
                                                                 <option value="10:00 PM">10:00 PM</option>
                                            }
                                                                </select>
                                                          </div>
                                                          <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                                          <select className="form-control" value={thuEndTimeEvChat} onChange={handleThuEndTimeEvChat}>
                                                          <option value="">End</option>
                                                          {evThuLast >= '04:00 PM'?
                                             <option value="04:00 PM" disabled>04:00 PM</option>   
                                                                 :
                                                                 <option value="04:00 PM">04:00 PM</option>    
                                            }
                                            
                                            {evThuLast >= '05:00 PM'?
                                                                 <option value="05:00 PM" disabled>05:00 PM</option>
                                                                 :
                                                                 <option value="05:00 PM">05:00 PM</option>
                                            }
                                            {evThuLast >= '06:00 PM'?
                                                                 <option value="06:00 PM" disabled>06:00 PM</option>
                                                                 :
                                                                 <option value="06:00 PM">06:00 PM</option>
                                            }
                                            {evThuLast >= '07:00 PM'?
                                                                      <option value="07:00 PM" disabled>07:00 PM</option>
                                                                 :
                                                                 <option value="07:00 PM">07:00 PM</option>
                                            }
                                            {evThuLast >= '08:00 PM'?
                                                                 <option value="08:00 PM" disabled>08:00 PM</option>
                                                                 :
                                                                 <option value="08:00 PM">08:00 PM</option>
                                            }
                                            {evThuLast >= '09:00 PM'?
                                                                 <option value="09:00 PM" disabled>09:00 PM</option>
                                                                 :
                                                                 <option value="09:00 PM">09:00 PM</option>
                                            }
                                            {evThuLast >= '10:00 PM'?
                                                                 <option value="10:00 PM" disabled>10:00 PM</option>
                                                                 :
                                                                 <option value="10:00 PM">10:00 PM</option>
                                            }
                                                                </select>
                                                        </div>
                                                                </div>
                                                              </div>
                                            
                                                      <div className="slot_friday mb-2">
                                                              <label style={{fontWeight:'600', fontSize:'14px'}}>Friday</label>
                                                               <div className="row">
                                                                  <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                                                  <select className="form-control" value={friStartTimeEvChat}
                                                               onChange={(event) => { setFriStartTimeEvChat( event.target.value ); }}>
                                                          <option value="">Start</option>
                                                             {evFriLast > '04:00 PM'?
                                             <option value="04:00 PM" disabled>04:00 PM</option>   
                                                                 :
                                                                 <option value="04:00 PM">04:00 PM</option>    
                                            }
                                            
                                            {evFriLast > '05:00 PM'?
                                                                 <option value="05:00 PM" disabled>05:00 PM</option>
                                                                 :
                                                                 <option value="05:00 PM">05:00 PM</option>
                                            }
                                            {evFriLast > '06:00 PM'?
                                                                 <option value="06:00 PM" disabled>06:00 PM</option>
                                                                 :
                                                                 <option value="06:00 PM">06:00 PM</option>
                                            }
                                            {evFriLast > '07:00 PM'?
                                                                      <option value="07:00 PM" disabled>07:00 PM</option>
                                                                 :
                                                                 <option value="07:00 PM">07:00 PM</option>
                                            }
                                            {evFriLast > '08:00 PM'?
                                                                 <option value="08:00 PM" disabled>08:00 PM</option>
                                                                 :
                                                                 <option value="08:00 PM">08:00 PM</option>
                                            }
                                            {evFriLast > '09:00 PM'?
                                                                 <option value="09:00 PM" disabled>09:00 PM</option>
                                                                 :
                                                                 <option value="09:00 PM">09:00 PM</option>
                                            }
                                            {evFriLast > '10:00 PM'?
                                                                 <option value="10:00 PM" disabled>10:00 PM</option>
                                                                 :
                                                                 <option value="10:00 PM">10:00 PM</option>
                                            }
                                                                </select>
                                                          </div>
                                                          <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                                          <select className="form-control" value={friEndTimeEvChat} onChange={handleFriEndTimeEvChat}>
                                                          <option value="">End</option>
                                                          {evFriLast >= '04:00 PM'?
                                             <option value="04:00 PM" disabled>04:00 PM</option>   
                                                                 :
                                                                 <option value="04:00 PM">04:00 PM</option>    
                                            }
                                            
                                            {evFriLast >= '05:00 PM'?
                                                                 <option value="05:00 PM" disabled>05:00 PM</option>
                                                                 :
                                                                 <option value="05:00 PM">05:00 PM</option>
                                            }
                                            {evFriLast >= '06:00 PM'?
                                                                 <option value="06:00 PM" disabled>06:00 PM</option>
                                                                 :
                                                                 <option value="06:00 PM">06:00 PM</option>
                                            }
                                            {evFriLast >= '07:00 PM'?
                                                                      <option value="07:00 PM" disabled>07:00 PM</option>
                                                                 :
                                                                 <option value="07:00 PM">07:00 PM</option>
                                            }
                                            {evFriLast >= '08:00 PM'?
                                                                 <option value="08:00 PM" disabled>08:00 PM</option>
                                                                 :
                                                                 <option value="08:00 PM">08:00 PM</option>
                                            }
                                            {evFriLast >= '09:00 PM'?
                                                                 <option value="09:00 PM" disabled>09:00 PM</option>
                                                                 :
                                                                 <option value="09:00 PM">09:00 PM</option>
                                            }
                                            {evFriLast >= '10:00 PM'?
                                                                 <option value="10:00 PM" disabled>10:00 PM</option>
                                                                 :
                                                                 <option value="10:00 PM">10:00 PM</option>
                                            }
                                                                </select>
                                                          </div>
                                                        </div>
                                                        </div>            
                                            </div>
                                            
                                            
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 superadmin_doctortimeslots_weekendsslot">
                                                                <h3 style={{fontWeight:'700', fontSize:'18px', marginTop:'15px', marginBottom:'15px',backgroundColor:'#5CB04D', color: '#fff', padding:'10px',
                                                              borderRadius:'5px'}}>TIME SLOTS FOR WEEK ENDS</h3>          
                                                              <div className="slot_Saturday mb-2">
                                                              <label style={{fontWeight:'600', fontSize:'14px'}}>Saturday</label>
                                                               <div className="row">
                                                                  <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                                                  <select className="form-control" value={satStartTimeEvChat}
                                                               onChange={(event) => { setSatStartTimeEvChat( event.target.value ); }}>
                                                            <option value="">Start</option>
                                                              {evSatLast > '04:00 PM'?
                                             <option value="04:00 PM" disabled>04:00 PM</option>   
                                                                 :
                                                                 <option value="04:00 PM">04:00 PM</option>    
                                            }
                                            
                                            {evSatLast > '05:00 PM'?
                                                                 <option value="05:00 PM" disabled>05:00 PM</option>
                                                                 :
                                                                 <option value="05:00 PM">05:00 PM</option>
                                            }
                                            {evSatLast > '06:00 PM'?
                                                                 <option value="06:00 PM" disabled>06:00 PM</option>
                                                                 :
                                                                 <option value="06:00 PM">06:00 PM</option>
                                            }
                                            {evSatLast > '07:00 PM'?
                                                                      <option value="07:00 PM" disabled>07:00 PM</option>
                                                                 :
                                                                 <option value="07:00 PM">07:00 PM</option>
                                            }
                                            {evSatLast > '08:00 PM'?
                                                                 <option value="08:00 PM" disabled>08:00 PM</option>
                                                                 :
                                                                 <option value="08:00 PM">08:00 PM</option>
                                            }
                                            {evSatLast > '09:00 PM'?
                                                                 <option value="09:00 PM" disabled>09:00 PM</option>
                                                                 :
                                                                 <option value="09:00 PM">09:00 PM</option>
                                            }
                                            {evSatLast > '10:00 PM'?
                                                                 <option value="10:00 PM" disabled>10:00 PM</option>
                                                                 :
                                                                 <option value="10:00 PM">10:00 PM</option>
                                            }
                                                                </select>
                                                        </div>
                                                        <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                                        <select className="form-control" value={satEndTimeEvChat} onChange={handleSatEndTimeEvChat}>
                                                        <option value="">End</option>
                                                        {evSatLast >= '04:00 PM'?
                                             <option value="04:00 PM" disabled>04:00 PM</option>   
                                                                 :
                                                                 <option value="04:00 PM">04:00 PM</option>    
                                            }
                                            
                                            {evSatLast >= '05:00 PM'?
                                                                 <option value="05:00 PM" disabled>05:00 PM</option>
                                                                 :
                                                                 <option value="05:00 PM">05:00 PM</option>
                                            }
                                            {evSatLast >= '06:00 PM'?
                                                                 <option value="06:00 PM" disabled>06:00 PM</option>
                                                                 :
                                                                 <option value="06:00 PM">06:00 PM</option>
                                            }
                                            {evSatLast >= '07:00 PM'?
                                                                      <option value="07:00 PM" disabled>07:00 PM</option>
                                                                 :
                                                                 <option value="07:00 PM">07:00 PM</option>
                                            }
                                            {evSatLast >= '08:00 PM'?
                                                                 <option value="08:00 PM" disabled>08:00 PM</option>
                                                                 :
                                                                 <option value="08:00 PM">08:00 PM</option>
                                            }
                                            {evSatLast >= '09:00 PM'?
                                                                 <option value="09:00 PM" disabled>09:00 PM</option>
                                                                 :
                                                                 <option value="09:00 PM">09:00 PM</option>
                                            }
                                            {evSatLast >= '10:00 PM'?
                                                                 <option value="10:00 PM" disabled>10:00 PM</option>
                                                                 :
                                                                 <option value="10:00 PM">10:00 PM</option>
                                            }
                                                                </select>
                                                        </div>
                                                                </div>
                                                              </div> 
                                            
                                                              <div className="slot_sunday mb-2">
                                                              <label style={{fontWeight:'600', fontSize:'14px'}}>Sunday</label>
                                                               <div className="row">
                                                                  <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                                                  <select className="form-control" value={sunStartTimeEvChat}
                                                               onChange={(event) => { setSunStartTimeEvChat( event.target.value ); }}>
                                                                 <option value="">Start</option>
                                                                 {evSunLast > '04:00 PM'?
                                             <option value="04:00 PM" disabled>04:00 PM</option>   
                                                                 :
                                                                 <option value="04:00 PM">04:00 PM</option>    
                                            }
                                            
                                            {evSunLast > '05:00 PM'?
                                                                 <option value="05:00 PM" disabled>05:00 PM</option>
                                                                 :
                                                                 <option value="05:00 PM">05:00 PM</option>
                                            }
                                            {evSunLast > '06:00 PM'?
                                                                 <option value="06:00 PM" disabled>06:00 PM</option>
                                                                 :
                                                                 <option value="06:00 PM">06:00 PM</option>
                                            }
                                            {evSunLast > '07:00 PM'?
                                                                      <option value="07:00 PM" disabled>07:00 PM</option>
                                                                 :
                                                                 <option value="07:00 PM">07:00 PM</option>
                                            }
                                            {evSunLast > '08:00 PM'?
                                                                 <option value="08:00 PM" disabled>08:00 PM</option>
                                                                 :
                                                                 <option value="08:00 PM">08:00 PM</option>
                                            }
                                            {evSunLast > '09:00 PM'?
                                                                 <option value="09:00 PM" disabled>09:00 PM</option>
                                                                 :
                                                                 <option value="09:00 PM">09:00 PM</option>
                                            }
                                            {evSunLast > '10:00 PM'?
                                                                 <option value="10:00 PM" disabled>10:00 PM</option>
                                                                 :
                                                                 <option value="10:00 PM">10:00 PM</option>
                                            }
                                                                </select>
                                                        </div>
                                                        <div className="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                                                        <select className="form-control" value={sunEndTimeEvChat} onChange={handleSunEndTimeEvChat}>
                                                        <option value="">End</option>
                                                        {evSunLast >= '04:00 PM'?
                                             <option value="04:00 PM" disabled>04:00 PM</option>   
                                                                 :
                                                                 <option value="04:00 PM">04:00 PM</option>    
                                            }
                                            
                                            {evSunLast >= '05:00 PM'?
                                                                 <option value="05:00 PM" disabled>05:00 PM</option>
                                                                 :
                                                                 <option value="05:00 PM">05:00 PM</option>
                                            }
                                            {evSunLast >= '06:00 PM'?
                                                                 <option value="06:00 PM" disabled>06:00 PM</option>
                                                                 :
                                                                 <option value="06:00 PM">06:00 PM</option>
                                            }
                                            {evSunLast >= '07:00 PM'?
                                                                      <option value="07:00 PM" disabled>07:00 PM</option>
                                                                 :
                                                                 <option value="07:00 PM">07:00 PM</option>
                                            }
                                            {evSunLast >= '08:00 PM'?
                                                                 <option value="08:00 PM" disabled>08:00 PM</option>
                                                                 :
                                                                 <option value="08:00 PM">08:00 PM</option>
                                            }
                                            {evSunLast >= '09:00 PM'?
                                                                 <option value="09:00 PM" disabled>09:00 PM</option>
                                                                 :
                                                                 <option value="09:00 PM">09:00 PM</option>
                                            }
                                            {evSunLast >= '10:00 PM'?
                                                                 <option value="10:00 PM" disabled>10:00 PM</option>
                                                                 :
                                                                 <option value="10:00 PM">10:00 PM</option>
                                            }
                                                                </select>
                                                        </div>
                                                      </div>
                                                      </div>
                                                      </div>
                                                      </div>
  <hr />
  <div className="superadmin_doctortimeslots_savebtn" style={{textAlign:'center', marginTop:'10px'}}>

                       <button className="btn" style={{backgroundColor: '#105c25', color: '#fff', fontFamily:'poppins medium'}} onClick={weekdaysAndWeekenddaysChat}>Save</button>&emsp;
                        <button className="btn" style={{backgroundColor: '#3A4652', color: '#fff', fontFamily:'poppins medium'}} onClick={reset}>Cancel</button>
                        </div>
                        </>
                     :
                     null
        }                       
  
           

                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
</section>
    );

}
export default DoctorTimeSlots;
