import React from 'react';
import {BrowserRouter as Router , Route, Switch} from 'react-router-dom';
import signin from './component/auth/signin';
import profileView from './component/profile/profileView';
import viewDepartments from './component/profile/viewDepartments';
//import editDepartment from './component/profile/editDepartment';
//import createHOD from './component/hod/createHOD';
//import createDoctor from './component/doctor/createDoctor';
import doctorTimeSlots from './component/timeSlots/doctorTimeSlots';
import viewAppointment from './component/appointments/viewAppointment';
import editAdmin from './component/profile/editAdmin';
import viewAdminDoctor from './component/doctor/viewAdminDoctor';
import editAdminDoctor from './component/doctor/editAdminDoctor';
import timeSlots from './component/timeSlots/timeSlots';
import appoinments from './component/timeSlots/appoinments';

import viewPharmacy from './component/pharmacy/viewPharmacy';
import viewLaboratory from './component/laboratory/viewLaboratory';

import paymentDashboard from './component/payment/paymentDashboard';
import allTransactions from './component/allTransactions/allTransactions';

import appointmentSummary from './component/appointmentSummary/appointmentSummary';

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
    <div>
    <Switch>
    <Route exact path="/" component={signin} /> 
    <Route exact path="/profileView" component={profileView} /> 
    <Route exact path="/editAdmin" component={editAdmin} /> 
    <Route exact path="/viewDepartments" component={viewDepartments} /> 
   {/* <Route exact path="/editDepartment" component={editDepartment} />*/} 
   {/*} <Route exact path="/createHOD" component={createHOD} />*/} 
  {/*   <Route exact path="/createDoctor" component={createDoctor} /> */} 
    <Route exact path="/doctorTimeSlots" component={doctorTimeSlots} /> 
    <Route exact path="/viewAppointment" component={viewAppointment} /> 
    <Route exact path="/viewAdminDoctor" component={viewAdminDoctor} /> 
    <Route exact path="/editAdminDoctor" component={editAdminDoctor} /> 
    <Route exact path="/timeSlots" component={timeSlots} /> 
    <Route exact path="/appointments" component={appoinments} /> 
    <Route exact path="/viewPharmacy" component={viewPharmacy} /> 
    <Route exact path="/viewLaboratory" component={viewLaboratory} /> 
    <Route exact path="/appointmentSummary" component={appointmentSummary} /> 

    <Route exact path="/paymentDashboard" component={paymentDashboard} /> 
    <Route exact path="/allTransactions" component={allTransactions} /> 

    </Switch>
    </div>
    </Router>
  );
}

export default App;
