import React from 'react';
import { Link } from 'react-router-dom';
import './allTransactions.css';
import Navigation from '../navigation/navigation';

function AllTransactions() {
  return (
    <div className="DrJengu-All_Transaction">
        <Navigation />
          <div className="All_Transaction-Details">
          &nbsp; <Link to={"/paymentDashboard"}><i className="fa fa-arrow-circle-o-left fa-3x" style={{color: '#5CB04D', marginLeft: '10px'}}></i> </Link>
   
        <div className="container">
          <div className="All_Transaction-title">
            <h3>Mr. Johnson</h3>
            <span>Your all Appointment booking transaction details.</span>
          </div>
        
          <div className="All_Transaction-transaction-table">
            <div className="All_Transaction-transaction-title">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" style={{alignSelf:'center'}}>
                <h5>Transaction Details</h5>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 transaction-filter-search d-flex">
                  <select className="mx-2">
                    <option>All</option>
                    <option>Paid</option>
                    <option>Pending</option>
                    <option>Failed</option>
                  </select>
                  <div className="all-transaction-search mx-2">
                  <i className="fa fa-search mr-1" aria-hidden="true"></i>
                  <input type="text" placeholder="Search"/>
                  </div>
                  </div>
                
              </div>
             
            </div>
            <div className="all-transaction-table-info all-transaction-large-view-table">
            <table className="table table-striped border">
            <thead>
              <tr>
                <th>#</th>
                <th>Transaction</th>
               
                <th>Doctor Name</th>
                <th>Appointment Details</th>
                <th>Charges</th>
                <th>Status</th>
              </tr>
            </thead>
              <tbody>
                <tr>
                <td>1</td>
                <td className="alltransaction-id"><span className="alltransaction-hover"  data-toggle="modal" data-target="#AllTransaction-Details">ID: 24783736</span>
                <p className="m-0"><span>10AM</span>, <span>13 Nov 2020</span></p></td>
                
                <td>Dr. Dhinesh Kumar</td>

                <td className="alltransaction-appointment-details"  data-toggle="modal" data-target="#AllTransaction-Details"><i className="fa fa-user-md mx-2" data-toggle="tooltip" data-placement="bottom" title="In-Person Consultation" aria-hidden="true"></i>
                  <span>4PM</span>, <span>03 Nov 2020</span></td>
                <td><span style={{fontFamily:'roboto'}}>UGX</span> <span>650</span></td>
                <td className="alltransaction-payment-paid"><span>Paid</span></td>
              </tr>
              <tr>
                <td>2</td>
                <td className="alltransaction-id"><span className="alltransaction-hover">ID: 34885485</span>
                <p className="m-0"><span>9AM</span>, <span>13 Nov 2020</span></p></td>
               
                <td>Dr. Rahul</td>
                <td className="alltransaction-appointment-details"><i className="fa fa-video-camera mx-2" data-toggle="tooltip" data-placement="bottom" title="Video Consultation"  aria-hidden="true"></i>
                  <span>3PM</span>, <span>03 Nov 2020</span></td>
                <td><span style={{fontFamily:'roboto'}}>UGX</span> <span>600</span></td>
                <td className="alltransaction-payment-pending"><span>Pending</span></td>
              </tr>

              <tr>
                <td>3</td>
                <td className="alltransaction-id"><span className="alltransaction-hover">ID: 54845185</span>
                <p className="m-0"><span>8:40AM</span>, <span>16 Oct 2020</span></p></td>
               
                <td>Dr. Dhinesh Kumar</td>
                <td className="alltransaction-appointment-details"><i className="fa fa-video-camera mx-2" data-toggle="tooltip" data-placement="bottom" title="Video Consultation"  aria-hidden="true"></i>
                  <span>5PM</span>, <span>17 Oct 2020</span></td>
                <td><span style={{fontFamily:'roboto'}}>UGX</span> <span>650</span></td>
                <td className="alltransaction-payment-paid"><span>Paid</span></td>
              </tr>

              <tr>
                <td>4</td>
                <td className="alltransaction-id"><span className="alltransaction-hover">ID: 74582315</span>
                <p className="m-0"><span>8:20AM</span>, <span>18 Sep 2020</span></p></td>
                
                <td>Dr. Dhinesh Kumar</td>
                <td className="alltransaction-appointment-details"><i className="fa fa-comments-o mx-2" data-toggle="tooltip" data-placement="bottom" title="Live chat Consultation"  aria-hidden="true"></i>
                  <span>5:30PM</span>, <span>18 Sep 2020</span></td>
                <td><span style={{fontFamily:'roboto'}}>UGX</span> <span>450</span></td>
                <td className="alltransaction-payment-failed"><span>Failed</span></td>
              </tr>

              </tbody>
            
            </table>
            </div>
            <div className="all-transaction-mobile-view-table my-3">
              <div className="">
                <div className="alltransaction-m-data my-2" data-toggle="modal" data-target="#AllTransaction-Details">
                <div className="row">
                      <div className="col-3 p-2" style={{alignSelf:'center'}}>
                      <div className="alltransaction-m-charges-status p-1">
                      <div className="m-charges-status alltransaction-m-charges-status-paid">
                        <span style={{fontFamily:'roboto'}}>UGX</span> <span>650</span>
                        <p className="m-alltransaction-payment-paid"><span>Paid</span></p>
                        </div>
                   
                    </div>
                      </div>
                      <div className="col-5 p-2" style={{alignSelf:'center'}}>
                      <div className="alltransaction-m-transaction-details p-1">
                      <p className="m-alltransaction-id">ID: 24783736
                      <span className="alltransaction-m-transaction-date-time">&nbsp; 10AM, 13 Nov 2020</span>
                      </p>
                     
                      <p><i className="fa fa-user mx-1"aria-hidden="true"></i> Mr. Johnson</p>
                      <p><i className="fa fa-user-md mx-1"aria-hidden="true"></i> Dr. Dhinesh Kumar</p>
                      
                    </div>
                        </div>
                        <div className="col-4 p-2" style={{alignSelf:'center'}}>
                        <div className="m-alltransaction-appointment-details p-1">
                      <div className="alltransaction-m-appointment-date-time">
                      <i className="fa fa-user-md mx-1" aria-hidden="true"></i><br/> 
                      <span> 4PM</span><br/> <span>03 Nov 2020</span>
                      </div>
                    </div>
                        </div>
                    </div>
                </div>
                <div className="alltransaction-m-data my-2">
                  <div className="row">
                      <div className="col-3 p-2" style={{alignSelf:'center'}}>
                      <div className="alltransaction-m-charges-status p-1">
                      <div className=".m-charges-status alltransaction-m-charges-status-paid">
                        <span style={{fontFamily:'roboto'}}>UGX</span> <span>600</span>
                        <p className="m-alltransaction-payment-paid"><span>Paid</span></p>
                        </div>
                   
                    </div>
                      </div>
                      <div className="col-5 p-2" style={{alignSelf:'center'}}>
                      <div className="alltransaction-m-transaction-details p-1">
                      <p className="m-alltransaction-id">ID: 34885485
                      <span className="alltransaction-m-transaction-date-time">&nbsp; 9AM, 13 Nov 2020</span>
                      </p>
                     
                      <p><i className="fa fa-user mx-1"aria-hidden="true"></i> Mr. Arun</p>
                      <p><i className="fa fa-user-md mx-1"aria-hidden="true"></i> Dr. Rahul</p>
                      
                    </div>
                        </div>
                        <div className="col-4 p-2" style={{alignSelf:'center'}}>
                        <div className="m-alltransaction-appointment-details p-1">
                      <div className="alltransaction-m-appointment-date-time">
                      <i className="fa fa-video-camera mx-1" aria-hidden="true"></i><br/> 
                      <span> 3PM</span><br/> <span>03 Nov 2020</span>
                      </div>
                    </div>
                        </div>
                    </div>
                
                    </div> 
                    <div className="alltransaction-m-data my-2">
                  <div className="row">
                      <div className="col-3 p-2" style={{alignSelf:'center'}}>
                      <div className="alltransaction-m-charges-status p-1">
                      <div className=".m-charges-status m-charges-status-pending">
                        <span style={{fontFamily:'roboto'}}>UGX</span> <span>650</span>
                        <p className="m-alltransaction-payment-pending"><span>Pending</span></p>
                        </div>
                   
                    </div>
                      </div>
                      <div className="col-5 p-2" style={{alignSelf:'center'}}>
                      <div className="alltransaction-m-transaction-details p-1">
                      <p className="m-alltransaction-id">ID: 54845185
                      <span className="alltransaction-m-transaction-date-time">&nbsp; 8:40AM, 13 Nov 2020</span>
                      </p>
                     
                      <p><i className="fa fa-user mx-1"aria-hidden="true"></i> Mr. David</p>
                      <p><i className="fa fa-user-md mx-1"aria-hidden="true"></i> Dr. Arun Kumar</p>
                      
                    </div>
                        </div>
                        <div className="col-4 p-2" style={{alignSelf:'center'}}>
                        <div className="m-alltransaction-appointment-details p-1">
                      <div className="alltransaction-m-appointment-date-time">
                      <i className="fa fa-video-camera mx-1" aria-hidden="true"></i><br/> 
                      <span> 5PM</span><br/> <span>03 Nov 2020</span>
                      </div>
                    </div>
                        </div>
                    </div>
                
                    </div> 
                    <div className="alltransaction-m-data my-2">
                  <div className="row">
                      <div className="col-3 p-2" style={{alignSelf:'center'}}>
                      <div className="alltransaction-m-charges-status p-1">
                      <div className=".m-charges-status m-charges-status-failed">
                        <span style={{fontFamily:'roboto'}}>UGX</span> <span>450</span>
                        <p className="m-alltransaction-payment-failed"><span>Failed</span></p>
                        </div>
                   
                    </div>
                      </div>
                      <div className="col-5 p-2" style={{alignSelf:'center'}}>
                      <div className="alltransaction-m-transaction-details p-1">
                      <p className="m-alltransaction-id">ID: 74582315
                      <span className="alltransaction-m-transaction-date-time">&nbsp; 8:20AM, 13 Nov 2020</span>
                      </p>
                     
                      <p><i className="fa fa-user mx-1"aria-hidden="true"></i> Mr. Rajiv</p>
                      <p><i className="fa fa-user-md mx-1"aria-hidden="true"></i> Dr. Johnson</p>
                      
                    </div>
                        </div>
                        <div className="col-4 p-2" style={{alignSelf:'center'}}>
                        <div className="m-alltransaction-appointment-details p-1">
                      <div className="alltransaction-m-appointment-date-time">
                      <i className="fa fa-comments-o mx-1" aria-hidden="true"></i><br/> 
                      <span> 5:30PM</span><br/> <span>03 Nov 2020</span>
                      </div>
                    </div>
                        </div>
                    </div>
                
                    </div> 
               
              </div>
               
            </div>
         
          </div>
        </div>


        {/* Transaction details modal */}

        <div className="modal fade bd-example-modal-lg" id="AllTransaction-Details" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalCenterTitle" style={{fontFamily:'poppins semibold'}}>Transaction Details</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="alltransaction-fulldetails">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                      <h6 className="my-3">Doctor Name:  <span>&nbsp;&nbsp; Dr.Dhinesh Kumar</span></h6>
                      <div className="alltransaction-id-modal">
                        <h6>Transaction Id: <span>&nbsp;&nbsp; 24783736</span></h6>
                        
                      </div>
                    </div>
                   <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" style={{alignSelf:'center'}}>
                   <div className="alltransaction-date-time-modal">
                        <p className="mb-1"><i className="fa fa-clock-o mx-1"aria-hidden="true"></i> &nbsp;10AM</p>
                        <p className="mb-1"><i className="fa fa-calendar mx-1"aria-hidden="true"></i> &nbsp;13 Nov 2020</p>
                        </div>
                   </div>
                  </div>
                  <div className="alltransaction-appointment-details-modal mt-4">
                    <h5>Appointment Details</h5>
                    <hr/>
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                      <h6 className="my-3">Appointment Date & Time: &nbsp;&nbsp;<span> 4PM, 3 Nov 2020</span></h6>
                     
                     <h6 className="my-3">Appointment Type:&nbsp;&nbsp;  <span> In-Person consultation</span></h6>
                     <h6 className="my-3">Charges:&nbsp;&nbsp; <span style={{fontFamily:'roboto medium'}}>UGX 650</span></h6>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <h6>Status:</h6>
                        <span className="alltransaction-payment-paid"><span>Paid</span></span>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
           
            </div>
          </div>
        </div>

      </div>
     
    </div>
  );
}

export default AllTransactions;
