import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { auth, database, storage } from '../../config/firebase';
import {secondaryDatabase} from '../../config/firebaseSecondary';
import Navigation from '../navigation/navigation';
import './createDoctor.css';

function EditAdminDoctor() {
  const [email, setEmail] = useState("");
  const [validates, setvalidates] = useState(false);
    const [emailAddress, setEmailAddress] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [userName, setUserName] = useState("");
    const [adminEmail, setAdminEmail] = useState("");
    const [adminPassword, setAdminPassword] = useState("");
    const [adminName, setAdminName] = useState("");
    const [medicalCenterName, setAdminMedicalCenterName] = useState("");

     const [adminId, setAdminId] = useState('');
    const [address1, setAddress1] = useState("");
    const [password, setPassword] = useState("");
    const [datas, setDatas] = useState([]);
    const [departmentDatas, setDepartmentDatas] = useState([]);
    const [loading, setLoading] = useState(false);

    const [department, setDepartment] = useState([]);

    const [departmentData, setDepartmentData] = useState("");

     const [superAdminId, setSuperAdminId] = useState('');
    const [departmentId, setDepartmentId] = useState("");
    const [location, setLocation] = useState("");
    const [locationId, setLocationId] = useState("");
    const [doctorImageUrl, setDoctorImageUrl] = useState("");
    const [alertMessage, setAlertMessage] = useState(false);
    const [progress, setProgress] = useState(0);

    const [address, setAddress] = useState("");
    const [addressOne, setAddressOne] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [qualification, setQualification] = useState("");
    const [registrationNumber, setRegistrationNumber] = useState("");

    const [emailValidation, setEmailValidation] = useState(false);
    const [userNameMessage, setUserNameMessage] = useState(false);
    const [emailMessage, setEmailMessage] = useState(false);
    const [passwordMessage, setPasswordMessage] = useState(false);
    const [mobileNumberMessage, setMobileNumberMessage] = useState(false);
    const [addressMessage, setAddressMessage] = useState(false);
    const [stateMessage, setStateMessage] = useState(false);
    const [countryMessage, setCountryMessage] = useState(false);
    const [pinCodeMessage, setPinCodeMessage] = useState(false);
    const [qualificationMessage, setQualificationMessage] = useState(false);

    const [buttonEnable, setButtonEnable] = useState(false);

    const locationPass = useLocation();
    const history = useHistory();
    useEffect(() => {       
        const getUserData = async () => {
           await auth.onAuthStateChanged((user) => {
           if (user) {
             setAdminId(auth.currentUser.uid);
             setEmail(auth.currentUser.email);
             console.log(auth.currentUser.uid);
             console.log(auth.currentUser.email);
            
            let userId = user.uid;
            database.ref('admin/'+auth.currentUser.uid+'/').once('value', snap=>{
              setAdminEmail(snap.val().emailAddress);  
              setAdminPassword(snap.val().password); 
              setAdminName(snap.val().userName); 
              setAdminMedicalCenterName(snap.val().medicalCenterName);
            }).then(()=>{
                if(email == adminEmail){
//window.location.href = '/dashboard';
                }
                else{
                    setAlertMessage(true);
                }
            })

            database.ref('admin/'+locationPass.state.adminIdPass+'/departments/'+
            (locationPass.state.departmentIdPass)+'/doctors/'+(locationPass.state.doctorIdPass)+'/').once('value', snap=>{
              setUserName(snap.val().doctorName);
              setRegistrationNumber(snap.val().registrationNumber);  
              setEmailAddress(snap.val().emailAddress); 
              setMobileNumber(snap.val().mobileNumber); 
              setAddress(snap.val().address);
              setAddressOne(snap.val().addressOne);
              setState(snap.val().state);
              setCountry(snap.val().country);
              setPinCode(snap.val().pinCode);
              setLocation(snap.val().branchPlace);
              setDoctorImageUrl(snap.val().doctorImageUrl); 
              setQualification(snap.val().qualification);
            })

            database.ref().child('locations/').once('value',function(snap) {
              let returnArray =[];
                snap.forEach(function(item) {
                
                  returnArray.push(item.val());
 console.log(returnArray);
 
                });
                 
            setDatas(returnArray); 
            });

            
             }
             else {
               window.location.href = '/';
             }
          });  
        }

      
          getUserData(); 
   
       },[]);

       let validate = (text) => {
        console.log(text);
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ ;
        if(reg.test(text) === false)
        {
        console.log("Email is Not Correct");
        setEmailAddress(text);
        setvalidates(false);
        return false;
          }
        else {
          setEmailAddress(text);
          setvalidates(true);
          console.log("Email is Correct");
        }
        }

let handleImageChange = (e) => {
                e.preventDefault();
            
                let reader = new FileReader();
                let file = e.target.files[0];
            
                reader.onloadend = () => {
                  let name = moment().unix()+file.name;
                  let name1 = file.name;
                  let ext = name1.split('.').pop();
                 // this.setState({ext: ext || '' });
            
                  const uploadTask = storage.ref(`doctor/${locationPass.state.doctorIdPass}/profile/${name}`).put(file);
            
                  uploadTask.on('state_changed', (snapshot) => {
                    // progrss function ....
                    const progresses = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgress(progresses);
                  },
                  (error) => {
                       // error function ....
                    console.log(error);
                  },
                () => {
            
                    // complete function ....
                    storage.ref(`doctor/${locationPass.state.doctorIdPass}/profile/`).child(name).getDownloadURL().then(url => {
                        console.log(url);
                        setDoctorImageUrl(url);
                    })
                });
                }
                reader.readAsDataURL(file)
                e.target.value = null;
              }

let submit = e => {
    e.preventDefault();
    setButtonEnable(true);
  if(mobileNumber.length == ''){
          setMobileNumberMessage(true);
          setButtonEnable(false);
      }
      else if(qualification == ''){
        setQualificationMessage(true);
        setButtonEnable(false);
    }
      else if(address == ''){
        setAddressMessage(true);
        setButtonEnable(false);
      }
        else if(state == ''){
            setStateMessage(true);
            setButtonEnable(false);
        }
        else if(country == ''){
          setCountryMessage(true);
          setButtonEnable(false);
      }
        else if(pinCode.length == ''){
            setPinCodeMessage(true);
            setButtonEnable(false);
        }
      else{
    database.ref('superAdmin/'+(locationPass.state.superAdminIdPass)+'/admin/'+(locationPass.state.adminIdPass)
    +'/departments/'+(locationPass.state.departmentIdPass)+'/doctors/'+(locationPass.state.doctorIdPass)+'/').
    update({
    mobileNumber: mobileNumber,

editedAt: moment().unix(),
doctorImageUrl: doctorImageUrl,
address: address.replace(/((\s*\S+)*)\s*/, "$1"),
addressOne: addressOne.replace(/((\s*\S+)*)\s*/, "$1"),
country: country.replace(/((\s*\S+)*)\s*/, "$1"),
state: state.replace(/((\s*\S+)*)\s*/, "$1"),
pinCode: pinCode,
qualification: qualification.replace(/((\s*\S+)*)\s*/, "$1")
});
      database.ref('admin/'+(locationPass.state.adminIdPass)+'/departments/'+
      (locationPass.state.departmentIdPass)+'/doctors/'+(locationPass.state.doctorIdPass)+'/').update({
        
        mobileNumber: mobileNumber,
    
    editedAt: moment().unix(),
    doctorImageUrl: doctorImageUrl,
    address: address.replace(/((\s*\S+)*)\s*/, "$1"),
    addressOne: addressOne.replace(/((\s*\S+)*)\s*/, "$1"),
    country: country.replace(/((\s*\S+)*)\s*/, "$1"),
    state: state.replace(/((\s*\S+)*)\s*/, "$1"),
    pinCode: pinCode,
    qualification: qualification.replace(/((\s*\S+)*)\s*/, "$1")
});
database.ref('users/'+(locationPass.state.doctorIdPass)+'/').update({

  mobileNumber: mobileNumber,

editedAt: moment().unix(),
doctorImageUrl: doctorImageUrl,
address: address.replace(/((\s*\S+)*)\s*/, "$1"),
addressOne: addressOne.replace(/((\s*\S+)*)\s*/, "$1"),
country: country.replace(/((\s*\S+)*)\s*/, "$1"),
state: state.replace(/((\s*\S+)*)\s*/, "$1"),
pinCode: pinCode,
qualification: qualification.replace(/((\s*\S+)*)\s*/, "$1")
})
secondaryDatabase.ref('doctorsNew/'+(locationPass.state.doctorIdPass)+'/').update({

  mobileNumber: mobileNumber,

editedAt: moment().unix(),
doctorImageUrl: doctorImageUrl,
address: address.replace(/((\s*\S+)*)\s*/, "$1"),
addressOne: addressOne.replace(/((\s*\S+)*)\s*/, "$1"),
country: country.replace(/((\s*\S+)*)\s*/, "$1"),
state: state.replace(/((\s*\S+)*)\s*/, "$1"),
pinCode: pinCode,
qualification: qualification.replace(/((\s*\S+)*)\s*/, "$1")
}).then(()=>{
  alert('Doctor is updated successfully.')
 // window.location.href = '/createHOD';
 history.push({
  pathname: 'viewAdminDoctor',
  state: {doctorIdPass: locationPass.state.doctorIdPass,
    departmentIdPass: locationPass.state.departmentIdPass,
  adminIdPass: locationPass.state.adminIdPass,
superAdminIdPass:locationPass.state.superAdminIdPass}
})
})
  
setButtonEnable(false); 
}
}

let reset = e => {
    e.preventDefault();
    history.push({
      pathname: 'viewAdminDoctor',
      state: {doctorIdPass: locationPass.state.doctorIdPass,
        departmentIdPass: locationPass.state.departmentIdPass,
      adminIdPass: locationPass.state.adminIdPass,
    superAdminIdPass:locationPass.state.superAdminIdPass}
    })
}



return (
  <section style={{backgroundColor: '#fff', overflow: 'auto', minHeight: '100%', fontFamily: 'poppins medium'}}>
<Navigation />
<div className="admincreatedocview">
      <div className="container">
          <div className="superadmincreatedoc_details" 
          style={{marginTop:'100px', marginBottom:'20px', backgroundColor:'white', boxShadow:' 0px 0 5px rgba(0, 0, 0, 0.3)', borderRadius:'10px',
         }}>
          <div className="superadmincreatedoc_title" style={{backgroundColor:'#105c25', color:'white', padding:'17px',
          borderTopLeftRadius:'10px', borderTopRightRadius:'10px'}}>
            <h3 style={{fontWeight:'700', fontSize:'24px', marginLeft:'5px', marginBottom:'0px'}}>EDIT DOCTOR</h3>          
           </div>
            <div className="row" style={{display:'flex', justifyContent:'center', padding:'30px 50px'}}>
              <div className="">
                <div className="superadmincreatedoc_info">
                  <div className="">
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label style={{fontWeight:'600', fontSize:'14px'}}>DOCTOR NAME </label>
       
      <input type="text" className="form-control" id="userName" style={{borderRadius:'25px', textIndent:'10px'}} 
      placeholder="Doctor Name" name="userName" value={userName} 
      onChange={(event) => { setUserName( event.target.value ); }} readOnly/>
      
      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label style={{fontWeight:'600', fontSize:'14px'}}>REGISTRATION NUMBER </label>
       
      <input type="text" className="form-control" id="registrationNumber" style={{borderRadius:'25px', textIndent:'10px'}} 
      placeholder="Registration Number" name="registrationNumber" value={registrationNumber} 
      onChange={(event) => { setRegistrationNumber( event.target.value ); }} readOnly/>
      
      </div>


      </div>

      <div className="row mt-2">
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label style={{fontWeight:'600', fontSize:'14px'}}>EMAIL ADDRESS </label>
       
      <input type="text" className="form-control" id="emailAddress" style={{borderRadius:'25px', textIndent:'10px'}} 
      placeholder="Email Address" name="emailAddress" value={emailAddress} 
      onChange={(event) => setEmailAddress(event.target.value)} readOnly/>
      
      </div>
      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label style={{fontWeight:'600', fontSize:'14px'}}>MOBILE NUMBER </label>
       
      <input type="tel" className="form-control" id="mobileNumber" style={{borderRadius:'25px', textIndent:'10px'}}
      placeholder="Mobile Number" name="mobileNumber" value={mobileNumber} 
      onChange={(event) => {
        if (isNaN(Number(event.target.value))) {
          return;
        } else {
          setMobileNumber( event.target.value );
        }
      }} maxLength="11" required/>
      
      </div>
      </div>
      
      <div className="row mt-2">

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label style={{fontWeight:'600', fontSize:'14px'}}>QUALIFICATION </label>
       
      <input type="text" className="form-control" id="qualification" style={{borderRadius:'25px', textIndent:'10px'}} 
      placeholder="Qualification" name="qualification" value={qualification} 
      onChange={(event) => { setQualification( event.target.value ); }} required/>
      
      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>LOCATION</label>
                        <input type="text" className="form-control" id="location" style={{borderRadius:'25px', textIndent:'10px'}}
      placeholder="Location" name="location" value={location} readOnly/>
</div>
</div>

      <div className="row mt-2">
                  
      <div className="col-12">
                          <label style={{fontWeight:'600', fontSize:'14px'}}>ADDRESS</label>
       
      <input type="text" className="form-control" id="address" style={{borderRadius:'25px', textIndent:'10px', marginBottom: '10px'}}
      placeholder="Address Line 1" name="address" value={address} onChange={(event) => { setAddress( event.target.value ); }} required/>
      
      <input type="text" className="form-control" id="addressOne" style={{borderRadius:'25px', textIndent:'10px'}}
                  placeholder="Address Line 2" name="addressOne" value={addressOne} onChange={(event) => { setAddressOne( event.target.value ); }}/>

      </div>
      </div>

      <div className="row mt-2">
      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>STATE</label>
  
       
      <input type="text" className="form-control" id="state" style={{borderRadius:'25px', textIndent:'10px'}}
      placeholder="State" name="state" value={state} onChange={(event) => { setState( event.target.value ); }} required/>
      
      </div>
      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <label style={{fontWeight:'600', fontSize:'14px'}}>COUNTRY</label>
       
      <input type="text" className="form-control" id="country" style={{borderRadius:'25px', textIndent:'10px'}}
      placeholder="Country" name="country" value={country} onChange={(event) => { setCountry( event.target.value ); }} required/>
      
      </div>
      </div>
      
      <div className="row mt-2">

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <label style={{fontWeight:'600', fontSize:'14px'}}>PIN CODE</label>
  
       
      <input type="tel" maxLength="6" className="form-control" id="pinCode" style={{borderRadius:'25px', textIndent:'10px'}}
      placeholder="Pin Code" name="pinCode" value={pinCode}
      onChange={(event) => {
        if (isNaN(Number(event.target.value))) {
          return;
        } else {
          setPinCode( event.target.value );
        }
      }} required/>
      
      </div>
      </div>

      <div className="superadmincreatedoc_uploadimage">
                    <h3 style={{fontWeight:'700', fontSize:'18px', marginTop:'15px', marginBottom:'15px',backgroundColor:'#5CB04D', color: '#fff', padding:'10px',
                    borderRadius:'5px'
                    }}>PROFILE IMAGE</h3>    
                  </div>



{doctorImageUrl == ''?
<div id='profile-upload'>
<div className="hvr-profile-img">
<input type="file" id="files" style={{color: 'transparent', visibility: 'hidden'}} onChange={handleImageChange} className="upload w180" accept="image/*" capture="camera" />
  </div>

<label for="files" className="fa"><b>Upload</b></label>
 
 </div>
 :
 <div id='profile-upload1'> 
 <img src={doctorImageUrl} className="avatar" style={{height: '150px', width: '150px', backgroundSize: 'cover', backgroundPosition: 'center', position: 'relative', objectFit: 'contain'}} />
 
 <div className="hvr-profile-img1">
  <input type="file" id="files" style={{color: 'transparent', visibility: 'hidden'}} onChange={handleImageChange} className="upload w180" accept="image/*" capture="camera" />
  </div> <label for="files" className="fa"><b>Update</b></label> {/* <i className="fa fa-camera"></i>*/} 
  </div> 
}

<br />
{progress == 0?
null:
                    <div className="col-12 my-2" style={{backgroundColor: 'black', borderRadius: '13px', padding: '3px'}}>

  <div style={{width: `${(progress)}%`, color: 'white', backgroundColor: '#0269D9', height: '20px', borderRadius: '10px'}}> 
   <center>{progress}%</center></div>
</div>
  }

{mobileNumberMessage == true?
        
        <div className='alert alert-danger alert-dismissible'>
                <button type='button' className='close' onClick={() => setPasswordMessage(false)}>&times;</button>
    <strong>Mobile number!</strong> Please enter 10 digit number.
    </div>
    :
    null
    }

{qualificationMessage == true?
  
  <div className='alert alert-danger alert-dismissible'>
          <button type='button' className='close' onClick={() => setQualificationMessage(false)}>&times;</button>
<strong>Warning!</strong> Please enter qualification.
</div>

:
null
}

{addressMessage == true?

<div className='alert alert-danger alert-dismissible'>
        <button type='button' className='close' onClick={() => setAddressMessage(false)}>&times;</button>
        <strong>Warning!</strong> Please enter address.
</div>

:
null
}

{stateMessage == true?

<div className='alert alert-danger alert-dismissible'>
        <button type='button' className='close' onClick={() => setStateMessage(false)}>&times;</button>
        <strong>Warning!</strong> Please enter state.
</div>

:
null
}

{countryMessage == true?

<div className='alert alert-danger alert-dismissible'>
        <button type='button' className='close' onClick={() => setCountryMessage(false)}>&times;</button>
        <strong>Warning!</strong> Please enter country.
</div>

:
null
}

{pinCodeMessage == true?

<div className='alert alert-danger alert-dismissible'>
        <button type='button' className='close' onClick={() => setPinCodeMessage(false)}>&times;</button>
        <strong>Warning!</strong> Please enter 6 digit pin code.
</div>

:
null
}


<hr/>
                  <div className="superadmincreatedoc_savebtn" style={{textAlign:'center', marginTop:'10px'}}>
                   <button className="btn" style={{backgroundColor: '#105c25', color: '#fff', fontFamily:'poppins medium'}} onClick={submit}>Save</button>&emsp;
                        <button className="btn" style={{backgroundColor: '#3A4652', color: '#fff', fontFamily:'poppins medium'}} onClick={reset}>Cancel</button>
                      
                   </div>
    
                   </div>
    </div>
      </div>
    </div>
    </div>
    </div>              
    </div> 
</section>
    );

}
export default EditAdminDoctor;
