import React, { useState, useEffect } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import moment from 'moment';
import { auth, database, storage } from '../../config/firebase';
import Navigation from '../navigation/navigation';
import './profileView.css'

function ProfileView() {
  const [email, setEmail] = useState("");
    const [adminEmail, setAdminEmail] = useState("");
    const [adminPhoneNumber, setAdminPhoneNumber] = useState("");
    const [adminName, setAdminName] = useState("");
    const [adminMedicalCenterName, setAdminMedicalCenterName] = useState("");

    const [departmentDatas, setDepartmentDatas] = useState("");

    const [adminId, setAdminId] = useState('');

     const [superAdminId, setSuperAdminId] = useState('');

    const [location, setLocation] = useState("");
    const [alertMessage, setAlertMessage] = useState(false);
    const [progress, setProgress] = useState("");
    const [adminImageUrl, setAdminImageUrl] = useState("");

    const [address, setAddress] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [addressOne, setAddressOne] = useState("");
    const [loading, setLoading] = useState(false);

    let history = useHistory();
    useEffect(() => {       
        const getUserData = async () => {
           await auth.onAuthStateChanged((user) => {
           if (user) {
             setAdminId(auth.currentUser.uid);
             setEmail(auth.currentUser.email);
             console.log(auth.currentUser.uid);
             console.log(auth.currentUser.email);
            
            let userId = user.uid;
            database.ref('admin/'+auth.currentUser.uid+'/').once('value', snap=>{
                setAdminEmail(snap.val().emailAddress);  
                setAdminPhoneNumber(snap.val().mobileNumber); 
                setAdminName(snap.val().userName); 
                setAdminMedicalCenterName(snap.val().medicalCenterName);
                setLocation(snap.val().branchPlace);
                setDepartmentDatas(snap.val().departments || '');
                setAdminImageUrl(snap.val().adminImageUrl || '');
                setSuperAdminId(snap.val().assignedByUid);
                setAddress(snap.val().address || '');
                setAddressOne(snap.val().addressOne || '');
                setState(snap.val().state || '');
                setCountry(snap.val().country || '');
                setPinCode(snap.val().pinCode || '');
                setLoading(true);
            }).then(()=>{
                if(email == adminEmail){
//window.location.href = '/dashboard';
                }
                else{
                    setAlertMessage(true);
                }
            })

            
             }
             else {
               window.location.href = '/';
             }
          });  
        }

      
          getUserData(); 
   
       },[]);
    
let addDoctors = (data, data1, data2, data3) => {
  history.push({
      pathname: 'createDoctor',
      state: {departmentIdPass: data,
        superAdminIdPass:data1,
        adminIdPass:data2,
      departmentNamePass: data3}
  })
}

let addHod = (data, data1, data2, data3) => {
  history.push({
      pathname: 'createHod',
      state: {departmentIdPass: data,
    superAdminIdPass:data1,
    adminIdPass:data2,
  departmentNamePass: data3}
  })
}

let editAdmin = (data, data1) => {
  history.push({
      pathname: 'editAdmin',
      state: {
      superAdminIdPass: data,
      adminIdPass:data1,
  }
})
}

return (
  <section style={{backgroundColor: '#ffffff', overflow: 'auto', minHeight: '100%'}}>
  <Navigation />
  <div className="Superadmin_profileview">
      <div className="Superadmin_profileview_section">
          <div className="py-2 mb-4" style={{marginTop:'70px'}}>
            <h3 className="Superadmin_profileview_title text-center" style={{fontFamily:'poppins extrabold', color: '#11772e'}}>Admin Profile</h3>         

          </div>
          <div className="container my-4" style={{backgroundColor:'#fff', padding:'25px', borderRadius:'10px', boxShadow:'2px 2px 7px rgba(22, 153, 59, 0.9)'}}>
          {loading == true?
        <>
        <div className="row m-0" style={{display:'flex',alignItems:'center'}}>
          <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">

<div className="Superadmin_ProfileImage text-center">
                      
{adminImageUrl == ''?
<img src={require('./hospital.jpg')} style={{borderRadius:'14px',width:'60%', height: '18vh', border:'3px solid #ccc', objectFit: 'fill'}}/>
:
<img src={adminImageUrl} style={{borderRadius:'14px',width:'60%', height: '18vh', border:'3px solid #ccc', objectFit: 'fill'}}/>
}

                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 my-1" style={{overflow:'hidden'}}>
            <div className="Superadmin_profile_name" style={{overflow:'hidden', textOverflow: 'ellipsis'}}>
              <span className="" style={{fontFamily:'poppins semibold', fontSize:'22px', whiteSpace: 'nowrap', color: '#11772e'}}>
              {adminName}
              </span>
            </div>
            <div className="Superadmin_profile_email">
              <span className="text-secondary" style={{fontFamily:'poppins medium', fontSize:'15px'}}>
              {adminEmail}
              </span>
            </div>

            <div className="Superadmin_profile_phone">
            {adminPhoneNumber == ''?
                  null:
                <img src={require('./phone1.png')} width="15px"/>
                }
              <span className="text-secondary" style={{fontFamily:'poppins medium', fontSize:'15px'}}>
              {adminPhoneNumber == ''?
                  null:
                <>
                {adminPhoneNumber}
                </>
                }
              
              </span>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 my-1">
            <div className="Superadmin_profile_hospital">
              <div className="Superadmin_profile_hospital_name" style={{overflow:'hidden', textOverflow: 'ellipsis'}}>
              <span className="" style={{fontFamily:'poppins Semibold', fontSize:'22px', whiteSpace: 'nowrap', color: '#11772e'}}>
              {adminMedicalCenterName}&nbsp;
              </span>
              </div>
              <div>
              <span className="text-secondary" style={{fontFamily:'poppins Semibold', fontStyle: 'italic', fontSize:'16px'}}>
              {location == ''?
              null
            :
            <>({location})</>
            }
              </span>
              </div>
              <div className="Superadmin_profile_hopital_address" style={{overflow:'hidden', textOverflow: 'ellipsis'}}>
              <span className="text-secondary" style={{fontFamily:'poppins medium', fontSize:'15px'}}>
              {address == ''?
null
:
             <> {address}&nbsp;</>}
                            {addressOne == ''?
null
:
              <>{addressOne}</>}
              </span>


              <br/>
              <span className="text-secondary" style={{fontFamily:'poppins medium', fontSize:'15px'}}>
              {state == ''?
null
:
<>{state}&nbsp;</>}
              
              </span>
              <span className="text-secondary" style={{fontFamily:'poppins medium'}}>
              {pinCode == ''?
null
:
              <>{pinCode}&nbsp;</>}
             
              </span>
              <span className="text-secondary" style={{fontFamily:'poppins medium', fontSize:'15px'}}>
              {country == ''?
null
:
<>{country}</>}
             
              </span><br/>
            

            </div>
            </div>

          </div>

          <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
    <div className="Superadmin_ProfileEditbutton">
    <button className="btn px-4 my-2" onClick={() => editAdmin(superAdminId, adminId)} 
     style={{border:'2px solid #105c25', fontFamily:'poppins medium', color:'white', backgroundImage: 'linear-gradient(to right, #11772e 10%, #105c25)',boxShadow:'2px 2px 7px rgba(22, 153, 59, 0.9)'}}><i className="fa fa-edit"aria-hidden="true"></i> Edit</button>
    </div>
    </div>
    </div>
    <hr/>
                      
      
    <div className="Superadmin_Profile_department my-4">
          <div className="Superadmin_Profile_department my-3">
          <h3 className="" style={{fontFamily:'poppins extrabold',fontSize:'20px', color: '#11772e'}}>Department</h3>
          </div>
                  {departmentDatas == ''?
                  <h6>No departments added</h6>
                  :
                
                  <div className="row m-0" style={{display:'flex', fontFamily:'poppins'}}>
      {Object.values(departmentDatas).filter((person1) => person1.checked === true).map((persons,index) =>
     

       <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 p-1 mb-2 text-center" style={{backgroundColor: index%2 == 0?
       '': '#eee'
}}>
         <div className="Superadmin_Profile_department_name" style={{backgroundColor: index%2 == 0?
         '#105c25': '#276c3a'
}}
        >
        <h6 style={{fontWeight:'700',fontSize:'14px', color:'#fff', textAlign:'center', padding:'15px',overflow:'hidden', textOverflow: 'ellipsis'}}>
             {persons.departmentName}</h6>
            </div>
       

      {persons.hodName == null || undefined?
      <div className="p-1">
      <span style={{fontSize:'16px',fontWeight:'600',overflow:'hidden', textOverflow: 'ellipsis'}}>
        HOD is not added</span>
      </div>
:
<div className="p-1">
<span style={{fontSize:'16px',fontWeight:'600',overflow:'hidden', textOverflow: 'ellipsis'}}>
{persons.hodName}</span>
            </div>
      }

<div style={{marginTop: '10px', marginBottom:'10px', backgroundColor: '#5CB04D', padding: '7px'}}>
              <span class="ml-1" style={{fontWeight: '700', color: '#fff', fontSize: '14px', margin: '5px', borderRadius: '5px'}}>Doctors</span>
              </div>
      {persons.doctors == null || undefined? 
        <div className="my-2">
      <div className="p-1">
      <span style={{fontSize:'14px',fontWeight:'500',overflow:'hidden', textOverflow: 'ellipsis'}}>No doctors</span>
            </div>
            </div>
      :
      <div>
      {Object.values(persons.doctors).map((personss,indexs)=> 
        <div className="my-2">
      <div className="p-1 text-left">
         <span style={{fontSize:'14px',fontWeight:'500',overflow:'hidden', textOverflow: 'ellipsis'}}>Dr. {personss.doctorName}</span>
            </div>
            </div>
      )}
      </div>
}
      </div>
      
      )}
     
      </div>
}


      </div>
      </>
    :
<center>
<div className="lds-roller">
<div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
</center>
}
    </div>

    </div>
    <br />
    </div>               
      
</section>
    );

}
export default ProfileView;
