import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { auth, database, storage } from '../../config/firebase';
import {secondaryDatabase} from '../../config/firebaseSecondary';
import chart1 from './img/chart-1.png';
import chart2 from './img/chart-2.png';
import './paymentDashboard.css';
import Navigation from '../navigation/navigation';

function PaymentDashboard() {
  const [email, setEmail] = useState("");
  const [adminId, setAdminId] = useState('');
    const [adminEmail, setAdminEmail] = useState("");
    const [adminPassword, setAdminPassword] = useState("");
    const [adminName, setAdminName] = useState("");
    const [adminMedicalCenterName, setAdminMedicalCenterName] = useState("");
    const [datas, setDatas] = useState([]);
    const [loading, setLoading] = useState(false);

    const [departmentDatas, setDepartmentDatas] = useState("");
    const [address, setAddress] = useState("");

    const [date1, setDate1] = useState(moment().format("DD-MM-YYYY"));

    const locationPass = useLocation();
    let history = useHistory();
    useEffect(() => {       
        const getUserData = async () => {
           await auth.onAuthStateChanged((user) => {
           if (user) {
             setAdminId(auth.currentUser.uid);
             setEmail(auth.currentUser.email);
             console.log(auth.currentUser.uid);
             console.log(auth.currentUser.email);
            
            let userId = user.uid;
            database.ref('admin/'+auth.currentUser.uid+'/').once('value', snap=>{
              setAdminEmail(snap.val().emailAddress);  
              setAdminPassword(snap.val().password); 
              setAdminName(snap.val().userName); 
              setAdminMedicalCenterName(snap.val().medicalCenterName);
              setDepartmentDatas(snap.val().departments || '');
               
                setAddress(snap.val().address);
            }).then(()=>{
                if(email == adminEmail){
//window.location.href = '/dashboard';
                }
                else{
                  window.location.href = '/';
                }
            })

            database.ref('transactions').orderByChild('transactionAt').on('value',function(snap) {
              let returnArray =[];
                snap.forEach(function(item) {
                
                  returnArray.push(item.val());
 console.log(returnArray);
 
                });
                 
            setDatas(returnArray); 
            setLoading(true);
            });

            
             }
             else {
               window.location.href = '/';
             }
          });  
        }

      
          getUserData(); 
   
       },[]);

       let dateFormats = (date) => {
        var data = moment(date, 'DD/M/Y').format('DD -MMMM Y');
        return data;
      }

      let dateFormatss = (date) => {
        var data = moment.unix(date).format('DD -MMMM Y');
        return data;
      }

      let timeFormats = (date) => {
        var data = moment.unix(date).format('hh:mm A');
        return data;
      }

  return (
    <div className="Jengu-PaymentDashboard">
      <Navigation/>
      <div className="PaymentDashboard-Details">
        <div className="container">
          <div className="PaymentDashboard-filter" style={{display:'flex', justifyContent:'flex-end'}}>
            <select className="p-2 mb-2">
              <option>All</option>
              <option>Today</option>
              <option selected>Last 1 Week</option>
              <option>Last 1 Month</option>
            </select>
          </div>
          <div className="PaymentDashboard-title">
            <h3>Payment Dashboard</h3>
            <span>Appointment booking payment transaction informations.</span>
          </div>
          <div className="PaymentDashboard-transaction-info my-3">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 p-2">
                <div className="total-transaction">
                  <span>Total Transactions</span>
                  <div className="d-flex" style={{justifyContent:'space-between'}}>
                  <h3>242</h3>
                  {/* <i className="fa fa-bar-chart" aria-hidden="true"></i> */}
                  <img className="mx-2" src={chart1} width="30px" height="35px"/>
                  </div>
                  <span className="today-update">Today +17</span>
                </div>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 p-2">
                <div className="successful-transaction">
                  <span>Successful Transactions</span>
                  <div className="d-flex" style={{justifyContent:'space-between'}}>
                  <h3>229</h3>
                  {/* <i className="fa fa-bar-chart" aria-hidden="true"></i> */}
                  <img className="mx-2" src={chart2} width="30px" height="35px"/>
                  </div>
                  <span className="today-update">Today +16</span>
                </div>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 p-2">
                <div className="total-payments">
                  <span className="total-payments-toptext">Total Payments</span>
                  <div className="d-flex" style={{justifyContent:'space-between'}}>
                  <h3>UGX <span>71,520</span></h3>
                  {/* <i className="fa fa-bar-chart" aria-hidden="true"></i> */}
                  <img className="mx-2" src={chart2} width="30px" height="35px"/>
                  </div>
                  <span className="today-update">Today +5700</span>
                </div>
              </div>

            </div>
          </div>

          <div className="PaymentDashboard-transaction-table">
            <div className="PaymentDashboard-transaction-title">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" style={{alignSelf:'center'}}>
                <h5>Transaction Details</h5>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 transaction-filter-search d-flex">
                  <select className="mx-2">
                    <option>All</option>
                    <option>Paid</option>
                    <option>Pending</option>
                    <option>Failed</option>
                  </select>
                  <div className="transaction-search mx-2">
                  <i className="fa fa-search mr-1" aria-hidden="true"></i>
                  <input type="text" placeholder="Search"/>
                  </div>
                  </div>
                
              </div>
             
            </div>
            <div className="transaction-table-info large-view-table">
            <table className="table table-striped border">
            <thead>
              <tr>
                <th>#</th>
                <th>Transaction</th>
                <th>Patient Name</th>
                <th>Doctor Name</th>
                <th>Appointment Details</th>
                <th>Charges</th>
                <th>Status</th>
              </tr>
            </thead>
              <tbody>
              {Object.values(datas).reverse().map((person, index) =>
                <tr>
                <td>{index +1}</td>
                <td className="transaction-id">
                
                  <span className="transactionid-hover"  data-toggle="modal" data-target="#Transaction-Details">
                    ID: {person.doctorAppBookedIndex}{person.doctorAppBookedKey} 
                    </span>
                
                    <p className="m-0"><span>{timeFormats(person.transactionAt)}</span>, <span>{dateFormatss(person.transactionAt).substring(0,2)}&nbsp;{dateFormatss(person.transactionAt).substring(4,7)}&nbsp;{dateFormatss(person.transactionAt).substr(-4,4)}</span></p></td>
                <td> <Link to={"/allTransactions"} style={{color: '#105c25'}}>Mr. {person.patientName}</Link></td>
                <td>Dr. {person.doctName}</td>

                <td className="appointment-details"  data-toggle="modal" data-target="#Transaction-Details">
                 {person.visitingType == 'inPersonConsultation'?
                  <i className="fa fa-user-md mx-2" data-toggle="tooltip" data-placement="bottom" title="In-Person Consultation" aria-hidden="true"></i>
                  :
                  null
                  }
                                   {person.visitingType == 'videoConsultation'?
                  <i className="fa fa-user-md mx-2" data-toggle="tooltip" data-placement="bottom" title="In-Person Consultation" aria-hidden="true"></i>
                  :
                  null
                  }
                                   {person.visitingType == 'liveChatConsultation'?
                 <i className="fa fa-comments-o mx-2" data-toggle="tooltip" data-placement="bottom" title="Live chat Consultation"  aria-hidden="true"></i>
                  :
                  null
                  }
                  <span>{person.selTime}</span>, <span>{dateFormats(person.selDate).substring(0,2)}&nbsp;{dateFormats(person.selDate).substring(4,7)}&nbsp;{dateFormats(person.selDate).substr(-4,4)}</span></td>
                <td><span style={{fontFamily:'roboto'}}>{person.consultationSymbol}</span> <span>{person.consultationFees}</span></td>
                <td className="payment-paid"><span>Paid</span></td>
              </tr>
                )}
              <tr>
                <td>2</td>
                <td className="transaction-id"><span className="transactionid-hover">ID: 34885485</span>
                <p className="m-0"><span>9AM</span>, <span>13 Nov 2020</span></p></td>
                <td><Link to={"/allTransactions"} style={{color: '#105c25'}}>Mr. Arun</Link></td>
                <td>Dr. Rahul</td>
                <td className="appointment-details"><i className="fa fa-video-camera mx-2" data-toggle="tooltip" data-placement="bottom" title="Video Consultation"  aria-hidden="true"></i>
                  <span>3PM</span>, <span>03 Nov 2020</span></td>
                <td><span style={{fontFamily:'roboto'}}>UGX</span> <span>600</span></td>
                <td className="payment-paid"><span>Paid</span></td>
              </tr>

              <tr>
                <td>3</td>
                <td className="transaction-id"><span className="transactionid-hover">ID: 54845185</span>
                <p className="m-0"><span>8:40AM</span>, <span>13 Nov 2020</span></p></td>
                <td><Link to={"/allTransactions"} style={{color: '#105c25'}}>Mr. David</Link></td>
                <td>Dr. Arun Kumar</td>
                <td className="appointment-details"><i className="fa fa-video-camera mx-2" data-toggle="tooltip" data-placement="bottom" title="Video Consultation"  aria-hidden="true"></i>
                  <span>5PM</span>, <span>03 Nov 2020</span></td>
                <td><span style={{fontFamily:'roboto'}}>UGX</span> <span>650</span></td>
                <td className="payment-pending"><span>Pending</span></td>
              </tr>

              <tr>
                <td>4</td>
                <td className="transaction-id"><span className="transactionid-hover">ID: 74582315</span>
                <p className="m-0"><span>8:20AM</span>, <span>13 Nov 2020</span></p></td>
                <td><Link to={"/allTransactions"} style={{color: '#105c25'}}>Mr. Rajiv</Link></td>
                <td>Dr. Johnson</td>
                <td className="appointment-details"><i className="fa fa-comments-o mx-2" data-toggle="tooltip" data-placement="bottom" title="Live chat Consultation"  aria-hidden="true"></i>
                  <span>5:30PM</span>, <span>03 Nov 2020</span></td>
                <td><span style={{fontFamily:'roboto'}}>UGX</span> <span>450</span></td>
                <td className="payment-failed"><span>Failed</span></td>
              </tr>

              </tbody>
            
            </table>
            </div>
            <div className="mobile-view-table my-3">
              <div className="">
                <div className="m-transaction-data my-2" data-toggle="modal" data-target="#Transaction-Details">
                <div className="row">
                      <div className="col-3 p-2" style={{alignSelf:'center'}}>
                      <div className="m-transaction-charges-status p-1">
                      <div className=".m-charges-status m-charges-status-paid">
                        <span style={{fontFamily:'roboto'}}>UGX</span> <span>650</span>
                        <p className="m-payment-paid"><span>Paid</span></p>
                        </div>
                   
                    </div>
                      </div>
                      <div className="col-5 p-2" style={{alignSelf:'center'}}>
                      <div className="m-transaction-details p-1">
                      <p className="m-transaction-id">ID: 24783736
                      <span className="m-transaction-date-time">&nbsp; 10AM, 13 Nov 2020</span>
                      </p>
                     
                      <p><i className="fa fa-user mx-1"aria-hidden="true"></i> Mr. Johnson</p>
                      <p><i className="fa fa-user-md mx-1"aria-hidden="true"></i> Dr. Dhinesh Kumar</p>
                      
                    </div>
                        </div>
                        <div className="col-4 p-2" style={{alignSelf:'center'}}>
                        <div className="m-appointment-details p-1">
                      <div className="m-appointment-date-time">
                      <i className="fa fa-user-md mx-1" aria-hidden="true"></i><br/> 
                      <span> 4PM</span><br/> <span>03 Nov 2020</span>
                      </div>
                    </div>
                        </div>
                    </div>
                </div>
                <div className="m-transaction-data my-2">
                  <div className="row">
                      <div className="col-3 p-2" style={{alignSelf:'center'}}>
                      <div className="m-transaction-charges-status p-1">
                      <div className=".m-charges-status m-charges-status-paid">
                        <span style={{fontFamily:'roboto'}}>UGX</span> <span>600</span>
                        <p className="m-payment-paid"><span>Paid</span></p>
                        </div>
                   
                    </div>
                      </div>
                      <div className="col-5 p-2" style={{alignSelf:'center'}}>
                      <div className="m-transaction-details p-1">
                      <p className="m-transaction-id">ID: 34885485
                      <span className="m-transaction-date-time">&nbsp; 9AM, 13 Nov 2020</span>
                      </p>
                     
                      <p><i className="fa fa-user mx-1"aria-hidden="true"></i> Mr. Arun</p>
                      <p><i className="fa fa-user-md mx-1"aria-hidden="true"></i> Dr. Rahul</p>
                      
                    </div>
                        </div>
                        <div className="col-4 p-2" style={{alignSelf:'center'}}>
                        <div className="m-appointment-details p-1">
                      <div className="m-appointment-date-time">
                      <i className="fa fa-video-camera mx-1" aria-hidden="true"></i><br/> 
                      <span> 3PM</span><br/> <span>03 Nov 2020</span>
                      </div>
                    </div>
                        </div>
                    </div>
                
                    </div> 
                    <div className="m-transaction-data my-2">
                  <div className="row">
                      <div className="col-3 p-2" style={{alignSelf:'center'}}>
                      <div className="m-transaction-charges-status p-1">
                      <div className=".m-charges-status m-charges-status-pending">
                        <span style={{fontFamily:'roboto'}}>UGX</span> <span>650</span>
                        <p className="m-payment-pending"><span>Pending</span></p>
                        </div>
                   
                    </div>
                      </div>
                      <div className="col-5 p-2" style={{alignSelf:'center'}}>
                      <div className="m-transaction-details p-1">
                      <p className="m-transaction-id">ID: 54845185
                      <span className="m-transaction-date-time">&nbsp; 8:40AM, 13 Nov 2020</span>
                      </p>
                     
                      <p><i className="fa fa-user mx-1"aria-hidden="true"></i> Mr. David</p>
                      <p><i className="fa fa-user-md mx-1"aria-hidden="true"></i> Dr. Arun Kumar</p>
                      
                    </div>
                        </div>
                        <div className="col-4 p-2" style={{alignSelf:'center'}}>
                        <div className="m-appointment-details p-1">
                      <div className="m-appointment-date-time">
                      <i className="fa fa-video-camera mx-1" aria-hidden="true"></i><br/> 
                      <span> 5PM</span><br/> <span>03 Nov 2020</span>
                      </div>
                    </div>
                        </div>
                    </div>
                
                    </div> 
                    <div className="m-transaction-data my-2">
                  <div className="row">
                      <div className="col-3 p-2" style={{alignSelf:'center'}}>
                      <div className="m-transaction-charges-status p-1">
                      <div className=".m-charges-status m-charges-status-failed">
                        <span style={{fontFamily:'roboto'}}>UGX</span> <span>450</span>
                        <p className="m-payment-failed"><span>Failed</span></p>
                        </div>
                   
                    </div>
                      </div>
                      <div className="col-5 p-2" style={{alignSelf:'center'}}>
                      <div className="m-transaction-details p-1">
                      <p className="m-transaction-id">ID: 74582315
                      <span className="m-transaction-date-time">&nbsp; 8:20AM, 13 Nov 2020</span>
                      </p>
                     
                      <p><i className="fa fa-user mx-1"aria-hidden="true"></i> Mr. Rajiv</p>
                      <p><i className="fa fa-user-md mx-1"aria-hidden="true"></i> Dr. Johnson</p>
                      
                    </div>
                        </div>
                        <div className="col-4 p-2" style={{alignSelf:'center'}}>
                        <div className="m-appointment-details p-1">
                      <div className="m-appointment-date-time">
                      <i className="fa fa-comments-o mx-1" aria-hidden="true"></i><br/> 
                      <span> 5:30PM</span><br/> <span>03 Nov 2020</span>
                      </div>
                    </div>
                        </div>
                    </div>
                
                    </div> 
               
              </div>
               
            </div>
         
          </div>
        </div>


        {/* Transaction details modal */}

        <div className="modal fade bd-example-modal-lg" id="Transaction-Details" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalCenterTitle" style={{fontFamily:'poppins semibold'}}>Transaction Details</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="transaction-fulldetails">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                      <h6 className="my-3">Patient Name: <span>&nbsp;&nbsp; Mr.Johnson</span></h6>
                     
                      <h6 className="my-3">Doctor Name:  <span>&nbsp;&nbsp; Dr.Dhinesh Kumar</span></h6>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <div className="transaction-id-modal">
                        <h6>Transaction Id:</h6>
                        <p>24783736</p>
                        <div className="transaction-date-time-modal">
                        <p><i className="fa fa-clock-o mx-1"aria-hidden="true"></i> &nbsp;10AM</p>
                        <p><i className="fa fa-calendar mx-1"aria-hidden="true"></i> &nbsp;13 Nov 2020</p>
                        </div>
                     
                      </div>
                    </div>
                  </div>
                  <div className="appointment-details-modal mt-3">
                    <h5>Appointment Details</h5>
                    <hr/>
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                      <h6 className="my-3">Appointment Date & Time: &nbsp;&nbsp;<span> 4PM, 3 Nov 2020</span></h6>
                     
                     <h6 className="my-3">Appointment Type:&nbsp;&nbsp;  <span> In-Person consultation</span></h6>
                     <h6 className="my-3">Charges:&nbsp;&nbsp; <span style={{fontFamily:'roboto medium'}}>₹ 650</span></h6>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <h6>Status:</h6>
                        <span className="payment-paid"><span>Paid</span></span>
                      </div>
                    </div>
                  </div>
                </div>
              {/*}  <div className="view-more-transactions" style={{textAlign:'end', fontFamily:'poppins medium'}}>
                  <p data-dismiss="modal">View More Transactions</p>
                </div>*/}
              </div>
           
            </div>
          </div>
        </div>

      </div>
     
    </div>
  );
}

export default PaymentDashboard;
