import * as firebase from 'firebase';

var config = {
  apiKey: "AIzaSyA2sVyxG6sW1SMptR5GEjrdP1nth5lLo3c",
  authDomain: "casedoctordb.firebaseapp.com",
  databaseURL: "https://casedoctordb.firebaseio.com",
  projectId: "casedoctordb",
  storageBucket: "casedoctordb.appspot.com",
  messagingSenderId: "22439180119",
  appId: "1:22439180119:web:59f2642cea1d334f727012",
  measurementId: "G-V7JMDWCZ8Y"
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(config);
  }
  
    const database = firebase.database();
    const auth = firebase.auth();
    const storage = firebase.storage();
  
    export {database, auth, storage};
 
